import { UseQueryOptions } from 'react-query';
import useQuery from 'hooks/useQuery';
import QueryKeys from 'hooks/queries/keys';
import { DataVisUrl } from 'models/types';

const useDataVisDashboardUrl = (
    isPowerBIEnabled: boolean,
    dashboard: string,
    reactQueryOptions?: UseQueryOptions<DataVisUrl, Error>
) => {
    const dashboardQuery = isPowerBIEnabled
        ? QueryKeys.POWERBI_DATA_VIS_DASHBOARD_URL(dashboard)
        : QueryKeys.DATA_VIS_DASHBOARD_URL(dashboard);
    return useQuery<DataVisUrl>(dashboardQuery, dashboardQuery, reactQueryOptions);
};
export default useDataVisDashboardUrl;
