import Card from 'pages/InsightsPage/dashboards/Card';
import PieChart from 'pages/InsightsPage/visualizations/PieChart';
import useContactSubStatus from './useContactSubStatus';

const SubscriptionStatusCard = () => {
    const { data, isError, isLoading } = useContactSubStatus();

    return (
        <Card isError={isError} isLoading={isLoading} label="Subscription Status">
            <PieChart data={data} />
        </Card>
    );
};

export default SubscriptionStatusCard;
