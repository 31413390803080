import { Box, CSSObject, Stack, Tooltip, Typography } from '@mui/material';

export type Props = {
    header: string;
    description?: React.ReactNode;
    descriptionIcon?: React.ReactNode;
    descriptionIconTooltip?: string;
    footer?: React.ReactNode;
    isActive?: boolean;
};

const DetailItem = ({ header, description, descriptionIcon, descriptionIconTooltip, footer, isActive }: Props) => {
    const activeSx: CSSObject = {
        color: isActive ? 'secondary.main' : 'primary.main',
    };

    const DescriptionIcon = (
        <Box
            sx={{
                ...activeSx,
                '&:hover': {
                    cursor: 'pointer',
                },
                mt: 0.5,
            }}
        >
            {description && descriptionIcon}
        </Box>
    );

    return (
        <Stack rowGap={0.5}>
            <Typography variant="caption" color="action.active" textTransform="capitalize">
                {header}
            </Typography>
            <Stack rowGap={0.5}>
                <Stack direction="row" alignItems="flex-start" columnGap={1.5}>
                    {typeof description === 'string' ? (
                        <Typography data-testid={header} variant="body1" sx={activeSx}>
                            {description}
                        </Typography>
                    ) : (
                        description
                    )}
                    {descriptionIconTooltip ? (
                        <Tooltip
                            title={
                                <Typography variant="body2" color="white">
                                    {descriptionIconTooltip}
                                </Typography>
                            }
                            placement="top"
                            data-testid="tooltip"
                        >
                            {DescriptionIcon}
                        </Tooltip>
                    ) : (
                        DescriptionIcon
                    )}
                </Stack>
                {footer}
            </Stack>
        </Stack>
    );
};

export default DetailItem;
