import React, { useState } from 'react';
import {
    Box,
    FormControl,
    FormHelperText,
    InputBase,
    InputLabel,
    MenuItem,
    Select as MUISelect,
    SelectProps,
    Theme,
    Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import { SxProps } from '@mui/system';

// TODO: rename Value to Option to clarify between Option object and Option.value string/number
export type Value = {
    label: React.ReactNode;
    value: any;
    disabled?: boolean;
};

export type Props = SelectProps & {
    error?: boolean;
    helperText?: string;
    label?: string | JSX.Element;
    labelStyles?: object;
    menuItemStyles?: object;
    onHover?: (item: any) => void;
    size?: 'small' | 'medium';
    StartAdornmentComponent?: ({ sx }: { sx?: SxProps<Theme>; [other: string]: any }) => JSX.Element;
    topItem?: React.ReactNode;
    values: Value[];
};

const baseBorderStyle = {
    borderRadius: '6px',
};

const StyledInput = styled(InputBase)(({ theme }) => ({
    '&.Mui-disabled .MuiSelect-select': {
        ...baseBorderStyle,
        borderColor: theme.palette.action.disabled,
        borderStyle: 'dashed',
        boxShadow: 'none',
    },
    '&.Mui-error .MuiSelect-select': {
        ...baseBorderStyle,
        borderColor: theme.palette.error.main,
        boxShadow: `1px 1px 0px ${theme.palette.error.main}`,
    },
    '&.Mui-focused .MuiSelect-select': {
        ...baseBorderStyle,
        borderColor: theme.palette.action.active,
        boxShadow: `1px 1px 0px ${theme.palette.action.active}`,
    },
    '&:hover .MuiSelect-select': {
        ...baseBorderStyle,
        borderColor: theme.palette.action.active,
        boxShadow: `1px 1px 0px ${theme.palette.action.active}`,
    },
    '& .MuiSelect-select.MuiInputBase-inputAdornedStart': {
        paddingLeft: '48px',
    },
}));

const SmallInputLabel = styled(InputLabel)(({ theme }) => ({
    '&.MuiInputLabel-shrink': {
        marginTop: 8,
    },
    '&.Mui-focused': {
        color: theme.palette.action.active,
    },
    '&.Mui-error': {
        color: theme.palette.error.main,
    },
}));

const MediumInputLabel = styled(InputLabel)(({ theme }) => ({
    '&.MuiInputLabel-shrink': {
        top: '0px',
    },
    '&.Mui-focused': {
        color: theme.palette.action.active,
    },
    '&.Mui-error': {
        color: theme.palette.error.main,
    },
}));

const getLabelTextColor = (focused: boolean, error: boolean, disabled: boolean): string => {
    if (error) {
        return 'error.main';
    }
    if (disabled) {
        return 'text.disabled';
    }
    if (focused) {
        return 'action.active';
    }
    return 'text.primary';
};

const getHelperTextColor = (error: boolean, disabled: boolean): string => {
    if (error) {
        return 'error.main';
    }
    if (disabled) {
        return 'text.disabled';
    }
};

export const getOptionValues = (options: Value[]) => options.map(({ value }) => value as string);

/**
 * Cured-styled select component. Inherits all props from `MUISelect`.
 *
 * @param error If `true`, the label is displayed in an error state.
 * @param fullWidth If `true`, the form control is displayed in full width.
 * @param helperText The helper text to display below the select.
 * @param label The label for the select.
 * @param labelStyles Pass custom styles for the select's menu items.
 * @param menuItemStyles Pass custom styles for the select's menu items.
 * @param size The size of the select height.
 * @param StartAdornmentComponent The start adornment component.
 * @param sx the MUI sx prop, applied to the Select component.
 * @param topItem The first item in the select.
 * @param values The values to display as options in the select.
 *
 * @returns The React node created by this component.
 */
const Select = ({
    disabled = false,
    error = false,
    fullWidth = true,
    helperText,
    label,
    labelStyles,
    menuItemStyles,
    onHover,
    size = 'medium',
    StartAdornmentComponent,
    sx,
    topItem = null,
    values = [],
    ...other
}: Props) => {
    const [inputFocused, setInputFocused] = useState(false);
    const InputLabelBasedOnSize = size === 'small' ? SmallInputLabel : MediumInputLabel;

    return (
        <FormControl fullWidth={fullWidth} sx={sx}>
            {label && (
                <Box sx={{ ...(size === 'small' && { pb: 1 }) }}>
                    <InputLabelBasedOnSize
                        data-testid="Select-label"
                        id="select-label"
                        sx={{
                            color: getLabelTextColor(inputFocused, error, disabled),
                            borderRadius: 2,
                            top: '-4px',
                            ...labelStyles,
                        }}
                        error={error}
                    >
                        {label}
                    </InputLabelBasedOnSize>
                </Box>
            )}
            <MUISelect
                label={label}
                labelId="select-label"
                id="select"
                IconComponent={ExpandMoreIcon}
                input={
                    <StyledInput
                        size={size}
                        startAdornment={
                            StartAdornmentComponent ? (
                                <StartAdornmentComponent
                                    sx={{ position: 'absolute', left: 16, ...(disabled && { color: 'text.disabled' }) }}
                                />
                            ) : undefined
                        }
                        onFocus={() => {
                            if (!inputFocused) {
                                setInputFocused(true);
                            }
                        }}
                        onMouseOver={(e: any) => {
                            const value = e.target.getAttribute('data-value');
                            if (value) {
                                onHover && onHover(value);
                            }

                            if (!inputFocused) {
                                setInputFocused(true);
                            }
                        }}
                        onBlur={() => {
                            if (inputFocused) {
                                setInputFocused(false);
                            }
                            onHover && onHover(null);
                        }}
                        onMouseLeave={() => {
                            if (inputFocused) {
                                setInputFocused(false);
                            }
                        }}
                    />
                }
                disabled={disabled}
                error={error}
                sx={sx}
                {...other}
            >
                {topItem}
                {values.map((option) => (
                    <MenuItem
                        sx={{ textTransform: 'capitalize', ...menuItemStyles }}
                        key={option.value}
                        value={option.value}
                        disabled={option.disabled}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </MUISelect>
            {helperText && (
                <FormHelperText data-testid="Select-helper-text" error={error} sx={{ alignSelf: 'flex-end' }}>
                    <Typography variant="helperText" sx={{ color: getHelperTextColor(error, disabled) }}>
                        {helperText}
                    </Typography>
                </FormHelperText>
            )}
        </FormControl>
    );
};

export default Select;
