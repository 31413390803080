import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { InfoOutlined, SmsOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import Dialog from 'domains/core/components/DialogStandard';
import TextField from 'domains/core/components/TextField';
import SuccessSnackbar from 'domains/core/components/Snackbars/SuccessSnackbar';
import useSendTestSMS from 'hooks/mutations/useSendTestSMS';

const selectContent = (state: any) => state.curation.sms.content;
const selector = createSelector(selectContent, (content) => ({
    content,
}));

type Props = {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    onClose: () => void;
};

type FormData = {
    phoneNumbers: string;
};

const SMSTestSendDialog = ({ isOpen, setIsOpen, onClose }: Props) => {
    const { content: message } = useSelector(selector);

    const {
        data: sendTestSMSData,
        error: sendTestSMSError,
        isError: isSendTestSMSError,
        isLoading: isSendTestSMSLoading,
        isSuccess: isSendTestSMSSuccess,
        mutate: sendTestSMS,
        reset: resetSendTestSMS,
    } = useSendTestSMS({
        onSuccess: (sendTestSMSData) => {
            if (sendTestSMSData.invalidNumbers.length > 0) {
                setHasInvalidNumbers(true);
                setInvalidNumbers(sendTestSMSData.invalidNumbers);
                setIsErrorDialogOpen(true);
            }
        },
        onError: (error, sendTestSMSInput) => {
            setHasInvalidNumbers(true);
            setInvalidNumbers(sendTestSMSInput.to);
            setIsErrorDialogOpen(true);
        },
    });

    const { control, handleSubmit } = useForm<FormData>({
        mode: 'onTouched',
    });

    const [hasInvalidNumbers, setHasInvalidNumbers] = useState(false);
    const [invalidNumbers, setInvalidNumbers] = useState([]);
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

    useEffect(() => {
        if (isOpen) {
            resetSendTestSMS();
        }
    }, [isOpen, resetSendTestSMS]);

    const formatPhoneNumbers = (numbers: string) => {
        const split = numbers.split(', ').filter(Boolean);
        const trim = split.map((number) => number.trim());
        const parse = trim.map((number) => {
            const hasLetters = /[a-zA-Z]/.test(number);
            let parsedNumber = '';
            try {
                parsedNumber = parsePhoneNumber(number, 'US').number;
            } catch (e) {}
            return hasLetters ? '' : parsedNumber;
        });
        return parse;
    };

    const isPhoneNumbersValid = (numbers: string[]) => {
        for (const number of numbers) {
            if (!isValidPhoneNumber(number)) {
                return false;
            }
        }
        return true;
    };

    const isPhoneNumbersMobile = (numbers: string[]) => {
        for (const number of numbers) {
            const parsedNumber = parsePhoneNumber(number, 'US');

            if (parsedNumber.getType() !== 'FIXED_LINE_OR_MOBILE') {
                return false;
            }
        }
        return true;
    };

    const onSubmit = (data: FormData) => {
        const to = formatPhoneNumbers(data.phoneNumbers);

        sendTestSMS({
            to,
            message,
        });
        onClose();
    };

    return (
        <>
            {isSendTestSMSSuccess && !hasInvalidNumbers && (
                <SuccessSnackbar successMessage="You've successfully sent a test sms." />
            )}
            {isSendTestSMSSuccess && hasInvalidNumbers && (
                <Dialog
                    icon={<SmsOutlined />}
                    title="partial failure"
                    body={
                        <>
                            <Typography variant="body1">{`${sendTestSMSData.errorMessage}`}</Typography>
                            <Typography variant="body1">{`${invalidNumbers.join(', ')}`}</Typography>
                        </>
                    }
                    buttonOneText="continue"
                    buttonTwoText="try again"
                    buttonOneOnClick={() => {
                        onClose();
                        setIsErrorDialogOpen(false);
                    }}
                    buttonTwoOnClick={() => {
                        setIsOpen(true);
                        setIsErrorDialogOpen(false);
                    }}
                    isOpen={isErrorDialogOpen}
                    onClose={onClose}
                />
            )}
            {isSendTestSMSError && (
                <Dialog
                    icon={<SmsOutlined />}
                    title="full failure"
                    body={
                        <>
                            <Typography variant="body1">{`${sendTestSMSError.message}`}</Typography>
                            <Typography variant="body1">{`${invalidNumbers.join(', ')}`}</Typography>
                        </>
                    }
                    buttonOneText="continue"
                    buttonTwoText="try again"
                    buttonOneOnClick={() => {
                        onClose();
                        setIsErrorDialogOpen(false);
                    }}
                    buttonTwoOnClick={() => {
                        setIsOpen(true);
                        setIsErrorDialogOpen(false);
                    }}
                    isOpen={isErrorDialogOpen}
                    onClose={onClose}
                />
            )}
            <Dialog
                icon={<SmsOutlined />}
                title="send test sms"
                body={
                    <Box sx={{ pt: 3, pb: 6 }}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Controller
                                control={control}
                                defaultValue=""
                                name="phoneNumbers"
                                render={({ field: { onBlur, onChange, value }, fieldState: { error, invalid } }) => {
                                    const helperText = error?.message;
                                    const helperTextMultipleNumbers = 'use commas to separate multiple phone numbers';

                                    return (
                                        <TextField
                                            error={invalid}
                                            fullWidth
                                            helperText={invalid ? helperText : helperTextMultipleNumbers}
                                            label="Send to"
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            value={value}
                                        />
                                    );
                                }}
                                rules={{
                                    required: 'please enter recipient phone number(s)',
                                    validate: {
                                        validNumber: (value) =>
                                            isPhoneNumbersValid(formatPhoneNumbers(value)) ||
                                            'please enter recipient phone number(s) in valid format',
                                        notMobileNumber: (value) =>
                                            isPhoneNumbersMobile(formatPhoneNumbers(value)) ||
                                            'please enter mobile phone number(s) for recipients',
                                    },
                                }}
                            />
                        </form>
                    </Box>
                }
                buttonOneText="cancel"
                buttonTwoText="send"
                buttonTwoProps={{ disabled: isSendTestSMSLoading }}
                buttonOneOnClick={onClose}
                buttonTwoOnClick={handleSubmit(onSubmit)}
                isOpen={isOpen}
                onClose={onClose}
                actionIcon={<InfoOutlined />}
                actionIconTooltip="test sends will not include opt-out messaging"
            />
        </>
    );
};

export default SMSTestSendDialog;
