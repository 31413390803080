import { useEffect, useState } from 'react';
import { FilterValuesState, SendForFilter } from 'pages/InsightsPage/dashboards/filtering/types';
import FilterAutocompleteInput from 'pages/InsightsPage/dashboards/filtering/FilterAutocompleteInput';

type Props = {
    data: SendForFilter[];
    disabled: boolean;
    filterValues: FilterValuesState;
    setFilterValues: (filters: FilterValuesState) => void;
};

const SendsFilter = ({ data, disabled, filterValues, setFilterValues }: Props) => {
    const [sendsToDisplay, setSendsToDisplay] = useState(data);

    const selectedSends = filterValues?.selectedSendsValue;

    // Filter sends to display based on selected specialty
    useEffect(() => {
        let sends = data;

        if (filterValues?.selectedSpecialtiesValue?.length) {
            sends = sends?.filter((send: SendForFilter) =>
                filterValues.selectedSpecialtiesValue.includes(send.specialty)
            );
        }

        setSendsToDisplay(sends);
    }, [data, filterValues]);

    return (
        <FilterAutocompleteInput
            disabled={disabled}
            label="Send Name"
            onChange={(newValue: any) => {
                const filteredSends = newValue.map((value: any) =>
                    sendsToDisplay.find((send: SendForFilter) => send.send_id === value.id)
                );
                setFilterValues({
                    ...filterValues,
                    selectedSendsValue: filteredSends,
                });
            }}
            options={sendsToDisplay}
            selectedValues={selectedSends}
        />
    );
};

export default SendsFilter;
