import { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import Button from 'domains/core/components/Button';
import logoImage from 'assets/images/svg/cured-logo-light.svg';
import loginErrorImage from 'assets/images/svg/innovaccer-login-error.svg';
import useConfig from 'hooks/useConfig';
import { themeColors } from 'colors';

type InnovaccerLoginErrorProps = {
    redirectUrl: string;
    isSessionExpired: boolean;
};

const InnovaccerLoginError = ({ redirectUrl, isSessionExpired }: InnovaccerLoginErrorProps) => {
    const {
        config: { INNOVACCER_ERROR_REDIRECT_TIMEOUT },
    } = useConfig();

    const defaultRedirectTimeout = INNOVACCER_ERROR_REDIRECT_TIMEOUT ?? 10;
    const [timeToRedirect, setTimetoRedirect] = useState(defaultRedirectTimeout);

    const isValidRedirectUrl = redirectUrl !== '';

    useEffect(() => {
        if (!isValidRedirectUrl) return;
        const loadTime = Date.now();
        const interval = setInterval(() => {
            const seconds = Math.floor((Date.now() - loadTime) / 1000);
            const setSeconds = timeToRedirect - seconds < 0 ? 0 : timeToRedirect - seconds;
            setTimetoRedirect(setSeconds);
        }, 500);
        return () => {
            clearInterval(interval);
        };
    }, [timeToRedirect, isValidRedirectUrl]);

    useEffect(() => {
        if (timeToRedirect === 0) {
            window.location.href = redirectUrl;
        }
    }, [timeToRedirect, redirectUrl]);

    const renderValidRedirectUrlCase = () => (
        <Stack direction="column" justifyContent="flex-start" alignItems="center" height="70%">
            <Typography variant="caption" color="primary.main" mt={3}>
                login error
            </Typography>
            <Typography variant="h3" mt={3}>
                {isSessionExpired ? 'your datashop session expired' : 'were unable to log you in'}
            </Typography>
            <Box mt={4} height="40%" width="80%" display="flex" flexDirection="column" alignItems="center">
                {renderLoginHelperText()}
            </Box>
        </Stack>
    );

    const renderLoginHelperText = () => {
        if (isSessionExpired) {
            return (
                <>
                    <Typography variant="body2" align="center" mt={1}>
                        you need to launch cured app from datashop again
                    </Typography>
                    <Typography variant="body2" align="center" mt={1}>
                        this can be due to a session timeout, invalid credentials, or an authorization issue
                    </Typography>
                    <Typography variant="body2" align="center" mt={1}>
                        you will be redirected to the innovaccer login page in <b>{String(timeToRedirect)}</b> seconds
                        or you can navigate directly there through the link above.
                    </Typography>
                    <Box mt={8} height="40%" width="80%" display="flex" flexDirection="column" alignItems="center">
                        <Button variant="contained" onClick={() => (window.location.href = redirectUrl)}>
                            login
                        </Button>
                    </Box>
                </>
            );
        }
        return (
            <>
                <Typography variant="body2" align="center" mt={1}>
                    this can be due to a session timeout, invalid credentials, or an authorisation issue
                </Typography>
                <Typography variant="body2" align="center" mt={1}>
                    you will be redirected to the innovaccer login page in <b>{String(timeToRedirect)}</b> seconds or
                    you can navigate directly there through the link below.
                </Typography>
                <Box mt={10} height="40%" width="80%" display="flex" flexDirection="column" alignItems="center">
                    <Button variant="contained" onClick={() => (window.location.href = redirectUrl)}>
                        login
                    </Button>
                </Box>
            </>
        );
    };

    const renderInvalidRedirectUrlCase = () => (
        <Stack direction="column" justifyContent="flex-start" alignItems="center" height="70%">
            <Stack direction="column" justifyContent="center" alignItems="center" height="50%" width="90%">
                <Typography variant="h3" mt={1}>
                    how did you even get here?
                </Typography>
            </Stack>
            <Stack direction="column" justifyContent="flex-start" alignItems="center" height="50%" width="100%"></Stack>
        </Stack>
    );

    return (
        <Box display="flex" height="100vh" width="100vw" flexDirection="column" data-testid="innovaccer-login-error">
            <Stack
                direction="column"
                justifyContent="space-evenly"
                alignItems="center"
                bgcolor={themeColors.warning.dark}
                height="30%"
            >
                <img src={logoImage} alt="cured logo" style={{ height: '3rem' }} />
                <Box>
                    <img src={loginErrorImage} alt="Innovaccer Login for Cured" style={{ height: '8rem' }} />
                </Box>
            </Stack>
            {isValidRedirectUrl ? renderValidRedirectUrlCase() : renderInvalidRedirectUrlCase()}
            <Box height="15%" position="fixed" bottom="0" left="0" right="0">
                <Typography variant="body2" align="center">
                    having troubles?
                </Typography>
                <Typography variant="body2" align="center">
                    simply reach out to your cured support team member
                </Typography>
            </Box>
        </Box>
    );
};

export default InnovaccerLoginError;
