const MutationKeys = {
    CANCEL_SEND: 'cancel-send',
    CREATE_CLICK_APPOINTMENT_VALUE_ASSUMPTION: 'create-click-appointment-value-assumption',
    CREATE_AUDIENCE: 'create-audience',
    CREATE_CAMPAIGN: 'create-campaign',
    CREATE_FOLDER: 'create-folder',
    CREATE_LANDING_PAGE: 'create-landing-page',
    CREATE_SEGMENT: 'create-segment',
    CREATE_SEND: 'create-send',
    CREATE_TEMPLATE: 'create-template',
    DELETE_CAMPAIGN: 'delete-campaign',
    DELETE_FOLDER: 'delete-folder',
    DELETE_LANDING_PAGE: 'delete-landing-page',
    DELETE_SEGMENT: 'delete-segment',
    DELETE_TEMPLATE: 'delete-template',
    GET_AUDIENCE_COUNT: 'get-audience-count',
    GET_APPOINTMENT_VALUE: 'get-appointment-value',
    GET_SEGMENT_PREVIEW_COUNT: 'get-segment-preview-count',
    RENAME_CONTENT: 'rename-content',
    SEND_TEST_EMAIL: 'send-test-email',
    SEND_TEST_SMS: 'send-test-sms',
    UPDATE_CAMPAIGN: 'update-campaign',
    UPDATE_CAMPAIGN_STATUS: 'update-campaign-status',
    UPDATE_SEGMENT: (segmentId: number) => ['update-segment', segmentId],
    UPDATE_SEND: (sendId: string) => ['update-send', sendId],
    UPDATE_LANDING_PAGE: 'update-landing-page',
    UPDATE_TEMPLATE: 'update-template',
    UNSUBSCRIBE: 'unsubscribe',
};

export default MutationKeys;
