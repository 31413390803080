import { UseQueryOptions } from 'react-query';
import useQuery from 'hooks/useQuery';
import { CuredApiPaths } from 'models/enums';
import QueryKeys from './keys';

type UseLandingPageParams = {
    landingPageId: number;
    reactQueryOptions?: UseQueryOptions<string, Error>;
};

const useLandingPageHTML = ({ landingPageId, reactQueryOptions }: UseLandingPageParams) =>
    useQuery<string>(
        QueryKeys.LANDING_PAGE_HTML(landingPageId),
        `${CuredApiPaths.LANDING_PAGES}/${landingPageId}/html`,
        reactQueryOptions
    );

export default useLandingPageHTML;
