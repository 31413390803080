import { UseQueryOptions } from 'react-query';
import { SegmentFilterRecordCollection } from 'domains/segments/types';
import { CuredApiPaths } from 'models/enums';
import useQuery from '../useQuery';
import QueryKeys from './keys';

type Params = {
    version?: number;
    reactQueryOptions?: UseQueryOptions<SegmentFilterRecordCollection, Error>;
};

const useSegmentFilters = ({ version, reactQueryOptions }: Params = {}) => {
    const params = {
        ...(version && { version }),
    };

    const axiosOptions = {
        ...(Object.keys(params).length && { params }),
    };

    return useQuery(QueryKeys.SEGMENT_FILTERS, CuredApiPaths.SEGMENT_FILTERS, reactQueryOptions, axiosOptions);
};

export default useSegmentFilters;
