import { useState } from 'react';
import { ContentTypeForComparison, ContentModalContentType } from 'domains/content/types';
import useDeleteLandingPage from 'hooks/mutations/useDeleteLandingPage';
import useDeleteTemplate from 'hooks/mutations/useDeleteTemplate';
import ErrorSnackbar from 'domains/core/components/Snackbars/ErrorSnackbar';
import InfoSnackbar from 'domains/core/components/Snackbars/InfoSnackbar';
import SuccessSnackbar from 'domains/core/components/Snackbars/SuccessSnackbar';
import ConfirmationDialog from 'domains/core/components/ConfirmationDialog';
import useDeleteFolder from 'hooks/mutations/useDeleteFolder';
import useContentQueryManager from 'hooks/useContentQueryParams';

type Props = {
    activeContent: ContentModalContentType;
    handleModalClose: () => void;
    isOpen: boolean;
    refetch: () => void;
    resetActiveContent: () => void;
};

const DeleteContentModal = ({ activeContent, handleModalClose, isOpen, refetch, resetActiveContent }: Props) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const onError = (error: Error) => {
        setErrorMessage(error.message);
        resetActiveContent();
    };

    const { changeViewParams, folderId } = useContentQueryManager();

    const onSuccess = () => {
        setSuccessMessage(`"${activeContent?.name}" successfully deleted.`);
        if (activeContent.type === ContentTypeForComparison.FOLDER && folderId !== -1) {
            changeViewParams({ newFolderId: -1 });
        }
        refetch();
        resetActiveContent();
    };

    const contentToDeleteId = activeContent ? `${activeContent?.id}` : null;
    const {
        isLoading: isDeleteLandingPageLoading,
        mutate: deleteLandingPage,
        isSuccess: isDeleteLandingPageSuccess,
    } = useDeleteLandingPage(contentToDeleteId, { onError, onSuccess });
    const {
        isLoading: isDeleteTemplateLoading,
        mutate: deleteTemplate,
        isSuccess: isDeleteTemplateSuccess,
    } = useDeleteTemplate(contentToDeleteId, { onError, onSuccess });
    const {
        isLoading: isDeleteFolderLoading,
        mutate: deleteFolder,
        isSuccess: isDeleteFolderSuccess,
    } = useDeleteFolder(contentToDeleteId, { onError, onSuccess });
    const getDeleteContentFunction = () => {
        switch (activeContent?.type) {
            case ContentTypeForComparison.EMAIL:
                return deleteTemplate;
            case ContentTypeForComparison.LANDING_PAGE:
                return deleteLandingPage;
            case ContentTypeForComparison.FOLDER:
                return deleteFolder;
            default:
                return () => {};
        }
    };

    const handleDeleteContent = () => {
        const deleteContent = getDeleteContentFunction();

        deleteContent();
        handleModalClose();
    };

    const onCancel = () => {
        handleModalClose();
        resetActiveContent();
    };

    const isSuccess = isDeleteLandingPageSuccess || isDeleteTemplateSuccess || isDeleteFolderSuccess;
    const isLoading = isDeleteLandingPageLoading || isDeleteTemplateLoading || isDeleteFolderLoading;
    const infoMessage = `Deleting "${activeContent?.name}"`;
    const contentText = `You are about to delete "${activeContent?.name}." This action cannot be undone.`;
    const title = `Delete ${activeContent?.type}`;

    return (
        <>
            {errorMessage && <ErrorSnackbar errorMessage={errorMessage} />}
            {isLoading && <InfoSnackbar successMessage={infoMessage} />}
            {isSuccess && <SuccessSnackbar successMessage={successMessage} />}
            <ConfirmationDialog
                dialogProps={{
                    confirmButtonText: 'Delete',
                    contentText,
                    title,
                }}
                onCancel={onCancel}
                onConfirm={handleDeleteContent}
                onModalClose={handleModalClose}
                open={isOpen}
            />
        </>
    );
};

export default DeleteContentModal;
