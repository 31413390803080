import {
    Autocomplete as MuiAutocomplete,
    AutocompleteProps,
    AutocompleteRenderInputParams,
    Stack,
} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import TextField from 'domains/core/components/TextField';
import InputLabel from 'domains/core/components/InputLabel';

export type AutocompleteOption<T> = {
    index?: number;
    label: string;
    value: T;
};

export type Props = Omit<AutocompleteProps<any, boolean, boolean, boolean>, 'renderInput'> & {
    error?: boolean;
    helperText?: string;
    label?: string;
    labelIcon?: JSX.Element;
    labelIconTooltipText?: string;
    leadingIcon?: JSX.Element;
    textFieldSx?: any;
    type: 'number' | 'string';
};

const Autocomplete = ({
    error,
    helperText,
    label,
    labelIcon,
    labelIconTooltipText,
    leadingIcon,
    placeholder,
    textFieldSx,
    ...rest
}: Props) => (
    <MuiAutocomplete
        data-test="autocomplete"
        data-testid="autocomplete"
        popupIcon={<ExpandMore color="primary" />}
        renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
                {...params}
                {...{ error }}
                {...{ helperText }}
                sx={textFieldSx}
                label={<InputLabel label={label} labelIcon={labelIcon} labelIconTooltipText={labelIconTooltipText} />}
                placeholder={placeholder}
                InputProps={{
                    ...params.InputProps,
                    ...(leadingIcon && { startAdornment: <Stack mr={1}>{leadingIcon}</Stack> }),
                }}
            />
        )}
        {...rest}
    />
);

export default Autocomplete;
