import { DoNotDisturb, MailOutline, SwapCalls } from '@mui/icons-material';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useEffect, useState } from 'react';
import { CustomCampaignSteps } from 'domains/campaigns/components/CustomCampaign/CustomCampaign';
import {
    resetConditionalSplitSend,
    setSplitAction,
} from 'domains/campaigns/components/CustomCampaign/customCampaignActions';
import { StateTypes } from 'domains/campaigns/components/CustomCampaign/customCampaignReducer';
import { SplitActions, SplitTypes } from 'domains/campaigns/types';
import FormLayout from 'domains/core/components/FormLayout';
import {
    iconStyles,
    labelStyles,
    sharedButtonStyles,
} from '../CustomCampaignChooseNextStep/CustomCampaignChooseNextStep.styles';

type Props = {
    activeSendIndex: number;
    campaignState: StateTypes;
    dispatchCampaignAction: React.Dispatch<any>;
    setActiveSendIndex: (index: number) => void;
    setActiveStep: (step: CustomCampaignSteps) => void;
};

const CustomCampaignChooseSplitAction = ({
    activeSendIndex,
    campaignState,
    dispatchCampaignAction,
    setActiveSendIndex,
    setActiveStep,
}: Props) => {
    const [selectedAction, setSelectedAction] = useState(null);

    const activeSend = campaignState.sends[activeSendIndex];
    const isMeetsCriteriaSplit = activeSend.splitType === SplitTypes.MEETS;
    const isDoesNotMeetCriteriaSplit = activeSend.splitType === SplitTypes.DOES_NOT_MEET;

    const handleExitClick = () => {
        setSelectedAction(null);
        dispatchCampaignAction(resetConditionalSplitSend(activeSendIndex));
        dispatchCampaignAction(setSplitAction(activeSendIndex, SplitActions.EXIT));
    };

    // Handles what happens on split action selection
    useEffect(() => {
        const isExitClicked = selectedAction === CustomCampaignSteps.PUBLISH_CAMPAIGN;
        if (isMeetsCriteriaSplit && isExitClicked) {
            handleExitClick();
            setActiveSendIndex(activeSendIndex + 1);
        }
        if (isDoesNotMeetCriteriaSplit && isExitClicked) {
            handleExitClick();
            setActiveStep(CustomCampaignSteps.CHOOSE_NEXT_STEP);
        }

        const isEmailClicked = selectedAction === CustomCampaignSteps.SELECT_CONTENT;
        if (isEmailClicked) {
            dispatchCampaignAction(setSplitAction(activeSendIndex, SplitActions.SEND));
            setActiveStep(selectedAction);
        }

        // We only want this to run when the send index or selected step change.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSendIndex, selectedAction]);

    const handleStepClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedAction((event.target as HTMLInputElement).value);
    };

    const steps = [
        {
            icon: <MailOutline sx={iconStyles} />,
            label: 'email',
            value: CustomCampaignSteps.SELECT_CONTENT,
        },
        {
            icon: <DoNotDisturb sx={iconStyles} />,
            label: 'exit curation',
            value: CustomCampaignSteps.PUBLISH_CAMPAIGN,
        },
    ];

    const guidingText = `Choose the next step for your audience who ${
        isDoesNotMeetCriteriaSplit ? 'does not meet' : 'meets'
    } the split criteria. Keeping your Curation goals in mind, select the action that supports your objective.`;

    return (
        <FormLayout guidingText={guidingText} header="select path actions" stepName="actions" icon={<SwapCalls />}>
            <RadioGroup onChange={handleStepClick} value={selectedAction}>
                {steps.map((step) => (
                    <FormControlLabel
                        key={step.label}
                        control={<Radio sx={{ display: 'none' }} />}
                        label={
                            <span style={labelStyles}>
                                {step.icon}
                                {step.label}
                            </span>
                        }
                        sx={sharedButtonStyles}
                        value={step.value}
                    />
                ))}
            </RadioGroup>
        </FormLayout>
    );
};

export default CustomCampaignChooseSplitAction;
