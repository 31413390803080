import { Email, Send } from 'domains/campaigns/types';
import { Template, isTemplate } from 'domains/content/types';
import { URLPaths } from 'models/enums';
import useTemplate from '../queries/useTemplate';
import useAppSendSenderProfileTemplate from '../queries/useAppSendSenderProfileTemplate';

// TODO - fix rule of hooks violations below in useEmailById
export const EmailForCampaignDetails = (sendId: Send['id']): Email => {
    const { data: emailDetail, isSuccess: isEmailDetailSuccess } = useAppSendSenderProfileTemplate(sendId, {
        enabled: Boolean(sendId),
    });

    if (!sendId) return;

    const { contentSnapshot, fromAddress, preHeader, sendName, subjectLine, templateName, scheduledTimestamp } =
        isEmailDetailSuccess && emailDetail;

    return {
        contentSnapshot,
        fromAddress,
        preHeader,
        scheduledTimestamp,
        sendName,
        subjectLine,
        templateName,
    };
};

// TODO - fix rule of hooks violations below in useEmailById
const EmailForCampaignFlow = (templateId: Template['id']) => {
    const { data: template } = useTemplate({ templateId: `${templateId}` });
    const templateName = isTemplate(template) ? template.name : template;

    return {
        templateName,
    };
};

type useEmailByIdProps = {
    contentIds: Send['id'][] | Template['id'][];
    previewContentId: number;
    urlPath: URLPaths.CAMPAIGNS | URLPaths.CURATIONS;
    onClickPreviewOpen: (index: number) => void;
    onClickPreviewClose: () => void;
};

const useEmailById = ({
    contentIds,
    previewContentId,
    urlPath,
    onClickPreviewOpen,
    onClickPreviewClose,
}: useEmailByIdProps) =>
    contentIds.map((contentId, index) => {
        const isCampaign = urlPath === URLPaths.CAMPAIGNS;
        const isPreviewOpen = index === previewContentId;

        const sendEmail = isCampaign && EmailForCampaignDetails(contentId);
        const templateName = !isCampaign && EmailForCampaignFlow(contentId);

        const onClick = () => {
            isPreviewOpen ? onClickPreviewClose() : onClickPreviewOpen(index);
        };

        return {
            ...sendEmail,
            ...templateName,
            contentId,
            isPreviewOpen,
            onClick,
        };
    });

export default useEmailById;
