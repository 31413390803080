import { Box, Stack } from '@mui/material';
import { useMemo } from 'react';
import { graphColors } from 'colors';

export const getHeaderName = (field: string) => field.replace(/_/g, ' ');

export enum ValueFormatTypes {
    CURRENCY = 'currency',
    PERCENTAGE = 'percentage',
}

export const getValueFormatter = ({ type, value }: { type?: ValueFormatTypes | null; value: string }) => {
    if (value === null) return '';

    const valueLocaleString = value.toLocaleString();
    if (type === ValueFormatTypes.PERCENTAGE) return `${valueLocaleString}%`;
    if (type === ValueFormatTypes.CURRENCY) return `$${valueLocaleString}`;
    return valueLocaleString;
};

export const renderCellWithPercentageBar = (value?: string) => (
    <Stack alignItems="center" direction="row">
        <Box height={16} mr={1} width={Number(value) * 2} style={{ background: graphColors[0] }} />
        {getValueFormatter({ type: ValueFormatTypes.PERCENTAGE, value })}
    </Stack>
);

export const useGetRows = (tableData: any) =>
    useMemo(
        () =>
            tableData?.map((tableDatum: {}, index: number) => ({
                id: index,
                ...tableDatum,
            })),
        [tableData]
    );
