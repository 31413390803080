import Card from 'pages/InsightsPage/dashboards/Card';
import useContactCount from './useContactCount';

const ContactsCard = () => {
    const { data, isError, isLoading } = useContactCount();

    return (
        <Card isError={isError} isFigureCard isLoading={isLoading} label="Contacts">
            {data?.count?.toLocaleString()}
        </Card>
    );
};

export default ContactsCard;
