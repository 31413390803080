import { useCallback, useEffect, useMemo } from 'react';
import { Node, Edge, Position } from 'reactflow';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { URLPaths } from 'models/enums';
import { route as routeSelector } from 'state/selectors';
import Graph from 'domains/campaigns/components/Graph';
import { Type } from 'domains/campaigns/components/Graph/EndNode';
import { NodeType } from 'domains/campaigns/components/Graph/types';
import useVerticallyCenterNode from 'domains/campaigns/components/Graph/useVerticallyCenterNode';
import { CurationState, EdgePropsPartial, NodePropsPartial } from 'domains/campaigns/types';
import theme from 'theme';

const SPACING = 8;
const SPACING_NODE_START = SPACING * 10;
const SPACING_NODE_END = SPACING * 60;

const edgeStyle = {
    animated: false,
    style: {
        stroke: theme.palette.action.active,
        strokeWidth: 2,
        strokeDasharray: 2,
    },
};

const getX = (node: NodePropsPartial) => (node?.data.isSplitMeets ? 0 : SPACING * 12);
const getY = (index: number) => SPACING * (SPACING * 1.25 + index * 16);

type Props = {
    isRouteStartOrPublish: boolean;
    selectIsCampaignRouteDisabled: (state: any) => (route: URLPaths) => boolean;
    nodesFromCuration: NodePropsPartial[];
    edgesFromCuration: EdgePropsPartial[];
    readonly?: boolean;
    /**
     * Optional if readonly is true.
     */
    selectCurationStateByRoute?: (state: any) => (route: URLPaths) => CurationState;
};

const LegacyCampaignNavBar = ({
    isRouteStartOrPublish,
    selectCurationStateByRoute,
    selectIsCampaignRouteDisabled,
    nodesFromCuration,
    edgesFromCuration,
    readonly = false,
}: Props) => {
    const history = useHistory();
    const route = useSelector((state) => routeSelector(state));

    const getCurationStateByRoute = useSelector(
        readonly ? () => (route: URLPaths) => ({}) : selectCurationStateByRoute
    );
    const isCurationRouteDisabled = useSelector(selectIsCampaignRouteDisabled);
    const centerNodeVertically = useVerticallyCenterNode();

    const isDisabled = useCallback(
        (routes: URLPaths[]): boolean => {
            const routeDisabled = routes.map(isCurationRouteDisabled);

            // If there are more then one route & any of the routes are enabled, then the tab is enabled.
            const isEveryRouteDisabled = routeDisabled.every((isRouteDisabled) => isRouteDisabled);

            return routes.length === 1 ? routeDisabled[0] : isEveryRouteDisabled;
        },
        [isCurationRouteDisabled]
    );

    const nodes: Node<any>[] = useMemo(() => {
        const onClick = (newIndex: number) => {
            history.push(nodes[newIndex + 1].data.routes[0]);
        };

        const nodeStart: Node<any> = {
            id: '0',
            connectable: false,
            type: NodeType.END_NODE,
            data: {
                label: 'start',
                type: Type.START,
            },
            position: {
                x: SPACING_NODE_START,
                y: 0,
            },
            sourcePosition: Position.Right,
        };

        const nodesNavigation: Node<any>[] = nodesFromCuration.map((node, index) => ({
            id: `${index + 1}`,
            connectable: false,
            type: NodeType.LEGACY_NAVIGATION,
            data: {
                ...node.data,
                getCurationStateByRoute,
                isDisabled: isDisabled(node.data.routes),
                disabledHoverState: route === node.data.routes[0],
                readonly,
                onClick: () => onClick(index),
            },
            position: {
                x: getX(node),
                y: getY(index),
            },
            targetPosition: node.targetPosition,
        }));

        const nodeEnd: Node<any> = {
            id: `${nodesFromCuration.length + 1}`,
            connectable: false,
            type: NodeType.END_NODE,
            data: {
                label: 'end',
                type: Type.END,
            },
            position: {
                x: SPACING_NODE_END,
                y: getY(nodesFromCuration.length),
            },
            targetPosition: Position.Left,
        };

        return [nodeStart, ...nodesNavigation, nodeEnd];
    }, [nodesFromCuration, readonly, history, route, getCurationStateByRoute, isDisabled]);

    const edges: Edge<any>[] = useMemo(() => {
        const edgeStart = {
            ...edgeStyle,
            id: 'e0-1',
            source: '0',
            target: '1',
            type: 'smoothstep',
            pathOptions: {
                borderRadius: 24,
            },
        };

        const edgesNavigation: Edge<any>[] = [];
        edgesFromCuration.forEach((edge, index) => {
            const edgePrevious = index;
            const edgeCurrent = index + 1;
            const edgeNext = index + 2;
            const isEdgeLast = index === edgesFromCuration.length - 1;

            if (edge.label === 'yes') {
                edgesNavigation.push({
                    id: `e${edgeCurrent}-${edgeNext}`,
                    source: `${edgeCurrent}`,
                    target: `${edgeNext}`,
                    ...edge,
                    ...edgeStyle,
                } as Edge<any>);
            } else if (edge.label === 'no') {
                edgesNavigation.push({
                    id: `e${edgePrevious}-${edgeNext}`,
                    source: `${edgePrevious}`,
                    target: `${edgeNext}`,
                    pathOptions: {
                        offset: 24,
                    },
                    ...edge,
                    ...edgeStyle,
                } as Edge<any>);
            } else if (isEdgeLast) {
                return;
            } else {
                edgesNavigation.push({
                    id: `e${edgeCurrent}-${edgeNext}`,
                    source: `${edgeCurrent}`,
                    target: `${edgeNext}`,
                    type: 'default',
                    ...edge,
                    ...edgeStyle,
                });
            }
        });

        const edgeEnd = {
            ...edgeStyle,
            id: `e${edgesNavigation.length + 1}-${edgesNavigation.length + 2}`,
            source: `${edgesNavigation.length + 1}`,
            target: `${edgesNavigation.length + 2}`,
            type: 'smoothstep',
            pathOptions: {
                borderRadius: 24,
            },
        };

        return [edgeStart, ...edgesNavigation, edgeEnd];
    }, [edgesFromCuration]);

    // Center the active node when the route changes.
    useEffect(() => {
        const nodeToCenter = nodes.find(
            (node: Node<any>) => node.type === NodeType.LEGACY_NAVIGATION && node.data.routes.includes(route)
        );
        nodeToCenter && centerNodeVertically(nodeToCenter.id);
    }, [nodes, route, centerNodeVertically]);

    return <Graph edges={edges} nodes={nodes} isFitView={isRouteStartOrPublish || readonly} />;
};

export default LegacyCampaignNavBar;
