import { useEffect } from 'react';
import { SortDirection } from 'models/enums';
import { ColumnInstance } from '../types';

const useManualSort = (
    manualSortBy: boolean,
    customTableColumns: ColumnInstance[],
    onSort: (column: string, direction: SortDirection) => void
) =>
    useEffect(
        () => {
            if (manualSortBy) {
                const sortedColInd = customTableColumns.findIndex((e) => e.isSorted);
                if (sortedColInd !== -1) {
                    const isSortedDesc = customTableColumns[sortedColInd].isSortedDesc;
                    const isCustomSort = customTableColumns[sortedColInd].customSort;
                    if (!isSortedDesc && isCustomSort) {
                        onSort(customTableColumns[sortedColInd].customSort, SortDirection.ASCENDING);
                    } else if (isSortedDesc && isCustomSort) {
                        onSort(customTableColumns[sortedColInd].customSort, SortDirection.DESCENDING);
                    } else if (!isSortedDesc) {
                        onSort(customTableColumns[sortedColInd].id, SortDirection.ASCENDING);
                    } else if (isSortedDesc) {
                        onSort(customTableColumns[sortedColInd].id, SortDirection.DESCENDING);
                    }
                } else {
                    onSort('', SortDirection.ASCENDING);
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [...customTableColumns.map((e) => e.isSorted), ...customTableColumns.map((e) => e.isSortedDesc)]
    );

export default useManualSort;
