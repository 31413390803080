import EmailPreviewContent from 'domains/content/components/EmailPreviewContent/EmailPreviewContent';
import { useState } from 'react';
import { EmailForCampaignDetails } from 'hooks/useEmailById/useEmailById';
import { Campaign, Send } from 'domains/campaigns/types';
import CampaignDetailsLayout from '../../CampaignDetailsLayout';
import SendDetails from './SendDetails';

type Props = {
    campaign: Campaign;
};

const MultiLevelConditionalSplitDetailsPage = ({ campaign }: Props) => {
    const [activeSendId, setActiveSendId] = useState(null);
    const activeEmail = EmailForCampaignDetails(activeSendId);

    const renderSends = () =>
        campaign.sends.map((send: Send) => (
            <SendDetails
                key={send.id}
                activeSendId={activeSendId}
                campaign={campaign}
                firstSendRecurrenceFrequency={campaign.sends[0].recurrenceFrequency}
                send={send}
                setActiveSendId={setActiveSendId}
            />
        ));
    const sends = renderSends();

    const renderEmailPreview = () => {
        if (!activeSendId && !activeEmail) return;

        return (
            <EmailPreviewContent
                emailTemplateHTML={activeEmail.contentSnapshot}
                fromAddress={activeEmail.fromAddress}
                isGridItem
                isOpen
                preHeader={activeEmail.preHeader}
                subjectLine={activeEmail.subjectLine}
                onClose={() => setActiveSendId(null)}
            />
        );
    };
    const emailPreview = renderEmailPreview();

    return <CampaignDetailsLayout left={sends} right={emailPreview} />;
};

export default MultiLevelConditionalSplitDetailsPage;
