export const propScoreByAgeAndGender = [
    {
        id: 'non-male',
        data: [
            { x: '20', y: '25' },
            { x: '21', y: '25' },
            { x: '22', y: '25' },
            { x: '23', y: '25' },
            { x: '24', y: '25' },
            { x: '25', y: '25' },
            { x: '26', y: '25' },
            { x: '27', y: '25' },
            { x: '28', y: '25' },
            { x: '29', y: '25.74303406' },
            { x: '30', y: '26' },
            { x: '31', y: '26.73048128' },
            { x: '32', y: '27' },
            { x: '33', y: '27' },
            { x: '34', y: '29.17808219' },
            { x: '35', y: '30.28661749' },
            { x: '36', y: '31.13203463' },
            { x: '37', y: '32.17008444' },
            { x: '38', y: '32.4076087' },
            { x: '39', y: '32.42467378' },
            { x: '40', y: '33.90107271' },
            { x: '41', y: '34.42496847' },
            { x: '42', y: '34.4297619' },
            { x: '43', y: '34.42681427' },
            { x: '44', y: '34.40740741' },
            { x: '45', y: '38.82608696' },
            { x: '46', y: '40.57951482' },
            { x: '47', y: '40.61669024' },
            { x: '48', y: '40.55037594' },
            { x: '49', y: '40.46758621' },
            { x: '50', y: '40.65578231' },
            { x: '51', y: '40.63947368' },
            { x: '52', y: '40.96153846' },
            { x: '53', y: '40.91328671' },
            { x: '54', y: '42.02026049' },
            { x: '55', y: '44.67042254' },
            { x: '56', y: '46.125' },
            { x: '57', y: '47.57083333' },
            { x: '58', y: '47.79198966' },
            { x: '59', y: '48.15451174' },
            { x: '60', y: '47.8833967' },
            { x: '61', y: '47.80446194' },
            { x: '62', y: '49.47210884' },
            { x: '63', y: '51.9452412' },
            { x: '64', y: '52.94472362' },
            { x: '65', y: '52.8781457' },
            { x: '66', y: '53.86981402' },
            { x: '67', y: '54.73487032' },
            { x: '68', y: '55.30152144' },
            { x: '69', y: '54.91369863' },
            { x: '70', y: '55.50890208' },
            { x: '71', y: '56.22788606' },
            { x: '72', y: '56.52236422' },
            { x: '73', y: '57.85353535' },
            { x: '74', y: '58.39071567' },
            { x: '75', y: '58.80917431' },
            { x: '76', y: '59.46444444' },
            { x: '77', y: '58.82476636' },
            { x: '78', y: '59.11968085' },
            { x: '79', y: '59.12835821' },
            { x: '80', y: '59.21036585' },
            { x: '81', y: '58.86062718' },
            { x: '82', y: '59.62598425' },
            { x: '83', y: '58.888' },
            { x: '84', y: '59.4562212' },
            { x: '85', y: '59.355' },
            { x: '86', y: '59.28723404' },
            { x: '87', y: '60.17977528' },
            { x: '88', y: '58.25' },
            { x: '89', y: '58.15873016' },
            { x: '90', y: '57.97413793' },
            { x: '91', y: '60.91578947' },
            { x: '92', y: '60.46067416' },
            { x: '93', y: '60.325' },
            { x: '94', y: '57.83908046' },
            { x: '95', y: '54.27272727' },
            { x: '96', y: '53.91111111' },
            { x: '97', y: '53.94117647' },
            { x: '98', y: '54.11764706' },
            { x: '99', y: '54.55555556' },
        ],
    },
    {
        id: 'male',
        data: [
            { x: '20', y: '25' },
            { x: '21', y: '25' },
            { x: '22', y: '25' },
            { x: '23', y: '25' },
            { x: '24', y: '25' },
            { x: '25', y: '25' },
            { x: '26', y: '25' },
            { x: '27', y: '25' },
            { x: '28', y: '25' },
            { x: '29', y: '25.72933071' },
            { x: '30', y: '26' },
            { x: '31', y: '26.74267782' },
            { x: '32', y: '27' },
            { x: '33', y: '27' },
            { x: '34', y: '29.21974522' },
            { x: '35', y: '30.2973262' },
            { x: '36', y: '31.15935335' },
            { x: '37', y: '32.12965051' },
            { x: '38', y: '32.43222222' },
            { x: '39', y: '32.4298441' },
            { x: '40', y: '33.89092997' },
            { x: '41', y: '34.40540541' },
            { x: '42', y: '34.44840764' },
            { x: '43', y: '34.44103194' },
            { x: '44', y: '34.41796875' },
            { x: '45', y: '38.22533333' },
            { x: '46', y: '39.55963303' },
            { x: '47', y: '39.79014085' },
            { x: '48', y: '39.53963839' },
            { x: '49', y: '39.60887097' },
            { x: '50', y: '39.71505376' },
            { x: '51', y: '39.84626234' },
            { x: '52', y: '40.18157182' },
            { x: '53', y: '39.92915531' },
            { x: '54', y: '40.78324468' },
            { x: '55', y: '42.3562753' },
            { x: '56', y: '43.05329593' },
            { x: '57', y: '43.61063041' },
            { x: '58', y: '44.00857143' },
            { x: '59', y: '44.01808786' },
            { x: '60', y: '43.99363868' },
            { x: '61', y: '44.01164295' },
            { x: '62', y: '45.59533074' },
            { x: '63', y: '48.63779528' },
            { x: '64', y: '49.58854167' },
            { x: '65', y: '49.68956044' },
            { x: '66', y: '50.88129032' },
            { x: '67', y: '51.78599736' },
            { x: '68', y: '52.4011544' },
            { x: '69', y: '52.64069264' },
            { x: '70', y: '52.77312139' },
            { x: '71', y: '54.23124043' },
            { x: '72', y: '55.55798319' },
            { x: '73', y: '58.07705779' },
            { x: '74', y: '57.7662116' },
            { x: '75', y: '58.65975104' },
            { x: '76', y: '58.14561028' },
            { x: '77', y: '59.8345679' },
            { x: '78', y: '58.92857143' },
            { x: '79', y: '59.34571429' },
            { x: '80', y: '59.26409496' },
            { x: '81', y: '59.16507937' },
            { x: '82', y: '59.26394052' },
            { x: '83', y: '60.076' },
            { x: '84', y: '59.29338843' },
            { x: '85', y: '59.07738095' },
            { x: '86', y: '59.88601036' },
            { x: '87', y: '59.50943396' },
            { x: '88', y: '59.46794872' },
            { x: '89', y: '60.15151515' },
            { x: '90', y: '57.765625' },
            { x: '91', y: '59.5106383' },
            { x: '92', y: '59.06122449' },
            { x: '93', y: '60.08421053' },
            { x: '94', y: '55.05128205' },
            { x: '95', y: '55.10909091' },
            { x: '96', y: '56.38181818' },
            { x: '97', y: '54.31914894' },
            { x: '98', y: '53.48780488' },
            { x: '99', y: '54.43333333' },
        ],
    },
];

export const propScoreContactsByContactsCount = [
    { id: '0-5', value: null },
    { id: '5-10', value: null },
    { id: '11-15', value: null },
    { id: '16-20', value: null },
    { id: '21-25', value: 18350 },
    { id: '26-30', value: 12713 },
    { id: '31-35', value: 15892 },
    { id: '36-40', value: 8765 },
    { id: '41-45', value: 13047 },
    { id: '46-50', value: 11464 },
    { id: '51-55', value: 9103 },
    { id: '56-60', value: 2928 },
    { id: '61-65', value: 3406 },
    { id: '66-70', value: 2564 },
    { id: '71-75', value: 1161 },
    { id: '76-80', value: 607 },
    { id: '81-85', value: null },
    { id: '86-90', value: null },
    { id: '91-95', value: null },
    { id: '96-100', value: null },
];

export const propScorePercent = [
    {
        id: 'percent at prop score',
        data: [
            { x: '25', y: '.817' },
            { x: '26', y: '.777' },
            { x: '27', y: '.719' },
            { x: '30', y: '.689' },
            { x: '31', y: '.671' },
            { x: '32', y: '.634' },
            { x: '33', y: '.611' },
            { x: '34', y: '.565' },
            { x: '35', y: '.530' },
            { x: '36', y: '.525' },
            { x: '37', y: '.524' },
            { x: '38', y: '.486' },
            { x: '39', y: '.447' },
            { x: '40', y: '.443' },
            { x: '41', y: '.423' },
            { x: '42', y: '.385' },
            { x: '43', y: '.362' },
            { x: '44', y: '.338' },
            { x: '45', y: '.312' },
            { x: '46', y: '.268' },
            { x: '47', y: '.245' },
            { x: '48', y: '.220' },
            { x: '49', y: '.211' },
            { x: '50', y: '.198' },
            { x: '51', y: '.178' },
            { x: '52', y: '.167' },
            { x: '53', y: '.155' },
            { x: '54', y: '.133' },
            { x: '55', y: '.107' },
            { x: '56', y: '.097' },
            { x: '57', y: '.094' },
            { x: '58', y: '.086' },
            { x: '59', y: '.082' },
            { x: '60', y: '.077' },
            { x: '61', y: '.070' },
            { x: '62', y: '.064' },
            { x: '63', y: '.059' },
            { x: '64', y: '.054' },
            { x: '65', y: '.043' },
            { x: '66', y: '.039' },
            { x: '67', y: '.033' },
            { x: '68', y: '.026' },
            { x: '69', y: '.021' },
            { x: '70', y: '.018' },
            { x: '71', y: '.013' },
            { x: '72', y: '.011' },
            { x: '73', y: '.010' },
            { x: '74', y: '.009' },
            { x: '75', y: '.006' },
            { x: '76', y: '.004' },
            { x: '77', y: '.002' },
            { x: '78', y: '.001' },
            { x: '79', y: '.000' },
        ],
    },
];
