import { Box } from '@mui/material';
import { useEffect } from 'react';
import theme from 'theme';
import * as DOMPurify from 'dompurify';

export type Props = {
    htmlContent: string;
    /**
     * Highlights selected link by blurring the text of the HTML.
     */
    selectedLink?: string;
};

export const highlightLink = (selectedLink: string, htmlContent: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    const selectedLinkCSS = `
      <style>
        .parent {
          position: relative;
        }
        .backdrop {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: white;
          opacity: 0.82;
        }
        .highlight {
          display: inline-block;
          position: relative;
        }
        .highlight::before {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: -30px;
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-left: 20px solid ${theme.palette.action.active};
        }
      </style>
    `;

    // Add the CSS
    const head = doc.head;
    const style = doc.createElement('style');
    style.innerHTML = selectedLinkCSS;
    head.appendChild(style);

    // Add the backdrop div and enclosing parent
    const body = doc.body;
    const parent = doc.createElement('div');
    parent.classList.add('parent');
    body.prepend(parent);

    const backdrop = doc.createElement('div');
    backdrop.classList.add('backdrop');
    parent.appendChild(backdrop);

    // Wrap the selected link with a span
    const links = doc.querySelectorAll(`a[href="${selectedLink}"], a[href='${selectedLink}']`);
    links.forEach((link) => {
        const highlight = doc.createElement('span');
        highlight.classList.add('highlight');
        link.parentNode.insertBefore(highlight, link);
        highlight.appendChild(link);
    });

    return doc.documentElement.outerHTML;
};

const HtmlRenderer = ({ htmlContent, selectedLink }: Props) => {
    const WHITELISTED_TAGS = ['#comment'];
    const config = {
        ADD_TAGS: WHITELISTED_TAGS,
        WHOLE_DOCUMENT: true,
    };
    const sanitizedHtml = DOMPurify.sanitize(htmlContent, config);

    useEffect(() => {
        if (selectedLink) {
            const link = document.querySelector(`a[href="${selectedLink}"], a[href='${selectedLink}']`);
            if (link) {
                link.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [selectedLink]);

    return (
        <Box
            sx={{
                position: 'relative',
                overflow: 'hidden',
            }}
        >
            <div
                dangerouslySetInnerHTML={{
                    __html: selectedLink ? highlightLink(selectedLink, sanitizedHtml) : sanitizedHtml,
                }}
            />
        </Box>
    );
};

export default HtmlRenderer;
