import { useDispatch, useSelector } from 'react-redux';
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import FormLayout from 'domains/core/components/FormLayout';
import { ContentFields, MetaDataFields, ScheduleFields } from 'domains/campaigns/types';
import SparkleIcon from 'domains/core/components/SvgIcons/SparkleIcon';
import useSegments from 'hooks/queries/useSegments';
import { CurationStep } from 'models/enums';
import CampaignPublishForm from 'domains/campaigns/pages/CampaignPublishPage/CampaignPublishForm';
import { RootState } from 'state';

export type Props = {
    selectContentFieldsAll: (state: RootState) => ContentFields[];
    selectMetadataFields: (state: RootState) => MetaDataFields;
    selectOrderedContentNodeIds: (state: RootState) => string[];
    selectScheduleFieldsAll: (state: RootState) => ScheduleFields[];
    selectSegmentId: (state: RootState) => number;
    actionCreatorSetContentPreviewNodeId: ActionCreatorWithOptionalPayload<string, string>;
};

export const CampaignPublishPage = ({
    selectContentFieldsAll,
    selectMetadataFields,
    selectOrderedContentNodeIds,
    selectScheduleFieldsAll,
    selectSegmentId,
    actionCreatorSetContentPreviewNodeId,
}: Props) => {
    const contentFieldsAll = useSelector(selectContentFieldsAll);
    const contentNodeIds = useSelector(selectOrderedContentNodeIds);
    const contentPreviewNodeId = useSelector(selectMetadataFields)?.contentPreviewNodeId;
    const scheduleFieldsAll = useSelector(selectScheduleFieldsAll);
    const segmentId = useSelector(selectSegmentId);

    const dispatch = useDispatch();
    const setContentPreviewNodeId = (contentPreviewNodeId: string) =>
        dispatch(actionCreatorSetContentPreviewNodeId(contentPreviewNodeId));

    // Segments are loaded by the audience dropdown in the flow, so the data is cached.
    const { isLoading: isAudiencesLoading, data: segments } = useSegments();

    if (isAudiencesLoading) {
        return null;
    }

    return (
        <FormLayout
            data-testid="curation-publish-form-layout"
            header="last step!"
            icon={<SparkleIcon />}
            stepName={CurationStep.PUBLISH}
            guidingText="Take one more moment to confirm the details of your Curation before you publish."
        >
            <CampaignPublishForm
                audience={segments?.find((audience) => audience.id === segmentId)}
                contentFieldsAll={contentFieldsAll}
                contentNodeIds={contentNodeIds}
                contentPreviewNodeId={contentPreviewNodeId}
                scheduleFieldsAll={scheduleFieldsAll}
                setContentPreviewNodeId={setContentPreviewNodeId}
            />
        </FormLayout>
    );
};

export default CampaignPublishPage;
