import { RouteComponentProps } from 'react-router';
import { PaidOutlined } from '@mui/icons-material';
import FormLayout from 'domains/core/components/FormLayout';
import { ClickApptAssumptionSpecialty, ValueCalculatorStep } from 'domains/value/types';
import ValueCalculatorSendSpecialtyForm from './ValueCalculatorSendSpecialtyForm';
import { useDispatch, useSelector } from 'react-redux';
import {
    setIsPreviewVisible,
    setSendId as setSendIdActionCreator,
    setSpecialty as setSpecialtyActionCreator,
} from 'domains/value/state/value';

const ValueCalculatorSendSpecialtyPage = (props: RouteComponentProps) => {
    const dispatch = useDispatch();

    const isPreviewVisible = useSelector((state: any) => state.value.isPreviewVisible);
    const sendId = useSelector((state: any) => state.value.sendId);
    const specialty = useSelector((state: any) => state.value.specialty);

    const setSendId = (sendId: number) => dispatch(setSendIdActionCreator(sendId));
    const setSpecialty = (specialty: ClickApptAssumptionSpecialty) => dispatch(setSpecialtyActionCreator(specialty));

    const onClickEmailPreview = () =>
        sendId && !isPreviewVisible ? dispatch(setIsPreviewVisible(true)) : dispatch(setIsPreviewVisible(false));

    return (
        <FormLayout
            header="let's get started"
            icon={<PaidOutlined />}
            stepName={ValueCalculatorStep.VALUE}
            guidingText="Select the send you want to track for the value calculation and the associated specialty."
        >
            <ValueCalculatorSendSpecialtyForm
                isPreviewVisible={isPreviewVisible}
                sendId={sendId}
                specialty={specialty}
                setSendId={setSendId}
                setSpecialty={setSpecialty}
                onClickEmailPreview={onClickEmailPreview}
            />
        </FormLayout>
    );
};

export default ValueCalculatorSendSpecialtyPage;
