import React from 'react';
import { useLocation } from 'react-router-dom';
import { useCustomAuth } from 'auth/AuthContext';
import { Stack } from '@mui/material';

type Props = {
    children?: React.ReactNode;
};

const Background = ({ children }: Props) => {
    const path = useLocation().pathname.substring(useLocation().pathname.indexOf('/') + 1);
    const { isAuthenticated } = useCustomAuth();

    if (path === '' && isAuthenticated) {
        return <Stack>{children}</Stack>;
    }
    return (
        <Stack maxWidth="128rem" width="100%">
            {children}
        </Stack>
    );
};

export default Background;
