import * as actionTypes from 'domains/campaigns/state/actionTypes';
import {
    EditAudiencePayload,
    EditContentPayload,
    EditSegmentsPayload,
    EditSegmentsActionPayload,
    EditDelayPayload,
    EditMetadataPayload,
    EditSchedulePayload,
} from 'domains/campaigns/types';

export const editAudience = (actionType: string, payload: EditAudiencePayload) => ({
    type: actionType,
    payload,
});

export const editContent = (actionType: string, payload: EditContentPayload) => ({
    type: actionType,
    payload,
});

export const editSegments = (actionType: string, payload: EditSegmentsPayload) => ({
    type: actionType,
    payload,
});

export const editSegmentsAction = (actionType: string, payload: EditSegmentsActionPayload) => ({
    type: actionType,
    payload,
});

export const editDelay = (actionType: string, payload: EditDelayPayload) => ({
    type: actionType,
    payload,
});

export const editMetadata = (actionType: string, payload: EditMetadataPayload) => ({
    type: actionType,
    payload,
});

export const editPublish = (actionType: string) => ({
    type: actionType,
});

export const editSchedule = (actionType: string, payload: EditSchedulePayload) => ({
    type: actionType,
    payload,
});

// legacy for sms send until
export const resetStateSingleSendLegacy = () => ({
    type: actionTypes.SINGLE_SEND_RESET_CURATION,
});

export const resetConditionalSplitState = () => ({
    type: actionTypes.CONDITIONAL_SPLIT_RESET_CURATION,
});

export const setScheduleDatetimeError = (actionType: string, isError: boolean) => ({
    type: actionType,
    payload: isError,
});
