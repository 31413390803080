import { Box, Stack } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import theme from 'theme';
import { getTimezone } from 'utils';
import Select, { Value } from 'domains/core/components/Select';
import {
    EventSelector,
    isDateRangeOperator,
    isDateRangeType,
    RecordTypeFilter,
    SegmentFilterEventFields,
    SegmentFilterState,
} from 'domains/segments/types';
import { getInitialDateRangeValue, getInitialDateValue, getOperatorValues } from '../utils';
import SubFilters from '../SubFilters';
import { SQLType } from 'models/enums';

const filterBorderStyles = {
    border: `1px dashed ${theme.palette.primary.main}`,
    borderRadius: 6,
};
const marginFullWidth = '0 0 -16px -16px';
const marginNonFullWidth = '-16px 0 -16px -8px';
const paddingFullWidthWithSubFilters = '16px 16px 16px 8px';
const paddingFullWidthWithoutSubFilters = '8px 8px 16px 8px';
const paddingDefault = '16px 0px 16px 8px';

const getPaddingStyle = (hasSubFilters: boolean, isResponsive: boolean) => {
    if (hasSubFilters) {
        return paddingFullWidthWithSubFilters;
    }
    return isResponsive ? paddingFullWidthWithoutSubFilters : paddingDefault;
};
const getEventFilterGroupStyles = (hasSubFilters: boolean, isResponsive: boolean) => {
    const borderStyles = hasSubFilters && filterBorderStyles;
    const marginStyle = isResponsive ? marginFullWidth : marginNonFullWidth;
    const paddingStyle = getPaddingStyle(hasSubFilters, isResponsive);

    return {
        ...borderStyles,
        margin: marginStyle,
        padding: paddingStyle,
    };
};

const operators: Value[] = Object.values(EventSelector).map((selector) => ({
    label: selector,
    value: selector,
}));

export type Props = {
    eventRecordTypeFilters: RecordTypeFilter[];
    filter: SegmentFilterState<SegmentFilterEventFields>;
    index: number;
    isResponsive?: boolean;
    isOperatorValueHighlighted?: boolean;
    handleDatetimeError: (filterId: string, error: boolean) => void;
    updateSegmentFilter: (
        index: number,
        filter: SegmentFilterState<SegmentFilterEventFields>,
        resetPreview?: boolean
    ) => void;
};

const EventFilter = ({
    eventRecordTypeFilters,
    filter,
    index,
    isResponsive,
    isOperatorValueHighlighted,
    handleDatetimeError,
    updateSegmentFilter,
}: Props) => {
    const { categorySpecificFields, id, name, operator } = filter;
    const { dateRangeDateTimeOperator, value } = categorySpecificFields;

    const dateRangeDateTimeOperators = getOperatorValues(SQLType.TIMESTAMP_WITH_TIME_ZONE);

    const filterId = id.toString();

    const filterDate = DateTime.fromISO(getInitialDateValue(value));
    const filterDateStart = DateTime.fromISO(getInitialDateRangeValue(value, 'start'));
    const filterDateEnd = DateTime.fromISO(getInitialDateRangeValue(value, 'end'));

    const [isAddDateRangeButtonVisible, setIsAddDateRangeButtonVisible] = useState(!value);
    const [date, setDate] = useState<DateTime | null>(filterDate);
    const [dateStart, setDateStart] = useState<DateTime | null>(filterDateStart);
    const [dateEnd, setDateEnd] = useState<DateTime | null>(filterDateEnd);

    const recordTypes = useMemo(
        () =>
            eventRecordTypeFilters?.map(({ recordType }) => ({
                label: recordType,
                value: recordType,
            })),
        [eventRecordTypeFilters]
    );

    const eventFilters = useMemo(
        () => eventRecordTypeFilters?.find((recordTypeFilter) => recordTypeFilter.recordType === name)?.filters,
        [eventRecordTypeFilters, name]
    );

    const handleDateChange = (datetime: DateTime, isDateRange: boolean, isStart: boolean) => {
        const newValue = isDateRange
            ? {
                  ...(isDateRangeType(filter.categorySpecificFields.value) && filter.categorySpecificFields.value),
                  ...(isStart ? { start: datetime.toISO() } : { end: datetime.toISO() }),
              }
            : datetime.toISO();

        const isValidDatetime = datetime && datetime.isValid;

        if (isValidDatetime) {
            updateSegmentFilter(index, {
                ...filter,
                categorySpecificFields: {
                    ...filter.categorySpecificFields,
                    value: newValue,
                },
            });
        }

        const isValidDateStart = datetime < dateEnd;
        const isValidDateEnd = datetime > dateStart;
        const isValidDateStartEnd = isStart ? isValidDateStart : isValidDateEnd;

        handleDatetimeError(filterId, isDateRange && (!isValidDatetime || !isValidDateStartEnd));

        if (isDateRange) {
            isStart ? setDateStart(datetime) : setDateEnd(datetime);
        } else {
            setDate(datetime);
        }
    };

    useEffect(() => {
        if (dateRangeDateTimeOperator) {
            if (isDateRangeOperator(dateRangeDateTimeOperator)) {
                updateSegmentFilter(index, {
                    ...filter,
                    categorySpecificFields: {
                        ...filter.categorySpecificFields,
                        value: {
                            start: dateStart.toISO(),
                            end: dateEnd.toISO(),
                        },
                    },
                });
            } else {
                handleDateChange(date, false, false);
            }
        } else if (!value) {
            setIsAddDateRangeButtonVisible(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date, dateEnd, dateStart, dateRangeDateTimeOperator, value]);

    const hasSubFilters = !!filter.categorySpecificFields.conditions?.length;

    return (
        <>
            <Stack direction="row" order="1">
                <Select
                    data-testid="contact-event-selector"
                    data-test="contact-event-selector"
                    label="Operator"
                    sx={{ textTransform: 'capitalize', width: '12rem' }}
                    value={operator ?? ''}
                    values={operators}
                    onChange={(e: any) =>
                        updateSegmentFilter(index, {
                            ...filter,
                            operator: e.target.value as EventSelector,
                        })
                    }
                />
            </Stack>
            {operator && (
                <Stack
                    direction="row"
                    order="2"
                    columnGap={2}
                    style={getEventFilterGroupStyles(hasSubFilters, isResponsive)}
                >
                    <Stack direction="column">
                        <Box {...(isResponsive && { marginLeft: '8px' })}>
                            <Select
                                data-testid="contact-event-record-type"
                                label="Filter"
                                value={name ?? ''}
                                values={recordTypes}
                                sx={{ textTransform: 'capitalize', width: '12rem' }}
                                onChange={(e: any) =>
                                    updateSegmentFilter(index, {
                                        ...filter,
                                        categorySpecificFields: {
                                            ...filter.categorySpecificFields,
                                            ...(filter.categorySpecificFields.conditions && { conditions: [] }),
                                        },
                                        name: e.target.value,
                                    })
                                }
                            />
                        </Box>
                        {name && (
                            <SubFilters
                                addButtonDataTestId="contact-event-add-sub-filter"
                                filterIndex={index}
                                filter={filter}
                                filters={eventFilters}
                                handleDatetimeError={handleDatetimeError}
                                isResponsive={isResponsive}
                                updateSegmentFilter={updateSegmentFilter}
                            />
                        )}
                    </Stack>
                </Stack>
            )}
            {!isAddDateRangeButtonVisible && (
                <Stack data-testid="filter-operator-value" direction="row" order="3" position="relative" columnGap={2}>
                    {isOperatorValueHighlighted && (
                        <Box
                            data-testid="highlighted-background"
                            position="absolute"
                            top={-16}
                            right={-8}
                            bottom={-16}
                            left={-8}
                            sx={{
                                backgroundColor: 'action.hover',
                                borderRadius: 2,
                            }}
                        />
                    )}
                    <Select
                        data-testid="filter-operator"
                        label="Operator"
                        value={dateRangeDateTimeOperator}
                        values={dateRangeDateTimeOperators}
                        sx={{ textTransform: 'capitalize', width: '12rem' }}
                        onChange={(e: any) =>
                            updateSegmentFilter(index, {
                                ...filter,
                                categorySpecificFields: {
                                    ...filter.categorySpecificFields,
                                    dateRangeDateTimeOperator: e.target.value,
                                    value: '',
                                },
                            })
                        }
                    />
                    {dateRangeDateTimeOperator && (
                        <Box data-testid="filter-value">
                            {isDateRangeOperator(dateRangeDateTimeOperator) ? (
                                <Stack direction="row" columnGap={2} data-testid="date-range-value">
                                    <DateTimePicker
                                        label={`date/time (${getTimezone()})`}
                                        maxDateTime={dateEnd}
                                        slotProps={{
                                            textField: {
                                                helperText: dateStart > dateEnd && 'Please enter a valid date range',
                                                sx: { width: '16rem' },
                                            },
                                        }}
                                        value={dateStart}
                                        onChange={(datetime) => handleDateChange(datetime, true, true)}
                                    />
                                    <DateTimePicker
                                        label={`date/time (${getTimezone()})`}
                                        minDateTime={dateStart}
                                        slotProps={{
                                            textField: {
                                                helperText: dateStart > dateEnd && 'Please enter a valid date range',
                                                sx: { width: '16rem' },
                                            },
                                        }}
                                        value={dateEnd}
                                        onChange={(datetime) => handleDateChange(datetime, true, false)}
                                    />
                                </Stack>
                            ) : (
                                <Box data-testid="date-value">
                                    <DateTimePicker
                                        label={`date/time (${getTimezone()})`}
                                        slotProps={{
                                            textField: {
                                                sx: { width: '16rem' },
                                            },
                                        }}
                                        value={date}
                                        onChange={(datetime) => handleDateChange(datetime, false, false)}
                                    />
                                </Box>
                            )}
                        </Box>
                    )}
                </Stack>
            )}
        </>
    );
};

export default EventFilter;
