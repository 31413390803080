import { themeColors } from 'colors';

type Props = {
    color: string;
    label: string;
    value: string;
};

const CustomTooltip = ({ color, label, value }: Props) => (
    <div
        style={{
            background: 'white',
            border: `1px solid ${themeColors.other.divider}`,
            borderRadius: '6px',
            fontSize: '12px',
            padding: '8px',
        }}
    >
        <div
            style={{
                alignItems: 'center',
                display: 'flex',
                fontWeight: 'bold',
                justifyContent: 'center',
            }}
        >
            <div
                style={{
                    background: color,
                    borderRadius: '50%',
                    height: '10px',
                    marginRight: '4px',
                    width: '10px',
                }}
            />
            {label}
        </div>
        <div>{value}</div>
    </div>
);

export default CustomTooltip;
