import { SQLType } from 'models/enums';
import TextField from 'domains/core/components/TextField';
import { SegmentDemographicValue } from 'domains/segments/types';
import { getInputType } from '../../utils';
import { SxProps, Theme } from '@mui/material';

type Props = {
    onChange: (value: any) => void;
    placeholderName: string;
    sqlType: SQLType;
    sx?: SxProps<Theme>;
    value: SegmentDemographicValue;
};

const TextFieldInput = ({ onChange, placeholderName, sqlType, sx, value }: Props) => (
    <TextField
        data-testid="valueTextField"
        label="Value"
        onChange={(e) => onChange(e.target.value)}
        placeholder={`Enter a value for ${placeholderName}`}
        sx={{
            width: '16rem',
            ...sx,
        }}
        type={getInputType(sqlType)}
        value={value}
    />
);

export default TextFieldInput;
