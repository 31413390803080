import { TimePicker as MuiTimePicker, TimePickerProps, TimePickerSlotsComponentsProps } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import merge from 'lodash/merge';
import { useState } from 'react';
import theme from 'theme';

const TimePicker = ({ slotProps: slotPropsFromProps, value, ...rest }: TimePickerProps<DateTime>) => {
    const [open, setOpen] = useState(false);

    const slotProps: TimePickerSlotsComponentsProps<DateTime> = {
        textField: {
            // @ts-ignore
            'data-testid': 'time-picker',
            InputLabelProps: {
                sx: {
                    top: '-4px',
                },
            },
        },
        inputAdornment: {
            position: 'start',
            sx: {
                '.Mui-focused & svg': {
                    fill: theme.palette.action.active,
                },
                svg: {
                    ...(open && { fill: theme.palette.action.active }),
                },
            },
        },
        desktopPaper: {
            sx: {
                marginTop: 2.125,
                paddingX: 2,
                '.MuiTypography-caption': {
                    letterSpacing: '0px',
                },
                '.MuiPickersArrowSwitcher-spacer': {
                    width: theme.spacing(2),
                },
                '.MuiPickersArrowSwitcher-button': {
                    padding: '6px',
                },
                '.MuiPickersArrowSwitcher-button:not(.Mui-disabled) svg': {
                    fill: theme.palette.action.active,
                },
            },
        },
        mobilePaper: {
            sx: {
                marginTop: 2.125,
                paddingX: 2,
                '.MuiTypography-caption': {
                    letterSpacing: '0px',
                },
                '.MuiPickersArrowSwitcher-spacer': {
                    width: theme.spacing(2),
                },
                '.MuiPickersArrowSwitcher-button': {
                    padding: '6px',
                },
                '.MuiPickersArrowSwitcher-button:not(.Mui-disabled) svg': {
                    fill: theme.palette.action.active,
                },
            },
        },
        popper: {
            placement: 'bottom',
            sx: {
                '.Mui-selected': {
                    backgroundColor: theme.palette.action.active,
                },
                '.MuiClock-root': {
                    marginTop: -3,
                },
                '.MuiClock-pin': {
                    backgroundColor: theme.palette.action.active,
                },
                '.MuiClock-amButton': {
                    ...((value?.toFormat('a').toLowerCase() === 'am' || !value) && {
                        backgroundColor: theme.palette.action.active,
                    }),
                },
                '.MuiClock-pmButton': {
                    ...(value?.toFormat('a').toLowerCase() === 'pm' && {
                        backgroundColor: theme.palette.action.active,
                    }),
                },
                '.MuiClockPicker-arrowSwitcher': {
                    position: 'static',
                    justifyContent: 'end',
                    marginTop: 1,
                },
                '.MuiClockPointer-root': {
                    backgroundColor: theme.palette.action.active,
                },
                '.MuiClockPointer-thumb': {
                    border: `16px solid ${theme.palette.action.active}`,
                },
                '.MuiIconButton-root': {
                    marginBottom: 1,
                },
                '.MuiIconButton-root:hover': {
                    backgroundColor: theme.palette.action.hover,
                    color: theme.palette.primary.main,
                },
                '.MuiIconButton-sizeMedium': {
                    marginX: 2,
                },
            },
        },
    };

    return (
        <MuiTimePicker
            format="hh:mm a"
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            slotProps={merge(slotProps, slotPropsFromProps)}
            value={value}
            {...rest}
        />
    );
};

export default TimePicker;
