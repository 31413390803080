import { CurationNavState, URLPaths } from 'models/enums';
import { Action } from 'models/types';
import { ROUTE_CHANGE } from 'state/constants';
import * as actionTypes from 'domains/campaigns/state/actionTypes';
import { getCurationNavState } from 'domains/campaigns/state/utils';
import {
    AudienceState,
    ContentState,
    MetadataState,
    ScheduleState,
    SendRecurrenceFrequency,
} from 'domains/campaigns/types';

export const initialState: ScheduleState = {
    navState: CurationNavState.PREVIEW,
    isComplete: false,
    isRecommendedTimeEnabled: false,
    scheduledTimestamp: null,
    isDatetimeError: false,
    recurrenceFrequency: SendRecurrenceFrequency.DOES_NOT_REPEAT,
};

export const isFormComplete = (state: ScheduleState) => state.scheduledTimestamp !== null && !state.isDatetimeError;

export type CurationCombinedState = {
    metadata: MetadataState;
    audience: AudienceState;
    content: ContentState;
    schedule: ScheduleState;
};

const isFormInProgress = (state: ScheduleState) => state.isDatetimeError;

export const scheduleReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case actionTypes.CONDITIONAL_SPLIT_EDIT_SCHEDULE:
            const stateAfterEdit = {
                ...state,
                scheduledTimestamp: action.payload.scheduledTimestamp,
                buttonClicked: action.payload.buttonClicked,
                isRecommendedTimeEnabled: action.payload.isRecommendedTimeEnabled ?? state.isRecommendedTimeEnabled,
                recurrenceFrequency: action.payload.recurrenceFrequency,
                scheduledEndTimestamp: action.payload.scheduledEndTimestamp,
            };
            const isComplete = isFormComplete(stateAfterEdit);
            return {
                ...stateAfterEdit,
                navState: getCurationNavState(
                    isComplete,
                    isFormInProgress(stateAfterEdit),
                    URLPaths.CURATIONS_CONDITIONAL_SPLIT_SCHEDULE,
                    undefined,
                    URLPaths.CURATIONS_CONDITIONAL_SPLIT
                ),
                isComplete: isComplete,
            };
        case ROUTE_CHANGE:
            const route = action?.payload?.location?.pathname;
            if (route === URLPaths.CURATIONS_CONDITIONAL_SPLIT_SCHEDULE) {
                // When the page is opened for the first time, set the initial state.
                const newScheduledTimestamp = state.scheduledTimestamp ?? null;
                const newState = {
                    ...state,
                    scheduledTimestamp: newScheduledTimestamp,
                };
                const isComplete = isFormComplete(newState);
                return {
                    ...newState,
                    navState: getCurationNavState(
                        isComplete,
                        false,
                        URLPaths.CURATIONS_CONDITIONAL_SPLIT_SCHEDULE,
                        action,
                        URLPaths.CURATIONS_CONDITIONAL_SPLIT
                    ),
                    isComplete,
                };
            }
            return {
                ...state,
                navState: getCurationNavState(
                    isFormComplete(state),
                    false,
                    URLPaths.CURATIONS_CONDITIONAL_SPLIT_SCHEDULE,
                    action,
                    URLPaths.CURATIONS_CONDITIONAL_SPLIT
                ),
            };
        case actionTypes.CONDITIONAL_SPLIT_SET_SCHEDULE_DATETIME_ERROR:
            return {
                ...state,
                isDatetimeError: action.payload,
                isComplete: isFormComplete({ ...state, isDatetimeError: action.payload }),
            };
        case actionTypes.CONDITIONAL_SPLIT_RESET_CURATION:
            return initialState;
        default:
            return state;
    }
};

export default scheduleReducer;
