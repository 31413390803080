import { useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import Button from 'domains/core/components/Button';
import { themeColors } from 'colors';

export type CurationItemProps = {
    imageSrc: any;
    isDisabled?: boolean;
    subtitle?: string;
    title: string;
    updateTag?: React.ReactNode;
    onClick: () => void;
};

const CurationItem = ({ imageSrc, isDisabled, subtitle, title, updateTag, onClick }: CurationItemProps) => {
    const [isHovered, setIsHovered] = useState(false);

    const containerStyles = (disabled: boolean) => ({
        position: 'relative',
        borderRadius: '6px',
        height: '20rem',
        overflow: 'hidden',
        alignItems: 'flex-end',
        border: disabled ? '0.125rem dashed' : '0.125rem solid',
        borderColor: disabled ? 'text.disabled' : 'other.black',
        '&:hover': {
            cursor: disabled ? 'initial' : 'pointer',
        },
    });
    const overlayHoverStyles = {
        zIndex: '10',
        flexGrow: '1',
        width: '100%',
        height: '100%',
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: !isDisabled && isHovered ? '#FFFFFFAA' : '#FFFFFF00',
    };
    const boxContainerStyles = {
        flexGrow: '1',
        width: '100%',
        height: '100%',
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
    };
    const buttonStyles = {
        background: themeColors.action.active,
        position: 'relative',
        bottom: '0.5rem',
        zIndex: !isDisabled && isHovered ? '100' : '-100',
        '&:hover': {
            background: themeColors.action.selected,
        },
    };
    const imageStyles = {
        width: 'calc(100% - 2rem)',
        height: '12rem',
        position: 'absolute',
        padding: '1rem',
    };
    const typeContainerStyles = {
        flexGrow: '1',
        width: '100%',
        height: '100%',
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'flex-end',
    };
    const backgroundStyles = {
        zIndex: '1',
        bottom: '0rem',
        left: '0rem',
        display: 'flex',
        width: 'calc(100% - 2.25rem)',
        background: 'rgba(255, 255, 255, 0.95)',
        padding: '1.125rem 1.375rem',
        justifyContent: 'flex-end',
    };
    const typeStyles = {
        color: !isDisabled && isHovered ? 'action.active' : 'primary.main',
        fontSize: '1.5rem',
        paddingTop: '0rem',
        fontWeight: '500',
        lineHeight: '2rem',
    };
    const comingSoonStyles = {
        color: 'action.active',
        fontSize: '1rem',
        letterSpacing: '0.125rem',
        position: 'relative',
        top: '0.25rem',
    };

    return (
        <Stack
            sx={containerStyles(isDisabled)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={!isDisabled ? onClick : undefined}
        >
            {updateTag}
            <Box sx={overlayHoverStyles} />
            <Box sx={imageStyles}>{imageSrc}</Box>
            <Stack sx={boxContainerStyles}>
                <Button sx={buttonStyles} size="medium" color="secondary" variant="contained">
                    Start Building
                </Button>
            </Stack>
            <Stack sx={typeContainerStyles}>
                <Stack sx={backgroundStyles}>
                    {isDisabled && <Typography sx={comingSoonStyles}>coming soon</Typography>}
                    <Typography sx={typeStyles}>{title}</Typography>
                    <Typography variant="body2">{subtitle}</Typography>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default CurationItem;
