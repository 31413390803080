import { UseQueryOptions } from 'react-query';
import useQuery from 'hooks/useQuery';
import { CuredApiPaths } from 'models/enums';
import { Template } from 'domains/content/types';
import QueryKeys from './keys';

const useTemplates = (reactQueryOptions?: UseQueryOptions<Template[], Error>) =>
    useQuery<Template[]>(QueryKeys.TEMPLATES, CuredApiPaths.TEMPLATES, reactQueryOptions);

export default useTemplates;
