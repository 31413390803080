import { CuredApiPaths } from 'models/enums';
import { Campaign } from 'domains/campaigns/types';
import { UseQueryOptions } from 'react-query';
import useQuery from '../useQuery';
import QueryKeys from './keys';

const useCampaigns = (reactQueryOptions?: UseQueryOptions<Campaign[], Error>) =>
    useQuery(QueryKeys.CAMPAIGNS, CuredApiPaths.CAMPAIGNS, reactQueryOptions);

export default useCampaigns;
