import * as actionTypes from '../constants';

export const toggleLeftNavPanelVisibility = () => ({
    type: actionTypes.TOGGLE_LEFT_NAV_PANEL_VISIBILITY,
});

export const minimizeLeftNavPanel = (isInCurationWorkflow: boolean) => ({
    type: actionTypes.MINIMIZE_LEFT_NAV_PANEL,
    payload: isInCurationWorkflow,
});

export const maximizeLeftNavPanel = () => ({
    type: actionTypes.MAXIMIZE_LEFT_NAV_PANEL,
});

export const onLeaveCurationWorkflow = () => ({
    type: actionTypes.ON_LEAVE_CURATION_WORKFLOW,
});

export const setLeftNavPanelButtonVisibility = (isVisible: boolean) => ({
    type: actionTypes.SET_LEFT_NAV_PANEL_BUTTON_VISIBILITY,
    payload: isVisible,
});
