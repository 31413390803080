import { InfoOutlined } from '@mui/icons-material';
import { Box, SxProps, Theme, Tooltip, Typography, TypographyProps } from '@mui/material';
import { ReactNode } from 'react';
import theme from 'theme';

const cardStyles: SxProps<Theme> = {
    border: '1px solid',
    borderColor: 'primary.light',
    borderRadius: 1,
    color: theme.palette.text.primary,
    height: '100%',
    minHeight: '130px',
    textAlign: 'center',
};

type Props = {
    children: ReactNode;
    isError?: boolean;
    isFigureCard?: boolean;
    isLoading?: boolean;
    label: string;
    sx?: SxProps<Theme>;
    tooltip?: string;
};

const Card = ({ children, isError, isFigureCard, isLoading, label, sx, tooltip }: Props) => {
    const renderCardBody = () => {
        const sharedProps: TypographyProps = {
            mt: 2,
            variant: isFigureCard ? 'body1' : 'body2',
        };

        if (isLoading)
            return (
                <Typography fontStyle="italic" {...sharedProps}>
                    Loading
                </Typography>
            );

        if (isError)
            return (
                <Typography color={theme.palette.error.main} {...sharedProps}>
                    Something went wrong.
                </Typography>
            );

        if (isFigureCard) {
            return (
                <Typography fontWeight="bold" variant="body1">
                    {children}
                </Typography>
            );
        }

        return children;
    };

    const renderCardLabel = () => {
        const sharedProps: TypographyProps = {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            variant: 'body2',
        };

        const typographyProps: TypographyProps = isFigureCard
            ? {
                  ...sharedProps,
              }
            : {
                  ...sharedProps,
                  mt: 2,
                  fontWeight: 'bold',
              };

        return (
            <Typography {...typographyProps}>
                {label}
                {tooltip && (
                    <Tooltip placement="top" title={tooltip}>
                        <InfoOutlined sx={{ height: '16px', marginTop: '2px' }} />
                    </Tooltip>
                )}
            </Typography>
        );
    };

    if (isFigureCard) {
        return (
            <Box sx={{ ...cardStyles, ...sx, alignContent: 'center' }}>
                {renderCardBody()}
                {renderCardLabel()}
            </Box>
        );
    }

    return (
        <Box sx={{ ...cardStyles, ...sx }}>
            {renderCardLabel()}
            {renderCardBody()}
        </Box>
    );
};

export default Card;
