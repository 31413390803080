import * as actionTypes from '../constants';

type Action = {
    type: string;
    payload: any;
};

type State = {
    isLeftNavPanelMaximized: boolean;
    isLeftNavPanelButtonVisible: boolean;
    isInCurationWorkflow: boolean;
};

export const initialState: State = {
    isLeftNavPanelMaximized: false,
    isLeftNavPanelButtonVisible: true,
    isInCurationWorkflow: false,
};

export const appVisualReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case actionTypes.TOGGLE_LEFT_NAV_PANEL_VISIBILITY:
            return { ...state, isLeftNavPanelMaximized: !state.isLeftNavPanelMaximized };
        case actionTypes.MINIMIZE_LEFT_NAV_PANEL:
            const isInCurationWorkflow = action.payload;
            if (isInCurationWorkflow && state.isInCurationWorkflow) {
                return { ...state };
            } else if (isInCurationWorkflow) {
                return { ...state, isLeftNavPanelMaximized: false, isInCurationWorkflow: true };
            } else {
                return { ...state, isLeftNavPanelMaximized: false };
            }
        case actionTypes.MAXIMIZE_LEFT_NAV_PANEL:
            return { ...state, isLeftNavPanelMaximized: true };
        case actionTypes.ON_LEAVE_CURATION_WORKFLOW:
            return { ...state, isInCurationWorkflow: false };
        case actionTypes.SET_LEFT_NAV_PANEL_BUTTON_VISIBILITY:
            return { ...state, isLeftNavPanelButtonVisible: action.payload };
        default:
            return state;
    }
};

export default appVisualReducer;
