import { UseMutationOptions } from 'react-query';
import { SendInput } from 'domains/campaigns/types';
import { CampaignType, CuredApiPaths } from 'models/enums';
import useMutation from '../useMutation';
import MutationKeys from './keys';

export type CreateCampaignMutationInput = {
    name: string;
    type: CampaignType;
    sends: SendInput[];
};

type CreateCampaignResponse = { id: string };

/**
 * Creates a campaign.
 * @param mutateOptions react-query useMutation option
 * @returns the react-query useMutation results
 * See https://react-query-v3.tanstack.com/reference/useMutation for options and return value
 */
const useCreateCampaign = (
    mutateOptions?: UseMutationOptions<CreateCampaignResponse, Error, CreateCampaignMutationInput>
) =>
    useMutation<CreateCampaignMutationInput, CreateCampaignResponse>(
        MutationKeys.CREATE_CAMPAIGN,
        CuredApiPaths.CAMPAIGNS,
        mutateOptions
    );

export default useCreateCampaign;
