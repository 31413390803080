import RefreshIcon from '@mui/icons-material/Refresh';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { ArrowBack, ContactPageOutlined, SupervisedUserCircleOutlined } from '@mui/icons-material';
import { Typography, Skeleton, Stack } from '@mui/material';
import { DateTime } from 'luxon';
import { URLPaths, SortDirection } from 'models/enums';
import useContacts from 'hooks/queries/useContacts';
import useImports from 'hooks/queries/useImports';
import Button from 'domains/core/components/Button';
import EmptyStateLayout from 'domains/core/components/EmptyStateLayout';
import { RotatingIconButton } from 'domains/core/components/IconButton';
import PageLayout from 'domains/core/components/PageLayout';
import ContactsUploadDialog from 'domains/contacts/components/ContactsUploadDialog';
import FileUploadButton from 'domains/contacts/components/FileUploadButton';
import useContactsFileUploadProps from 'domains/contacts/hooks/useContactsFileUploadProps';
import ContactsTable from './ContactsTable';

type LastUploadProps = {
    imports: any;
    loading: boolean;
};

const formatLastUpdatedTime = (imports: any) => {
    if (!imports || imports.length === 0) {
        return 'never';
    }
    const dateTime = DateTime.fromISO(imports[0].jobTimestamp);
    const dateTimePart1 = dateTime.toFormat('cccc, MMMM d, yyyy');
    const dateTimePart2 = dateTime.toFormat('h:mma').toLowerCase();
    return `${dateTimePart1} at ${dateTimePart2}`;
};

const LastUpload = ({ imports, loading }: LastUploadProps) => (
    <Typography variant="body2">
        {loading ? <Skeleton width={360} /> : `Last updated on ${formatLastUpdatedTime(imports)}`}
    </Typography>
);

/**
 * The contacts home page for viewing and managing contacts.
 * This page has two different views:
 *  - Directly accessing the Contacts Page from the side bar (.../contacts)
 *  - Viewing an Segment from the visibility icon (eyeball icon) in the Segments Table (.../segments/contacts)
 * */
const ContactsPage = () => {
    const history = useHistory<any>();

    const route = history.location.pathname;
    const segmentId = history.location.state?.segmentId ?? null;
    const segmentName = history.location.state?.segmentName ?? '';

    // State variables so that the React Query refetch can be according to the proper sort params.
    const [sortColumnState, setSortColumnState] = useState('');
    const [sortDirectionState, setSortDirectionState] = useState(SortDirection.ASCENDING);

    const {
        data: contacts,
        isSuccess: isContactsSuccess,
        isRefetching: isContactsRefetching,
        refetch: contactsRefetch,
    } = useContacts({
        options: {
            segmentId,
            sortColumn: sortColumnState,
            sortDirection: sortDirectionState,
        },
        reactQueryOptions: {
            enabled: Boolean(sortColumnState) && Boolean(sortDirectionState),
        },
    });
    const { data: imports, isLoading: isImportsLoading } = useImports();

    const fileUploadProps = useContactsFileUploadProps();

    const isFromSegments = route === URLPaths.SEGMENTS_CONTACTS;
    const isContactsEmpty = !contacts?.length;

    useEffect(() => {
        if (isFromSegments && !segmentId && !segmentName) {
            history.push(URLPaths.SEGMENTS);
        }
    }, [history, isFromSegments, segmentId, segmentName]);

    useEffect(() => {
        contactsRefetch();
    }, [contactsRefetch, sortColumnState, sortDirectionState]);

    const onSort = (sortColumn: string, direction: SortDirection) => {
        setSortColumnState(sortColumn);
        setSortDirectionState(direction);
    };

    const RefreshButton = (
        <Stack direction="row" alignItems="center">
            <Typography variant="buttonMedium">Refresh</Typography>
            <RotatingIconButton
                aria-label="refresh"
                disabled={isContactsRefetching}
                isRefetching={isContactsRefetching}
                Icon={RefreshIcon}
                iconSx={{ fontSize: 20 }}
                handleClick={() => contactsRefetch()}
            />
        </Stack>
    );

    if (!isFromSegments && isContactsSuccess && isContactsEmpty) {
        return (
            <EmptyStateLayout
                caption="contacts"
                header="let's get started"
                guidingText="Upload your contacts to begin engaging with your audience."
            >
                <ContactsUploadDialog {...fileUploadProps} />
                <FileUploadButton {...fileUploadProps} />
            </EmptyStateLayout>
        );
    }

    return (
        <PageLayout
            header={
                <Stack rowGap={1}>
                    <Typography variant="h4">{!isFromSegments ? 'contacts' : segmentName}</Typography>
                    {!isFromSegments && <LastUpload imports={imports} loading={isImportsLoading} />}
                </Stack>
            }
            headerIcon={
                !isFromSegments ? (
                    <ContactPageOutlined fontSize="inherit" />
                ) : (
                    <SupervisedUserCircleOutlined fontSize="inherit" />
                )
            }
            headerAction={
                !isFromSegments ? (
                    <Stack direction="row" alignItems="center" columnGap={2}>
                        {RefreshButton}
                        <FileUploadButton {...fileUploadProps} />
                    </Stack>
                ) : (
                    <Button variant="text" startIcon={<ArrowBack />} onClick={() => history.push(URLPaths.SEGMENTS)}>
                        All Audiences
                    </Button>
                )
            }
        >
            <ContactsUploadDialog {...fileUploadProps} />
            {isContactsSuccess && !isContactsEmpty && <ContactsTable contacts={contacts} onSort={onSort} />}
            {isFromSegments && isContactsSuccess && isContactsEmpty && (
                <Typography>There are no contacts in this audience.</Typography>
            )}
        </PageLayout>
    );
};

export default ContactsPage;
