/* Single send curation constants */
export const SINGLE_SEND_EDIT_SMS_CURATION = 'SINGLE_SEND_EDIT_SMS_CURATION';
export const SINGLE_SEND_RESET_CURATION = 'SINGLE_SEND_RESET_CURATION';
export const SINGLE_SEND_SET_SMS_CURATION_DATETIME_ERROR = 'SINGLE_SEND_SET_SMS_CURATION_DATETIME_ERROR';

/* Time delay curation constants */
export const TIME_DELAY_EDIT_METADATA = 'TIME_DELAY_EDIT_METADATA';
export const TIME_DELAY_RESET_CURATION = 'TIME_DELAY_RESET_CURATION';

/* Conditional split curation constants */
export const CONDITIONAL_SPLIT_EDIT_AUDIENCE = 'CONDITIONAL_SPLIT_EDIT_AUDIENCE';
export const CONDITIONAL_SPLIT_EDIT_CONTENT1 = 'CONDITIONAL_SPLIT_EDIT_CONTENT1';
export const CONDITIONAL_SPLIT_EDIT_CONTENT2 = 'CONDITIONAL_SPLIT_EDIT_CONTENT2';
export const CONDITIONAL_SPLIT_EDIT_SEGMENTS = 'CONDITIONAL_SPLIT_EDIT_SEGMENTS';
export const CONDITIONAL_SPLIT_EDIT_SEGMENTS_MEETS_ACTION = 'CONDITIONAL_SPLIT_EDIT_SEGMENTS_MEETS_ACTION';
export const CONDITIONAL_SPLIT_EDIT_SEGMENTS_MEETS_CONTENT = 'CONDITIONAL_SPLIT_EDIT_SEGMENTS_MEETS_CONTENT';
export const CONDITIONAL_SPLIT_EDIT_SEGMENTS_MEETS_NOT_ACTION = 'CONDITIONAL_SPLIT_EDIT_SEGMENTS_MEETS_NOT_ACTION';
export const CONDITIONAL_SPLIT_EDIT_SEGMENTS_MEETS_NOT_CONTENT = 'CONDITIONAL_SPLIT_EDIT_SEGMENTS_MEETS_NOT_CONTENT';

export const CONDITIONAL_SPLIT_EDIT_DELAY = 'CONDITIONAL_SPLIT_EDIT_DELAY';
export const CONDITIONAL_SPLIT_EDIT_METADATA = 'CONDITIONAL_SPLIT_EDIT_METADATA';
export const CONDITIONAL_SPLIT_EDIT_PUBLISH = 'CONDITIONAL_SPLIT_EDIT_PUBLISH';
export const CONDITIONAL_SPLIT_EDIT_SCHEDULE = 'CONDITIONAL_SPLIT_EDIT_SCHEDULE';

export const CONDITIONAL_SPLIT_RESET_CURATION = 'CONDITIONAL_SPLIT_RESET_CURATION';

export const CONDITIONAL_SPLIT_SET_SCHEDULE_DATETIME_ERROR = 'CONDITIONAL_SPLIT_SET_SCHEDULE_DATETIME_ERROR';
