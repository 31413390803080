import { UseQueryOptions } from 'react-query';
import { CuredApiPaths } from 'models/enums';
import useQuery from 'hooks/useQuery';

const useContactSubscribes = (reactQueryOptions?: UseQueryOptions<any, Error>) => {
    const path = CuredApiPaths.REPORT_CONTACTS_SUBSCRIBES;

    return useQuery('contacts-subscribes', path, reactQueryOptions);
};

export default useContactSubscribes;
