import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import FormLayout from 'domains/core/components/FormLayout';
import useSegments from 'hooks/queries/useSegments';
import { CurationStep } from 'models/enums';
import SparkleIcon from 'domains/core/components/SvgIcons/SparkleIcon';
import { RootState } from 'state';
import { DelayFields, ScheduleFields } from 'domains/campaigns/types';
import CampaignPublishForm from 'domains/campaigns/pages/CampaignPublishPage/CampaignPublishForm';
import { getConditionalSplitCurationDetails } from 'domains/campaigns/utils';

const selectAudienceId = (state: any) => state.curation.conditionalSplit.audience.audienceId;
const selectContent1Id = (state: any) => state.curation.conditionalSplit.content1.templateId;

const selectSegments = (state: any) => state.curation.conditionalSplit.segments.segments;
const selectSegmentsMeetsContentId = (state: any) => state.curation.conditionalSplit.segmentsMeetsContent.templateId;
const selectSegmentsMeetsNotContentId = (state: any) =>
    state.curation.conditionalSplit.segmentsMeetsNotContent.templateId;

const selector = createSelector(
    selectAudienceId,
    selectContent1Id,
    selectSegments,
    selectSegmentsMeetsContentId,
    selectSegmentsMeetsNotContentId,
    (audienceId, content1Id, segments, segmentsMeetsContentId, segmentsMeetsNotContentId) => ({
        audienceId,
        content1Id,
        segments,
        segmentsMeetsContentId,
        segmentsMeetsNotContentId,
    })
);

type Props = {
    delayedSendTimestamp: string;
    selectDelayFields: (state: RootState) => DelayFields;
    selectScheduleFields: (state: RootState) => ScheduleFields;
};

const ConditionalSplitPublishPage = ({ delayedSendTimestamp, selectDelayFields, selectScheduleFields }: Props) => {
    const { audienceId, content1Id, segments, segmentsMeetsContentId, segmentsMeetsNotContentId } = useSelector(
        selector
    );

    const delayFields = useSelector(selectDelayFields);
    const scheduleFields = useSelector(selectScheduleFields);

    const { isLoading: isAudiencesLoading, data: audiencesData } = useSegments();

    if (isAudiencesLoading) {
        return null;
    }

    const audience = audiencesData?.find((audience) => audience.id === audienceId);

    const firstSend = {
        id: content1Id,
        recurrenceFrequency: scheduleFields.recurrenceFrequency,
        scheduledEndTimestamp: scheduleFields.scheduledEndTimestamp,
        scheduledTimestamp: scheduleFields.scheduledTimestamp,
    };
    const yesCriteriaSend = {
        id: segmentsMeetsContentId,
        delayFields,
        scheduledTimestamp: delayedSendTimestamp,
    };
    const noCriteriaSend = {
        id: segmentsMeetsNotContentId,
        delayFields,
        scheduledTimestamp: delayedSendTimestamp,
    };
    const details = getConditionalSplitCurationDetails({
        firstSend,
        noCriteriaSend,
        segments,
        yesCriteriaSend,
    });

    return (
        <FormLayout
            data-testid="curation-publish-form-layout"
            header="last step!"
            icon={<SparkleIcon />}
            stepName={CurationStep.PUBLISH}
            guidingText="Take one more moment to confirm the details of your conditional split Curation before you publish."
        >
            <CampaignPublishForm audience={audience} details={details} />
        </FormLayout>
    );
};

export default ConditionalSplitPublishPage;
