import { Grid, Typography } from '@mui/material';
import DashboardHeader from '../DashboardHeader';
import Card from '../Card';
import LineGraph from 'pages/InsightsPage/visualizations/LineGraph';
import CustomTooltip from 'pages/InsightsPage/visualizations/CustomTooltip';
import BarGraph from 'pages/InsightsPage/visualizations/BarGraph';
import USMap from 'pages/InsightsPage/visualizations/USMap';
import mockMapData from './mockMapData.json';
import { propScoreByAgeAndGender, propScoreContactsByContactsCount, propScorePercent } from './mockData';

const ContactsDashboard = () => (
    <div style={{ paddingBottom: '32px' }}>
        <Grid container spacing={3}>
            <Grid item xs={12} md={8} alignSelf="center">
                <DashboardHeader
                    title="propensity scoring"
                    subtitle="understanding propensity scoring"
                    description="Propensity scores are predictions generated from machine learning models that focus on discovering the likelihood that an individual will use, engage with, or purchase a given product or service. A contact with a higher propensity score is more likely to proceed with a desired action (e.g. schedule a cardiology appointment)."
                />
            </Grid>
            <Grid item container xs={12} spacing={2} justifyContent="center">
                <Grid item xs={12} md={3} alignSelf="center" textAlign="right">
                    <Typography variant="h6">how does it work?</Typography>
                    Find out how age and gender affect cardiology propensity scores (‘non-male’ refers to female- and
                    nonbinary-identifying individuals).
                </Grid>
                <Grid item xs={12} md={8}>
                    <Card label="Cardiology Propensity Score by Age and Gender">
                        <LineGraph
                            bottomLegendLabel="age"
                            data={propScoreByAgeAndGender}
                            leftLegendLabel="cardiology propensity score"
                            legends={[
                                {
                                    anchor: 'bottom',
                                    direction: 'row',
                                    translateY: 70,
                                    itemWidth: 80,
                                    itemHeight: 20,
                                    symbolSize: 12,
                                    symbolShape: 'circle',
                                },
                            ]}
                            renderCustomTooltip={(point) => (
                                <CustomTooltip
                                    color={point.serieColor}
                                    label={`age - ${point.data.xFormatted}`}
                                    value={`${point.serieId} - ${point.data.yFormatted}`}
                                />
                            )}
                            scale="linear"
                        />
                    </Card>
                </Grid>
            </Grid>
            <Grid item container xs={12} spacing={2} justifyContent="center">
                <Grid item xs={12} md={3} alignSelf="center" textAlign="right">
                    <Typography variant="h6">zip code view</Typography>
                    Understand the potential for your contacts to utilize cardiology services, based on their geographic
                    location.
                </Grid>
                <Grid item xs={12} md={8}>
                    <Card label="Average Cardiology Propensity Score by Zip Code">
                        <USMap bubbleData={mockMapData} />
                    </Card>
                </Grid>
            </Grid>
            <Grid item container xs={12} spacing={2} justifyContent="center">
                <Grid item xs={12} md={3} alignSelf="center" textAlign="right">
                    <Typography variant="h6">counting contacts</Typography>
                    Understand how your contacts are distributed over the propensity score spectrum to determine how to
                    segment your audience.
                </Grid>
                <Grid item xs={12} md={8}>
                    <Card label="Number of Contacts by Cardiology Propensity Score">
                        <BarGraph
                            bottomLegendLabel="cardiology propensity score"
                            data={propScoreContactsByContactsCount}
                            leftLegendLabel="number of contacts"
                        />
                    </Card>
                </Grid>
            </Grid>
            <Grid item container xs={12} spacing={2} justifyContent="center">
                <Grid item xs={12} md={3} alignSelf="center" textAlign="right">
                    <Typography variant="h6">visualizing propensity scores</Typography>
                    Build your audience based on the percentage of contacts you want to reach.
                </Grid>
                <Grid item xs={12} md={8}>
                    <Card label="Percentage of Contacts At or Above Each Cardiology Propensity Score">
                        <LineGraph
                            bottomLegendLabel="cardiology propensity score"
                            data={propScorePercent}
                            enableArea
                            leftLegendLabel="percentage of contacts"
                            renderCustomTooltip={(point) => (
                                <CustomTooltip
                                    color={point.serieColor}
                                    label={`cardiology propensity score - ${point.data.xFormatted}`}
                                    value={`percent of data at or above - ${point.data.yFormatted}`}
                                />
                            )}
                            scale="percentage"
                        />
                    </Card>
                </Grid>
            </Grid>
            <Grid item container xs={12} spacing={2} justifyContent="center">
                <Grid item xs={12} md={5} textAlign="center">
                    <Typography variant="h6">next steps</Typography>
                    Now create an audience based on cardiology propensity scores – we suggest you start by filtering on
                    contacts with higher propensity scores. Click <a href="/audiences/create">here</a> to create an
                    audience now.
                </Grid>
            </Grid>
        </Grid>
    </div>
);

export default ContactsDashboard;
