import { CustomCampaignSteps } from 'domains/campaigns/components/CustomCampaign/CustomCampaign';
import Button from 'domains/core/components/Button';
import { useEffect } from 'react';

type Props = {
    activeStep: CustomCampaignSteps;
    isContinueEnabled: boolean;
    onContinueClick: () => void;
    onPublishClick: () => void;
    setIsContinueEnabled: (isContinueEnabled: boolean) => void;
};

const CustomCampaignNavigationButtons = ({
    activeStep,
    isContinueEnabled,
    onContinueClick,
    onPublishClick,
    setIsContinueEnabled,
}: Props) => {
    useEffect(() => {
        // Disabling the continue button with every step change
        // as every step has its own unique validation requirements
        setIsContinueEnabled(false);
    }, [activeStep, setIsContinueEnabled]);

    const hideNavigationButtons =
        activeStep === CustomCampaignSteps.CHOOSE_NEXT_STEP || activeStep === CustomCampaignSteps.SELECT_PATH_ACTION;
    if (hideNavigationButtons) return;

    if (activeStep === CustomCampaignSteps.PUBLISH_CAMPAIGN) {
        return (
            <Button onClick={onPublishClick} variant="outlined">
                Publish
            </Button>
        );
    }

    return (
        <Button disabled={!isContinueEnabled} onClick={onContinueClick} variant="outlined">
            Continue
        </Button>
    );
};

export default CustomCampaignNavigationButtons;
