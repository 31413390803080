import { Email, Send } from 'domains/campaigns/types';
import { CuredApiPaths } from 'models/enums';
import { UseQueryOptions } from 'react-query';
import useQuery from '../useQuery';
import QueryKeys from './keys';
import { PathWithID } from 'models/types';

const useAppSendSenderProfileTemplate = (sendId: Send['id'], reactQueryOptions?: UseQueryOptions<Email, Error>) => {
    const path = new PathWithID(CuredApiPaths.APP_SENDS, `${sendId}/sender-profile-template`);
    return useQuery(QueryKeys.APP(path.url), path, reactQueryOptions);
};

export default useAppSendSenderProfileTemplate;
