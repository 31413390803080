import { CSSProperties, forwardRef } from 'react';
import { Button as MUIButton, ButtonProps, Theme, Typography } from '@mui/material';
import theme from 'theme';

/**
 * The font we are using is off center, so we need to offset it a little to bring it back to center.
 * Since we are using a Typography component for this, the font styles that could normally all applied through the theme have to be applied manually.
 * */
export const getFontStyles = (
    theme: Theme,
    {
        color = 'primary',
        disabled = false,
        size = theme.components.MuiButton.defaultProps.size,
        variant = 'text',
        sx,
    }: ButtonProps
): CSSProperties => ({
    ...{
        small: {
            fontSize: theme.typography.buttonSmall.fontSize,
            fontFamily: theme.typography.buttonSmall.fontFamily,
            fontWeight: theme.typography.buttonSmall.fontWeight,
            letterSpacing: theme.typography.buttonSmall.letterSpacing,
            lineHeight: theme.typography.buttonSmall.lineHeight,
        },
        medium: {
            fontSize: theme.typography.buttonMedium.fontSize,
            fontFamily: theme.typography.buttonMedium.fontFamily,
            fontWeight: theme.typography.buttonMedium.fontWeight,
            letterSpacing: theme.typography.buttonMedium.letterSpacing,
            lineHeight: theme.typography.buttonMedium.lineHeight,
        },
        large: {
            fontSize: theme.typography.buttonLarge.fontSize,
            fontFamily: theme.typography.buttonLarge.fontFamily,
            fontWeight: theme.typography.buttonLarge.fontWeight,
            letterSpacing: theme.typography.buttonLarge.letterSpacing,
            lineHeight: theme.typography.buttonLarge.lineHeight,
        },
    }[size],
    ...(variant === 'contained' && { color: theme.palette.primary.contrastText }),
    ...((variant === 'outlined' || variant === 'text') &&
        color === 'secondary' && { color: theme.palette.secondary.main }),
    ...((variant === 'outlined' || variant === 'text') && disabled && { color: theme.palette.action.disabled }),

    // @ts-ignore
    ...(sx?.color && { color: sx?.color }),
});

/**
 * A wrapper around the MUI Button component. Our acumin-pro font has uneven vertical spacing, so we need to render the children as a Typography and apply the appropriate font styles.
 * @param props the MUI Button props
 * @returns The React node created by this component.
 */
const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    const { children } = props;
    return (
        <MUIButton ref={ref} {...props}>
            {typeof children === 'string' ? (
                <Typography
                    pb={0.25}
                    sx={{
                        ...getFontStyles(theme, props),
                    }}
                >
                    {children}
                </Typography>
            ) : (
                children
            )}
        </MUIButton>
    );
});

Button.displayName = 'Button';

export default Button;
