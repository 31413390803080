import { UseQueryOptions } from 'react-query';
import { SenderProfile } from 'domains/content/types';
import { CuredApiPaths } from 'models/enums';
import useQuery from '../useQuery';
import QueryKeys from './keys';

/**
 * Returns a react query hook containing the sender profiles data.
 * @param reactQueryOptions
 * @returns
 */
const useSenderProfiles = (reactQueryOptions?: UseQueryOptions<SenderProfile[], Error>) =>
    useQuery<SenderProfile[]>(QueryKeys.SENDER_PROFILES, CuredApiPaths.SENDER_PROFILES, reactQueryOptions);

export default useSenderProfiles;
