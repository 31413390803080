import React from 'react';
import { Box, Container, Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Button from 'domains/core/components/Button';
import unsubscribeImage from 'assets/images/png/unsubscribe.png';
import SuccessSnackbar from 'domains/core/components/Snackbars/SuccessSnackbar';
import ErrorSnackbar from 'domains/core/components/Snackbars/ErrorSnackbar';
import useUnsubscribe from 'hooks/mutations/useUnsubscribe';
import theme from 'theme';

const useStyles = makeStyles({
    center: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            backgroundColor: 'white',
        },
        [theme.breakpoints.up('md')]: {
            backgroundImage: 'linear-gradient(to left, #a1fadb, #a1fadb)',
            backgroundPosition: 'right bottom',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '26.25% 100%',
        },
    },
});
/**
 * A page to confirm unsubscribe, and display a success message to the user.
 *
 * @returnsThe React node created by this component.
 */
const UnsubscribeLandingPage = () => {
    const params = new URLSearchParams(window.location.search);
    const campaignId = params.get('campaignId');
    const clientId = params.get('clientId');
    const curedId = params.get('curedId');
    const jobId = params.get('jobId');

    const hiddenMdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const { mutate, isSuccess, isError } = useUnsubscribe({ campaignId, clientId, curedId, jobId });
    const classes = useStyles();

    return (
        <Container className={classes.container}>
            {isSuccess && <SuccessSnackbar successMessage="You've been successfully unsubscribed." />}
            {isError && (
                <ErrorSnackbar errorMessage="Sorry, there was an error while processing your unsubscribe request." />
            )}
            <Grid container>
                {!hiddenMdDown && <Grid item md={1} />}
                <Grid item sm={12} md={6}>
                    <Box className={classes.center}>
                        <Typography align="center" variant="caption" color="secondary.dark">
                            unsubscribe
                        </Typography>
                        <Typography align="center" variant="h3" mt={4} mb={4}>
                            we're sad to see you go!
                        </Typography>
                        <Typography align="center" variant="body1" mt={2} mb={6}>
                            But we value your privacy more. <br />
                            Please confirm your removal to stop receiving emails from this sender.
                        </Typography>
                        <Box>
                            <Button variant="contained" onClick={() => mutate({ status: 'unsubscribed' })}>
                                Confirm Unsubscribe
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                {!hiddenMdDown && (
                    <>
                        <Grid item md={4}>
                            <Box className={classes.center}>
                                <Box>
                                    <img src={unsubscribeImage} alt="Unsubscribe from Cured" />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item sm={1} />
                    </>
                )}
            </Grid>
        </Container>
    );
};

export default UnsubscribeLandingPage;
