import { InfoOutlined } from '@mui/icons-material';
import { Checkbox, FormControlLabel, Stack, Tooltip } from '@mui/material';

type Props = {
    isNullValuesIncluded: boolean;
    updateFilter: () => void;
};

const IncludeNullValuesCheckbox = ({ isNullValuesIncluded, updateFilter }: Props) => (
    <Stack direction="row" alignItems="center" columnGap={2}>
        <FormControlLabel
            control={
                <Checkbox
                    checked={isNullValuesIncluded ?? false}
                    color="secondary"
                    size="small"
                    onChange={updateFilter}
                />
            }
            label="Include null values"
            sx={{
                // Accounting for the 9px padding around the checkbox
                margin: '-9px',
                '.MuiFormControlLabel-label': {
                    fontSize: '1rem',
                },
            }}
        />
        <Tooltip title="When checked, contacts with a null value in this field are eligible for this filter criteria.">
            <InfoOutlined sx={{ fontSize: 14, mt: 0.5 }} />
        </Tooltip>
    </Stack>
);

export default IncludeNullValuesCheckbox;
