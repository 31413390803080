import { DateTime } from 'luxon';
import { EmailSendInput, EmailSendInputState, SplitTypes } from 'domains/campaigns/types';
import {
    EngagementAPIOperator,
    EngagementName,
    EngagementUIOperator,
    SegmentDemographic,
} from 'domains/segments/types';
import {
    convertFilterToEvent,
    engagementUiToApiOperator,
    getInclusionOperatorFromUIToAPI,
} from 'domains/segments/utils';
import { FilterCategory } from 'models/enums';

export const getDelayValue = (timeDifference: number): number =>
    timeDifference % 24 ? timeDifference : timeDifference / 24;

export const getDelayUnit = (timeDifference: number): string => (timeDifference % 24 ? 'hours' : 'days');

export const getTimeDifferenceInHours = (firstTime: string, secondTime?: string): number => {
    if (!secondTime) return;

    const firstDate = DateTime.fromISO(firstTime);
    const secondDate = DateTime.fromISO(secondTime);

    // Handle small rounding differences by calculating the time difference then rounding it.
    const timeDifference = parseFloat(Math.abs(secondDate.diff(firstDate, 'hours').as('hours')).toFixed(2));

    return timeDifference;
};

export const getDelayUnitAndValue = (parentAudienceDelayInterval?: string) => {
    if (!parentAudienceDelayInterval)
        return {
            initialDelayValue: '',
            initialDelayUnit: '',
        };
    const words = parentAudienceDelayInterval.split(' ');
    const initialDelayValue = words[0];
    const initialDelayUnit = words[1];
    return { initialDelayValue, initialDelayUnit };
};

export const getDelayedSendTime = (previousSendTimestamp: string, delayValue?: string, delayUnit?: string) => {
    if (!delayValue || !delayUnit) return;

    const formattedDelayValue = Number(delayValue);
    return DateTime.fromISO(previousSendTimestamp || '')
        .plus({ [delayUnit]: formattedDelayValue })
        .toISO();
};

export const getSegmentsForApi = (send: EmailSendInputState, sendForApi: EmailSendInput) => {
    // Presently, we're only supporting a single segment (hence the 0).
    const firstSegment = send.segments[0];
    const { name, operator, category, categorySpecificFields } = firstSegment;
    const isDoesNotMeetCriteriaSend = send?.splitType === SplitTypes.DOES_NOT_MEET;

    if (category === FilterCategory.CONTACT_ACTIVITY) {
        const meetsCriteriaOperator = engagementUiToApiOperator[operator as EngagementUIOperator];
        const operatorForApi = isDoesNotMeetCriteriaSend ? operator : meetsCriteriaOperator;

        sendForApi.segments = [
            {
                name: name as EngagementName,
                operator: operatorForApi as EngagementAPIOperator,
                value: categorySpecificFields?.value ? Number(categorySpecificFields?.value) : null,
            },
        ];
    }

    if (category === FilterCategory.CONTACT_DETAILS) {
        sendForApi.segments = [];
        sendForApi.segmentDefinition = {
            matchType: 'AND',
            contactEvent: [],
            demographic: [
                {
                    name,
                    operator: getInclusionOperatorFromUIToAPI(operator) as SegmentDemographic['operator'],
                    value: categorySpecificFields?.value,
                },
            ],
            engagement: [],
            ...(isDoesNotMeetCriteriaSend && { negateQuery: true }),
        };
    }

    if (category === FilterCategory.CONTACT_EVENTS) {
        sendForApi.segments = [];
        sendForApi.segmentDefinition = {
            matchType: 'AND',
            contactEvent: [convertFilterToEvent(firstSegment)],
            demographic: [],
            engagement: [],
            ...(isDoesNotMeetCriteriaSend && { negateQuery: true }),
        };
    }
};
