import { useEffect, useMemo } from 'react';
import { CuredApiPaths } from 'models/enums';
import Card from 'pages/InsightsPage/dashboards/Card';
import { FiltersForApiState } from 'pages/InsightsPage/dashboards/filtering/types';
import useFilteredData from 'pages/InsightsPage/dashboards/filtering/useFilteredData';
import DataGrid from 'pages/InsightsPage/visualizations/DataGrid';
import {
    getHeaderName,
    getValueFormatter,
    useGetRows,
    ValueFormatTypes,
} from 'pages/InsightsPage/visualizations/DataGrid/utils';

type Props = {
    filtersForApi: FiltersForApiState;
};

const ValueSummaryByCurationCard = ({ filtersForApi }: Props) => {
    const { getData, isError, isLoading, data } = useFilteredData({
        key: 'value-overview-dashboard/value-summary',
        path: CuredApiPaths.REPORT_VALUE_SUMMARY,
    });

    useEffect(() => {
        getData(filtersForApi);
    }, [filtersForApi, getData]);

    const tableData = data as {}[];

    const columns: any = useMemo(() => {
        if (!tableData?.length) return;

        const columnFields = Object.keys(tableData?.[0]);
        const columns = columnFields?.map((field) => ({
            field,
            headerName: getHeaderName(field),
            valueFormatter: ({ value }: { value?: string }) => {
                const getType = () => {
                    if (field.includes('rate')) return ValueFormatTypes.PERCENTAGE;
                    if (field.includes('value')) return ValueFormatTypes.CURRENCY;
                    return null;
                };
                return getValueFormatter({ type: getType(), value });
            },
            flex: 1,
        }));

        return columns;
    }, [tableData]);

    const rows = useGetRows(tableData);

    return (
        <Card isError={isError} isLoading={isLoading} label="Value Summary by Curation">
            <DataGrid columns={columns} rows={rows} hideFooterRowCount />
        </Card>
    );
};

export default ValueSummaryByCurationCard;
