import { UseQueryOptions } from 'react-query';
import { CuredApiPaths } from 'models/enums';
import { LandingPage } from 'domains/content/types';
import useQuery from 'hooks/useQuery';
import QueryKeys from './keys';

const useLandingPages = (reactQueryOptions?: UseQueryOptions<LandingPage[], Error>) =>
    useQuery<LandingPage[]>(QueryKeys.LANDING_PAGES, CuredApiPaths.LANDING_PAGES, reactQueryOptions);

export default useLandingPages;
