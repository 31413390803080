import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import useConfig, { ConfigProvider } from 'hooks/useConfig';
import App from './App';
import LDProvider from 'domains/core/components/LDProvider';
import store, { history } from './state';
import { AuthProvider } from 'auth/AuthContext';
import { init as initFullStory } from '@fullstory/browser';

const queryClient = new QueryClient();

const AppUsingConfig = () => {
    const { config, loading } = useConfig();

    if (loading) {
        return null;
    }

    if (config.FULLSTORY_ORG_ID) {
        initFullStory({ orgId: config.FULLSTORY_ORG_ID });
    }

    const onRedirectCallback = (appState: AppState) => history.push(appState?.returnTo || window.location.pathname);

    return (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <Auth0Provider
                    domain={config.AUTH0_DOMAIN}
                    clientId={config.AUTH0_CLIENT_ID}
                    redirectUri={config.AUTH0_REDIRECT_URI}
                    audience={config.AUTH0_AUDIENCE}
                    useRefreshTokens={true}
                    cacheLocation="localstorage"
                    onRedirectCallback={onRedirectCallback}
                >
                    <AuthProvider>
                        <QueryClientProvider client={queryClient}>
                            <LDProvider>
                                <App />
                            </LDProvider>
                        </QueryClientProvider>
                    </AuthProvider>
                </Auth0Provider>
            </ConnectedRouter>
        </Provider>
    );
};

const AppWithProviders = () => (
    <React.StrictMode>
        <ConfigProvider>
            <AppUsingConfig />
        </ConfigProvider>
    </React.StrictMode>
);

ReactDOM.render(<AppWithProviders />, document.getElementById('root'));
