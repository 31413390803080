import React from 'react';
import { styled } from '@mui/system';
import { Box, Typography } from '@mui/material';
import { useCustomAuth } from 'auth/AuthContext';
import useConfig from 'hooks/useConfig';
import logoImage from 'assets/images/svg/cured-logo-light.svg';

const StyledLink = styled('a')(({ theme }) => ({
    cursor: 'pointer',
    color: theme.palette.other.lime,
    lineHeight: '0px',
}));

const FinishSignupPage = () => {
    const { logout } = useCustomAuth();
    const {
        config: { AUTH0_HOME_URI },
    } = useConfig();

    return (
        <Box
            display="flex"
            flex={1}
            flexDirection="column"
            justifyContent="center"
            textAlign="center"
            alignItems="center"
            sx={{ bgcolor: 'primary.main' }}
        >
            <Box mb={12.5}>
                <img src={logoImage} alt="cured logo" width="400px" />
            </Box>
            <Typography variant="h5" sx={{ color: 'primary.contrastText' }}>
                thank you for creating your account with cured!
            </Typography>
            <Typography variant="h5" sx={{ color: 'primary.contrastText' }}>
                a member of the onboarding team will contact you to complete the process.
            </Typography>
            <Typography variant="h5" sx={{ color: 'primary.contrastText' }}>
                <StyledLink
                    onClick={() => {
                        logout({ returnTo: AUTH0_HOME_URI });
                    }}
                    href="/#"
                >
                    log out
                </StyledLink>{' '}
                and log back in once a member of the onboarding team has granted you access.
            </Typography>
        </Box>
    );
};

export default FinishSignupPage;
