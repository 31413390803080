import { UseQueryOptions } from 'react-query';
import useQuery from 'hooks/useQuery';
import { CuredApiPaths, BeeConfigType } from 'models/enums';
import { PathWithID } from 'models/types';
import { Template as TemplateResponse } from 'domains/content/types';
import QueryKeys from './keys';

// The API will return a Template for saved templates or a string (the footer as a json string) for the default.
type Template = string | TemplateResponse;

// TODO: Retype templateId from 'string' to 'number' to not need type conversion when calling this hook every time
type UseTemplateParams = {
    templateId: string;
    type?: BeeConfigType;
    reactQueryOptions?: UseQueryOptions<Template, Error>;
};

const useTemplate = ({ templateId, type = BeeConfigType.EMAIL, reactQueryOptions }: UseTemplateParams) =>
    useQuery<Template>(
        QueryKeys.TEMPLATE_ID(templateId),
        `${
            new PathWithID(CuredApiPaths.TEMPLATES, templateId === 'default' ? templateId : Number(templateId)).url
        }?type=${type}`,
        reactQueryOptions
    );

export default useTemplate;
