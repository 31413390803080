import invert from 'lodash/invert';
import without from 'lodash/without';
import { DateTime } from 'luxon';
import { RootState } from 'state';
import { CurationNavState, CurationSegmentsAction, URLPaths } from 'models/enums';
import {
    CurationState,
    ConditionalSplitRoutes,
    CampaignKey,
    ContentFields,
    ScheduleFields,
    DelayFields,
    MetaDataFields,
} from 'domains/campaigns/types';
import {
    CampaignGraph,
    CampaignNode,
    CampaignNodeType,
    CampaignState,
    ContentNode,
    MetaDataNode,
    ScheduleNode,
    SegmentNode,
    DelayNode,
} from './createCampaignSlice';
import { getCampaignNavState, getCampaignRoute } from './utils';

/**
 * Routes
 */
export const selectNodeRoutes = (state: RootState) => ({
    campaignKey,
    isFirstContentNodeVisited,
    node,
}: {
    campaignKey: CampaignKey;
    isFirstContentNodeVisited: boolean;
    node: CampaignNode;
}) => {
    const { SEGMENT_NODE, CONTENT_NODE, DELAY_NODE } = CampaignNodeType;
    const { edges, nodes } = state.curation[campaignKey].campaignGraph;
    const { id, type } = node;

    let routes: string[] = [];
    let firstContentNodeVisited = isFirstContentNodeVisited;

    switch (type) {
        case SEGMENT_NODE:
        case DELAY_NODE:
            routes = [getCampaignRoute({ campaignKey, nodes, nodeId: id })];
            break;
        case CONTENT_NODE:
            const nextNode = edges.find((edge) => edge.source === id);
            if (nextNode) {
                const nextNodeDetails = nodes.find((node) => node.id === nextNode.target);
                if (nextNodeDetails) {
                    routes = firstContentNodeVisited
                        ? [getCampaignRoute({ campaignKey, nodes, nodeId: id })] // content
                        : [
                              getCampaignRoute({ campaignKey, nodes, nodeId: id }),
                              getCampaignRoute({ campaignKey, nodes, nodeId: nextNodeDetails.id }),
                          ]; // content, schedule
                    firstContentNodeVisited = true;
                }
            }
            break;
        default:
            break;
    }
    return { routes, firstContentNodeVisited };
};

const getNodeById = (state: RootState, campaignKey: CampaignKey, nodeId: string) =>
    state.curation[campaignKey].campaignGraph.nodes.find((node) => node.id === nodeId);

const getAdjacentNode = (
    state: RootState,
    campaignKey: CampaignKey,
    nodeId: string,
    direction: 'previous' | 'next'
) => {
    const edges = state.curation[campaignKey].campaignGraph.edges;
    let adjacentNodeId = null;

    edges.forEach((edge) => {
        if (direction === 'previous' && edge.target === nodeId) {
            adjacentNodeId = edge.source;
        } else if (direction === 'next' && edge.source === nodeId) {
            adjacentNodeId = edge.target;
        }
    });

    return getNodeById(state, campaignKey, adjacentNodeId);
};

// Selects the routes for the campaign where the key is the route, and the value is the nodeId
const selectCampaignRouteToNodeIdMap = (campaignKey: CampaignKey) => (state: RootState): Record<string, string> => {
    const { nodes } = state.curation[campaignKey].campaignGraph;
    return nodes.reduce((acc, { id }) => ({ ...acc, [getCampaignRoute({ campaignKey, nodes, nodeId: id })]: id }), {});
};

export const selectNodeIdByRoute = (campaignKey: CampaignKey) => (state: RootState) => (route: string): string => {
    const routeNodeIdPair = selectCampaignRouteToNodeIdMap(campaignKey)(state);
    const nodeId = routeNodeIdPair[route];
    return nodeId;
};

export const selectNextRoute = (campaignKey: CampaignKey) => (state: RootState) => (route: string): string | null => {
    const nodeId = selectNodeIdByRoute(campaignKey)(state)(route);

    // Get a list of content nodes
    const contentNodes = selectNodesByType<CampaignNode>(campaignKey, CampaignNodeType.CONTENT_NODE)(state);

    // Find the next node. If it doesn't exist, fall back to the last content node
    const nextNode = getAdjacentNode(state, campaignKey, nodeId, 'next') || contentNodes[contentNodes.length - 1];

    const { nodes } = state.curation[campaignKey].campaignGraph;
    // Return the route for the next node, if it exists
    return nextNode ? getCampaignRoute({ campaignKey, nodes, nodeId: nextNode.id }) : null;
};

export const selectRouteByType = <T extends CampaignNode>(campaignKey: CampaignKey, type: CampaignNodeType) => (
    state: RootState
): string => {
    const routes = selectCampaignRouteToNodeIdMap(campaignKey)(state);
    const nodeId = selectNodeByType<T>(campaignKey, type)(state)?.id;
    if (!nodeId) {
        throw new Error('Node not found for given type');
    }
    const route = invert(routes)[nodeId];
    return route;
};

export const selectMetadataRoute = (campaignKey: CampaignKey) => (state: RootState): string =>
    selectRouteByType<MetaDataNode>(campaignKey, CampaignNodeType.META_DATA_NODE)(state);

/**
 * Boolean checks
 */
export const selectIsCampaignRouteDisabled = (campaignKey: CampaignKey) => (state: RootState) => (
    route: string
): boolean => {
    const nodeId = selectNodeIdByRoute(campaignKey)(state)(route);

    const previousNode = getAdjacentNode(state, campaignKey, nodeId, 'previous');

    // If there is no previous node, this node can't be disabled
    if (!previousNode) return false;

    const isComplete = selectIsCampaignNodeComplete(campaignKey, previousNode.id)(state);
    return !isComplete;
};

export const selectIsRouteNextDisabled = (campaignKey: CampaignKey) => (state: RootState) => (
    route: string
): boolean => {
    const routes = selectCampaignRouteToNodeIdMap(campaignKey)(state);
    const nodeId = routes[route];

    const nextNode = getAdjacentNode(state, campaignKey, nodeId, 'next');

    // If there is no next node, this route can't be disabled
    if (!nextNode) return false;

    const nextRoute = Object.keys(routes).find((route) => routes[route] === nextNode.id);
    if (!nextRoute) {
        // If the next node doesn't have an associated route, return false
        return false;
    }

    const isRouteDisabled = selectIsCampaignRouteDisabled(campaignKey)(state);
    return isRouteDisabled(nextRoute);
};

const selectIsRouteIsNodeTypes = (campaignKey: CampaignKey, nodeTypes: CampaignNodeType[]) => (state: RootState) => (
    route: string
): boolean => {
    const { nodes } = state.curation[campaignKey].campaignGraph;

    // Find a node that corresponds to the current route.
    const correspondingNode = nodes.find((node) => getCampaignRoute({ campaignKey, nodes, nodeId: node.id }) === route);

    // Return true if the corresponding node type matches and false otherwise.
    return correspondingNode ? nodeTypes.includes(correspondingNode.type) : false;
};

export const selectIsRouteStartOrPublish = (campaignKey: CampaignKey) =>
    selectIsRouteIsNodeTypes(campaignKey, [CampaignNodeType.META_DATA_NODE, CampaignNodeType.PUBLISH_NODE]);

export const selectIsRouteAfterLastContent = (campaignKey: CampaignKey) => (state: RootState) => (
    route: string
): boolean => {
    const nodes = state.curation[campaignKey].campaignGraph.nodes;
    let isLastContentNode = false;

    for (const node of nodes) {
        if (node.type === CampaignNodeType.CONTENT_NODE && node.sendIndex === 1) {
            isLastContentNode = true;
        }
        // Check if the node is after the first delay node
        if (
            isLastContentNode &&
            (node.type === CampaignNodeType.CONTENT_NODE || node.type === CampaignNodeType.DELAY_NODE) &&
            getCampaignRoute({ campaignKey, nodes, nodeId: node.id }) === route
        ) {
            return true;
        }
    }

    return false;
};

export const selectIsRouteDelayAfterLastContent = (campaignKey: CampaignKey) => (state: RootState) => (
    route: string
): boolean => {
    const nodes = state.curation[campaignKey].campaignGraph.nodes;
    let isLastContentNode = false;

    for (const node of nodes) {
        if (node.type === CampaignNodeType.CONTENT_NODE && node.sendIndex === 1) {
            isLastContentNode = true;
        }
        // Check if the node is after the first delay node
        if (
            isLastContentNode &&
            node.type === CampaignNodeType.DELAY_NODE &&
            getCampaignRoute({ campaignKey, nodes, nodeId: node.id }) === route
        ) {
            return true;
        }
    }

    return false;
};

const selectIsCampaignNodeComplete = (campaignKey: CampaignKey, nodeId: string) => (state: RootState): boolean => {
    const node = selectNodeById(campaignKey, nodeId)(state);
    const campaignTree = state.curation[campaignKey].campaignTree;
    const { sendIndex, type } = node;
    const send = sendIndex || sendIndex === 0 ? campaignTree.sends[sendIndex] : null;

    switch (type) {
        case CampaignNodeType.META_DATA_NODE:
            const { name } = node;
            return !!name;
        case CampaignNodeType.SEGMENT_NODE:
            return !!send && !!send.segmentId;
        case CampaignNodeType.CONTENT_NODE:
            return (
                !!send &&
                // If the user enters a preheader, we want to validate the length
                (!!(send.preHeader.length > 0) ? !!(send.preHeader.length >= 3) : true) &&
                !!send.senderProfileId &&
                !!(send.subjectLine.length >= 3) &&
                !!send.templateId
            );
        case CampaignNodeType.CONDITIONAL_SPLIT_NODE:
            // Conditional split is not supported in the graph based state yet.
            return false;
        case CampaignNodeType.SCHEDULE_NODE:
            const { dateTimeError } = node;
            return !dateTimeError && !!send && !!send.scheduledTimestamp;
        case CampaignNodeType.DELAY_NODE:
            const { delay, unit } = node;
            return !!delay && !!unit;
        case CampaignNodeType.PUBLISH_NODE:
            const { isPublishClicked } = node;
            return !!isPublishClicked;
        default:
            return false;
    }
};

export const selectIsCampaignComplete = (campaignKey: CampaignKey) => (state: RootState): boolean => {
    const nodes = state.curation[campaignKey].campaignGraph.nodes;
    return nodes.every((node) => selectIsCampaignNodeComplete(campaignKey, node.id)(state));
};

export const selectIsSMSCurationComplete = (state: RootState) =>
    state.curation.sms.isComplete && state.curation.sms.buttonClicked;

/**
 * States
 */
const selectIsCampaignNodeInProgress = (campaignKey: CampaignKey, nodeId: string) => (state: RootState): boolean => {
    const node = selectNodeById(campaignKey, nodeId)(state);
    const campaignTree = state.curation[campaignKey].campaignTree;
    const { sendIndex, type } = node;
    const send = sendIndex || sendIndex === 0 ? campaignTree.sends[sendIndex] : null;
    switch (type) {
        case CampaignNodeType.META_DATA_NODE:
            return false;
        case CampaignNodeType.SEGMENT_NODE:
            return false;
        case CampaignNodeType.CONTENT_NODE:
            return (
                Boolean(send) &&
                (Boolean(send.senderProfileId) || Boolean(send.subjectLine.length >= 3) || Boolean(send.templateId))
            );
        case CampaignNodeType.CONDITIONAL_SPLIT_NODE:
            // Conditional split is not supported in the graph based state yet.
            return false;
        case CampaignNodeType.SCHEDULE_NODE:
            const { isRecommendedTimeEnabled, dateTimeError } = node;
            return (
                isRecommendedTimeEnabled ||
                dateTimeError ||
                (Boolean(send) &&
                    (Boolean(send.recurrenceFrequency) ||
                        Boolean(send.scheduledEndTimestamp) ||
                        Boolean(send.scheduledTimestamp)))
            );
        case CampaignNodeType.DELAY_NODE:
            const { delay, unit } = node;
            return Boolean(delay) || Boolean(unit);
        case CampaignNodeType.PUBLISH_NODE:
            return false;
        default:
            return false;
    }
};

export const selectNavStateByRoute = (state: RootState) => (
    route: string,
    campaignKey: CampaignKey
): CurationNavState => {
    const routes = selectCampaignRouteToNodeIdMap(campaignKey)(state);
    const nodeId = routes[route];
    const isComplete = selectIsCampaignNodeComplete(campaignKey, nodeId)(state);
    const isInProgress = selectIsCampaignNodeInProgress(campaignKey, nodeId)(state);

    // Get the preview route
    const metadataNodeId = selectNodeByType<MetaDataNode>(campaignKey, CampaignNodeType.META_DATA_NODE)(state).id;
    const previewRoute = invert(routes)[metadataNodeId];
    const currentRoute = state.router.location.pathname;
    const navState = getCampaignNavState({
        isComplete,
        isInProgress,
        currentRoute,
        inProgressActiveRoute: route,
        previewRoute,
    });

    return navState;
};

export const selectStateByCampaignKey = (campaignKey: CampaignKey) => (state: RootState): CampaignState =>
    state.curation[campaignKey];

/**
 * Nodes
 */
// This is the helper function that does the actual graph traversal.
// It's a pure function that doesn't depend on Redux or RootState.
export const getNodesFromGraph = (campaignGraph: CampaignGraph): CampaignNode[] => {
    const { nodes, edges } = campaignGraph;

    let metadataNode = nodes.find((node) => node.type === CampaignNodeType.META_DATA_NODE);

    if (!metadataNode) {
        return [];
    }

    let queue = [metadataNode];
    let orderedNodes: CampaignNode[] = [];

    // Create a constant-time lookup table for nodes for efficiency if nodes[] is large
    const nodeMap = new Map(nodes.map((node) => [node.id, node]));

    while (queue.length > 0) {
        const currentNode = queue.shift();

        if (currentNode) {
            orderedNodes.push(currentNode);
        }

        const connectedNodes = edges
            .filter((edge) => edge.source === currentNode.id)
            .map((edge) => nodeMap.get(edge.target))
            .filter((node): node is CampaignNode => !!node);
        // Ensure non-undefined CampaignNode[] with type guard

        queue.push(...connectedNodes);
    }
    return orderedNodes;
};

const selectCampaignGraph = (campaignKey: CampaignKey) => (state: RootState): CampaignGraph =>
    state.curation[campaignKey].campaignGraph;

const selectNodesByFilter = <T extends CampaignNode>(
    campaignKey: CampaignKey,
    filterFn: (node: CampaignNode) => boolean
) => (state: RootState): T[] => {
    const campaignGraph = selectCampaignGraph(campaignKey)(state);
    const nodes = getNodesFromGraph(campaignGraph);
    return (nodes.filter(filterFn) as unknown[]) as T[];
};

export const selectNodesByType = <T extends CampaignNode>(campaignKey: CampaignKey, type: CampaignNodeType) => (
    state: RootState
): T[] => selectNodesByFilter<T>(campaignKey, (node) => node.type === type)(state);

export const selectNodeIdsByType = (campaignKey: CampaignKey, nodeType: CampaignNodeType) => (
    state: RootState
): string[] => {
    const nodesByType = selectNodesByFilter(campaignKey, (node) => node.type === nodeType)(state);
    return nodesByType.map((node) => node.id);
};
export const selectNodeById = <T extends CampaignNode>(campaignKey: CampaignKey, nodeId: string) => (
    state: RootState
): T | undefined => selectNodesByFilter<T>(campaignKey, (node) => node.id === nodeId)(state)[0];

export const selectNodeByType = <T extends CampaignNode>(campaignKey: CampaignKey, type: CampaignNodeType) => (
    state: RootState
): T | undefined => selectNodesByFilter<T>(campaignKey, (node) => node.type === type)(state)[0];

export const selectContentFieldsAll = (campaignKey: CampaignKey) => (state: RootState): ContentFields[] => {
    const nodes = selectNodesByType<CampaignNode>(campaignKey, CampaignNodeType.CONTENT_NODE)(state);
    return nodes.map((node) => selectContentFieldsByNodeId(campaignKey, node.id)(state));
};

export const selectScheduleFieldsAll = (campaignKey: CampaignKey) => (state: RootState): ScheduleFields[] => {
    const scheduleNode = selectNodeByType<ScheduleNode>(campaignKey, CampaignNodeType.SCHEDULE_NODE)(state);

    const { isRecommendedTimeEnabled } = scheduleNode;
    return state.curation[campaignKey].campaignTree.sends.map((send) => ({
        parentAudienceDelayInterval: send.parentAudienceDelayInterval,
        scheduledTimestamp: send.scheduledTimestamp,
        scheduledEndTimestamp: send.scheduledEndTimestamp,
        recurrenceFrequency: send.recurrenceFrequency,
        isRecommendedTimeEnabled,
    }));
};

export const selectScheduleFields = (campaignKey: CampaignKey) => (state: RootState): ScheduleFields => {
    const scheduleNode = selectNodeByType<ScheduleNode>(campaignKey, CampaignNodeType.SCHEDULE_NODE)(state);
    const correspondingSend = state.curation[campaignKey].campaignTree.sends[scheduleNode.sendIndex];

    const { isRecommendedTimeEnabled } = scheduleNode;
    const {
        parentAudienceDelayInterval,
        recurrenceFrequency,
        scheduledEndTimestamp,
        scheduledTimestamp,
    } = correspondingSend;
    return {
        isRecommendedTimeEnabled,
        parentAudienceDelayInterval,
        recurrenceFrequency,
        scheduledEndTimestamp,
        scheduledTimestamp,
    };
};

export const selectMetadataFields = (campaignKey: CampaignKey) => (state: RootState): MetaDataFields => {
    const metadataNode = selectNodeByType<MetaDataNode>(campaignKey, CampaignNodeType.META_DATA_NODE)(state);

    const { contentPreviewNodeId, name } = metadataNode;
    return { contentPreviewNodeId, name };
};

export const selectContentFieldsByNodeId = (campaignKey: CampaignKey, nodeId: string) => (
    state: RootState
): ContentFields => {
    if (!nodeId) {
        return {} as ContentFields;
    }
    const contentNode = selectNodeById<ContentNode>(campaignKey, nodeId)(state);

    const correspondingSend = state.curation[campaignKey].campaignTree.sends[contentNode.sendIndex];
    // This is called in the CampaignFlow for other nodes that aren't content nodes. In that case, there is no corresponding send, so just return an empty object.
    if (!correspondingSend) {
        return {} as ContentFields;
    }
    const { preHeader, senderProfileId, subjectLine, templateId } = correspondingSend;

    return { preHeader, senderProfileId: Number(senderProfileId), subjectLine, templateId: Number(templateId) };
};

export const selectFieldsByRoute = <T extends CampaignNode>(campaignKey: CampaignKey) => (state: RootState) => (
    route: string
): T | undefined => {
    const nodeId = selectNodeIdByRoute(campaignKey)(state)(route);
    return selectNodeById<T>(campaignKey, nodeId)(state);
};

export const selectDelayFieldsByNodeId = (campaignKey: CampaignKey, nodeId: string) => (
    state: RootState
): DelayFields => {
    if (!nodeId) {
        return {} as DelayFields;
    }
    const delayNode = selectNodeById<DelayNode>(campaignKey, nodeId)(state);
    const { delay, unit } = delayNode;

    return { delay, unit };
};

export const selectSegmentId = (campaignKey: CampaignKey) => (state: RootState): number => {
    const segmentNode = state.curation[campaignKey].campaignGraph.nodes.find(
        (node) => node.type === CampaignNodeType.SEGMENT_NODE
    ) as SegmentNode;
    const correspondingSend = state.curation[campaignKey].campaignTree.sends[segmentNode.sendIndex];
    return correspondingSend.segmentId;
};

/**
 * Derived data
 */
export const selectDelayErrorMessage = (campaignKey: CampaignKey, nodeId: string) => (state: RootState): string => {
    const { beforeTimestamp, afterTimestamp } = selectTimestampsBeforeAndAfterDelay(campaignKey, nodeId)(state);

    if (!beforeTimestamp || !afterTimestamp) {
        return '';
    }

    const oneYearDayFromNow = DateTime.now().plus({ year: 1 });
    const secondDate = DateTime.fromISO(afterTimestamp);
    const dateIsValid = secondDate <= oneYearDayFromNow;

    if (!dateIsValid) {
        return 'Time delays cannot be more than one year into the future.';
    }

    return '';
};

export const selectTimestampsBeforeAndAfterDelay = (campaignKey: CampaignKey, nodeId: string) => (
    state: RootState
): { beforeTimestamp: string; afterTimestamp: string } => {
    const { nodes, edges } = state.curation[campaignKey].campaignGraph;
    const { sends } = state.curation[campaignKey].campaignTree;

    // Get the edge that connects to the delay node
    const connectedEdge = edges.find((edge) => edge.target === nodeId);

    // Get the node before the delay node
    const beforeNode = nodes.find((node) => node.id === connectedEdge.source);

    // Get the edge that comes after the delay node
    const nextEdge = edges.find((edge) => edge.source === nodeId);

    // Get the node after the delay node
    const afterNode = nodes.find((node) => node.id === nextEdge.target);

    // Retrieve the sends for the before and after nodes using their sendIndex values
    const beforeSend = sends[beforeNode?.sendIndex || 0]; // Default to 0 if sendIndex is not defined
    const afterSend = sends[afterNode?.sendIndex || 0]; // Default to 0 if sendIndex is not defined

    const beforeTimestamp = beforeSend?.scheduledTimestamp || '';
    const afterTimestamp = afterSend?.scheduledTimestamp || '';

    return { beforeTimestamp, afterTimestamp };
};

/**
 * Conditional split
 */
export const selectConditionalSplitRoutes = (state: RootState): string[] => {
    const { action: segmentsMeetsAction } = state.curation.conditionalSplit.segmentsMeetsAction;
    const { action: segmentsMeetsNotAction } = state.curation.conditionalSplit.segmentsMeetsNotAction;

    const routesToRemove = [
        ...(segmentsMeetsAction === CurationSegmentsAction.EXIT_CURATION
            ? [URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS_MEETS_CONTENT]
            : []),
        ...(segmentsMeetsNotAction === CurationSegmentsAction.EXIT_CURATION
            ? [URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS_MEETS_NOT_CONTENT]
            : []),
    ];

    return without(ConditionalSplitRoutes, ...routesToRemove);
};

export const selectConditionalSplitStateByRoute = (state: RootState) => (route: string): CurationState => {
    const routeToState: Record<string, any> = {
        [URLPaths.CURATIONS_CONDITIONAL_SPLIT]: state.curation.conditionalSplit.metadata,
        [URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENT]: state.curation.conditionalSplit.audience,
        [URLPaths.CURATIONS_CONDITIONAL_SPLIT_1_CONTENT]: state.curation.conditionalSplit.content1,
        [URLPaths.CURATIONS_CONDITIONAL_SPLIT_SCHEDULE]: state.curation.conditionalSplit.schedule,
        [URLPaths.CURATIONS_CONDITIONAL_SPLIT_DELAY]: state.curation.conditionalSplit.delay,
        [URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS]: state.curation.conditionalSplit.segments,
        [URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS_MEETS_ACTION]:
            state.curation.conditionalSplit.segmentsMeetsAction,
        [URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS_MEETS_CONTENT]:
            state.curation.conditionalSplit.segmentsMeetsContent,
        [URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS_MEETS_NOT_ACTION]:
            state.curation.conditionalSplit.segmentsMeetsNotAction,
        [URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS_MEETS_NOT_CONTENT]:
            state.curation.conditionalSplit.segmentsMeetsNotContent,
        [URLPaths.CURATIONS_CONDITIONAL_SPLIT_PUBLISH]: state.curation.conditionalSplit.publish,
    };
    return routeToState[route];
};

export const selectConditionalSplitSecondEmailTimestamp = (state: RootState) => {
    const campaignState = state.curation.conditionalSplit;
    const { scheduledTimestamp } = campaignState.schedule;
    const { delay, unit } = campaignState.delay;

    if (scheduledTimestamp && delay && unit) {
        const secondEmailTimestamp = DateTime.fromISO(scheduledTimestamp).plus({ [unit.slice(0, -1)]: delay });

        if (secondEmailTimestamp.isValid) {
            return secondEmailTimestamp.toISO();
        }
    }
    return null;
};

export const selectIsConditionalSplitRouteDisabled = (state: RootState) => (route: string): boolean => {
    if (route.includes(URLPaths.CURATIONS_CONDITIONAL_SPLIT)) {
        if (route === URLPaths.CURATIONS_CONDITIONAL_SPLIT || route === URLPaths.CURATIONS_SMS) {
            return false;
        }
        const routeIndex = selectConditionalSplitRoutes(state).indexOf(route);
        const previousStep = selectConditionalSplitRoutes(state)[routeIndex - 1];
        const previousStepIsComplete = selectConditionalSplitStateByRoute(state)(previousStep)?.isComplete;
        return !previousStepIsComplete;
    }
    return false;
};

export const selectIsConditionalSplitCurationFormComplete = (state: RootState): boolean =>
    state.curation.conditionalSplit.metadata.isComplete &&
    state.curation.conditionalSplit.audience.isComplete &&
    state.curation.conditionalSplit.content1.isComplete &&
    state.curation.conditionalSplit.schedule.isComplete &&
    state.curation.conditionalSplit.delay.isComplete &&
    state.curation.conditionalSplit.segments.isComplete &&
    state.curation.conditionalSplit.segmentsMeetsAction.isComplete &&
    (state.curation.conditionalSplit.segmentsMeetsAction.action === CurationSegmentsAction.SEND_EMAIL
        ? state.curation.conditionalSplit.segmentsMeetsContent.isComplete
        : true) &&
    state.curation.conditionalSplit.segmentsMeetsNotAction.isComplete &&
    (state.curation.conditionalSplit.segmentsMeetsNotAction.action === CurationSegmentsAction.SEND_EMAIL
        ? state.curation.conditionalSplit.segmentsMeetsNotContent.isComplete
        : true) &&
    state.curation.conditionalSplit.publish.publishClicked;
