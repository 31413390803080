import { AutoFixHigh } from '@mui/icons-material';
import { useState } from 'react';
import {
    setPreHeader,
    setSenderProfileId,
    setSubjectLine,
} from 'domains/campaigns/components/CustomCampaign/customCampaignActions';
import SubjectLinesDialog from 'domains/campaigns/components/SubjectLinesDialog';
import { EmailSendInputState } from 'domains/campaigns/types';
import IconButton from 'domains/core/components/IconButton';
import InputLabel from 'domains/core/components/InputLabel';
import Select from 'domains/core/components/Select';
import TextField from 'domains/core/components/TextField';
import { SenderProfile } from 'domains/content/types';
import useSenderProfiles from 'hooks/queries/useSenderProfiles';
import useTemplateSuggestedSubjectLines from 'hooks/queries/useTemplateSuggestedSubjectLines';

type Props = {
    activeSendIndex: number;
    currentSend: EmailSendInputState;
    dispatchCampaignAction: React.Dispatch<any>;
};

const AdditionalContentFields = ({ activeSendIndex, currentSend, dispatchCampaignAction }: Props) => {
    const { preHeader, senderProfileId, subjectLine, templateId } = currentSend;

    const [isSubjectLinesDialogOpen, setIsSubjectLinesDialogOpen] = useState(false);

    const { isLoading: isSenderProfilesLoading, data: senderProfiles } = useSenderProfiles();

    const { data: suggestedSubjectLines } = useTemplateSuggestedSubjectLines({
        templateId,
        reactQueryOptions: {
            enabled: !!templateId,
        },
    });

    const subjectLineGeneratorHelperText = templateId
        ? `Click to use Cured's Subject Line Curator.`
        : `Select content for the Subject Line Generator.`;

    return (
        <>
            <Select
                required
                label={<InputLabel label="Sender profile" labelIconTooltipText="Who is sending this email?" />}
                menuItemStyles={{ textTransform: 'none' }}
                onChange={(e: any) => dispatchCampaignAction(setSenderProfileId(e.target.value, activeSendIndex))}
                value={senderProfileId ?? ''}
                values={
                    !isSenderProfilesLoading
                        ? senderProfiles?.map((senderProfile: SenderProfile) => ({
                              value: senderProfile.id,
                              label: senderProfile.from_address,
                          }))
                        : []
                }
                fullWidth
            />
            <div style={{ position: 'relative' }}>
                <TextField
                    counterCurrent={subjectLine?.length ?? 0}
                    counterMax={255}
                    data-test="curation-content-page-subject-line"
                    fullWidth
                    inputProps={{ maxLength: 255 }}
                    label={
                        <InputLabel
                            label="Subject line"
                            labelIconTooltipText="A descriptive overview of your message that gives contacts a reason to open."
                        />
                    }
                    onChange={(e: any) => dispatchCampaignAction(setSubjectLine(e.target.value, activeSendIndex))}
                    value={subjectLine}
                    withCounter
                />
                <IconButton
                    buttonWrapperCss={{
                        position: 'absolute',
                        right: '-50px',
                        top: '4px',
                    }}
                    disabled={!templateId}
                    handleClick={() => setIsSubjectLinesDialogOpen(true)}
                    Icon={AutoFixHigh}
                    title={subjectLineGeneratorHelperText}
                />
                <SubjectLinesDialog
                    isOpen={isSubjectLinesDialogOpen}
                    subjectLines={suggestedSubjectLines}
                    onClickSubjectLine={(index: number) => {
                        dispatchCampaignAction(setSubjectLine(suggestedSubjectLines[index], activeSendIndex));
                    }}
                    onClose={() => setIsSubjectLinesDialogOpen(false)}
                />
            </div>
            <TextField
                counterCurrent={preHeader?.length ?? 0}
                counterMax={100}
                data-test="curation-content-page-preview"
                fullWidth
                inputProps={{ maxLength: 100 }}
                label={
                    <InputLabel
                        label="Preview text"
                        labelIconTooltipText="A quick glimpse into the content of your message that appears after the subject line."
                    />
                }
                onChange={(e: any) => dispatchCampaignAction(setPreHeader(e.target.value, activeSendIndex))}
                value={preHeader}
                withCounter
            />
        </>
    );
};

export default AdditionalContentFields;
