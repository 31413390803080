import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import FormLayout from 'domains/core/components/FormLayout';
import AdsClickIcon from 'domains/core/components/SvgIcons/AdsClickIcon';
import {
    setClickUrl as setClickUrlActionCreator,
    setPreviewClickUrl as setPreviewClickUrlActionCreator,
    setUniqueClickRate as setClickthroughRateActionCreator,
    resetUniqueClickRate as resetUniqueClickRateActionCreator,
} from 'domains/value/state/value';
import { ValueCalculatorStep } from 'domains/value/types';
import ValueCalculatorConversionEventForm from './ValueCalculatorConversionEventForm';
import { getPercentageFromRate } from 'utils';

const ValueCalculatorConversionEventPage = (props: RouteComponentProps) => {
    const dispatch = useDispatch();

    const formLayoutProps = {
        header: 'determine the qualification criteria',
        guidingText:
            'Choose the forecasted unique click rate and select the link that will qualify a contact to be included in the value calculation.',
        stepName: ValueCalculatorStep.QUALIFICATION_CRITERIA,
    };

    const sendId = useSelector((state: any) => state.value.sendId);
    const uniqueClickRate = getPercentageFromRate(useSelector((state: any) => state.value.uniqueClickRate));
    const clickUrl = useSelector((state: any) => state.value.clickUrl);

    const resetUniqueClickRate = () => dispatch(resetUniqueClickRateActionCreator());

    const setUniqueClickRate = (uniqueClickRate: number) => dispatch(setClickthroughRateActionCreator(uniqueClickRate));
    const setClickUrl = (clickUrl: string) => dispatch(setClickUrlActionCreator(clickUrl));
    const setPreviewClickUrl = (clickUrl: string) => dispatch(setPreviewClickUrlActionCreator(clickUrl));

    return (
        <FormLayout icon={<AdsClickIcon />} {...formLayoutProps}>
            <ValueCalculatorConversionEventForm
                uniqueClickRate={uniqueClickRate}
                clickUrl={clickUrl}
                sendId={sendId}
                resetUniqueClickRate={resetUniqueClickRate}
                setUniqueClickRate={setUniqueClickRate}
                setClickUrl={setClickUrl}
                setPreviewClickUrl={setPreviewClickUrl}
            />
        </FormLayout>
    );
};

export default ValueCalculatorConversionEventPage;
