import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Button from 'domains/core/components/Button';
import { route as routeSelector } from 'state/selectors';
import { ButtonProps } from '@mui/material';
import { RootState } from 'state';

export type Props = {
    buttonTextConditional?: string;
    routeConditional?: string;
    selectIsRouteDisabled: (state: RootState) => (route: string) => boolean;
    selectIsRouteNextDisabled: (state: RootState) => (route: string) => boolean;
    selectNextRoute: (state: RootState) => (route: string) => string | null;
    handleRouteConditional?: () => void;
    routeConditionalDisabled?: (state: any) => boolean;
};

const ButtonContinue = ({
    buttonTextConditional,
    routeConditional,
    selectIsRouteDisabled,
    selectIsRouteNextDisabled,
    selectNextRoute,
    handleRouteConditional,
    routeConditionalDisabled,
    ...props
}: Props & ButtonProps) => {
    const history = useHistory();
    const route = useSelector(routeSelector);
    const continueButtonText = route === routeConditional ? buttonTextConditional : 'Continue';

    const isRouteConditionalDisabled = useSelector(routeConditionalDisabled ?? ((state: any) => false));
    const isRouteDisabled = useSelector(selectIsRouteDisabled);
    const isRouteNextDisabled = useSelector(selectIsRouteNextDisabled);
    const getNextRoute = useSelector(selectNextRoute);

    const handleClick = () => {
        if (route === routeConditional) {
            handleRouteConditional();
        } else {
            const nextRoute = getNextRoute(route);
            history.push(nextRoute);
        }
    };

    const isRouteConditionalAndIsDisabled = route === routeConditional ? isRouteConditionalDisabled : false;

    return (
        <Button
            disabled={isRouteConditionalAndIsDisabled || isRouteDisabled(route) || isRouteNextDisabled(route)}
            onClick={handleClick}
            variant="outlined"
            {...props}
        >
            {continueButtonText}
        </Button>
    );
};

export default ButtonContinue;
