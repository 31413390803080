import useMutation from 'hooks/useMutation';
import { ApiMethods, CuredApiPaths } from 'models/enums';
import { CampaignForFilter, FiltersForApiState, SendForFilter } from '../../filtering/types';

type Data = {
    campaigns: CampaignForFilter[];
    sends: SendForFilter[];
};

type ReturnTypes = {
    getData: ({ timeframe }: { timeframe: FiltersForApiState['timeframe'] }) => void;
    isError: boolean;
    isLoading: boolean;
    data: Data;
};

const useGetFilterData = (): ReturnTypes => {
    const { mutate: getData, isError, isLoading, data } = useMutation(
        'curations-dashboard/filter-data',
        CuredApiPaths.REPORT_CURATION_FILTER_DATA,
        {},
        ApiMethods.POST
    );

    const returnData = data as ReturnTypes['data'];
    return { getData, isError, isLoading, data: returnData };
};

export default useGetFilterData;
