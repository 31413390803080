import { UseQueryOptions } from 'react-query';
import { CuredApiPaths } from '../../models/enums';
import { Import } from '../../models/types';
import useQuery from '../useQuery';
import QueryKeys from './keys';

const useImports = (reactQueryOptions?: UseQueryOptions<Import[], Error>) =>
    useQuery(QueryKeys.IMPORTS, CuredApiPaths.IMPORTS, reactQueryOptions);

export default useImports;
