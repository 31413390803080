import { Skeleton, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import ErrorSnackbar from 'domains/core/components/Snackbars/ErrorSnackbar';
import useClickAppointmentValue from 'hooks/mutations/useClickAppointmentValue';
import { getNumberWithCommas, getPercentageFromRate } from 'utils';
import { ClickAppointmentValue } from 'domains/value/types';
import { isAppointmentValueInRange } from 'domains/value/state/selectors';

const ForecastedValueCalculation = ({
    appointmentValue,
    attendRate,
    scheduleRate,
    sendId,
    uniqueClickRate,
}: ClickAppointmentValue) => {
    const {
        data: appointmentValueResponse,
        mutate,
        isLoading,
        isError: isClickAppointmentValueError,
        error: clickAppointmentValueError,
    } = useClickAppointmentValue();

    const showGuidingMinWidth = useMediaQuery('(min-width:1073px)');
    const showGuidingMaxWidth = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const showGuidingRange = showGuidingMinWidth || showGuidingMaxWidth;

    const showInputsMinWidth = useMediaQuery('(min-width:989px)');
    const showInputsMaxWidth = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const showInputsRange = showInputsMinWidth || showInputsMaxWidth;

    const isInRange = isAppointmentValueInRange(appointmentValue);
    const isError = isClickAppointmentValueError || !isInRange;

    useEffect(() => {
        if (
            [attendRate, appointmentValue, scheduleRate, sendId, uniqueClickRate].every((value) => value) &&
            isInRange
        ) {
            mutate({
                appointmentValue,
                attendRate,
                scheduleRate,
                sendId,
                uniqueClickRate,
            });
        }
    }, [appointmentValue, attendRate, isInRange, mutate, scheduleRate, sendId, uniqueClickRate]);

    return (
        <>
            {isError && (
                <ErrorSnackbar
                    errorMessage={
                        clickAppointmentValueError?.message ||
                        'Please enter a conversion value between 0 and $1,000,000.'
                    }
                />
            )}
            <Stack
                maxWidth="68.75%"
                justifyContent={showGuidingRange ? 'space-evenly' : showInputsRange ? 'center' : 'flex-start'}
                spacing={showGuidingRange ? -4 : 1}
                pt={showGuidingRange || showInputsRange ? 0 : 4}
                pl={2}
                height="100%"
            >
                <Typography color="action.active" variant="body1">
                    The forecasted value of your send is:
                </Typography>
                {isLoading || isError ? (
                    <Typography variant="h4" component="p">
                        <Skeleton data-testid="skeleton" />
                    </Typography>
                ) : (
                    <Typography data-testid="forecasted-value" variant="h4" component="p">
                        {!isLoading &&
                            (Boolean(appointmentValueResponse?.estimatedValue) ||
                                appointmentValueResponse?.estimatedValue === 0) &&
                            `$${getNumberWithCommas(appointmentValueResponse?.estimatedValue)}`}
                    </Typography>
                )}
                {showGuidingRange && (
                    <Typography variant="overline" color="action.active">
                        This is calculated by multiplying your audience{' '}
                        {!isLoading && (
                            <>
                                {' of '}
                                <Typography variant="overline" component="span" display="inline">
                                    {getNumberWithCommas(appointmentValueResponse?.contactCount)}
                                </Typography>
                            </>
                        )}{' '}
                        by your selections:
                    </Typography>
                )}
                {showInputsRange && (
                    <Stack spacing={0.5}>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography variant="overline">{getPercentageFromRate(uniqueClickRate)}%</Typography>
                            <Typography variant="overline" color="action.active">
                                forecasted unique click
                            </Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                            <Stack direction="row" columnGap={0.5}>
                                <Typography variant="overline" color="action.active">
                                    x
                                </Typography>
                                <Typography variant="overline">{getPercentageFromRate(scheduleRate)}%</Typography>
                            </Stack>
                            <Typography variant="overline" color="action.active">
                                scheduled rate
                            </Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                            <Stack direction="row" columnGap={0.5}>
                                <Typography variant="overline" color="action.active">
                                    x
                                </Typography>
                                <Typography variant="overline">{getPercentageFromRate(attendRate)}%</Typography>
                            </Stack>
                            <Typography variant="overline" color="action.active">
                                attended rate
                            </Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                            <Stack direction="row" columnGap={0.5}>
                                <Typography variant="overline" color="action.active">
                                    x
                                </Typography>
                                <Typography variant="overline">${getNumberWithCommas(appointmentValue)}</Typography>
                            </Stack>
                            <Typography variant="overline" color="action.active">
                                conversion value
                            </Typography>
                        </Stack>
                    </Stack>
                )}
            </Stack>
        </>
    );
};

export default ForecastedValueCalculation;
