import { Campaign } from 'domains/campaigns/types';
import CampaignDetails from 'domains/campaigns/components/CampaignDetails/CampaignDetails';
import CampaignDetailsLayout from '../CampaignDetailsLayout';
import CurationListAndFirstAudience from '../CurationListAndFirstAudience';

type Props = {
    campaign: Campaign;
};

const SMSDetailsPage = ({ campaign }: Props) => {
    const sendDetails = campaign.sends.map((send) => ({
        contentNodeId: `${send.id}`,
        contentId: send.id,
        scheduledTimestamp: send?.scheduledTimestamp,
    }));

    return (
        <CampaignDetailsLayout
            left={
                <>
                    <CurationListAndFirstAudience campaign={campaign} />
                    <CampaignDetails channel={campaign.channel} details={sendDetails} isCampaign />
                </>
            }
        />
    );
};

export default SMSDetailsPage;
