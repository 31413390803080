import React, { useState, useEffect, useContext, createContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router';
import { URLPaths, AuthConstants } from 'models/enums';

type AuthContextProps = {
    customAuthWrapper: () => {
        isAuthenticated: boolean;
        isLoading: boolean;
        loginWithRedirect: (appState?: any | null) => void;
        user: any | null;
        getAccessTokenSilently: () => {};
        logout?: (opts?: { returnTo: string } | null) => void;
        isInnovaccerLogin?: any | Boolean;
    };
    setAuth0Provider: () => void;
    saveInnovaccerCreds: (accessToken: string, userData: any) => void;
};

const stub = (): never => {
    throw new Error('You forgot to wrap your component in <Auth0Provider>.');
};
export const initialContext = {
    customAuthWrapper: stub,
    setAuth0Provider: stub,
    saveInnovaccerCreds: stub,
};
const AuthContext = createContext<AuthContextProps | null>(initialContext);

export function useCustomAuth(context = AuthContext) {
    const { customAuthWrapper } = useContext(context);
    const customAuth = customAuthWrapper();
    return customAuth;
}

export function useInnovaccerLogin(context = AuthContext) {
    const { saveInnovaccerCreds } = useContext(context);
    return {
        saveInnovaccerCreds,
    };
}

export function useAuth0Login(context = AuthContext) {
    const { setAuth0Provider } = useContext(context);
    const { loginWithRedirect } = useAuth0();
    return { loginWithRedirect, setAuth0Provider };
}

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [innovaccerAuthenticated, setInnovaccerAuthenticated] = useState(false);
    const [innovaccerLoginLoading, setInnovaccerLoginLoading] = useState(false);
    const [innovaccerUser, setInnovaccerUser] = useState(null);
    const [innovaccerToken, setInnovaccerToken] = useState(null);

    const { isAuthenticated, isLoading, loginWithRedirect, user, logout, getAccessTokenSilently } = useAuth0();
    const history = useHistory();

    useEffect(() => {
        const provider = localStorage.getItem(AuthConstants.OUTREACH_AUTH_PROVIDER);
        if (provider === null) return;
        if (provider === AuthConstants.INNOVACCER_DATASHOP_PROVIDER) {
            const user = localStorage.getItem(AuthConstants.OUTREACH_AUTH_USER);
            if (Boolean(user)) {
                const innovaccerToken = localStorage.getItem(AuthConstants.OUTREACH_AUTH_TOKEN);
                const parsedUser = JSON.parse(user);
                setInnovaccerUser(parsedUser);
                setInnovaccerToken(innovaccerToken);
                setInnovaccerAuthenticated(true);
            } else {
                localStorage.removeItem(AuthConstants.OUTREACH_AUTH_PROVIDER);
                localStorage.removeItem(AuthConstants.OUTREACH_AUTH_TOKEN);
                localStorage.removeItem(AuthConstants.OUTREACH_AUTH_USER);
            }
        }
    }, []);

    const innovaccerLogout = (reason: { returnTo: string } | null = null) => {
        const tenantId = (innovaccerUser as any)[AuthConstants.UAM_TENANT_ID];
        const redirectUrl = (innovaccerUser as any)[AuthConstants.REDIRECT_URL];
        localStorage.removeItem(AuthConstants.OUTREACH_AUTH_USER);
        localStorage.removeItem(AuthConstants.OUTREACH_AUTH_PROVIDER);
        localStorage.removeItem(AuthConstants.OUTREACH_AUTH_TOKEN);
        setInnovaccerAuthenticated(false);
        setInnovaccerUser(null);
        setInnovaccerToken(null);
        if (reason?.returnTo === AuthConstants.SESSION_EXPIRED) {
            history.push(`${URLPaths.INNOVACCER_LOGIN}/${tenantId}/${AuthConstants.SESSION_EXPIRED}`);
        } else {
            window.location.href = redirectUrl;
        }
    };

    const customAuthWrapper = () => {
        const provider = localStorage.getItem(AuthConstants.OUTREACH_AUTH_PROVIDER);
        if (provider === AuthConstants.AUTH0_PROVIDER) {
            return {
                isAuthenticated,
                isLoading,
                loginWithRedirect,
                user,
                logout,
                getAccessTokenSilently,
                isInnovaccerLogin: false,
            };
        }
        if (!innovaccerAuthenticated) {
            return {
                isAuthenticated: innovaccerAuthenticated,
                isInnovaccerLogin: false,
                user: {},
                isLoading: false,
                loginWithRedirect: () => {},
                getAccessTokenSilently: (): undefined => null,
                logout: innovaccerLogout,
            };
        } else {
            return {
                isAuthenticated: innovaccerAuthenticated,
                isInnovaccerLogin: true,
                user: innovaccerUser,
                isLoading: innovaccerLoginLoading,
                loginWithRedirect: () => {},
                getAccessTokenSilently: () =>
                    `innovaccer.${(innovaccerUser as any)[AuthConstants.UAM_TENANT_ID]}:` + String(innovaccerToken),
                logout: innovaccerLogout,
            };
        }
    };

    const setAuth0Provider = () => {
        localStorage.setItem(AuthConstants.OUTREACH_AUTH_PROVIDER, AuthConstants.AUTH0_PROVIDER);
    };

    const saveInnovaccerCreds = async (accessToken: string, userData: any) => {
        localStorage.setItem(AuthConstants.OUTREACH_AUTH_USER, JSON.stringify(userData));
        localStorage.setItem(AuthConstants.OUTREACH_AUTH_TOKEN, accessToken);
        localStorage.setItem(AuthConstants.OUTREACH_AUTH_PROVIDER, AuthConstants.INNOVACCER_DATASHOP_PROVIDER);
        setInnovaccerUser(userData);
        setInnovaccerToken(accessToken);
        setInnovaccerLoginLoading(false);
        setInnovaccerAuthenticated(true);
        history.push('/');
    };

    return (
        <AuthContext.Provider
            value={{
                customAuthWrapper,
                setAuth0Provider,
                saveInnovaccerCreds,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
