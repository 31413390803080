import { MailOutline, QueryBuilder, SupervisedUserCircleOutlined } from '@mui/icons-material';
import { SvgIconProps } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Node, Edge, Position } from 'reactflow';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Graph from 'domains/campaigns/components/Graph';
import { Type } from 'domains/campaigns/components/Graph/EndNode';
import useVerticallyCenterNode from 'domains/campaigns/components/Graph/useVerticallyCenterNode';
import { CurationNavState, URLPaths } from 'models/enums';
import { route as routeSelector } from 'state/selectors';
import { CampaignKey, EdgePropsPartial, NodePropsPartial } from 'domains/campaigns/types';
import { CampaignState, CampaignNode, CampaignNodeType } from 'domains/campaigns/state/createCampaignSlice';
import theme from 'theme';
import { NodeType } from '../Graph/types';
import { selectNavStateByRoute, getNodesFromGraph, selectNodeRoutes } from 'domains/campaigns/state/selectors';
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';

const nodeTypeToIconAndLabelMap = {
    [CampaignNodeType.META_DATA_NODE]: { icon: () => <></>, label: '' },
    [CampaignNodeType.SEGMENT_NODE]: { icon: SupervisedUserCircleOutlined, label: 'audience' },
    [CampaignNodeType.CONTENT_NODE]: { icon: MailOutline, label: 'email' },
    [CampaignNodeType.CONDITIONAL_SPLIT_NODE]: { icon: () => <></>, label: '' },
    [CampaignNodeType.SCHEDULE_NODE]: { icon: MailOutline, label: 'email' },
    [CampaignNodeType.DELAY_NODE]: { icon: QueryBuilder, label: 'time delay' },
    [CampaignNodeType.PUBLISH_NODE]: { icon: () => <></>, label: '' },
};

// If multiple routes are under the same nav button, we need to calculate the state based on all routes under that nav button.
const getNavStateFromNavStates = (navStates: CurationNavState[]): CurationNavState => {
    if (navStates.every((navStates) => navStates === CurationNavState.PREVIEW)) {
        return CurationNavState.PREVIEW;
    }
    if (navStates.some((navState) => navState === CurationNavState.IN_PROGRESS_CURRENT)) {
        return CurationNavState.IN_PROGRESS_CURRENT;
    }
    if (navStates.every((navState) => navState === CurationNavState.IN_PROGRESS)) {
        return CurationNavState.IN_PROGRESS;
    }
    if (navStates.every((navState) => navState === CurationNavState.OPEN)) {
        return CurationNavState.OPEN;
    }
    if (navStates.every((navState) => navState === CurationNavState.COMPLETED)) {
        return CurationNavState.COMPLETED;
    }
};

const SPACING = 8;
const SPACING_NODE_START = SPACING * 10;
const SPACING_NODE_END = SPACING * 60;

const edgeStyle = {
    animated: false,
    style: {
        stroke: theme.palette.action.active,
        strokeWidth: 2,
        strokeDasharray: 2,
    },
};

const getX = (node: NodePropsPartial, offset?: number) => {
    // For conditional split
    // If node is "meets criteria", keep x at 0 to horizontally align with other nodes
    // If node is "does not meet criteria", offset x to the right
    const x = node?.data.isSplitMeets ? 0 : SPACING * 16;
    return offset ? x + offset : x;
};
const getY = (nodeIndex: number, offset?: number) => {
    // Calculate y based on nodeIndex (result of visual trial & error)
    const y = SPACING * (SPACING * 1.25 + nodeIndex * 16);
    return offset ? y + offset : y;
};

type Props = {
    actionCreatorDeleteSend?: ActionCreatorWithOptionalPayload<number>;
    campaignKey: CampaignKey;
    readonly?: boolean;
    onClickRemoveSend?: () => void;
    selectCampaignState: (state: any) => CampaignState;
    selectIsCampaignRouteDisabled: (state: any) => (route: URLPaths) => boolean;
    selectIsRouteStartOrPublish: (state: any) => (route: URLPaths) => boolean;
};

const CampaignNavBar = ({
    actionCreatorDeleteSend,
    campaignKey,
    readonly = false,
    onClickRemoveSend,
    selectCampaignState,
    selectIsCampaignRouteDisabled,
    selectIsRouteStartOrPublish,
}: Props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const campaignState = useSelector(selectCampaignState);
    const route = useSelector(routeSelector);

    const getNavStateByRoute = useSelector(selectNavStateByRoute);
    const getNodeRoutes = useSelector(selectNodeRoutes);
    const isCampaignRouteDisabled = useSelector(selectIsCampaignRouteDisabled);
    const isRouteStartOrPublish = useSelector(selectIsRouteStartOrPublish);

    const [isMouseOnRemove, setIsMouseOnRemove] = useState(false);

    const centerNodeVertically = useVerticallyCenterNode();

    const isDisabled = useCallback(
        (routes: URLPaths[]): boolean => {
            const routeDisabled = routes.map(isCampaignRouteDisabled);

            // If there are more then one route & any of the routes are enabled, then the tab is enabled.
            const isEveryRouteDisabled = routeDisabled.every((isRouteDisabled) => isRouteDisabled);

            return routes.length === 1 ? routeDisabled[0] : isEveryRouteDisabled;
        },
        [isCampaignRouteDisabled]
    );

    const nodes: NodePropsPartial[] = useMemo(() => {
        let isFirstContentNodeVisited = false;

        const orderedNodes = getNodesFromGraph(campaignState.campaignGraph);

        return orderedNodes.reduce((accumulator: NodePropsPartial[], node: CampaignNode, index) => {
            if (
                [CampaignNodeType.SEGMENT_NODE, CampaignNodeType.CONTENT_NODE, CampaignNodeType.DELAY_NODE].includes(
                    node.type
                )
            ) {
                const { routes, firstContentNodeVisited } = getNodeRoutes({
                    campaignKey,
                    isFirstContentNodeVisited,
                    node,
                });
                isFirstContentNodeVisited = firstContentNodeVisited;

                const { icon: IconComponent, label } = nodeTypeToIconAndLabelMap[node.type];

                const nodeIndexBeforePublishNode = orderedNodes.length - 2;
                const isRemoveEnabled =
                    node.type === CampaignNodeType.CONTENT_NODE &&
                    index === nodeIndexBeforePublishNode &&
                    node.sendIndex > 1;

                const onClickRemove = (node: CampaignNode) => {
                    dispatch(actionCreatorDeleteSend(node.sendIndex));
                    setIsMouseOnRemove(false);
                    onClickRemoveSend();
                };

                const newNode: NodePropsPartial = {
                    data: {
                        isRemoveEnabled,
                        label,
                        leftIcon: (props: SvgIconProps) => <IconComponent {...props} />,
                        routes,
                        ...(isRemoveEnabled && {
                            onClickRemove: () => onClickRemove(node),
                        }),
                    },
                    ...(isRemoveEnabled && {
                        parentNode: `${orderedNodes.length}`,
                    }),
                };
                return [...accumulator, newNode];
            }
            return accumulator;
        }, []);
    }, [actionCreatorDeleteSend, campaignKey, campaignState.campaignGraph, dispatch, getNodeRoutes, onClickRemoveSend]);

    // Get edges from the graph, might need only for conditional split and beyond.
    const edges: EdgePropsPartial[] = useMemo(() => nodes.map(() => ({})), [nodes]);

    const decoratedNodes: Node<any>[] = useMemo(() => {
        const onClick = (newIndex: number) => {
            history.push(decoratedNodes[newIndex + 1].data.routes[0]);
        };

        const nodeStart: Node<any> = {
            id: '0',
            connectable: false,
            type: NodeType.END_NODE,
            data: {
                label: 'start',
                type: Type.START,
            },
            position: {
                x: SPACING_NODE_START,
                y: 0,
            },
            sourcePosition: Position.Right,
        };

        const nodesNavigation: Node<any>[] = nodes.map((node, index) => {
            const navStates: CurationNavState[] = node.data.routes.map((route: string) =>
                getNavStateByRoute(route, campaignKey)
            );
            const navState = getNavStateFromNavStates(navStates);

            return {
                id: `${index + 1}`,
                connectable: false,
                type: NodeType.NAVIGATION,
                data: {
                    ...node.data,
                    disabledHoverState: route === node.data.routes[0],
                    isDisabled: isDisabled(node.data.routes),
                    isMouseOnRemove,
                    navState,
                    readonly,
                    onClick: () => onClick(index),
                    onMouseEnterRemove: () => setIsMouseOnRemove(true),
                    onMouseLeaveRemove: () => setIsMouseOnRemove(false),
                },
                position: {
                    x: getX(node),
                    y: getY(index),
                },
                targetPosition: node.targetPosition,
            };
        });

        const nodeIndexWithRemove = nodesNavigation.findIndex((node) => node.data.isRemoveEnabled);
        const isRouteNodeWithRemove = nodesNavigation[nodeIndexWithRemove]?.data.routes.includes(route);
        const isRouteNodeBeforeNodeWithRemove = nodesNavigation[nodeIndexWithRemove - 1]?.data.routes.includes(route);

        const nodeEnd: Node<any> = {
            id: `${nodes.length + 1}`,
            connectable: false,
            type: NodeType.END_NODE,
            data: {
                label: 'end',
                type: Type.END,
            },
            position: {
                x: SPACING_NODE_END,
                y: isRouteNodeWithRemove ? getY(nodes.length, SPACING * 2) : getY(nodes.length),
            },
            targetPosition: Position.Left,
        };

        const nodeGroupWidthCondition = isRouteNodeWithRemove || isRouteNodeBeforeNodeWithRemove;
        const nodeGroupWidth = nodeGroupWidthCondition ? '28.5rem' : '24.75rem';

        const nodeGroupForSendRemoval: Node<any> = {
            id: `${nodes.length + 2}`, // after nodeEnd to prevent shifting nodeEnd
            connectable: false,
            type: 'group',
            data: {},
            position: {
                x: getX(nodes[nodes.length - 1], -SPACING * 2.5),
                y: getY(nodes.length - 2, -SPACING * 3),
            },
            style: {
                backgroundColor: 'rgba(231, 250, 245, 0.7)',
                border: 'none',
                borderRadius: '4px',
                width: nodeGroupWidth,
                height: isRouteNodeWithRemove ? '17.75rem' : '16.5rem',
                zIndex: -1,
            },
        };

        if (isMouseOnRemove) {
            return [nodeStart, ...nodesNavigation, nodeEnd, nodeGroupForSendRemoval];
        }
        return [nodeStart, ...nodesNavigation, nodeEnd];
    }, [nodes, isMouseOnRemove, history, route, isDisabled, readonly, getNavStateByRoute, campaignKey]);

    const decoratedEdges: Edge<any>[] = useMemo(() => {
        const edgeStart = {
            ...edgeStyle,
            id: 'e0-1',
            source: '0',
            target: '1',
            type: 'smoothstep',
            pathOptions: {
                borderRadius: 24,
            },
        };

        const edgesNavigation: Edge<any>[] = [];
        edges.forEach((edge, index) => {
            const edgePrevious = index;
            const edgeCurrent = index + 1;
            const edgeNext = index + 2;
            const isEdgeLast = index === edges.length - 1;

            if (edge.label === 'yes') {
                edgesNavigation.push({
                    id: `e${edgeCurrent}-${edgeNext}`,
                    source: `${edgeCurrent}`,
                    target: `${edgeNext}`,
                    ...edge,
                    ...edgeStyle,
                } as Edge<any>);
            } else if (edge.label === 'no') {
                edgesNavigation.push({
                    id: `e${edgePrevious}-${edgeNext}`,
                    source: `${edgePrevious}`,
                    target: `${edgeNext}`,
                    pathOptions: {
                        offset: 24,
                    },
                    ...edge,
                    ...edgeStyle,
                } as Edge<any>);
            } else if (isEdgeLast) {
                return;
            } else {
                edgesNavigation.push({
                    id: `e${edgeCurrent}-${edgeNext}`,
                    source: `${edgeCurrent}`,
                    target: `${edgeNext}`,
                    type: 'default',
                    ...edge,
                    ...edgeStyle,
                });
            }
        });

        const edgeEnd = {
            ...edgeStyle,
            id: `e${edgesNavigation.length + 1}-${edgesNavigation.length + 2}`,
            source: `${edgesNavigation.length + 1}`,
            target: `${edgesNavigation.length + 2}`,
            type: 'smoothstep',
            pathOptions: {
                borderRadius: 24,
            },
        };

        return [edgeStart, ...edgesNavigation, edgeEnd];
    }, [edges]);

    // Center the active node when the route changes.
    useEffect(() => {
        const nodeToCenter = decoratedNodes.find(
            (node: Node<any>) => node.type === NodeType.NAVIGATION && node.data.routes.includes(route)
        );
        nodeToCenter && centerNodeVertically(nodeToCenter.id);
    }, [decoratedNodes, route, centerNodeVertically]);

    return <Graph edges={decoratedEdges} nodes={decoratedNodes} isFitView={isRouteStartOrPublish(route) || readonly} />;
};

export default CampaignNavBar;
