import { Menu as MuiMenu, MenuItem, MenuProps } from '@mui/material';
import { cloneElement } from 'react';
import { useState } from 'react';

type Props = {
    items: any[];
    button: any;
    menuProps?: MenuProps;
};

const Menu = ({ items, button, menuProps }: Props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const onClickMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const onCloseMenu = () => {
        setAnchorEl(null);
    };

    const Button = () => cloneElement(button, { onClick: onClickMenu });

    const MenuItems = () =>
        items.map(({ name, value, onClick }) => (
            <MenuItem
                key={value}
                onClick={(e) => {
                    onClick(e);
                    onCloseMenu();
                }}
            >
                {name}
            </MenuItem>
        ));

    return (
        <>
            {Button()}
            <MuiMenu
                keepMounted
                anchorEl={anchorEl}
                open={open}
                onClose={onCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                {...menuProps}
            >
                {MenuItems()}
            </MuiMenu>
        </>
    );
};

export default Menu;
