import { InfoOutlined, Percent } from '@mui/icons-material';
import { Stack, Tooltip, Typography } from '@mui/material';
import TextField from 'domains/core/components/TextField';
import InputLabel from 'domains/core/components/InputLabel';
import Select, { Value } from 'domains/core/components/Select';
import Alert from 'domains/core/components/Alert';
import theme from 'theme';

type Props = {
    attendRate: number;
    contactEventRecordType: string;
    contactEventRecordTypesOptions: Value[];
    scheduleRate: number;
    isLoading?: boolean;
    resetAttendRate: () => void;
    resetScheduleRate: () => void;
    setAttendRate: (attendRate: number) => void;
    setScheduleRate: (scheduleRate: number) => void;
    setContactEventRecordType: (contactEventRecordType: string) => void;
};

const ValueCalculatorConversionMetricForm = ({
    attendRate,
    contactEventRecordType,
    contactEventRecordTypesOptions,
    scheduleRate,
    isLoading,
    resetAttendRate,
    resetScheduleRate,
    setAttendRate,
    setContactEventRecordType,
    setScheduleRate,
}: Props) => {
    const isEventOptionsReady = !isLoading;

    return (
        <Stack rowGap={4}>
            <TextField
                value={scheduleRate !== 0 ? scheduleRate : ''}
                onChange={(e) => setScheduleRate(Number(e.target.value))}
                onBlur={() => !scheduleRate && resetScheduleRate()}
                label={
                    <InputLabel
                        label="Scheduled rate"
                        labelIconTooltipText="The rate at which users who click on the conversion link actually schedule an appointment or sign up for event."
                    />
                }
                trailingIcon={<Percent />}
                type="number"
                width="100%"
            />
            <TextField
                value={attendRate !== 0 ? attendRate : ''}
                onChange={(e) => setAttendRate(Number(e.target.value))}
                onBlur={() => !attendRate && resetAttendRate()}
                label={
                    <InputLabel
                        label="Attended rate"
                        labelIconTooltipText="The rate at which contacts who schedule actually attend the appointment or event."
                    />
                }
                trailingIcon={<Percent />}
                type="number"
                width="100%"
            />
            {isEventOptionsReady && (
                <Stack rowGap={6} marginY={2}>
                    {!!contactEventRecordTypesOptions.length ? (
                        <>
                            <Typography variant="body1">
                                Pick an event to calculate the actual conversion.
                                <Tooltip title="Contacts who meet the qualification criteria are tracked to the event selected.">
                                    <InfoOutlined
                                        style={{
                                            fontSize: '12px',
                                            marginLeft: 0.5,
                                            paddingTop: 0.5,
                                        }}
                                    />
                                </Tooltip>
                            </Typography>
                            <Select
                                disabled={!!!contactEventRecordTypesOptions.length}
                                label={contactEventRecordType ? 'Event' : 'Select Event'}
                                style={{ textTransform: 'capitalize' }}
                                value={contactEventRecordType ?? ''}
                                values={contactEventRecordTypesOptions}
                                onChange={(e: any) => setContactEventRecordType(e.target.value)}
                            />
                        </>
                    ) : (
                        <Alert
                            elevation={0}
                            severity="info"
                            variant="outlined"
                            style={{ borderColor: theme.palette.info.main, borderWidth: '2px' }}
                        >
                            Upload event data to select actual conversion metrics in addition to the estimates.
                        </Alert>
                    )}
                </Stack>
            )}
        </Stack>
    );
};

export default ValueCalculatorConversionMetricForm;
