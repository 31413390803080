import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { SwapCalls } from '@mui/icons-material';
import { URLPaths } from 'models/enums';
import useCampaigns from 'hooks/queries/useCampaigns';
import useContacts from 'hooks/queries/useContacts';
import useFeatureSettings from 'hooks/queries/useFeatureSettings';
import Button from 'domains/core/components/Button';
import DropdownButton from 'domains/core/components/DropdownButton';
import EmptyStateLayout from 'domains/core/components/EmptyStateLayout';
import PageLayout from 'domains/core/components/PageLayout';
import { resetStateSingleSendLegacy, resetConditionalSplitState } from 'domains/campaigns/state/actionCreators';
import { resetState as resetStateSingleSend } from 'domains/campaigns/state/singleSend';
import { resetState as resetStateEmailSeries } from 'domains/campaigns/state/emailSeries';
import ContactsUploadDialog from 'domains/contacts/components/ContactsUploadDialog';
import FileUploadButton from 'domains/contacts/components/FileUploadButton';
import useContactsFileUploadProps from 'domains/contacts/hooks/useContactsFileUploadProps';
import CampaignsTable from './CampaignsTable';

export const CampaignsPage = () => {
    const dispatch = useDispatch();
    const { data: featureSettings, isSuccess: isFeatureSettingsSuccess } = useFeatureSettings();
    const history = useHistory();

    const {
        data: campaigns,
        isRefetching: isCampaignsRefetching,
        isSuccess: isCampaignsSuccess,
        refetch: refetchCampaigns,
    } = useCampaigns({
        refetchInterval: 30000,
    });
    const { data: contacts, isSuccess: isContactsSuccess } = useContacts({
        options: { limit: '1' },
    });

    const fileUploadProps = useContactsFileUploadProps();

    const isContactsEmpty = !contacts?.length;

    if (isContactsSuccess && isContactsEmpty) {
        return (
            <EmptyStateLayout
                caption="curations"
                header="create authentic connections"
                guidingText="Once your contacts are uploaded, build your first Curation to begin communicating with your contacts."
            >
                <ContactsUploadDialog {...fileUploadProps} />
                <FileUploadButton {...fileUploadProps} />
            </EmptyStateLayout>
        );
    }

    return (
        <PageLayout
            header="curations"
            headerIcon={<SwapCalls fontSize="inherit" />}
            headerAction={
                isFeatureSettingsSuccess && featureSettings?.sms_enabled ? (
                    <DropdownButton
                        ariaLabel="Choose type of curation to create."
                        icon={<SwapCalls />}
                        options={[
                            {
                                label: 'Email',
                                onClick: () => {
                                    history.push(URLPaths.CURATIONS_LANDING);
                                    dispatch(resetStateSingleSend());
                                    dispatch(resetStateEmailSeries());
                                    dispatch(resetConditionalSplitState());
                                },
                            },
                            {
                                label: 'SMS',
                                onClick: () => {
                                    history.push(URLPaths.CURATIONS_SMS);
                                    dispatch(resetStateSingleSendLegacy());
                                },
                            },
                        ]}
                        label="Create"
                        variant="outlined"
                        size="medium"
                    />
                ) : (
                    <Button
                        variant="outlined"
                        endIcon={<SwapCalls />}
                        onClick={() => history.push(URLPaths.CURATIONS_LANDING)}
                    >
                        Create
                    </Button>
                )
            }
        >
            {isCampaignsSuccess && (
                <CampaignsTable campaigns={campaigns} isRefetching={isCampaignsRefetching} refetch={refetchCampaigns} />
            )}
        </PageLayout>
    );
};

export default CampaignsPage;
