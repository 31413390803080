import { AutoFixHigh } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle, Link, Skeleton, Stack, Typography } from '@mui/material';
import theme from 'theme';
import Button from 'domains/core/components/Button/Button';
import InputLabel from 'domains/core/components/InputLabel';

type Props = {
    isOpen: boolean;
    subjectLines: string[];
    onClickSubjectLine: (index: number) => void;
    onClose: () => void;
};

const renderDialogButtons = (
    subjectLines: string[],
    onClickSubjectLine: (index: number) => void,
    onClose: () => void
) => {
    const SUBJECT_LINES_COUNT = 5;
    const buttonVariant = 'outlined';

    if (subjectLines) {
        const subjectLinesToShow = subjectLines.slice(0, SUBJECT_LINES_COUNT);
        const handleClickSubjectLine = (index: number) => {
            onClickSubjectLine(index);
            onClose();
        };

        return subjectLinesToShow.map((subjectLine, index) => (
            <Button
                key={subjectLine}
                data-testid={`subject-line-button-${index}`}
                variant={buttonVariant}
                onClick={() => handleClickSubjectLine(index)}
            >
                {subjectLine}
            </Button>
        ));
    }

    return Array(SUBJECT_LINES_COUNT)
        .fill(null)
        .map((_, index) => (
            <Button
                key={index}
                disabled
                sx={{
                    // overriding the disabled button styles
                    border: '0.125rem solid #123133 !important',
                    boxShadow: '1px 1px 0px #123133 !important',
                }}
                variant={buttonVariant}
            >
                <Skeleton width={480} />
            </Button>
        ));
};

const SubjectLinesDialog = ({ isOpen, subjectLines, onClickSubjectLine, onClose }: Props) => (
    <Dialog maxWidth="md" open={isOpen} onClose={onClose}>
        <DialogTitle id="upload-contacts-dialog">
            <Stack alignItems="center" direction="row" spacing={2}>
                <AutoFixHigh color="action" sx={{ fontSize: theme.spacing(3) }} />
                <InputLabel
                    label="suggested subject lines"
                    labelIconTooltipText={
                        <Stack rowGap={2}>
                            <Typography variant="tooltip">
                                Subject lines are suggestions generated by reviewing your email content using AI
                                technology. You are responsible for verifying and selecting the final content.
                            </Typography>
                            <Typography variant="tooltip">
                                Please contact{' '}
                                <Link href="mailto: support@cured.health" color="common.white">
                                    support@cured.health
                                </Link>{' '}
                                to flag inappropriate or inaccurate subject lines.
                            </Typography>
                        </Stack>
                    }
                />
            </Stack>
        </DialogTitle>
        <DialogContent>
            <Typography mt={2} mb={4}>
                Click to use and edit any of the suggested subject lines below.
            </Typography>
            <Stack mb={3} rowGap={2}>
                {renderDialogButtons(subjectLines, onClickSubjectLine, onClose)}
            </Stack>
        </DialogContent>
        <DialogActions>
            <Stack direction="row" justifyContent="flex-end" width="100%">
                <Button variant="contained" onClick={onClose}>
                    Cancel
                </Button>
            </Stack>
        </DialogActions>
    </Dialog>
);

export default SubjectLinesDialog;
