import { UseMutationOptions } from 'react-query';
import { CuredApiPaths } from 'models/enums';
import useMutation from 'hooks/useMutation';
import MutationKeys from './keys';
import { AppointmentValueResponse, ClickAppointmentValue } from 'domains/value/types';

const useClickAppointmentValue = (
    mutateOptions?: UseMutationOptions<AppointmentValueResponse, Error, ClickAppointmentValue>
) =>
    useMutation<ClickAppointmentValue, AppointmentValueResponse>(
        MutationKeys.GET_APPOINTMENT_VALUE,
        CuredApiPaths.APP_CLICK_APPOINTMENT_VALUE,
        mutateOptions
    );

export default useClickAppointmentValue;
