import { AddCircleOutlineOutlined } from '@mui/icons-material';
import Button from 'domains/core/components/Button';

type Props = {
    dataTestId: string;
    handleClick: () => void;
};

const buttonStyles = {
    flex: 1,
    maxWidth: '117px',
    padding: '2px 0',
};

const iconStyles = {
    height: '16px',
    marginRight: '5px',
    width: '16px',
};

const AddSubFilterButton = ({ dataTestId, handleClick }: Props) => (
    <Button data-testid={dataTestId} sx={buttonStyles} variant="outlined" onClick={handleClick}>
        <AddCircleOutlineOutlined sx={iconStyles} />
        sub-filter
    </Button>
);

export default AddSubFilterButton;
