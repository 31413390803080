import { CuredApiPaths } from '../../models/enums';
import { UseMutationOptions } from 'react-query';
import { PathWithID } from '../../models/types';
import { ApiMethods } from '../../models/enums';
import useMutation from '../useMutation';
import MutationKeys from './keys';

const useDeleteLandingPage = (landingPageId: string, mutateOptions?: UseMutationOptions<unknown, Error>) =>
    useMutation(
        MutationKeys.DELETE_LANDING_PAGE,
        new PathWithID(CuredApiPaths.LANDING_PAGES, landingPageId),
        mutateOptions,
        ApiMethods.DELETE
    );

export default useDeleteLandingPage;
