import { ContactPageOutlined } from '@mui/icons-material';
import Button from 'domains/core/components/Button';

export type Props = {
    setIsFileUploadDialogOpen: (isOpen: boolean) => void;
};

const FileUploadButton = ({ setIsFileUploadDialogOpen }: Props) => (
    <Button
        variant="outlined"
        data-testid="file-upload-button"
        onClick={() => setIsFileUploadDialogOpen(true)}
        endIcon={<ContactPageOutlined />}
    >
        Upload
    </Button>
);

export default FileUploadButton;
