import AddIcon from '@mui/icons-material/Add';
import { Stack, MenuItem, Typography } from '@mui/material';

const CreateNewDropDownItem = (props: any) => (
    <MenuItem {...props}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography>Create New</Typography>
            <AddIcon fontSize="inherit" />
        </Stack>
    </MenuItem>
);

export default CreateNewDropDownItem;
