import { MenuItem } from '@mui/material';
import { useMemo } from 'react';
import { useHistory } from 'react-router';
import { Template } from 'domains/content/types';
import Autocomplete from 'domains/core/components/Autocomplete';
import CreateNewDropDownItem from 'domains/core/components/CreateNewDropDownItem';
import useTemplates from 'hooks/queries/useTemplates';
import { URLPaths } from 'models/enums';
import ButtonEmailPreview from './ButtonEmailPreview';
import { AutocompleteOption } from '../Autocomplete/Autocomplete';

export type Props = {
    onChange: (e: any, option: AutocompleteOption<Template>) => void;
    onEmailPreviewClick: () => void;
    onInputChange: (e: any, inputValue: string) => void;
    isPreviewVisible: boolean;
    textfieldInput: string;
    value: AutocompleteOption<Template>;
};

const createNewOption = { index: 0, value: undefined as any, label: 'create new' };

const AutocompleteContent = ({
    onChange,
    onEmailPreviewClick,
    onInputChange,
    isPreviewVisible,
    textfieldInput,
    value,
}: Props) => {
    const history = useHistory();
    const { isSuccess: isTemplatesSuccess, data: templates } = useTemplates();

    const templatesOptions: AutocompleteOption<Template>[] = useMemo(
        () =>
            isTemplatesSuccess
                ? templates
                      .filter((template) => !template.isLayout)
                      .map((template: Template, index: number) => ({
                          index: index + 1,
                          value: template,
                          label: template.name,
                      }))
                : [],
        [isTemplatesSuccess, templates]
    );
    const options = [createNewOption, ...templatesOptions];

    const handleCreateNew = () => {
        history.push(`${URLPaths.CONTENT_CREATE_EMAIL}?id=default`);
    };

    return (
        <Autocomplete
            data-test="content-autocomplete"
            data-testid="content-autocomplete"
            freeSolo={false}
            fullWidth
            getOptionLabel={(option) => option.label ?? ''}
            inputValue={textfieldInput ?? ''}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            label="Content"
            leadingIcon={
                value ? <ButtonEmailPreview onClick={onEmailPreviewClick} isActive={isPreviewVisible} /> : null
            }
            onChange={onChange}
            onInputChange={onInputChange}
            options={options ?? []}
            placeholder="Select or create content"
            renderOption={({ className, ...rest }, { index, label, value }) => {
                const shouldRenderTopOption = index === 0;

                return shouldRenderTopOption ? (
                    <CreateNewDropDownItem
                        {...rest}
                        children={label}
                        key={index}
                        value={value}
                        onClick={handleCreateNew}
                    />
                ) : (
                    <MenuItem {...rest} children={label} key={value.id} value={value} />
                );
            }}
            type="string"
            value={value ?? null}
        />
    );
};

export default AutocompleteContent;
