import { CSSProperties } from 'react';
import { Handle, Position } from 'reactflow';
import { Box, Typography } from '@mui/material';
import theme from 'theme';

export enum Type {
    START = 'Start',
    END = 'END',
}

export type Props = {
    isConnectable: boolean;
    data: {
        label: string;
        type: Type;
    };
    sourcePosition?: Position;
    targetPosition?: Position;
};

const hiddenStyles: CSSProperties = {
    visibility: 'hidden',
};

const EndNode = ({ data: { label, type }, isConnectable, sourcePosition, targetPosition }: Props) => (
    <Box
        sx={{
            borderStyle: 'solid',
            borderColor: theme.palette.action.active,
            borderRadius: '6px',
            borderWidth: 1,
            paddingY: 0.5,
            paddingX: 1.5,
        }}
    >
        {type === Type.END && (
            <Handle
                isConnectable={isConnectable}
                type="target"
                position={targetPosition ?? Position.Top}
                style={hiddenStyles}
            />
        )}
        <Typography variant="overline" color="action.active">
            {label}
        </Typography>
        {type === Type.START && (
            <Handle
                isConnectable={isConnectable}
                type="source"
                position={sourcePosition ?? Position.Bottom}
                style={hiddenStyles}
            />
        )}
    </Box>
);

export default EndNode;
