import { UseMutationOptions } from 'react-query';
import useMutation from 'hooks/useMutation';
import { CuredApiPaths } from 'models/enums';
import { CreateTemplateInput } from 'domains/content/types';
import MutationKeys from './keys';

/**
 * Creates a template.
 * @param mutateOptions react-query useMutation option
 * @returns the react-query useMutation results
 * See https://react-query-v3.tanstack.com/reference/useMutation for options and return value
 */
const useCreateTemplate = (mutateOptions: UseMutationOptions<unknown, Error, CreateTemplateInput>) =>
    useMutation<CreateTemplateInput>(MutationKeys.CREATE_TEMPLATE, CuredApiPaths.TEMPLATES, mutateOptions);

export default useCreateTemplate;
