import { UseMutationOptions } from 'react-query';
import { CuredApiPaths } from 'models/enums';
import useMutation from '../useMutation';
import MutationKeys from './keys';

export type NewFolderPostBody = {
    name: string;
};

/**
 * Creates a folder.
 * @param mutateOptions react-query useMutation option
 * @returns the react-query useMutation results
 * See https://react-query-v3.tanstack.com/reference/useMutation for options and return value
 */
const useCreateFolder = (mutateOptions?: UseMutationOptions<null, Error, NewFolderPostBody>) =>
    useMutation<NewFolderPostBody, null>(MutationKeys.CREATE_FOLDER, CuredApiPaths.FOLDERS, mutateOptions);

export default useCreateFolder;
