import { Grid, Typography } from '@mui/material';
import Card from 'pages/InsightsPage/dashboards/Card';
import { StatsDataProps } from '../../ValueOverviewDashboard';

type Props = {
    data: StatsDataProps['stats'];
    isError: boolean;
    isLoading: boolean;
};

const ValueOverviewStatsCards = ({ data, isError, isLoading }: Props) => {
    const rowData = {
        forecasted: {
            description:
                'An upfront prediction using forecased unique click rate multiplied by the audience from the send and the fallout assumptions',
            eventFigure: data?.forecasted_events,
            eventTooltip: 'Estimated using the forecasted unique click rate',
            label: 'forecasted',
            rateFigure: data?.forecasted_click_rate,
            rateTooltip: 'The assumed unique click rate selected in the calculation input',
            valueFigure: data?.forecasted_value,
            valueTooltip: 'Estimated using the forecasted unique click rate',
        },
        estimated: {
            description:
                'An estimate using the actual unique click rate multiplied by the audience from the send and the fallout assumptions',
            eventFigure: data?.estimated_events,
            eventTooltip: 'Estimated using the actual unique click rate',
            label: 'estimated',
            rateFigure: data?.estimated_click_rate,
            rateTooltip: 'The actual unique click rate from the conversion link selected in the send',
            valueFigure: data?.estimated_value,
            valueTooltip: 'Estimated using the actual unique click rate',
        },
        actuals: {
            description: 'An actual calculation using tracked events from contacts who meet the qualification criteria',
            eventFigure: data?.actual_events,
            eventTooltip: 'Actual qualifying events',
            label: 'actuals',
            rateFigure: data?.actual_click_rate,
            rateTooltip: 'The actual unique click rate from the conversion link selected in the send',
            valueFigure: data?.actual_value,
            valueTooltip: 'Calculated using actual events',
        },
    };

    const StatsRow = ({ label }: { label: 'forecasted' | 'estimated' | 'actuals' }) => (
        <Grid container item justifyContent="center" spacing={2} xs={12}>
            <Grid item xs={12} md={3}>
                <Typography variant="h6" mt={1}>
                    {label}
                </Typography>
                {rowData[label].description}
            </Grid>
            <Grid item xs={12} md={2}>
                <Card
                    isError={isError}
                    isFigureCard
                    isLoading={isLoading}
                    label="Unique Click Rate"
                    tooltip={rowData[label].rateTooltip}
                >
                    {(rowData[label].rateFigure * 100)?.toLocaleString()}%
                </Card>
            </Grid>
            <Grid item xs={12} md={2}>
                <Card
                    isError={isError}
                    isFigureCard
                    isLoading={isLoading}
                    label="Events"
                    tooltip={rowData[label].eventTooltip}
                >
                    {rowData[label].eventFigure?.toLocaleString()}
                </Card>
            </Grid>
            <Grid item xs={12} md={2}>
                <Card
                    isError={isError}
                    isFigureCard
                    isLoading={isLoading}
                    label="Value"
                    tooltip={rowData[label].valueTooltip}
                >
                    ${rowData[label].valueFigure}
                </Card>
            </Grid>
        </Grid>
    );

    return (
        <>
            <StatsRow label="forecasted" />
            <StatsRow label="estimated" />
            <StatsRow label="actuals" />
            <Grid item xs={12} sx={{ paddingTop: '12px !important' }}>
                <Typography align="center" display="block" variant="helperText">
                    When viewing aggregate, click rates showcase the averages across sends. The value and appointments
                    are the total amounts from each send.
                </Typography>
            </Grid>
        </>
    );
};

export default ValueOverviewStatsCards;
