import { Switch, Route } from 'react-router-dom';
import { URLPaths } from 'models/enums';
import ValueCalculatorSendSpecialtyPage from 'domains/value/pages/ValueCalculatorSendSpecialtyPage';
import ValueCalculatorConversionEventPage from 'domains/value/pages/ValueCalculatorConversionEventPage';
import ValueCalculatorConversionMetricPage from 'domains/value/pages/ValueCalculatorConversionMetricPage';
import ValueCalculatorConversionValuePage from 'domains/value/pages/ValueCalculatorConversionValuePage';

const ValueCalculatorNav = () => (
    <Switch>
        <Route
            render={(props) => <ValueCalculatorSendSpecialtyPage {...props} />}
            path={URLPaths.VALUE_SEND_SPECIALTY}
            exact
        />
        <Route
            render={(props) => <ValueCalculatorConversionEventPage {...props} />}
            path={URLPaths.VALUE_CONVERSION_EVENT}
            exact
        />
        <Route
            render={(props) => <ValueCalculatorConversionMetricPage {...props} />}
            path={URLPaths.VALUE_CONVERSION_METRIC}
            exact
        />
        <Route
            render={(props) => <ValueCalculatorConversionValuePage {...props} />}
            path={URLPaths.VALUE_CONVERSION_VALUE}
            exact
        />
    </Switch>
);

export default ValueCalculatorNav;
