import ClockIcon from '@mui/icons-material/QueryBuilder';
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from 'state';
import FormLayout from 'domains/core/components/FormLayout';
import {
    DelayFields,
    ScheduleFields,
    SendRecurrenceFrequency,
    EditDelayPayload as LegacyEditDelayPayload,
} from 'domains/campaigns/types';
import { EditDelayPayload } from 'domains/campaigns/state/createCampaignSlice';
import { CurationStep } from 'models/enums';
import CurationDelayForm from './CurationDelayForm';

/**
 * Single send and time delay curations have the same basic Redux store shape and require nodeId, editDelayActionCreator,
 * selectDelayFields, selectScheduleFields, selectTimestampsBeforeAndAfterDelay, and selectDelayErrorMessage.
 * Conditional split curations currently have a different Redux store shape and do not use redux-toolkit, so legacyErrorMessage,
 * legacyNextEmailTimestamp, and legacyEditDelayFields were added as a stopgap to allow CS curations to use this file.
 *
 * TLDR; you must pass nodeId, editDelayActionCreator, selectDelayFields, selectScheduleFields,
 * selectTimestampsBeforeAndAfterDelay, and selectDelayErrorMessage to use this component.
 * If working in CS code, you must pass legacyErrorMessage, legacyNextEmailTimestamp,
 * legacyEditDelayFields, selectDelayFields, and selectScheduleFields.
 */

type Props = {
    legacyErrorMessage?: string;
    legacyNextEmailTimestamp?: string;
    nodeId?: string;
    editDelayActionCreator?: ActionCreatorWithOptionalPayload<EditDelayPayload, string>;
    legacyEditDelayFields?: (payload: LegacyEditDelayPayload) => void;
    selectDelayFields: (state: RootState) => DelayFields;
    selectScheduleFields: (state: RootState) => ScheduleFields;
    selectTimestampsBeforeAndAfterDelay?: (state: RootState) => { beforeTimestamp: string; afterTimestamp: string };
    selectDelayErrorMessage?: (state: RootState) => string;
};

type LegacyDelayFormWrapperProps = {
    delayFields: DelayFields;
    legacyErrorMessage: Props['legacyErrorMessage'];
    legacyNextEmailTimestamp: Props['legacyNextEmailTimestamp'];
    recurrenceFrequency: SendRecurrenceFrequency;
    legacyEditDelayFields: Props['legacyEditDelayFields'];
};

const LegacyDelayFormWrapper = ({
    delayFields,
    legacyErrorMessage,
    legacyNextEmailTimestamp,
    recurrenceFrequency,
    legacyEditDelayFields,
}: LegacyDelayFormWrapperProps) => (
    <CurationDelayForm
        delayFields={{ delay: delayFields.delay, unit: delayFields.unit }}
        errorMessage={legacyErrorMessage}
        nextEmailTimestamp={legacyNextEmailTimestamp}
        recurrenceFrequency={recurrenceFrequency}
        legacyEditDelayFields={legacyEditDelayFields}
    />
);

type DelayFormWrapperProps = {
    delayFields: DelayFields;
    nodeId: Props['nodeId'];
    recurrenceFrequency: SendRecurrenceFrequency;
    dispatch: Dispatch;
    editDelayActionCreator: Props['editDelayActionCreator'];
    selectDelayErrorMessage: Props['selectDelayErrorMessage'];
    selectTimestampsBeforeAndAfterDelay: Props['selectTimestampsBeforeAndAfterDelay'];
};

const DelayFormWrapper = ({
    delayFields,
    nodeId,
    recurrenceFrequency,
    dispatch,
    editDelayActionCreator,
    selectDelayErrorMessage,
    selectTimestampsBeforeAndAfterDelay,
}: DelayFormWrapperProps) => {
    const editDelayFields = (payload: EditDelayPayload) => dispatch(editDelayActionCreator(payload));
    const errorMessage = useSelector(selectDelayErrorMessage);
    const { afterTimestamp: nextEmailTimestamp } = useSelector(selectTimestampsBeforeAndAfterDelay);

    return (
        <CurationDelayForm
            delayFields={delayFields}
            errorMessage={errorMessage}
            nextEmailTimestamp={nextEmailTimestamp}
            nodeId={nodeId}
            recurrenceFrequency={recurrenceFrequency}
            editDelayFields={editDelayFields}
        />
    );
};

export const CurationDelayPage = ({
    legacyErrorMessage,
    legacyNextEmailTimestamp,
    nodeId,
    editDelayActionCreator,
    legacyEditDelayFields,
    selectDelayFields,
    selectScheduleFields,
    selectTimestampsBeforeAndAfterDelay,
    selectDelayErrorMessage,
}: Props) => {
    const dispatch = useDispatch();

    const { recurrenceFrequency } = useSelector(selectScheduleFields);
    const delayFields = useSelector(selectDelayFields);

    const renderDelayForm = () => {
        if (legacyEditDelayFields) {
            return (
                <LegacyDelayFormWrapper
                    delayFields={delayFields}
                    legacyErrorMessage={legacyErrorMessage}
                    legacyNextEmailTimestamp={legacyNextEmailTimestamp}
                    recurrenceFrequency={recurrenceFrequency}
                    legacyEditDelayFields={legacyEditDelayFields}
                />
            );
        }

        return (
            <DelayFormWrapper
                delayFields={delayFields}
                nodeId={nodeId}
                recurrenceFrequency={recurrenceFrequency}
                dispatch={dispatch}
                editDelayActionCreator={editDelayActionCreator}
                selectDelayErrorMessage={selectDelayErrorMessage}
                selectTimestampsBeforeAndAfterDelay={selectTimestampsBeforeAndAfterDelay}
            />
        );
    };

    return (
        <FormLayout
            data-testid="curation-delay-form-layout"
            header="setup time delay"
            icon={<ClockIcon />}
            stepName={CurationStep.TIME_DELAY}
            guidingText="Choose how long you want to wait before sending the next email. This time should align with your Curations objective."
        >
            {renderDelayForm()}
        </FormLayout>
    );
};

export default CurationDelayPage;
