import { useMemo } from 'react';
import { useHistory } from 'react-router';
import { Tooltip, Typography } from '@mui/material';
import { AudienceStatus, URLPaths } from 'models/enums';
import { ClickApptValueAssumption } from 'domains/value/types';
import Table, { ColumnOption } from 'domains/core/components/Table';
import AudienceStatusText from 'domains/core/components/AudienceStatusText';
import MoreOptionsDropdown from 'domains/core/components/MoreOptionsDropdown';

type Props = {
    clickApptValueAssumptionResponses: ClickApptValueAssumption[];
    isRefetching: boolean;
    refetch: () => void;
};

const ValueCalculatorTable = ({ clickApptValueAssumptionResponses, isRefetching, refetch }: Props) => {
    const history = useHistory();

    const data = clickApptValueAssumptionResponses.map((response) => ({ ...response, id: response.sendId }));

    const columns: ColumnOption[] = useMemo(
        () => [
            {
                Header: 'name',
                accessor: 'sendName',
                align: 'left',
            },
            {
                Header: () => (
                    <Tooltip title="for calculations that have sent, you can view progress and track insights over time in insights">
                        <Typography component="span">status</Typography>
                    </Tooltip>
                ),
                accessor: 'audienceStatus',
                align: 'center',
                Cell: (value) => {
                    const audienceStatus = value.row.original.audienceStatus;

                    return <AudienceStatusText audienceStatus={audienceStatus} />;
                },
            },
            {
                Header: 'specialty',
                accessor: 'specialty',
                align: 'center',
            },
            {
                Header: '',
                accessor: 'options',
                align: 'right',
                disableSortBy: true,
                Cell: (value) => {
                    const clickApptValueAssumption: ClickApptValueAssumption = value.row.original;

                    const isAudienceStatusCanceled =
                        clickApptValueAssumption.audienceStatus === AudienceStatus.CANCELED;

                    const viewDetailsMenuItem = {
                        disabled: isAudienceStatusCanceled,
                        name: 'View Details',
                        onClick: () => {
                            history.push(`${URLPaths.VALUE_DETAILS}/${clickApptValueAssumption.sendId}/summary`);
                        },
                        tooltipTitle: isAudienceStatusCanceled && 'Values not available for canceled sends.',
                    };

                    const viewDashboardMenuItem = {
                        disabled: clickApptValueAssumption.audienceStatus !== AudienceStatus.COMPLETE,
                        name: 'View Dashboard',
                        onClick: () => history.push(URLPaths.INSIGHTS),
                    };

                    return (
                        <MoreOptionsDropdown
                            buttonTestId={`name-${clickApptValueAssumption.sendName}-options`}
                            menuItems={[viewDetailsMenuItem, viewDashboardMenuItem]}
                        />
                    );
                },
            },
        ],
        [history]
    );

    return (
        <Table
            data={data}
            columns={columns}
            defaultSortingRules={[{ id: 'name', desc: false }]}
            isRefetching={isRefetching}
            refetch={refetch}
        />
    );
};

export default ValueCalculatorTable;
