import { Stack } from '@mui/material';
import { DelayFields, ScheduleFields, EditDelayPayload as LegacyEditDelayPayload } from 'domains/campaigns/types';
import Select from 'domains/core/components/Select';
import TextField from 'domains/core/components/TextField';
import ErrorSnackbar from 'domains/core/components/Snackbars/ErrorSnackbar';
import { EditDelayPayload } from 'domains/campaigns/state/createCampaignSlice';
import DelayInfo from './DelayInfo';

const timeframeValues = [
    { value: 'hours', label: 'Hours' },
    { value: 'days', label: 'Days' },
];

/**
 * Single send and time delay curations have the same basic Redux store shape and require nodeId and editDelayFields.
 * Conditional split curations currently have a different Redux store shape and do not use redux-toolkit, so
 * legacyEditDelayFields was added as a stopgap to allow CS curations to use this file.
 *
 * TLDR; you must pass nodeId and editDelayFields use this component.
 * If working in CS code, you must pass legacyEditDelayFields.
 */

export type Props = {
    delayFields: DelayFields;
    errorMessage: string;
    nextEmailTimestamp: string;
    nodeId?: string;
    recurrenceFrequency: ScheduleFields['recurrenceFrequency'];
    editDelayFields?: (delayFields: EditDelayPayload) => void;
    legacyEditDelayFields?: (payload: LegacyEditDelayPayload) => void;
};

const CurationDelayForm = ({
    delayFields,
    errorMessage,
    nextEmailTimestamp,
    nodeId,
    recurrenceFrequency,
    editDelayFields,
    legacyEditDelayFields,
}: Props) => {
    const { delay, unit } = delayFields;
    const shouldShowAlert = !!delay && !!unit;

    const handleDelayChange = (e: any) => {
        // Don't allow decimal or negative values.
        if (e.target.value.includes('.') || e.target.value.includes('-')) return;

        const delayAsNum = Number(e.target.value);
        // Very large numbers cause Dates to be invalid, so limit the delay.
        if (delayAsNum < 10000) {
            if (!!legacyEditDelayFields) {
                legacyEditDelayFields({ delay: delayAsNum, unit, errorMessage });
            } else {
                editDelayFields({ delayFields: { ...delayFields, delay: delayAsNum }, nodeId });
            }
        }
    };

    const handleUnitChange = (e: any) => {
        if (!!legacyEditDelayFields) {
            legacyEditDelayFields({ delay, unit: e.target.value, errorMessage });
        } else {
            editDelayFields({ delayFields: { ...delayFields, unit: e.target.value }, nodeId });
        }
    };

    return (
        <>
            {!!errorMessage && <ErrorSnackbar errorMessage={errorMessage} />}
            <Stack spacing={4} width="100%">
                <Stack spacing={2} direction="row">
                    <TextField
                        label="Delay"
                        type="number"
                        value={delay ?? ''}
                        onChange={handleDelayChange}
                        inputProps={{ maxLength: 255 }}
                        fullWidth
                    />
                    <Select label="Timeframe" values={timeframeValues} onChange={handleUnitChange} value={unit ?? ''} />
                </Stack>
                {shouldShowAlert && (
                    <DelayInfo
                        delayFields={delayFields}
                        nextEmailTimestamp={nextEmailTimestamp}
                        recurrenceFrequency={recurrenceFrequency}
                    />
                )}
            </Stack>
        </>
    );
};

export default CurationDelayForm;
