import { useMutation as reactQueryUseMutation, UseMutationOptions, MutationKey } from 'react-query';
import useConfig from 'hooks/useConfig';
import { ApiMethods, CuredApiPaths } from '../models/enums';
import useGetAxiosInstance from './useGetAxiosInstance';
import { PathWithID } from '../models/types';
import { getErrorWithMessage } from 'utils';

const isPathWithID = (path: CuredApiPaths | PathWithID | string): path is PathWithID =>
    Boolean((path as PathWithID)?.path);

/**
 * A wrapper around the react-query hook of the same name.
 * For more details see: https://react-query-v3.tanstack.com/reference/useMutation
 * @param mutationKey a unique id for the mutation used for caching
 * @param path the API path
 * @param mutateOptions options for the mutation
 * @returns the react-query mutation
 */
export default function useMutation<TVariables = unknown, TData = unknown, TError = Error, TContext = unknown>(
    mutationKey: MutationKey,
    // todo - see if there is a way to make this type stronger
    path: CuredApiPaths | PathWithID | string,
    mutateOptions: UseMutationOptions<TData, TError, TVariables, TContext>,
    method: ApiMethods.DELETE | ApiMethods.PATCH | ApiMethods.POST | ApiMethods.PUT = ApiMethods.POST,
    authenticated: boolean = true
) {
    const useConfigQuery = useConfig();

    if (!useConfigQuery.config) {
        throw new Error(
            'You must mock useMutation hooks in unit tests. See the docs for details: https://github.com/Cured-Health/web-app/blob/development/frontend/docs/testing.md#how-to-mock-usequery-hooks'
        );
    }

    const {
        config: { API_URL },
    } = useConfig();
    const getApiInstance = useGetAxiosInstance(method, undefined, authenticated);

    // const mutationResult = reactQueryUseMutation<TData, TError, TData, TContext>(
    const mutationResult = reactQueryUseMutation<TData, TError, TVariables, TContext>(
        mutationKey,
        async (body: TVariables): Promise<TData> => {
            const apiInstance = await getApiInstance();
            const APIMethodToInstanceMethod = {
                [ApiMethods.DELETE]: apiInstance.delete,
                [ApiMethods.PATCH]: apiInstance.patch,
                [ApiMethods.POST]: apiInstance.post,
                [ApiMethods.PUT]: apiInstance.put,
            };

            const mutate: any = APIMethodToInstanceMethod[method];
            const url = isPathWithID(path) ? `${API_URL}${path.url}` : path;
            try {
                return (await mutate(url, body)).data;
            } catch (error) {
                const errorWithMessage = getErrorWithMessage(error);
                throw errorWithMessage;
            }
        },
        mutateOptions
    );

    return mutationResult;
}
