import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { CuredApiPaths } from 'models/enums';
import Card from 'pages/InsightsPage/dashboards/Card';
import { FiltersForApiState } from 'pages/InsightsPage/dashboards/filtering/types';
import useFilteredData from 'pages/InsightsPage/dashboards/filtering/useFilteredData';
import FunnelChart from 'pages/InsightsPage/visualizations/FunnelChart';
import { FunnelDataItem } from 'pages/InsightsPage/visualizations/FunnelChart/FunnelChart';

type Props = {
    filtersForApi: FiltersForApiState;
};

const EngagementFunnelCard = ({ filtersForApi }: Props) => {
    const { getData, isError, isLoading, data } = useFilteredData({
        key: 'curations-dashboard/engagement-funnel',
        path: CuredApiPaths.REPORT_CURATION_ENGAGEMENT_FUNNEL,
    });

    useEffect(() => {
        getData(filtersForApi);
    }, [filtersForApi, getData]);

    return (
        <Card isError={isError} isLoading={isLoading} label="Engagement Funnel">
            <Typography fontSize={16} mt={1} variant="body2">
                Identify drop offs between steps in the funnel to identify areas for improvement.
            </Typography>
            <FunnelChart data={data as FunnelDataItem[]} height="340px" />
        </Card>
    );
};

export default EngagementFunnelCard;
