import { Box } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import NoDataMessage from '../NoDataMessage';

const DataGrid = ({ columns, hideFooterRowCount, initialState, rows }: any) => {
    if (!columns || !rows) return <NoDataMessage />;

    return (
        <Box
            sx={{
                height: '400px',
                marginTop: 2,
                '& .MuiDataGrid-columnHeaderTitle': {
                    textTransform: 'capitalize',
                },
            }}
        >
            <DataGridPro
                autoHeight={false}
                columns={columns}
                hideFooterRowCount={hideFooterRowCount}
                initialState={initialState}
                rows={rows}
                sx={{ border: 0 }}
            />
        </Box>
    );
};

export default DataGrid;
