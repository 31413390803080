type Props = {
    lookerEmbedURL: string;
};

const LookerEmbed = ({ lookerEmbedURL }: Props) => (
    <iframe
        id="looker-frame"
        frameBorder={0}
        width="100%"
        height="100%"
        title="Looker Dashboard"
        src={lookerEmbedURL}
    />
);

export default LookerEmbed;
