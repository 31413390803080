import { InfoOutlined } from '@mui/icons-material';
import { Box, Grid, Stack, Tooltip, Typography } from '@mui/material';

export type Props = {
    children: JSX.Element;
    header: string;
    stepName: string;
    guidingText?: string;
    guidingTooltipText?: string;
    icon?: JSX.Element;
};

const FormLayout = ({ children, header, stepName, guidingText, guidingTooltipText, icon, ...rest }: Props) => {
    const guiding = guidingTooltipText ? (
        <Stack direction="row" alignItems="center" mt={3} columnGap={1}>
            <Typography variant="body1">
                {guidingText}
                <Tooltip title={guidingTooltipText}>
                    <InfoOutlined sx={{ ml: 0.5, fontSize: '12px', pt: 0.5 }} />
                </Tooltip>
            </Typography>
        </Stack>
    ) : (
        <Typography variant="body1" mt={3}>
            {guidingText}
        </Typography>
    );

    return (
        <Grid container height="100%" alignContent="start" {...rest}>
            <Grid item xs={12}>
                <Stack direction="row" alignItems="center" columnGap={1}>
                    <Box color="action.active" mt={1}>
                        {icon}
                    </Box>
                    <Typography variant="caption" color="primary.main">
                        {stepName}
                    </Typography>
                </Stack>
            </Grid>
            <Grid item>
                <Typography variant="h3" mt={1}>
                    {header}
                </Typography>
            </Grid>
            <Grid item>{guiding}</Grid>
            <Grid item xs={12} mt={6}>
                {children}
            </Grid>
        </Grid>
    );
};

export default FormLayout;
