import { InfoOutlined } from '@mui/icons-material';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, Tooltip, Typography } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { ChangeEvent, useEffect } from 'react';
import { getTimezone } from 'utils';
import { setScheduledEndTimestamp } from 'domains/campaigns/components/CustomCampaign/customCampaignActions';
import { getEndDateTimeError, minDate } from 'domains/campaigns/utils';

export type Props = {
    dispatchCampaignAction: React.Dispatch<any>;
    endDate: DateTime;
    hasEndDate: boolean;
    sendDate: DateTime;
    setEndDate: (payload: DateTime) => void;
    setHasEndDate: (payload: boolean) => void;
    setIsContinueEnabled: (isContinueEnabled: boolean) => void;
    setIsRecommendedTimeSwitchTouched: (payload: boolean) => void;
};

const EndDateTimePicker = ({
    dispatchCampaignAction,
    endDate,
    hasEndDate,
    sendDate,
    setEndDate,
    setHasEndDate,
    setIsContinueEnabled,
    setIsRecommendedTimeSwitchTouched,
}: Props) => {
    const onChangeHasEndDate = (event: ChangeEvent<HTMLInputElement>) => {
        if ((event.target as HTMLInputElement).value === 'true') {
            setHasEndDate(true);
            setEndDate(sendDate);
        } else {
            dispatchCampaignAction(setScheduledEndTimestamp(''));
            setHasEndDate(false);
            setEndDate(null);
        }
        setIsRecommendedTimeSwitchTouched(true);
    };

    const onChangeEndDatetime = (datetime: DateTime) => {
        setEndDate(datetime);

        if (datetime?.isValid) {
            const newDatetime = datetime.set({ year: datetime.year, month: datetime.month, day: datetime.day });
            dispatchCampaignAction(setScheduledEndTimestamp(newDatetime.toISO()));
            setEndDate(newDatetime);
        }

        setIsRecommendedTimeSwitchTouched(true);
    };

    const endDateErrorMessage = getEndDateTimeError(endDate, sendDate);
    const hasEndDateError = hasEndDate && endDate;
    const shouldShowEndDateErrorMessage = hasEndDateError && Boolean(endDateErrorMessage);

    useEffect(() => {
        setIsContinueEnabled(!shouldShowEndDateErrorMessage);
    }, [setIsContinueEnabled, shouldShowEndDateErrorMessage]);

    return (
        <>
            <FormControl>
                <Stack direction="row" columnGap={2} alignItems="center" my={-2}>
                    <FormLabel>
                        <Typography variant="body2">End Date/Time</Typography>
                    </FormLabel>
                    <RadioGroup
                        value={hasEndDate}
                        onChange={onChangeHasEndDate}
                        defaultValue={false}
                        name="end-date-group"
                        row
                    >
                        <FormControlLabel
                            value={false}
                            control={<Radio size="small" color="secondary" />}
                            label={<Typography variant="body2">Never</Typography>}
                        />
                        <FormControlLabel
                            value={true}
                            control={<Radio size="small" color="secondary" />}
                            label={<Typography variant="body2">Ends On</Typography>}
                            data-testid="recurrence-end-date-label"
                        />
                    </RadioGroup>
                </Stack>
            </FormControl>
            {hasEndDate && (
                <DateTimePicker
                    data-testid="send-date-picker"
                    label={
                        <>
                            End Date/Time ({getTimezone()})
                            <Tooltip title="This is the last day that any sends will go out.">
                                <InfoOutlined style={{ fontSize: '14px', margin: '0 0 -2px 6px' }} />
                            </Tooltip>
                        </>
                    }
                    minDateTime={minDate()}
                    slotProps={{
                        textField: {
                            error: shouldShowEndDateErrorMessage,
                            helperText: hasEndDateError && endDateErrorMessage,
                            id: 'end-datetime-picker',
                        },
                        popper: {
                            placement: 'bottom-end',
                        },
                    }}
                    value={endDate}
                    onChange={(datetime) => onChangeEndDatetime(datetime)}
                />
            )}
        </>
    );
};

export default EndDateTimePicker;
