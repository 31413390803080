import Chip from 'domains/core/components/Chip';
import { SegmentDefinition } from 'domains/segments/types';

type MatchTypeChipProps = {
    matchType: SegmentDefinition['matchType'];
};

const MatchTypeChip = ({ matchType }: MatchTypeChipProps) => (
    <div>
        <Chip label={matchType} sx={{ margin: '2px 8px 2px 0', textTransform: 'lowercase' }} />
    </div>
);

export default MatchTypeChip;
