import Card from 'pages/InsightsPage/dashboards/Card';
import BarGraph from 'pages/InsightsPage/visualizations/BarGraph';
import { StatsDataProps } from '../../ValueOverviewDashboard';

type Props = {
    data: StatsDataProps['values'];
    isError: boolean;
    isLoading: boolean;
};

const ValueBarGraphCard = ({ data, isError, isLoading }: Props) => (
    <Card
        isError={isError}
        isLoading={isLoading}
        label="Value"
        tooltip="Comparison of the forecasted, estimated, and actual value"
    >
        <BarGraph data={data} leftLegendLabel="dollar amount" useSingleColor={false} valueFormat="currency" />
    </Card>
);

export default ValueBarGraphCard;
