import { useState } from 'react';
import { useMediaQuery } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { UncapitalizeObjectKeys } from '@mui/x-date-pickers/internals/utils/slots-migration';
import { DatePicker as MUIDatePicker, DatePickerProps, DatePickerSlotsComponents } from '@mui/x-date-pickers';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import merge from 'lodash/merge';
import { DateTime } from 'luxon';
import theme from 'theme';

const DatePicker = ({
    maxDate,
    minDate,
    slotProps: slotPropsFromProps,
    slots: slotsFromProps,
    value,
    ...rest
}: DatePickerProps<DateTime>) => {
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const handleIconClick = () => setOpen(!open);
    const handleTextFieldClick = () => setOpen(true);

    /*
        The mobile DatePicker does not have an icon, so we need to enable opening the DatePicker by clicking the text field on mobile only.
        To do that, we use the same media query that MUI uses here - https://github.com/mui/mui-x/blob/next/packages/x-date-pickers/src/DatePicker/DatePicker.tsx
        defaults to `true` in environments where `window.matchMedia` would not be available (i.e. test/jsdom)
    */
    const isDesktop = useMediaQuery('@media (pointer: fine)', { defaultMatches: true });

    const slotProps = {
        textField: {
            onMouseDown: isDesktop ? undefined : handleTextFieldClick,
        },
        inputAdornment: {
            position: 'start',
        },
        desktopPaper: {
            sx: {
                marginTop: 2.125,
                marginBottom: 2.125,
                paddingTop: 2,
            },
        },
        mobilePaper: {
            sx: {
                marginTop: 2.125,
                marginBottom: 2.125,
                paddingTop: 2,
            },
        },
        popper: {
            placement: 'bottom',
            sx: {
                '.MuiPickersArrowSwitcher-button:not(.Mui-disabled) svg': {
                    fill: theme.palette.action.active,
                },
                '.MuiButtonBase-root:hover': {
                    backgroundColor: theme.palette.action.hover,
                    color: theme.palette.primary.main,
                },
                '.MuiPickersDay-root.Mui-selected': {
                    backgroundColor: theme.palette.action.active,
                },
                '.MuiPickersDay-root.Mui-selected:hover': {
                    backgroundColor: theme.palette.action.hover,
                    color: theme.palette.primary.main,
                },
                '.PrivatePickersYear-yearButton:hover': {
                    backgroundColor: theme.palette.action.hover,
                    color: theme.palette.primary.main,
                },
                '.PrivatePickersYear-yearButton.Mui-selected': {
                    backgroundColor: theme.palette.action.active,
                },
                '.PrivatePickersYear-yearButton.Mui-selected:hover': {
                    backgroundColor: theme.palette.action.hover,
                    color: theme.palette.primary.main,
                },
            },
        },
    };

    // Disable the year select if the min and max date are within the current year.
    const isYearSelectDisabled = minDate?.year === maxDate?.year && DateTime.now().year === minDate?.year;

    const slots: UncapitalizeObjectKeys<DatePickerSlotsComponents<DateTime>> = {
        openPickerIcon: () => <CalendarTodayIcon color={open ? 'action' : 'primary'} onClick={handleIconClick} />,
        switchViewIcon: isYearSelectDisabled
            ? () => <></>
            : (props) => <ArrowDropDownRoundedIcon color="primary" {...props} />,
        switchViewButton: isYearSelectDisabled ? () => <></> : undefined,
    };

    return (
        <MUIDatePicker
            slotProps={merge(slotProps, slotPropsFromProps)}
            slots={merge(slots, slotsFromProps)}
            maxDate={maxDate}
            minDate={minDate}
            onAccept={handleClose}
            onClose={handleClose}
            open={open}
            value={value}
            {...rest}
        />
    );
};
export default DatePicker;
