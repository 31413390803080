import { Typography } from '@mui/material';

type Props = {
    title: string;
    subtitle: string;
    description?: string;
};

const DashboardHeader = ({ title, subtitle, description }: Props) => (
    <>
        <Typography variant="caption" color="action.active">
            {title}
        </Typography>
        <Typography variant="h3" mt={1} mb={1}>
            {subtitle}
        </Typography>
        {description}
    </>
);

export default DashboardHeader;
