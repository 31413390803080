import { useHistory } from 'react-router';
import { SwapCalls } from '@mui/icons-material';
import { URLPaths } from 'models/enums';
import Button from 'domains/core/components/Button';
import EmptyStateLayout from 'domains/core/components/EmptyStateLayout';
import ErrorSnackbar from 'domains/core/components/Snackbars/ErrorSnackbar';
import ValueCalculatorFlow from 'domains/value/components/ValueCalculatorFlow/ValueCalculatorFlow';
import useAppSendsValueAssumption from 'hooks/queries/useAppSendsValueAssumption';

const ValueCalculatorCreatePage = () => {
    const history = useHistory();
    const {
        data: sends,
        error: sendsError,
        isError: sendsIsError,
        isLoading: sendsIsLoading,
        isSuccess: sendsIsSuccess,
    } = useAppSendsValueAssumption();

    const onClickCurations = () => history.push(URLPaths.CURATIONS);

    if (sendsIsLoading || sendsIsError || (sendsIsSuccess && sends.length)) {
        return (
            <>
                {sendsIsError && <ErrorSnackbar errorMessage={sendsError.message} />}
                <ValueCalculatorFlow />
            </>
        );
    }

    if (!sends?.length) {
        return (
            <EmptyStateLayout
                caption="value"
                header="track the impact of your marketing efforts"
                guidingText="Once your contacts are loaded and you have scheduled your first curation, you can start tracking value."
            >
                <Button variant="outlined" endIcon={<SwapCalls />} onClick={onClickCurations}>
                    Curations
                </Button>
            </EmptyStateLayout>
        );
    }
};

export default ValueCalculatorCreatePage;
