import { SxProps, Theme } from '@mui/material';
import TextField from 'domains/core/components/TextField';
import { SegmentDemographicValue } from 'domains/segments/types';

type Props = {
    placeholderName: string;
    sx?: SxProps<Theme>;
    value: SegmentDemographicValue;
    onChange: (value: any) => void;
};

const PercentageInput = ({ onChange, placeholderName, sx, value }: Props) => (
    <TextField
        data-testid="valueTextField"
        label="Value"
        onChange={(e: any) => onChange(e.target.value)}
        placeholder={`Enter a percentile value for ${placeholderName}`}
        sx={{
            width: '16rem',
            ...sx,
        }}
        type="number"
        value={value}
    />
);

export default PercentageInput;
