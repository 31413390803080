import Card from 'pages/InsightsPage/dashboards/Card';
import PieChart from 'pages/InsightsPage/visualizations/PieChart';
import useContactDomains from './useContactDomains';

const DomainsCard = () => {
    const { data, isError, isLoading } = useContactDomains();

    return (
        <Card isError={isError} isLoading={isLoading} label="Domains">
            <PieChart data={data} />
        </Card>
    );
};

export default DomainsCard;
