import { MoreVert } from '@mui/icons-material';
import { useState } from 'react';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';

export type MenuItemProps = {
    disabled?: boolean;
    name: string;
    onClick: () => void;
    onMouseLeave?: () => any;
    onMouseOver?: () => any;
    tooltipTitle?: string;
};

export type Props = {
    buttonTestId?: string;
    menuItems: MenuItemProps[];
};

const MoreOptionsDropdown = ({ buttonTestId, menuItems }: Props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClickButton = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const closeMenu = (event?: React.MouseEvent<HTMLElement>) => {
        event?.stopPropagation();
        setAnchorEl(null);
    };

    const handleClickMenuItem = (event: React.MouseEvent<HTMLElement>, onClick: MenuItemProps['onClick']) => {
        event.stopPropagation();
        closeMenu();
        onClick();
    };

    const renderMenuItem = (menuItem: MenuItemProps) => (
        <MenuItem
            key={menuItem.name}
            disabled={menuItem.disabled}
            onClick={(event) => handleClickMenuItem(event, menuItem.onClick)}
            onMouseLeave={menuItem.onMouseLeave}
            onMouseOver={menuItem.onMouseOver}
        >
            {menuItem.name}
        </MenuItem>
    );

    const renderDropdownOption = (menuItem: MenuItemProps) => {
        if (!!menuItem.tooltipTitle) {
            return (
                <Tooltip key={menuItem.name} title={menuItem.tooltipTitle} placement="left">
                    <span>{renderMenuItem(menuItem)}</span>
                </Tooltip>
            );
        }

        return renderMenuItem(menuItem);
    };

    return (
        <>
            <IconButton data-test={buttonTestId} aria-label="More options" color="primary" onClick={handleClickButton}>
                <MoreVert />
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} keepMounted onClose={closeMenu}>
                {menuItems.map((menuItem) => renderDropdownOption(menuItem))}
            </Menu>
        </>
    );
};

export default MoreOptionsDropdown;
