import { useMemo, useState } from 'react';
import Autocomplete from 'domains/core/components/Autocomplete';
import ButtonEmailPreview from 'domains/core/components/AutocompleteContent/ButtonEmailPreview';
import { Send } from 'domains/campaigns/types';
import { Props as AutocompleteProps } from 'domains/core/components/Autocomplete';
import { AutocompleteOption } from 'domains/core/components/Autocomplete/Autocomplete';
import ProgressCircleIcon from 'domains/core/components/SvgIcons/ProgressCircleIcon';
import useAppSendsValueAssumption from 'hooks/queries/useAppSendsValueAssumption';

export type Props = {
    isPreviewVisible: boolean;
    inputValue: string;
    value: Send['id'];
    onChange: (e: any, option: AutocompleteOption<Send['id']>) => void;
    onInputChange: (e: any, value: string) => void;
    onClickEmailPreview: () => void;
};

const AutocompleteSend = ({
    isPreviewVisible,
    inputValue,
    value,
    onChange,
    onInputChange,
    onClickEmailPreview,
    ...props
}: Props & Partial<AutocompleteProps>) => {
    const { data: sends, isLoading: sendsIsLoading, isSuccess: sendsIsSuccess } = useAppSendsValueAssumption();

    const options: AutocompleteOption<Send['id']>[] = useMemo(
        () => (sendsIsSuccess ? sends.map(({ id, name }) => ({ label: name, value: id })) : []),
        [sends, sendsIsSuccess]
    );

    const [hasFocus, setFocus] = useState(false);

    const autocompleteValue = useMemo(() => options.find((option) => option.value === value) ?? null, [options, value]);

    const leadingIcon = () => {
        if (hasFocus && sendsIsLoading) {
            return <ProgressCircleIcon />;
        }
        if (value) {
            return <ButtonEmailPreview onClick={onClickEmailPreview} isActive={isPreviewVisible} />;
        }
        return null;
    };

    return (
        <Autocomplete
            value={autocompleteValue}
            inputValue={inputValue}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            onChange={onChange}
            onInputChange={onInputChange}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            options={options}
            label="Select send"
            leadingIcon={leadingIcon()}
            placeholder={hasFocus && sendsIsLoading ? 'Loading sends...' : 'Select send'}
            data-test="autocomplete-send"
            data-testid="autocomplete-send"
            type="number"
            {...props}
        />
    );
};

export default AutocompleteSend;
