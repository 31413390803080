import { Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { getFormattedLocalTime } from 'utils';

export type Props = {
    startTime: string;
    endTime?: string;
};

export const formatDateTime = (datetime: string) => {
    const sendDatetimeLuxon = DateTime.fromISO(datetime);
    const date = sendDatetimeLuxon.toLocaleString(DateTime.DATE_MED);
    const time = getFormattedLocalTime(sendDatetimeLuxon.toISO());

    return `${date} at ${time}`;
};

export const formatRange = (startTime: string, endTime: string) => {
    const isSameTime = startTime === endTime || !endTime;
    const isSameYear = startTime?.slice(0, 4) === endTime?.slice(0, 4);

    let startTimeFormatted;
    if (isSameTime) {
        startTimeFormatted = DateTime.fromISO(startTime).toLocaleString(DateTime.DATE_MED);
    } else if (isSameYear) {
        startTimeFormatted = DateTime.fromISO(startTime).toFormat('MMM d');
    } else {
        startTimeFormatted = DateTime.fromISO(startTime).toLocaleString(DateTime.DATE_MED);
    }
    const endTimeFormatted = DateTime.fromISO(endTime).toLocaleString(DateTime.DATE_MED);

    const scheduleTime = isSameTime ? startTimeFormatted : `${startTimeFormatted} - ${endTimeFormatted}`;
    return scheduleTime;
};

const DateRangeText = ({ startTime, endTime }: Props) => {
    const scheduleTime = formatRange(startTime, endTime);

    return (
        <>
            <Typography component="span" variant="body1">
                {scheduleTime}
            </Typography>
        </>
    );
};

export default DateRangeText;
