import { useEffect } from 'react';
import { CuredApiPaths } from 'models/enums';
import Card from 'pages/InsightsPage/dashboards/Card';
import { FiltersForApiState } from 'pages/InsightsPage/dashboards/filtering/types';
import useFilteredData from 'pages/InsightsPage/dashboards/filtering/useFilteredData';
import PieChart from 'pages/InsightsPage/visualizations/PieChart';
import { PieChartProps } from 'pages/InsightsPage/visualizations/PieChart/PieChart';

type Props = {
    filtersForApi: FiltersForApiState;
};

const ClicksByDeviceCard = ({ filtersForApi }: Props) => {
    const { getData, isError, isLoading, data } = useFilteredData({
        key: 'curations-dashboard/device-clicks',
        path: CuredApiPaths.REPORT_CURATION_DEVICE_CLICKS,
    });

    useEffect(() => {
        getData(filtersForApi);
    }, [filtersForApi, getData]);

    return (
        <Card isError={isError} isLoading={isLoading} label="Clicks by Device">
            <PieChart data={data as PieChartProps['data']} />
        </Card>
    );
};

export default ClicksByDeviceCard;
