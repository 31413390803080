import useMutation from 'hooks/useMutation';
import { ApiMethods, CuredApiPaths } from 'models/enums';
import { FiltersForApiState } from './types';

type Args = {
    key: string;
    path: CuredApiPaths;
};

type ReturnTypes = {
    getData: (filters: FiltersForApiState) => void;
    isError: boolean;
    isLoading: boolean;
    data: any;
};

const useFilteredData = ({ key, path }: Args): ReturnTypes => {
    const { mutate: getData, isError, isLoading, data } = useMutation(key, path, {}, ApiMethods.POST);

    return { getData, isError, isLoading, data };
};

export default useFilteredData;
