import { Stack } from '@mui/material';
import CurationAudienceDropdown from 'domains/campaigns/components/CurationAudienceDropdown';
import { AudienceState } from 'domains/campaigns/types';
import { CampaignChannels } from 'domains/campaigns/types';

type Props = {
    audienceState: AudienceState;
    editAudience: (audienceId: number) => void;
};

const CurationAudienceForm = ({ audienceState, editAudience }: Props) => (
    <Stack alignItems="center">
        <CurationAudienceDropdown
            audienceId={audienceState.audienceId}
            channel={CampaignChannels.EMAIL}
            editAudience={editAudience}
        />
    </Stack>
);
export default CurationAudienceForm;
