import React from 'react';
import { Alert as MuiAlert, AlertProps } from '@mui/material';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return (
        <MuiAlert
            ref={ref}
            elevation={6}
            variant="filled"
            sx={{
                borderRadius: '.25rem',
                display: 'flex',
                alignItems: 'center',
            }}
            {...props}
        />
    );
});

export default Alert;
