import { SendRecurrenceFrequency } from 'domains/campaigns/types';
import { DateTime } from 'luxon';

const createDateTime = (date: DateTime, hour: number, minute: number) => {
    const dateObject = { year: date.year, month: date.month, day: date.day };
    return DateTime.fromObject({ hour, minute, ...dateObject }).toLocal();
};

export const getRecommendedTime = (date: DateTime, recurrenceFrequency?: SendRecurrenceFrequency): luxon.DateTime => {
    if (recurrenceFrequency === SendRecurrenceFrequency.EVERY_WEEKDAY) {
        return createDateTime(date, 12, 35);
    }

    if (recurrenceFrequency === SendRecurrenceFrequency.DAILY) {
        return createDateTime(date, 12, 6);
    }

    let recommendedTime;

    switch (date.weekday) {
        case 1: // Monday
            recommendedTime = createDateTime(date, 10, 36);
            break;
        case 2: // Tuesday
            recommendedTime = createDateTime(date, 9, 28);
            break;
        case 3: // Wednesday
            recommendedTime = createDateTime(date, 12, 37);
            break;
        case 4: // Thursday
            recommendedTime = createDateTime(date, 11, 3);
            break;
        case 5: // Friday
            recommendedTime = createDateTime(date, 11, 24);
            break;
        case 6: // Saturday
            recommendedTime = createDateTime(date, 6, 33);
            break;
        case 7: // Sunday
            recommendedTime = createDateTime(date, 16, 8);
            break;
        default:
            recommendedTime = createDateTime(date, 12, 0);
            break;
    }

    return recommendedTime;
};
