import { v4 as uuidv4 } from 'uuid';
import { getCamelToKebabCase } from 'utils';
import { CurationNavState, URLPaths } from 'models/enums';
import { Action } from 'models/types';
import { Campaign, CampaignChannels, CampaignKey, Send } from 'domains/campaigns/types';
import {
    getTimeDifferenceInHours,
    getDelayUnit,
    getDelayValue,
} from 'domains/campaigns/pages/CurationsPage/CampaignDetailsPage/timeFormatting';
import { CampaignGraph, CampaignNode, CampaignNodeType, CampaignState, CampaignTree } from './createCampaignSlice';

/**
 * Calculates the navigation state. Legacy function.
 * @param formComplete whether or not the form is complete
 * @param formInProgress whether or not the form is in progress
 * @param url the url where the state should be in progress active
 * @param action the action, only pass for the ROUTE_CHANGE action, otherwise the function assumes that the user is on corresponding page
 * @param previewRoute determines which route specifies the PREVIEW state for the navigation
 * @returns the curation nav state
 */
export const getCurationNavState = (
    formComplete: boolean,
    formInProgress: boolean,
    url: string,
    action?: Action,
    previewRoute?: string
) => {
    const route = action?.payload?.location?.pathname ?? url;

    if (route === previewRoute) {
        return CurationNavState.PREVIEW;
    }

    if (route === url) {
        return CurationNavState.IN_PROGRESS_CURRENT;
    }

    if (formComplete) {
        return CurationNavState.COMPLETED;
    }

    if (formInProgress) {
        return CurationNavState.IN_PROGRESS;
    }

    return CurationNavState.OPEN;
};

export const getCampaignNavState = ({
    isComplete,
    isInProgress,
    currentRoute,
    inProgressActiveRoute,
    previewRoute,
}: {
    isComplete: boolean;
    isInProgress: boolean;
    currentRoute: string;
    inProgressActiveRoute: string;
    previewRoute: string;
}) => {
    if (currentRoute === previewRoute) {
        return CurationNavState.PREVIEW;
    }

    if (currentRoute === inProgressActiveRoute) {
        return CurationNavState.IN_PROGRESS_CURRENT;
    }

    if (isComplete) {
        return CurationNavState.COMPLETED;
    }

    if (isInProgress) {
        return CurationNavState.IN_PROGRESS;
    }

    return CurationNavState.OPEN;
};

export const getCampaignRoute = ({
    campaignKey,
    nodes,
    nodeId,
}: {
    campaignKey: CampaignKey;
    nodes?: CampaignNode[];
    nodeId?: string;
}) => {
    const campaignKeyKebabCase = getCamelToKebabCase(campaignKey);
    const pathShared = `${URLPaths.CURATIONS_LANDING}/${campaignKeyKebabCase}`;

    // Check if nodes is not provided or is empty
    // Check if nodeId is not provided or is an empty string
    // If true, return pathShared
    if (!nodes || !nodeId || nodeId.trim() === '') {
        return pathShared;
    }

    const node = nodes.find((node) => node.id === nodeId);
    const nodeType = node?.type;

    // If there's multiple nodeIds (i.e. content & delay nodes for addOnSends)
    // Return node's index + 1 so it's friendlier for users (i.e. '.../content/1' instead of '.../content')
    const getNodeIndex = () => {
        const nodesByType = nodes.filter((node) => node.type === nodeType);
        const nodeIds = nodesByType.map((node) => node.id);
        const index = nodeIds.findIndex((id) => id === nodeId);
        return nodeIds.length > 1 && index + 1;
    };

    let path;

    switch (nodeType) {
        case CampaignNodeType.META_DATA_NODE:
            path = `${pathShared}`;
            break;
        case CampaignNodeType.SEGMENT_NODE:
            path = `${pathShared}/audience`;
            break;
        case CampaignNodeType.CONTENT_NODE:
            path = `${pathShared}/content`;
            break;
        case CampaignNodeType.SCHEDULE_NODE:
            path = `${pathShared}/schedule`;
            break;
        case CampaignNodeType.DELAY_NODE:
            path = `${pathShared}/delay`;
            break;
        case CampaignNodeType.PUBLISH_NODE:
            path = `${pathShared}/publish`;
            break;
        default:
            path = `${pathShared}`;
            break;
    }

    return getNodeIndex() ? `${path}/${getNodeIndex()}` : path;
};

export const getCampaignState = ({ campaign }: { campaign: Campaign }): CampaignState => {
    const { sends } = campaign;

    const updatedGraphNodes = [
        ...sends.reduce((nodes, send, index) => {
            if (index === 0) {
                return [
                    ...nodes,
                    createMetaDataNode(campaign.name),
                    createSegmentNode(index),
                    createContentNode(index),
                    createScheduleNode(index),
                ];
            } else if (index < sends.length) {
                const previousSend = sends[index - 1];
                return [...nodes, createDelayNode(previousSend, send), createContentNode(index)];
            }
            return nodes;
        }, []),
        createPublishNode(),
    ];

    const updatedSends = sends.map((send) => ({
        channel: CampaignChannels.EMAIL as CampaignChannels.EMAIL,
        name: send.name,
        preHeader: send.preHeader,
        scheduledTimestamp: send.scheduledTimestamp,
        recurrenceFrequency: send.recurrenceFrequency,
        senderProfileId: send.emailSenderProfileId,
        subjectLine: send.subjectLine,
        templateId: send.emailTemplateId,
        segmentId: send.segmentId,
    }));

    const campaignGraph: CampaignGraph = {
        nodes: updatedGraphNodes,
        edges: updatedGraphNodes.reduce((edges, _, index, arr) => {
            if (index < arr.length - 1) {
                edges.push({
                    id: uuidv4(),
                    source: updatedGraphNodes[index].id,
                    target: updatedGraphNodes[index + 1].id,
                });
            }
            return edges;
        }, []),
    };

    const campaignTree: CampaignTree = {
        sends: [...updatedSends],
    };

    return {
        campaignGraph,
        campaignTree,
    };
};

const createNode = (type: CampaignNodeType, sendIndex?: number, additionalProps?: {}) => ({
    id: uuidv4(),
    type,
    ...(sendIndex !== undefined && { sendIndex }),
    ...additionalProps,
});

const createMetaDataNode = (campaignName: string) =>
    createNode(CampaignNodeType.META_DATA_NODE, undefined, {
        name: campaignName,
        contentPreviewNodeId: null,
    });

const createSegmentNode = (sendIndex: number) => createNode(CampaignNodeType.SEGMENT_NODE, sendIndex);

const createContentNode = (sendIndex: number) => createNode(CampaignNodeType.CONTENT_NODE, sendIndex);

const createScheduleNode = (sendIndex: number) =>
    createNode(CampaignNodeType.SCHEDULE_NODE, sendIndex, {
        isRecommendedTimeEnabled: false,
        dateTimeError: false,
    });

const createDelayNode = (currentSend: Send, nextSend: Send) => {
    const timeDifference = getTimeDifferenceInHours(currentSend.scheduledTimestamp, nextSend.scheduledTimestamp);

    const delay = getDelayValue(timeDifference);
    const unit = getDelayUnit(timeDifference);

    return createNode(CampaignNodeType.DELAY_NODE, undefined, { delay, unit });
};

const createPublishNode = () => createNode(CampaignNodeType.PUBLISH_NODE);
