import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Stack } from '@mui/material';
import Button from 'domains/core/components/Button';
import navSparkleImage from 'assets/images/svg/nav-sparkle.svg';
import theme from 'theme';
export type Props = {
    title: string;
    body: string;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    onClose: () => void;
    buttonOneText: string;
    buttonTwoText: string;
    buttonOneOnClick: () => void;
    buttonTwoOnClick: () => void;
};

/**
 * Success modal.
 * @param title The title of the modal.
 * @param body The body text of the modal.
 * @param isOpen Determines if modal is open.
 * @param setIsOpen Changes the state of isOpen.
 * @param onClose Callback for when modal is closed.
 * @param buttonOneText The text inside button one.
 * @param buttonTwoText The text inside button two.
 * @param buttonOneOnClick The function executed for button one.
 * @param buttonTwoOnClick The function executed for button two.
 * @returns The React node created by this component.
 */
const SuccessDialog = ({
    title,
    body,
    isOpen,
    setIsOpen,
    onClose,
    buttonOneText,
    buttonTwoText,
    buttonOneOnClick,
    buttonTwoOnClick,
}: Props) => (
    <Dialog maxWidth="sm" fullWidth open={isOpen} onClose={onClose} aria-labelledby="curation-success-modal">
        <DialogTitle>
            <Stack alignContent="center" direction="row" spacing={2}>
                <img width={theme.spacing(3)} src={navSparkleImage} alt="success modal title logo" />
                <Typography variant="h6">{title}</Typography>
            </Stack>
        </DialogTitle>
        <DialogContent>
            <Typography variant="body1">{body}</Typography>
        </DialogContent>
        <DialogActions sx={{ marginTop: '1rem' }}>
            <Button
                variant="text"
                onClick={() => {
                    setIsOpen(false);
                    buttonOneOnClick();
                }}
            >
                {buttonOneText}
            </Button>
            <Button
                variant="contained"
                onClick={() => {
                    setIsOpen(false);
                    buttonTwoOnClick();
                }}
            >
                {buttonTwoText}
            </Button>
        </DialogActions>
    </Dialog>
);

export default SuccessDialog;
