import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import startCase from 'lodash/startCase';
import { Menu, MenuItem, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Button from 'domains/core/components/Button';
import { URLPaths } from 'models/enums';
import { Contact, ContactColumn } from 'models/types';
import Table, { ColumnOption, renderDate } from 'domains/core/components/Table';
import { getAgeFromBirthday, getFormattedAddress, getFormattedPhoneNumber } from 'utils';
import { SortDirection, SQLType } from 'models/enums';
import useContactColumns from 'hooks/queries/useContactColumns';
import './ContactsTable.css';

const rowsPerPageArray = [5, 10, 20, 50, 100];

type ContactColumnWithSort = ContactColumn & {
    disableSortBy: boolean;
};

type Props = {
    contacts: any[];
    onSort?: (sortColumn: string, direction: SortDirection) => void;
};

const underscoreRegex = /_+/g;
const underscoreReplace = (value: string): string => value?.replace(underscoreRegex, ' ');

/**
 * A table used for displaying contacts in the contacts page.
 *
 * @param contacts The contacts data used for the table.
 * @param onSort Function used to re-query and sort the contacts table.
 *
 * @returns The React node created by this component.
 */
const ContactsTable = ({ contacts, onSort }: Props) => {
    const history = useHistory<any>();
    const route = history.location.pathname;
    const disableSortBy = route === URLPaths.SEGMENTS_CONTACTS;

    /* Pagination constants and functions */
    const totalContacts = contacts?.length;
    const [contactsPerPage, setContactsPerPage] = useState(10);
    const [contactsDisplayed, setContactsDisplayed] = useState(contacts?.slice(0, contactsPerPage));
    const [page, setPage] = useState(1);

    let numberPages = Math.ceil(totalContacts / contactsPerPage);

    const { data: contactColumns, isLoading } = useContactColumns();

    useEffect(() => {
        setContactsDisplayed(contacts?.slice(0, contactsPerPage));
    }, [setContactsDisplayed, contacts, contactsPerPage]);

    const setContactsDisplayedByPage = (page: number) => {
        setContactsDisplayed(contacts?.slice(contactsPerPage * (page - 1), contactsPerPage * page));
    };

    const setContactsDisplayedByRowsPerPage = (rowsPerPage: number) => {
        setContactsDisplayed(contacts?.slice(rowsPerPage * (page - 1), rowsPerPage * page));
    };

    const previousPage = () => {
        if (page > 1 && page !== 1) {
            let temp = page - 1;
            setPage(temp);
            setContactsDisplayedByPage(temp);
        }
    };

    const nextPage = () => {
        if (page < numberPages && page !== numberPages) {
            let temp = page + 1;
            setPage(temp);
            setContactsDisplayedByPage(temp);
        }
    };

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event: any) => {
        const { myValue } = event.currentTarget.dataset;
        if (myValue) {
            setContactsPerPage(parseInt(myValue));
            setContactsDisplayedByRowsPerPage(parseInt(myValue));
        } else {
            const temp = contactsPerPage;
            setContactsPerPage(temp);
            setContactsDisplayedByRowsPerPage(temp);
        }
        setAnchorEl(null);
    };

    const sortByMostRecentVisit = (rowA: any, rowB: any, columnId: string, desc: boolean) => {
        const minDate = new Date(-8640000000000000);
        const aDate = rowA?.original.most_recent_visit ? new Date(rowA.original.most_recent_visit) : minDate;
        const bDate = rowB?.original.most_recent_visit ? new Date(rowB.original.most_recent_visit) : minDate;

        return aDate.getTime() - bDate.getTime();
    };

    const data: object[] = useMemo(
        () =>
            contactsDisplayed.map((contact) => ({
                ...contact,
                birth_date: contact.birth_date ? new Date(contact.birth_date) : '',
            })),
        [contactsDisplayed]
    );

    const columns: ColumnOption[] = useMemo(() => {
        // We may be able to remove this after https://cured.atlassian.net/browse/COC-381 is completed, and blank columns are not shown.
        const style = {};
        //Filtered columns is contactColumns, but mutated to include cases for age and address
        let filteredColumns: ContactColumnWithSort[] =
            contactColumns?.map((contactColumn) => ({ ...contactColumn, disableSortBy })) ?? [];

        // Add email_subscription_status column which is not part of metadata.
        filteredColumns.push({
            columnName: 'email_subscription_status',
            isCustom: false,
            isMergeTag: false,
            isPHI: false,
            isSegmentable: false,
            personalizationDefault: '',
            sqlType: SQLType.CHARACTER_VARYING,
            disableSortBy: true,
        });

        // Remove is_deactivated column from display
        filteredColumns = filteredColumns.filter((column) => !['is_deactivated'].includes(column.columnName));

        if (contactsDisplayed.length === 0 || isLoading) {
            return [];
        }

        return filteredColumns
            .flatMap((column: ContactColumnWithSort, index: number): any => {
                //Switch case for special cases based on column's type
                switch (column.sqlType) {
                    case SQLType.CHARACTER_VARYING_ARRAY:
                        return {
                            Header: underscoreReplace(column.columnName),
                            accessor: column.columnName,
                            align: 'center' as const,
                            style,
                            order: 99,
                            disableSortBy: column.disableSortBy,
                            Cell: ({ value }: any) => value?.join(','),
                        };
                    case 'character varying':
                        if (column.columnName === 'address') {
                            return {
                                id: index.toString(),
                                Header: 'Address',
                                accessor: (originalRow: Contact, rowIndex: any) => getFormattedAddress(originalRow),
                                align: 'center' as const,
                                style,
                                order: 5,
                                customSort: 'street_address',
                                disableSortBy: column.disableSortBy,
                            };
                        } else if (column.columnName === 'subscription_status') {
                            return {
                                Header: 'Subscription Status',
                                accessor: 'subscription_status',
                                align: 'center' as const,
                                Cell: ({ value: status }: any) => (
                                    <Typography
                                        color={status === 'active' ? 'success.content' : 'warning.contrastText'}
                                    >
                                        {startCase(status)}
                                    </Typography>
                                ),
                                style,
                                order: 99,
                                customSort: 'cs.status',
                                disableSortBy: column.disableSortBy,
                            };
                        } else if (column.columnName === 'phone_number') {
                            return {
                                Header: underscoreReplace(column.columnName),
                                accessor: column.columnName,
                                align: 'left' as const,
                                //Formats US phone numbers in the cell
                                Cell: ({ value: phone_number }: { value: string }) =>
                                    getFormattedPhoneNumber(phone_number),
                                style,
                                order: 4,
                                disableSortBy: column.disableSortBy,
                            };
                        } else if (column.columnName === 'first_name') {
                            return {
                                Header: underscoreReplace(column.columnName),
                                accessor: column.columnName,
                                align: 'left' as const,
                                style,
                                order: 1,
                                disableSortBy: column.disableSortBy,
                            };
                        } else if (column.columnName === 'last_name') {
                            return {
                                Header: underscoreReplace(column.columnName),
                                accessor: column.columnName,
                                align: 'left' as const,
                                style,
                                order: 2,
                                disableSortBy: column.disableSortBy,
                            };
                        } else if (column.columnName === 'email') {
                            return {
                                Header: underscoreReplace(column.columnName),
                                accessor: column.columnName,
                                align: 'left' as const,
                                style,
                                order: 3,
                                disableSortBy: column.disableSortBy,
                            };
                        } else if (column.columnName === 'street_address') {
                            return {
                                Header: underscoreReplace(column.columnName),
                                accessor: column.columnName,
                                align: 'left' as const,
                                style,
                                order: 5,
                                disableSortBy: column.disableSortBy,
                            };
                        } else if (column.columnName === 'street_address_2') {
                            return {
                                Header: underscoreReplace(column.columnName),
                                accessor: column.columnName,
                                align: 'left' as const,
                                style,
                                order: 6,
                                disableSortBy: column.disableSortBy,
                            };
                        } else if (column.columnName === 'state_code') {
                            return {
                                Header: underscoreReplace(column.columnName),
                                accessor: column.columnName,
                                align: 'left' as const,
                                style,
                                order: 7,
                                disableSortBy: column.disableSortBy,
                            };
                        } else if (column.columnName === 'zip_code') {
                            return {
                                Header: underscoreReplace(column.columnName),
                                accessor: column.columnName,
                                align: 'left' as const,
                                style,
                                order: 8,
                                disableSortBy: column.disableSortBy,
                            };
                        } else if (column.columnName === 'administrative_gender') {
                            return {
                                Header: underscoreReplace(column.columnName),
                                accessor: column.columnName,
                                align: 'left' as const,
                                style,
                                order: 11,
                                disableSortBy: column.disableSortBy,
                            };
                        } else if (column.columnName === 'race') {
                            return {
                                Header: underscoreReplace(column.columnName),
                                accessor: column.columnName,
                                align: 'left' as const,
                                style,
                                order: 12,
                                disableSortBy: column.disableSortBy,
                            };
                        } else if (column.columnName === 'preferred_contact_method') {
                            return {
                                Header: underscoreReplace(column.columnName),
                                accessor: column.columnName,
                                align: 'left' as const,
                                style,
                                order: 13,
                                disableSortBy,
                            };
                        } else {
                            return {
                                Header: underscoreReplace(column.columnName),
                                accessor: column.columnName,
                                align: 'left' as const,
                                style,
                                order: 99,
                                disableSortBy: column.disableSortBy,
                            };
                        }
                    case 'double precision':
                    case 'integer':
                        return {
                            Header: underscoreReplace(column.columnName),
                            accessor: column.columnName,
                            align: 'right' as const,
                            style,
                            order: 99,
                            disableSortBy: column.disableSortBy,
                        };
                    case 'date':
                    case 'timestamp with time zone':
                        if (column.columnName === 'most_recent_visit') {
                            return {
                                Header: underscoreReplace(column.columnName),
                                accessor: column.columnName,
                                align: 'center' as const,
                                sortType: sortByMostRecentVisit,
                                Cell: ({ value }: { value: string }) => renderDate({ value: new Date(value) }),
                                style,
                                order: 99,
                                disableSortBy: column.disableSortBy,
                            };
                        } else if (column.columnName === 'birth_date') {
                            const birthDateColumns: any[] = [
                                {
                                    id: 'birth_date',
                                    Header: underscoreReplace(column.columnName),
                                    accessor: column.columnName,
                                    sortType: 'datetime' as const,
                                    align: 'left' as const,
                                    Cell: renderDate,
                                    style,
                                    order: 9,
                                    customSort: 'birth_date',
                                    disableSortBy: column.disableSortBy,
                                },
                            ];

                            const ageColumnExists = filteredColumns.some((c) => c.columnName === 'age');
                            if (!ageColumnExists) {
                                birthDateColumns.push({
                                    id: 'age',
                                    Header: 'Age',
                                    accessor: 'birth_date',
                                    align: 'center' as const,
                                    sortType: 'datetime' as const,
                                    sortInverted: 'true',
                                    Cell: ({ value }: any) => getAgeFromBirthday(value),
                                    style,
                                    order: 10,
                                    customSort: 'birth_date',
                                    disableSortBy: true,
                                });
                            }

                            return birthDateColumns;
                        } else {
                            return {
                                Header: underscoreReplace(column.columnName),
                                accessor: column.columnName,
                                align: 'center' as const,
                                style,
                                order: 99,
                                disableSortBy: column.disableSortBy,
                            };
                        }
                    case 'boolean':
                        return {
                            Header: underscoreReplace(column.columnName.replace(/^is_/g, '')),
                            accessor: column.columnName,
                            Cell: ({ value }: any) => (typeof value == 'boolean' ? value?.toString() : ''),
                            align: 'center' as const,
                            style,
                            order: 99,
                            disableSortBy: column.disableSortBy,
                        };
                    default:
                        return {
                            Header: underscoreReplace(column.columnName),
                            accessor: column.columnName,
                            align: 'center' as const,
                            style,
                            order: 99,
                            disableSortBy: column.disableSortBy,
                        };
                }
            })
            .sort((a, b) => {
                if (a.order < b.order) {
                    return -1;
                }
                if (a.order > b.order) {
                    return 1;
                }
                return 0;
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    if (contactsDisplayed.length === 0 || isLoading) {
        return null;
    }

    return (
        <>
            <Table
                data={data}
                columns={columns}
                defaultSortingRules={[
                    { id: 'subscription_status', desc: false },
                    { id: 'last_name', desc: false },
                ]}
                manualSortBy={true}
                onSort={(columnId, direction) => {
                    setPage(1);
                    onSort(columnId, direction);
                }}
            />
            <div id="contacts-table-pagination-container">
                <div id="contacts-table-rows-per-page">
                    <div>rows per page:</div>
                    <div id="contacts-table-rows-per-page-dropdown">
                        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                            {contactsPerPage}
                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            sx={{
                                marginLeft: '.5rem',
                            }}
                        >
                            {rowsPerPageArray.map((rowsPerPage) => (
                                <MenuItem
                                    onClick={handleClose}
                                    key={rowsPerPage}
                                    data-my-value={rowsPerPage}
                                    value={rowsPerPage}
                                    sx={{
                                        paddingLeft: '.5rem',
                                        paddingRight: '.5rem',
                                        textAlign: 'center',
                                        width: '3rem',
                                    }}
                                >
                                    <span className="contacts-table-rows-per-page-dropdown-text">{rowsPerPage}</span>
                                </MenuItem>
                            ))}
                        </Menu>
                    </div>
                </div>
                <div id="contacts-table-page-number">
                    page {page} of {numberPages}
                </div>
                <div id="contacts-table-page-navigation">
                    <Button
                        id="contacts-table-page-navigation-button"
                        sx={{ minWidth: '40px', maxWidth: '40px' }}
                        onClick={() => {
                            previousPage();
                        }}
                    >
                        <ChevronLeftIcon fontSize="small" />
                    </Button>
                    <Button
                        id="contacts-table-page-navigation-button"
                        sx={{ minWidth: '40px', maxWidth: '40px' }}
                        onClick={() => {
                            nextPage();
                        }}
                    >
                        <ChevronRightIcon fontSize="small" />
                    </Button>
                </div>
            </div>
        </>
    );
};

export default ContactsTable;
