import { Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { AudienceStatus } from 'models/enums';

const SharedSx = {
    borderRadius: '0.25rem',
    padding: '0.125rem 0.75rem 0.25rem 0.75rem',
    whiteSpace: 'nowrap',
};

export const AudienceStatusToSx: Record<string, SxProps<Theme>> = {
    [AudienceStatus.PENDING]: {
        ...SharedSx,
        bgcolor: 'white',
        color: 'primary.main',
    },
    [AudienceStatus.ACTIVE]: {
        ...SharedSx,
        bgcolor: 'warning.main',
        color: 'primary.main',
    },
    [AudienceStatus.COMPLETE]: {
        ...SharedSx,
        bgcolor: 'action.active',
        color: 'primary.contrastText',
    },
    [AudienceStatus.FAILED]: {
        ...SharedSx,
        bgcolor: 'error.main',
        color: 'primary.contrastText',
    },
    [AudienceStatus.CANCELED]: {
        ...SharedSx,
        bgcolor: 'primary.main',
        color: 'primary.contrastText',
    },
    [AudienceStatus.PAUSED]: {
        ...SharedSx,
        bgcolor: 'white',
        color: 'primary.main',
    },
};

export type Props = {
    audienceStatus: AudienceStatus;
};

const AudienceStatusText = ({ audienceStatus }: Props) => {
    if (!audienceStatus) {
        return null;
    }

    return (
        <Typography
            className="audience-status"
            component={'span'}
            data-testid="audience-status"
            sx={{ ...AudienceStatusToSx[audienceStatus], textTransform: 'lowercase' }}
        >
            {audienceStatus}
        </Typography>
    );
};

export default AudienceStatusText;
