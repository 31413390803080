import { DateTime } from 'luxon';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import LegacyCurationAudiencePage from 'domains/campaigns/pages/LegacyCurationAudiencePage';
import LegacyCurationContentPage from 'domains/campaigns/pages/LegacyCurationContentPage';
import LegacyCurationSegmentsPage from 'domains/campaigns/pages/LegacyCurationSegmentsPage';
import LegacyCurationSegmentsActionPage from 'domains/campaigns/pages/LegacyCurationSegmentsActionPage';
import { editDelay as editDelayActionCreator } from 'domains/campaigns/state/actionCreators';
import { EditDelayPayload, EditSchedulePayload } from 'domains/campaigns/types';
import {
    editSchedule as editScheduleActionCreator,
    setScheduleDatetimeError as setScheduleDatetimeErrorActionCreator,
} from 'domains/campaigns/state/actionCreators';
import {
    CONDITIONAL_SPLIT_EDIT_AUDIENCE,
    CONDITIONAL_SPLIT_EDIT_CONTENT1,
    CONDITIONAL_SPLIT_EDIT_DELAY,
    CONDITIONAL_SPLIT_EDIT_METADATA,
    CONDITIONAL_SPLIT_EDIT_SCHEDULE,
    CONDITIONAL_SPLIT_EDIT_SEGMENTS,
    CONDITIONAL_SPLIT_EDIT_SEGMENTS_MEETS_ACTION,
    CONDITIONAL_SPLIT_EDIT_SEGMENTS_MEETS_CONTENT,
    CONDITIONAL_SPLIT_EDIT_SEGMENTS_MEETS_NOT_ACTION,
    CONDITIONAL_SPLIT_EDIT_SEGMENTS_MEETS_NOT_CONTENT,
    CONDITIONAL_SPLIT_SET_SCHEDULE_DATETIME_ERROR,
} from 'domains/campaigns/state/actionTypes';
import { selectConditionalSplitSecondEmailTimestamp } from 'domains/campaigns/state/selectors';
import { URLPaths } from 'models/enums';
import ConditionalSplitPublishPage from './ConditionalSplitPublishPage';
import LegacyCurationNamePage from '../../LegacyCurationNamePage';
import CurationSchedulePage from '../../CurationSchedulePage';
import CurationDelayPage from '../../CurationDelayPage';

/**
 * In lieu of entirely updating the conditional split Redux implementation and store shape
 * to match single send and time delay, we're defining some actions and props in this file
 * and passing them to non-legacy components as legacy props.
 *
 * Ultimately, we will need to update CS to use redux toolkit with the same basic store shape to remove these legacy props.
 */

const getErrorMessage = (firstEmailTimestamp: string, secondEmailTimestamp: string): string => {
    if (!firstEmailTimestamp || !secondEmailTimestamp) return '';

    const oneYearDayFromNow = DateTime.now().plus({ year: 1 });
    const secondDate = DateTime.fromISO(secondEmailTimestamp);
    const dateIsValid = secondDate <= oneYearDayFromNow;

    if (dateIsValid) return '';

    return 'Time delays cannot be more than one year into the future.';
};

const ConditionalSplitNav = () => {
    const dispatch = useDispatch();

    // Schedule page
    const editSchedule = (payload: EditSchedulePayload) =>
        dispatch(editScheduleActionCreator(CONDITIONAL_SPLIT_EDIT_SCHEDULE, payload));
    const setScheduleDatetimeError = (isError: boolean) =>
        dispatch(setScheduleDatetimeErrorActionCreator(CONDITIONAL_SPLIT_SET_SCHEDULE_DATETIME_ERROR, isError));

    // Delay page
    const firstEmailTimestamp = useSelector(
        (state: any) => state.curation.conditionalSplit.schedule.scheduledTimestamp
    );
    const delayedSendTimestamp = useSelector(selectConditionalSplitSecondEmailTimestamp);
    const errorMessage = getErrorMessage(firstEmailTimestamp, delayedSendTimestamp);
    const editDelay = (payload: EditDelayPayload) =>
        dispatch(editDelayActionCreator(CONDITIONAL_SPLIT_EDIT_DELAY, payload));

    return (
        <Switch>
            <Route
                path={URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENT}
                render={(props) => (
                    <LegacyCurationAudiencePage
                        editAudienceActionType={CONDITIONAL_SPLIT_EDIT_AUDIENCE}
                        audienceSelector={(state: any) => state.curation.conditionalSplit.audience}
                        {...props}
                    />
                )}
                exact
            />
            <Route
                path={URLPaths.CURATIONS_CONDITIONAL_SPLIT_1_CONTENT}
                render={(props) => (
                    <LegacyCurationContentPage
                        editContentActionType={CONDITIONAL_SPLIT_EDIT_CONTENT1}
                        editMetadataActionType={CONDITIONAL_SPLIT_EDIT_METADATA}
                        contentIndex={0}
                        contentSelector={(state: any) => state.curation.conditionalSplit.content1}
                        metadataSelector={(state: any) => state.curation.conditionalSplit.metadata}
                        formLayoutProps={{
                            guidingText:
                                'Select the first email you want your audience to receive. Keep the goal of your Curation in mind and choose content that best supports your objective. ',
                        }}
                        {...props}
                    />
                )}
                exact
            />
            <Route
                path={URLPaths.CURATIONS_CONDITIONAL_SPLIT_SCHEDULE}
                render={() => (
                    <CurationSchedulePage
                        allowRecurrence={true}
                        legacySetDatetimeError={setScheduleDatetimeError}
                        legacyEditSchedule={editSchedule}
                        selectScheduleFields={(state: any) => state.curation.conditionalSplit.schedule}
                    />
                )}
                exact
            />
            <Route
                path={URLPaths.CURATIONS_CONDITIONAL_SPLIT_DELAY}
                render={() => (
                    <CurationDelayPage
                        legacyErrorMessage={errorMessage}
                        legacyNextEmailTimestamp={delayedSendTimestamp}
                        legacyEditDelayFields={editDelay}
                        selectDelayFields={(state: any) => state.curation.conditionalSplit.delay}
                        selectScheduleFields={(state: any) => state.curation.conditionalSplit.schedule}
                    />
                )}
                exact
            />
            <Route
                path={URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS}
                render={(props) => (
                    <LegacyCurationSegmentsPage
                        editSegmentsActionType={CONDITIONAL_SPLIT_EDIT_SEGMENTS}
                        segmentsSelector={(state: any) => state.curation.conditionalSplit.segments}
                        {...props}
                    />
                )}
                exact
            />
            <Route
                path={URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS_MEETS_ACTION}
                render={(props) => (
                    <LegacyCurationSegmentsActionPage
                        editSegmentsActionActionType={CONDITIONAL_SPLIT_EDIT_SEGMENTS_MEETS_ACTION}
                        editContentActionType={CONDITIONAL_SPLIT_EDIT_SEGMENTS_MEETS_CONTENT}
                        segmentsActionSelector={(state: any) => state.curation.conditionalSplit.segmentsMeetsAction}
                        formLayoutProps={{
                            guidingText:
                                'Choose the next step for your audience who meets the split criteria. Keeping your Curation goals in mind, select the action that supports your objective.',
                        }}
                        {...props}
                    />
                )}
                exact
            />
            <Route
                path={URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS_MEETS_CONTENT}
                render={(props) => (
                    <LegacyCurationContentPage
                        editContentActionType={CONDITIONAL_SPLIT_EDIT_SEGMENTS_MEETS_CONTENT}
                        editMetadataActionType={CONDITIONAL_SPLIT_EDIT_METADATA}
                        contentIndex={1}
                        contentSelector={(state: any) => state.curation.conditionalSplit.segmentsMeetsContent}
                        metadataSelector={(state: any) => state.curation.conditionalSplit.metadata}
                        {...props}
                    />
                )}
                exact
            />
            <Route
                path={URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS_MEETS_NOT_ACTION}
                render={(props) => (
                    <LegacyCurationSegmentsActionPage
                        editSegmentsActionActionType={CONDITIONAL_SPLIT_EDIT_SEGMENTS_MEETS_NOT_ACTION}
                        editContentActionType={CONDITIONAL_SPLIT_EDIT_SEGMENTS_MEETS_NOT_CONTENT}
                        segmentsActionSelector={(state: any) => state.curation.conditionalSplit.segmentsMeetsNotAction}
                        formLayoutProps={{
                            guidingText:
                                'Choose the next step for your audience who does not meet the split criteria. Keeping your Curation goals in mind, select the action that supports your objective.',
                        }}
                        {...props}
                    />
                )}
                exact
            />
            <Route
                path={URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS_MEETS_NOT_CONTENT}
                render={(props) => (
                    <LegacyCurationContentPage
                        editContentActionType={CONDITIONAL_SPLIT_EDIT_SEGMENTS_MEETS_NOT_CONTENT}
                        editMetadataActionType={CONDITIONAL_SPLIT_EDIT_METADATA}
                        contentIndex={2}
                        contentSelector={(state: any) => state.curation.conditionalSplit.segmentsMeetsNotContent}
                        metadataSelector={(state: any) => state.curation.conditionalSplit.metadata}
                        {...props}
                    />
                )}
                exact
            />
            <Route
                path={URLPaths.CURATIONS_CONDITIONAL_SPLIT_PUBLISH}
                render={() => (
                    <ConditionalSplitPublishPage
                        delayedSendTimestamp={delayedSendTimestamp}
                        selectDelayFields={(state: any) => state.curation.conditionalSplit.delay}
                        selectScheduleFields={(state: any) => state.curation.conditionalSplit.schedule}
                    />
                )}
                exact
            />
            {/* Will render if none of the other routes match */}
            <Route
                render={(props) => (
                    <LegacyCurationNamePage
                        editMetadataActionType={CONDITIONAL_SPLIT_EDIT_METADATA}
                        metadataSelector={(state: any) => state.curation.conditionalSplit.metadata}
                        {...props}
                    />
                )}
            />
        </Switch>
    );
};

export default ConditionalSplitNav;
