import { groupBy } from 'lodash';
import { Send } from 'domains/campaigns/types';
import {
    BooleanOperator,
    EngagementAPIOperator,
    EngagementName,
    EngagementUIOperator,
    EventSelector,
    InclusionAPIOperator,
    InclusionUIOperator,
    isDateRangeOperator,
    isRelativeDateRangeType,
    RecordTypeFilter,
    Segment,
    SegmentDefinitionInput,
    SegmentDemographic,
    SegmentDemographicInput,
    SegmentEngagement,
    SegmentEvent,
    SegmentEventInput,
    SegmentFilter,
    SegmentFilterCategorySpecificField,
    SegmentFilterDemographicFields,
    SegmentFilterEngagementFields,
    SegmentFilterEventFields,
    SegmentFilterState,
    SegmentFilterValue,
} from 'domains/segments/types';
import { FilterCategory } from 'models/enums';

export const engagementUiToApiOperator: Record<EngagementUIOperator, EngagementAPIOperator> = {
    [EngagementUIOperator.DID_CLICK]: EngagementAPIOperator.CONTAINS,
    [EngagementUIOperator.DID_NOT_CLICK]: EngagementAPIOperator.DOES_NOT_CONTAIN,
    [EngagementUIOperator.DID_RECEIVE]: EngagementAPIOperator.CONTAINS,
    [EngagementUIOperator.DID_NOT_RECEIVE]: EngagementAPIOperator.DOES_NOT_CONTAIN,
    [EngagementUIOperator.DID_OPEN]: EngagementAPIOperator.CONTAINS,
    [EngagementUIOperator.DID_NOT_OPEN]: EngagementAPIOperator.DOES_NOT_CONTAIN,
};

export const engagementApiToUiOperator: Record<EngagementName, Record<EngagementAPIOperator, EngagementUIOperator>> = {
    [EngagementName.CLICKED_SENDS]: {
        [EngagementAPIOperator.CONTAINS]: EngagementUIOperator.DID_CLICK,
        [EngagementAPIOperator.DOES_NOT_CONTAIN]: EngagementUIOperator.DID_NOT_CLICK,
    },
    [EngagementName.RECEIVED_SENDS]: {
        [EngagementAPIOperator.CONTAINS]: EngagementUIOperator.DID_RECEIVE,
        [EngagementAPIOperator.DOES_NOT_CONTAIN]: EngagementUIOperator.DID_NOT_RECEIVE,
    },
    [EngagementName.OPENED_SENDS]: {
        [EngagementAPIOperator.CONTAINS]: EngagementUIOperator.DID_OPEN,
        [EngagementAPIOperator.DOES_NOT_CONTAIN]: EngagementUIOperator.DID_NOT_OPEN,
    },
};

export const eventApiToUiOperator: Record<string, EventSelector> = {
    exists: EventSelector.INCLUDES,
    'not exists': EventSelector.DOES_NOT_INCLUDE,
};

export const isRelativeDateRangeToday = (value: SegmentFilterValue) =>
    isRelativeDateRangeType(value) && value.duration.value === 0 && value.offset.value === 0;

export const isRelativeDateRangeNext = (value: SegmentFilterValue) =>
    isRelativeDateRangeType(value) &&
    !isRelativeDateRangeToday(value) &&
    value.duration.value !== null &&
    value.duration.value >= 0 &&
    value.offset.value >= 0;

export const isRelativeDateRangeLast = (value: SegmentFilterValue) =>
    isRelativeDateRangeType(value) &&
    !isRelativeDateRangeToday(value) &&
    value.duration.value !== null &&
    value.duration.value >= 0 &&
    value.duration.value + value.offset.value === 0;

export const hasInvalidRelativeDates = (segment: Segment) =>
    !!segment.segmentDefinition?.demographic?.find(
        ({ operator, value }) =>
            isDateRangeOperator(operator) &&
            isRelativeDateRangeType(value) &&
            !isRelativeDateRangeNext(value) &&
            !isRelativeDateRangeLast(value) &&
            !isRelativeDateRangeToday(value)
    );

export const getDemographicFilters = ({
    hasAllContacts,
    recordTypes,
    segmentRecordTypes,
}: {
    hasAllContacts: boolean;
    recordTypes: RecordTypeFilter['recordType'][];
    segmentRecordTypes: RecordTypeFilter[];
}) => {
    const getFilters = (recordTypeFilters: RecordTypeFilter[]) =>
        recordTypeFilters.flatMap((recordTypeElement) => recordTypeElement.filters);

    const getRecordTypeFiltersBySelectedRecordTypes = (recordTypeFilters: RecordTypeFilter[]) =>
        recordTypeFilters.filter((segmentRecordType) => recordTypes.includes(segmentRecordType.recordType));

    const filters =
        (hasAllContacts
            ? getFilters(segmentRecordTypes)
            : getFilters(getRecordTypeFiltersBySelectedRecordTypes(segmentRecordTypes))) ?? [];

    const uniqueFiltersByName = filters.reduce<Record<string, SegmentFilter>>((acc, filter) => {
        acc[filter.name] = filter;
        return acc;
    }, {});

    return Object.values(uniqueFiltersByName);
};

export const getInclusionOperatorFromAPIToUI = (operator: SegmentDemographic['operator']) => {
    if (operator === InclusionAPIOperator.IN) return InclusionUIOperator.IS_IN;
    if (operator === InclusionAPIOperator.NOT_IN) return InclusionUIOperator.IS_NOT_IN;
    return operator;
};

export const getInclusionOperatorFromUIToAPI = (
    operator: SegmentFilterState<SegmentFilterDemographicFields>['operator']
) => {
    if (operator === InclusionUIOperator.IS_IN) return InclusionAPIOperator.IN;
    if (operator === InclusionUIOperator.IS_NOT_IN) return InclusionAPIOperator.NOT_IN;
    return operator;
};

export const getSegmentDefinition = ({
    filters,
    hasAllContacts,
    matchType,
    recordTypes,
}: {
    filters: SegmentFilterState<SegmentFilterCategorySpecificField>[];
    hasAllContacts: boolean;
    matchType: 'AND' | 'OR';
    recordTypes: RecordTypeFilter['recordType'][];
}): SegmentDefinitionInput => {
    const categoryMap = {
        [FilterCategory.CONTACT_ACTIVITY]: 'engagement',
        [FilterCategory.CONTACT_DETAILS]: 'demographic',
        [FilterCategory.CONTACT_EVENTS]: 'contactEvent',
    };

    const { contactEvent = [], demographic = [], engagement = [] } = groupBy(
        filters,
        (filter) => categoryMap[filter.category] || ''
    );

    return {
        matchType,
        ...(!hasAllContacts && { contactRecordType: recordTypes }),
        contactEvent: (contactEvent as SegmentFilterState<SegmentFilterEventFields>[]).map(convertFilterToEvent),
        demographic: (demographic as SegmentFilterState<SegmentFilterDemographicFields>[]).map(
            convertFilterToDemographic
        ),
        engagement: (engagement as SegmentFilterState<SegmentFilterEngagementFields>[]).map(convertFilterToEngagement),
    };
};

export const convertFilterToDemographic = (
    filter: SegmentFilterState<SegmentFilterDemographicFields>
): SegmentDemographicInput => ({
    includeNullValues: filter.includeNullValues,
    name: filter.name,
    operator: getInclusionOperatorFromUIToAPI(filter.operator) as SegmentDemographic['operator'],
    value: filter.categorySpecificFields.value,
});

export const convertFilterToEngagement = (
    filter: SegmentFilterState<SegmentFilterEngagementFields>
): SegmentEngagement => ({
    name: filter.name as EngagementName,
    operator: engagementUiToApiOperator[filter.operator as EngagementUIOperator] as EngagementAPIOperator,
    value: filter.categorySpecificFields.value as number,
    segmentLinkValue: filter.categorySpecificFields.segmentLinkInput.value as string,
});

export const convertFilterToEvent = (filter: SegmentFilterState<SegmentFilterEventFields>): SegmentEventInput => {
    const getConditionsWithoutAutocomplete = (conditions: SegmentFilterEventFields['conditions']) =>
        conditions?.map(({ autocompleteValue, autocompleteInputValue, ...condition }) => condition) ?? [];

    const getConditionsWithOperatorsFromUIToAPI = (conditions: any): SegmentDemographicInput[] =>
        conditions?.map((condition: any) => ({
            ...condition,
            operator: getInclusionOperatorFromUIToAPI(condition.operator) as SegmentDemographic['operator'],
        })) ?? [];

    const getValue = (value: SegmentFilterEventFields['value']): SegmentDemographicInput[] =>
        value
            ? [
                  {
                      name: 'start',
                      operator: filter.categorySpecificFields?.dateRangeDateTimeOperator,
                      value,
                  },
              ]
            : [];

    const getSelectorFromUIToAPI = (operator: SegmentFilterState<SegmentFilterEventFields>['operator']) => {
        if (operator === EventSelector.INCLUDES || operator === EventSelector.DOES_NOT_INCLUDE) return 'any';
    };

    const getOperatorFromUIToAPI = (operator: SegmentFilterState<SegmentFilterEventFields>['operator']) => {
        if (operator === EventSelector.INCLUDES) return 'exists';
        if (operator === EventSelector.DOES_NOT_INCLUDE) return 'not exists';
    };

    const otherConditions =
        getConditionsWithOperatorsFromUIToAPI(
            getConditionsWithoutAutocomplete(filter.categorySpecificFields?.conditions)
        ) ?? [];

    return {
        conditions: [
            {
                name: 'record_type',
                operator: BooleanOperator.IS_EQUAL_TO,
                value: filter.name,
            },
            ...otherConditions,
            ...getValue(filter.categorySpecificFields?.value),
        ],
        selector: getSelectorFromUIToAPI(filter.operator),
        operator: getOperatorFromUIToAPI(filter.operator),
    };
};

export const getEventFilter = (event: SegmentEvent, id: number): SegmentFilterState<SegmentFilterEventFields> => {
    const operator = event.operator ? eventApiToUiOperator[event.operator] : EventSelector.INCLUDES;
    const recordType = event.conditions.find((condition) => condition.name === 'record_type');

    const conditionsFiltered = event.conditions.filter((condition) => condition.name !== 'record_type');

    const conditions = conditionsFiltered.map((condition) => ({
        ...condition,
        operator: getInclusionOperatorFromAPIToUI(condition.operator),
        autocompleteValue: Array.isArray(condition.value)
            ? condition.value.map((value) => ({
                  label: `${value}`,
                  value,
              }))
            : { label: `${condition.value}`, value: `${condition.value}` },
        ...(!isDateRangeOperator(operator) &&
            !Array.isArray(condition.value) && {
                autocompleteInputValue: `${condition.value}`,
            }),
    }));

    return {
        category: FilterCategory.CONTACT_EVENTS,
        categorySpecificFields: {
            conditions,
            value: '',
        },
        id,
        name: `${recordType?.value}`,
        operator,
    };
};

export const getDemographicFilter = (
    demographic: SegmentDemographic,
    id: number
): SegmentFilterState<SegmentFilterDemographicFields> => ({
    category: FilterCategory.CONTACT_DETAILS,
    categorySpecificFields: {
        value: demographic.value,
    },
    id,
    includeNullValues: demographic.includeNullValues,
    name: demographic.name,
    operator: getInclusionOperatorFromAPIToUI(demographic.operator),
    autocompleteValue: Array.isArray(demographic.value)
        ? demographic.value.map((value) => ({
              label: `${value}`,
              value,
          }))
        : { label: `${demographic.value}`, value: `${demographic.value}` },
    ...(!isDateRangeOperator(demographic.operator) &&
        !Array.isArray(demographic.value) && {
            autocompleteInputValue: `${demographic.value}`,
        }),
});

export const getEngagementFilter = (
    engagement: SegmentEngagement,
    id: number,
    sends: Send[]
): SegmentFilterState<SegmentFilterEngagementFields> => {
    const selectedSend: Send = sends?.find((send) => send.id === engagement.value);

    return {
        ...engagement,
        category: FilterCategory.CONTACT_ACTIVITY,
        categorySpecificFields: {
            value: engagement.value,
            segmentLinkInput: {
                value: engagement.segmentLinkValue,
                autocompleteInputValue: engagement?.segmentLinkValue,
                autocompleteValue: {
                    label: engagement?.segmentLinkValue,
                    value: engagement?.segmentLinkValue,
                },
            },
        },
        id,
        name: engagement.name,
        operator: engagementApiToUiOperator[engagement.name][engagement.operator],
        autocompleteInputValue: selectedSend?.name,
        autocompleteValue: {
            label: selectedSend?.name,
            value: selectedSend?.id,
        },
    };
};
