import { Percent } from '@mui/icons-material';
import { Stack } from '@mui/material';
import uniq from 'lodash/uniq';
import Select from 'domains/core/components/Select';
import TextField from 'domains/core/components/TextField';
import InputLabel from 'domains/core/components/InputLabel';
import useSendById from 'hooks/queries/useSendById';

export const getAllUniqueLinkValues = (html?: string) => {
    if (!html) {
        return [];
    }
    const linkMatches = Array.from(html.matchAll(/<a\s+(?:[^>]*?\s+)?href="([^"]*)"/g));
    const uniqueLinks = uniq(linkMatches.map((linkMatch) => linkMatch[1]));
    return uniqueLinks.map((link) => ({
        label: link,
        value: link,
    }));
};

type Props = {
    uniqueClickRate: number;
    clickUrl: string;
    sendId: number;
    resetUniqueClickRate: () => void;
    setClickUrl: (clickUrl: string) => void;
    setPreviewClickUrl: (clickUrl: string) => void;
    setUniqueClickRate: (uniqueClickRate: number) => void;
};

const ValueCalculatorConversionEventForm = ({
    uniqueClickRate,
    clickUrl,
    sendId,
    resetUniqueClickRate,
    setUniqueClickRate,
    setClickUrl,
    setPreviewClickUrl,
}: Props) => {
    const { data: send } = useSendById(sendId, {
        enabled: Boolean(sendId),
    });
    const linkValues = getAllUniqueLinkValues(send?.contentSnapshot).filter(
        (linkValue) => !linkValue.label.includes('unsubscribe')
    );

    return (
        <Stack rowGap={4}>
            <Select
                menuItemStyles={{ textTransform: 'none' }}
                values={linkValues}
                label={
                    <InputLabel
                        label="Select link"
                        labelIconTooltipText="This link from the send is used to track actual unique click rate."
                    />
                }
                value={clickUrl ?? ''}
                onChange={(e: any) => {
                    setClickUrl(e.target.value);
                    setPreviewClickUrl(null);
                }}
                onHover={(value) => setPreviewClickUrl(value)}
            />
            <TextField
                label={
                    <InputLabel
                        label="Forecasted unique click rate"
                        labelIconTooltipText="This unique click rate provides a forecasted estimate. Once your send delivers, the actual unique click data is used."
                    />
                }
                width="100%"
                type="number"
                value={uniqueClickRate === 0 ? '' : uniqueClickRate}
                onChange={(e) => setUniqueClickRate(Number(e.target.value))}
                onBlur={() => !uniqueClickRate && resetUniqueClickRate()}
                trailingIcon={<Percent />}
            />
        </Stack>
    );
};

export default ValueCalculatorConversionEventForm;
