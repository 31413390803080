import { UseMutationOptions } from 'react-query';
import useMutation from 'hooks/useMutation';
import { CuredApiPaths } from 'models/enums';
import MutationKeys from './keys';

type SendTestSMSResponse = {
    validNumbers: string[];
    invalidNumbers: string[];
    isSuccess: boolean;
    errorMessage?: string;
};

type SendTestSMSInput = {
    to: string[];
    message: string;
};

/**
 * Sends a test SMS.
 * @param mutateOptions react-query useMutation option
 * @returns the react-query useMutation results
 * See https://react-query-v3.tanstack.com/reference/useMutation for options and return value
 */
const useSendTestSMS = (mutateOptions?: UseMutationOptions<SendTestSMSResponse, Error, SendTestSMSInput>) =>
    useMutation<SendTestSMSInput, SendTestSMSResponse>(
        MutationKeys.SEND_TEST_SMS,
        CuredApiPaths.TEST_SMS,
        mutateOptions
    );

export default useSendTestSMS;
