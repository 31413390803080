export type DownloadFileParams = {
    file: string;
    fileType: 'html' | 'csv';
    fileName: string;
};

const downloadFile = ({ file, fileType, fileName }: DownloadFileParams) => {
    const blob = new Blob([file], { type: `text/${fileType};charset=utf-8;` });
    const link = document.createElement('a');

    link.href = URL.createObjectURL(blob);
    link.download = `${fileName}.${fileType}`;
    link.style.visibility = 'hidden';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export default downloadFile;
