import { Typography } from '@mui/material';

const NoDataMessage = ({ height }: { height?: string }) => (
    <Typography
        display="flex"
        alignItems="center"
        justifyContent="center"
        // 40px is the height of the card header
        // scooting them up another 20px so they look centered
        height={height ?? 'calc(100% - 60px)'}
        variant="body2"
    >
        No data available.
    </Typography>
);

export default NoDataMessage;
