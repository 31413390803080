import { QueryKey, useQuery as reactQueryUseQuery, UseQueryOptions } from 'react-query';
import { getErrorWithMessage } from 'utils';
import useConfig from 'hooks/useConfig';
import useGetAxiosInstance from 'hooks/useGetAxiosInstance';
import { ApiMethods, CuredApiPaths } from 'models/enums';
import { PathWithID } from 'models/types';

const defaultReactQueryOptions = {
    refetchOnWindowFocus: false,
};

export const isPathWithID = (path: CuredApiPaths | PathWithID | string): path is PathWithID =>
    Boolean((path as PathWithID)?.path);
/**
 * A wrapper around the react-query hook of the same name.
 * For more details see: https://react-query.tanstack.com/reference/useQuery
 * @param queryKey a unique id for the query used for caching
 * @param path the API path
 * @param reactQueryOptions options for the query
 * @param axiosOptions options for the axios request
 * @returns the react-query query
 */
export default function useQuery<TData = unknown>(
    queryKey: QueryKey,
    path: CuredApiPaths | PathWithID | string,
    reactQueryOptions?: UseQueryOptions<TData, Error, TData>,
    axiosOptions?: any
) {
    const useConfigQuery = useConfig();

    if (!useConfigQuery.config) {
        throw new Error(
            'You must mock useQuery hooks in unit tests. See the docs for details: https://github.com/Cured-Health/web-app/blob/development/frontend/docs/testing.md#how-to-mock-usequery-hooks'
        );
    }

    const {
        config: { API_URL },
    } = useConfigQuery;

    const getApiInstance = useGetAxiosInstance(ApiMethods.GET, axiosOptions);

    const queryResult = reactQueryUseQuery<TData, Error, TData>(
        queryKey,
        async () => {
            const apiInstance = await getApiInstance();
            const url = isPathWithID(path) ? `${API_URL}${path.url}` : path;

            try {
                return (await apiInstance.get(url, axiosOptions)).data;
            } catch (error) {
                const errorWithMessage = getErrorWithMessage(error);
                throw errorWithMessage;
            }
        },
        { ...defaultReactQueryOptions, ...reactQueryOptions }
    );

    return queryResult;
}
