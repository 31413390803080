import { SwapCalls } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import FormLayout from 'domains/core/components/FormLayout';
import { CurationStep } from 'models/enums';
import { RootState } from 'state';
import CurationNameForm from './CurationNameForm';
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import { MetaDataFields } from 'domains/campaigns/types';

type Props = {
    selectMetadataFields: (state: RootState) => MetaDataFields;
    editNameActionCreator: ActionCreatorWithOptionalPayload<string, string>;
};

const CurationNamePage = ({ selectMetadataFields, editNameActionCreator }: Props) => {
    const dispatch = useDispatch();
    const editName = (name: string) => dispatch(editNameActionCreator(name));
    const name = useSelector(selectMetadataFields).name;

    return (
        <FormLayout
            header="let's get going"
            stepName={CurationStep.CURATION}
            icon={<SwapCalls />}
            guidingText="Name your Curation. Then continue in the flow to set up your send. All it takes is a few steps. You've got this!"
        >
            <CurationNameForm curationName={name} editName={editName} />
        </FormLayout>
    );
};

export default CurationNamePage;
