import { Edge, Node } from 'reactflow';
import { getCamelToKebabCase } from 'utils';
import {
    SegmentDefinition,
    SegmentEngagement,
    SegmentFilterEngagementFields,
    SegmentFilterState,
} from 'domains/segments/types';
import {
    CampaignStatus,
    CampaignType,
    CurationNavState,
    CurationSegmentsAction,
    SendJobStatus,
    SendJobType,
    URLPaths,
} from 'models/enums';

export enum SendRecurrenceFrequency {
    CONTINUOUS = 'continuous',
    DOES_NOT_REPEAT = 'does not repeat',
    DAILY = 'daily',
    WEEKLY = 'weekly',
    EVERY_WEEKDAY = 'every weekday',
}

export const iSegmentsActionState = (segmentsAction: CurationState): segmentsAction is SegmentsActionState =>
    Boolean((segmentsAction as SegmentsActionState)?.action);

export type Audience = {
    id: number;
    parentAudienceDelayInterval?: string;
    parentAudienceId?: number;
};

export type Campaign<SendT = Send> = {
    audiences?: Audience[];
    id: string;
    name: string;
    type: CampaignType;
    channel: SendJobType;
    status: CampaignStatus;
    startTime: string;
    endTime: string;
    sends: SendT[];
    canCancel: boolean;
    canDelete: boolean;
    canPause: boolean;
    canResume: boolean;
    canStop: boolean;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
    recurrenceFrequency?: string;
};

/**
 * For now the only campaign type that is not supported for the graph based state is conditional split.
 */
export enum CampaignKey {
    SINGLE_SEND = 'singleSend',
    EMAIL_SERIES = 'emailSeries',
}

/**
 * The API does not return the all the fields for Send in GET /campaigns.
 */
export type PartialSend = {
    id: number;
    recurrenceFrequency?: SendRecurrenceFrequency;
    scheduledEndTimestamp?: string;
    scheduledTimestamp: string;
};

export type Send = {
    audienceId?: number;
    campaignId: string;
    channel: SendJobType;
    contentSnapshot: string;
    contentSnapshotDefaultTemplateData: object;
    contentSnapshotTemplateFields: string;
    editorContentSnapshot: string;
    emailSenderProfileId: number;
    emailTemplateId: number;
    executionArn: string;
    id: number;
    isActive: boolean;
    name: string;
    preHeader: string;
    scheduledTimestamp: string;
    segmentId: number;
    sendClassification: string;
    sendDefinitionId: number;
    splitAction?: SplitActions;
    splitType?: SplitTypes;
    status: SendJobStatus;
    subjectLine: string;
    createdTimestamp?: string;
    deletedAt?: string;
    lastModifiedTimestamp?: string;
    parentAudienceDelayInterval?: string;
    parentId?: number;
    pickupTimestamp?: string;
    recurrenceFrequency?: SendRecurrenceFrequency;
    scheduledEndTimestamp?: string;
};

export enum CampaignChannels {
    EMAIL = 'EMAIL',
    SMS = 'SMS',
    NOOP = 'NOOP',
}

/**
 * State types
 */
export type CurationState = {
    navState: CurationNavState;
    /**
     * True if the form is complete regardless of the active route.
     * Different than CurationNavState.COMPLETED because when the form is complete but the user is on that route, then the state is IN_PROGRESS_CURRENT
     */
    isComplete: boolean;
};
export type AudienceState = CurationState & {
    audienceId: number | null;
};
export type ContentState = CurationState & {
    preheader: string;
    senderProfileId: number | null;
    subjectLine: string;
    templateId: number | null;
};
export type DelayState = CurationState & {
    delay: number;
    unit: DelayUnit;
    errorMessage: string;
};
export type MetadataState = CurationState & {
    curationName: string;
    previewContentId: number | null;
    urlPath: URLPaths.CAMPAIGNS | URLPaths.CURATIONS;
};
export type PublishState = CurationState & {
    /**
     * The curation is not considered complete until the publish button is clicked.
     * Otherwise the RouteLeavingGuard will not prompt the user when they navigate away on the publish page.
     */
    publishClicked: boolean;
};
export type ScheduleState = CurationState & {
    isDatetimeError: boolean;
    isRecommendedTimeEnabled: boolean;
    scheduledTimestamp: string | null;
    recurrenceFrequency?: SendRecurrenceFrequency;
    scheduledEndTimestamp?: string;
};
export type SegmentsActionState = CurationState & {
    action: CurationSegmentsAction;
};
export type SegmentsState = CurationState & {
    segments: SegmentEngagement[];
};

/**
 * Payload types
 */
export type EditAudiencePayload = {
    audienceId: number;
};
export type EditContentPayload = {
    preheader: string;
    senderProfileId: number | null;
    subjectLine: string;
    templateId: number | null;
};
export type EditDelayPayload = {
    delay: number;
    unit: DelayUnit;
    errorMessage: string;
};
export type EditMetadataPayload = {
    curationName?: string;
    previewContentId?: number | null;
    urlPath?: URLPaths.CAMPAIGNS | URLPaths.CURATIONS;
};
export type EditSchedulePayload = {
    isRecommendedTimeEnabled: boolean;
    scheduledTimestamp: string | null;
    recurrenceFrequency?: SendRecurrenceFrequency;
    scheduledEndTimestamp?: string;
};
export type EditSegmentsPayload = {
    segments: SegmentEngagement[];
};
export type EditSegmentsActionPayload = {
    action: CurationSegmentsAction;
};

export type Email = {
    contentSnapshot: string;
    fromAddress: string;
    preHeader: string;
    subjectLine: string;
    scheduledTimestamp: string;
    templateName: string;
    sendName?: string;
};

export type EmailSendInput = {
    channel: CampaignChannels.EMAIL;
    name: string;
    parentId?: number;
    parentIndex?: number;
    parentAudienceDelayInterval?: string;
    preHeader: string;
    scheduledTimestamp?: string;
    recurrenceFrequency?: SendRecurrenceFrequency;
    scheduledEndTimestamp?: string;
    senderProfileId: number;
    subjectLine: string;
    templateId: number;
    segmentId?: number;
    segments?: SegmentEngagement[];
    segmentDefinition?: SegmentDefinition;
    splitAction?: SplitActions;
    splitType?: SplitTypes;
};

export type ExitActionInput = {
    channel: CampaignChannels.NOOP;
    parentIndex: number;
    splitAction: SplitActions.EXIT;
    splitType: SplitTypes;
};

export enum SplitTypes {
    MEETS = 'meets criteria',
    DOES_NOT_MEET = 'does not meet criteria',
}

export enum SplitActions {
    SEND = 'send',
    EXIT = 'exit',
}

// This type is for custom curations
export type EmailSendInputState = Omit<EmailSendInput, 'segments'> & {
    hasRecommendedTime?: boolean;
    // Custom curations are tracking segments like we do in audience builder
    // because we're using part of the audience builder code
    segments?: SegmentFilterState<SegmentFilterEngagementFields>[];
    splitType?: SplitTypes;
    splitAction?: SplitActions;
};

type SmsSendInput = {
    channel: CampaignChannels.SMS;
    content: string;
    name: string;
    scheduledTimestamp: string;
    segmentId?: number;
};

export type SendInput = EmailSendInput | SmsSendInput | ExitActionInput;

/**
 * Curation routes
 */
export const ConditionalSplitRoutes = [
    URLPaths.CURATIONS_CONDITIONAL_SPLIT,
    URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENT,
    URLPaths.CURATIONS_CONDITIONAL_SPLIT_1_CONTENT,
    URLPaths.CURATIONS_CONDITIONAL_SPLIT_SCHEDULE,
    URLPaths.CURATIONS_CONDITIONAL_SPLIT_DELAY,
    URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS,
    URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS_MEETS_ACTION,
    URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS_MEETS_CONTENT,
    URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS_MEETS_NOT_ACTION,
    URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS_MEETS_NOT_CONTENT,
    URLPaths.CURATIONS_CONDITIONAL_SPLIT_PUBLISH,
];

export const getCreateCampaignRoute = (campaignKey: CampaignKey, nodeId: string) => {
    const campaignKeyKebabCase = getCamelToKebabCase(campaignKey);
    return `/curations/email/${campaignKeyKebabCase}/${nodeId}`;
};

/**
 * Types for partial props passed from different curations' NavBar to LegacyCampaignNavBar
 */
export type NodePropsPartial = Omit<Node, 'id' | 'position'>;
export type EdgePropsPartial = Omit<Edge, 'id' | 'source' | 'target'>;

export type ContentFields = {
    isAddOnSendClicked?: boolean;
    preHeader: string;
    senderProfileId: number;
    subjectLine: string;
    templateId: number;
};

export type MetaDataFields = {
    contentPreviewNodeId: string;
    name: string;
};

export type ScheduleFields = {
    isRecommendedTimeEnabled: boolean;
    scheduledTimestamp: string;
    parentAudienceDelayInterval?: string;
    recurrenceFrequency?: SendRecurrenceFrequency;
    scheduledEndTimestamp?: string;
};

export enum DelayUnit {
    DAYS = 'days',
    HOURS = 'hours',
}

export type DelayFields = {
    delay: number;
    unit: DelayUnit;
};
