import { ArrowForward } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import Button from 'domains/core/components/Button';
import articleImage from 'assets/images/svg/homepage/homepage-image.svg';
import articleBackgroundImage from 'assets/images/svg/homepage/homepage-background.svg';
import logoImage from 'assets/images/svg/cured-innovaccer.svg';
import theme from 'theme';

const callToActionLink = 'https://www.cured.health/resources/data-driven-digital-marketing-introducing-consumer-360';

const containerStyles = {
    background: `url(${articleBackgroundImage}) center/cover no-repeat`,
    height: '100%',
    paddingX: 8,
    position: 'relative',
};

const buttonStyles = {
    alignSelf: 'flex-start',
    border: '1px solid transparent',
    margin: '20px 0 0 -20px',
    '&:hover': {
        borderColor: theme.palette.text.secondary,
    },
};

const imageWrapperStyles = {
    height: '100%',
    marginTop: '-80px',
};

const logoStyles = {
    bottom: 32,
    position: 'absolute',
    right: 64,
};

const Article = () => (
    <Stack sx={containerStyles}>
        <Stack>
            <Typography variant="caption" color="action.active" paddingTop={7}>
                best practices
            </Typography>
            <Typography variant="h3" paddingTop={1} color="primary.main">
                introducing
            </Typography>
            <Typography variant="h3" paddingTop={0} color="primary.main">
                consumer 360
            </Typography>
            <Button
                size="large"
                sx={buttonStyles}
                endIcon={<ArrowForward fontSize="inherit" />}
                onClick={() => window.open(callToActionLink, '_blank')}
            >
                Learn More
            </Button>
        </Stack>
        <Stack sx={imageWrapperStyles} flexDirection="row">
            <img width="100%" src={articleImage} alt="" />
        </Stack>
        <Box sx={logoStyles}>
            <img height="48px" src={logoImage} alt="cured by innovaccer logo" />
        </Box>
    </Stack>
);

export default Article;
