import { combineReducers } from 'redux';
import audienceReducer from './audience';
import contentReducer from './content';
import delayReducer from './delay';
import metadataReducer from './metadata';
import publishReducer from './publish';
import scheduleReducer from './schedule';
import segmentsReducer from './segments';
import segmentsMeetsActionReducer from './segmentsMeetsAction';
import segmentsMeetsContentReducer from './segmentsMeetsContent';
import segmentsMeetsNotActionReducer from './segmentsMeetsNotAction';
import segmentsMeetsNotContentReducer from './segmentsMeetsNotContent';

const conditionalSplitReducer = combineReducers({
    metadata: metadataReducer,
    audience: audienceReducer,
    content1: contentReducer,
    schedule: scheduleReducer,
    delay: delayReducer,
    segments: segmentsReducer,
    segmentsMeetsAction: segmentsMeetsActionReducer,
    segmentsMeetsContent: segmentsMeetsContentReducer,
    segmentsMeetsNotAction: segmentsMeetsNotActionReducer,
    segmentsMeetsNotContent: segmentsMeetsNotContentReducer,
    publish: publishReducer,
});

export default conditionalSplitReducer;
