import { Typography } from '@mui/material';
import { SegmentDemographic, SegmentEvent } from 'domains/segments/types';
import Chip from 'domains/core/components/Chip';
import { SQLType } from 'models/enums';
import FilterDetails from '../../FilterDetails';
import { renderValue, secondarySpanProps } from '../../FilterDetails/FilterDetails';
import { getInclusionOperatorFromAPIToUI } from 'domains/segments/utils';

type Props = {
    conditions: SegmentEvent['conditions'];
};

const Conditions = ({ conditions }: Props) => {
    const renderCondition = ({ includeNullValues, name, operator, sqlType, value }: SegmentDemographic) => {
        const isDate = sqlType === SQLType.DATE || sqlType === SQLType.TIMESTAMP_WITH_TIME_ZONE;

        if (isDate) {
            // using legacy code from FilterDetails to handle dates as
            // there's a lot of complex logic to untangle
            return (
                <FilterDetails
                    includeNullValues={includeNullValues}
                    name={name}
                    operator={operator}
                    sqlType={sqlType}
                    value={value}
                />
            );
        }

        return (
            <>
                <Typography {...secondarySpanProps}>{name} </Typography>
                {getInclusionOperatorFromAPIToUI(operator)}
                {renderValue(value)}
                {includeNullValues && ' including nulls'}
            </>
        );
    };

    return (
        <>
            {conditions?.map(({ includeNullValues, name, operator, sqlType, value }, index) => (
                <div key={`${name}-${operator}`} style={{ marginTop: '2px' }}>
                    <Chip label={index === 0 ? 'with' : 'and'} sx={{ marginRight: '8px' }} variant="outlined" />
                    {renderCondition({ includeNullValues, name, operator, sqlType, value })}
                </div>
            ))}
        </>
    );
};

export default Conditions;
