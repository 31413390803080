import { CuredApiPaths } from 'models/enums';
import { Send } from 'domains/campaigns/types';
import { UseQueryOptions } from 'react-query';
import useQuery from '../useQuery';
import QueryKeys from './keys';

type Params = {
    path?: CuredApiPaths.SENDS | string;
    reactQueryOptions?: UseQueryOptions<Send[], Error>;
};

const useSends = (params: Params = {}) => {
    const { path = CuredApiPaths.SENDS, reactQueryOptions } = params;
    return useQuery(QueryKeys.SENDS(path), path, reactQueryOptions);
};

export default useSends;
