import { useFlags } from 'launchdarkly-react-client-sdk';
import ContentPageWithFolders from './ContentPageWithFolders';
import ContentPageWithoutFolders from './ContentPageWithoutFolders';

const ContentPage = () => {
    const { contentFolder: hasContentFoldersFeature } = useFlags();

    if (hasContentFoldersFeature === undefined) return;
    return hasContentFoldersFeature ? <ContentPageWithFolders /> : <ContentPageWithoutFolders />;
};

export default ContentPage;
