import { InfoOutlined } from '@mui/icons-material';
import { Stack, Tooltip, Typography } from '@mui/material';

export type Props = {
    label: string;
    labelIconTooltipText?: string;
};

// todo: consolidate with InputLabel
const SwitchInputLabel = ({ label, labelIconTooltipText }: Props) =>
    labelIconTooltipText ? (
        <Tooltip
            title={labelIconTooltipText}
            sx={{
                // Setting pointerEvents: 'auto' allows the Tooltip to show on hover if it's used in the label.
                pointerEvents: 'auto',
            }}
        >
            <Stack direction="row" alignItems="center" pb={0.5} columnGap={1}>
                <Typography variant="body2">{label}</Typography>
                <InfoOutlined sx={{ fontSize: '14px', pt: 0.5 }} />
            </Stack>
        </Tooltip>
    ) : (
        <>{label}</>
    );

export default SwitchInputLabel;
