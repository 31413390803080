import { ReactNode } from 'react';
import { themeColors } from 'colors';
import Button from 'domains/core/components/Button';

const buttonStyles = {
    border: `2px solid ${themeColors.primary.main}`,
    borderRadius: 1,
    boxShadow: 'none',
    height: '60px',
    justifyContent: 'flex-start',
    margin: '8px',
    padding: '16px',
    width: '100%',
};

type ButtonProps = {
    disabled?: boolean;
    isActive?: boolean;
    icon: ReactNode;
    label: string;
    onClick?: () => void;
    value?: ReactNode;
};

const CustomCampaignNavigationButton = ({ disabled, isActive, icon, label, onClick, value }: ButtonProps) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button
            disabled={disabled}
            onClick={onClick}
            startIcon={icon}
            size="large"
            variant="outlined"
            sx={{
                ...buttonStyles,
                borderColor: isActive && themeColors.action.active,
                pointerEvents: !onClick && 'none',
            }}
        >
            <span style={{ fontSize: '20px', margin: '-2px 8px 0 0' }}>{label} </span>{' '}
            <span style={{ lineHeight: 1.25, textAlign: 'left' }}>{value}</span>
        </Button>
    </div>
);

export default CustomCampaignNavigationButton;
