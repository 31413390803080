import Card from 'pages/InsightsPage/dashboards/Card';
import USMap from 'pages/InsightsPage/visualizations/USMap';
import useContactLocation from './useContactLocations';

const LocationCard = () => {
    const { data, isError, isLoading } = useContactLocation();

    return (
        <Card isError={isError} isLoading={isLoading} label="Location">
            <USMap bubbleData={data} />
        </Card>
    );
};

export default LocationCard;
