import { DateRange } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import DateRangePicker from 'domains/core/components/DateRangePicker';
import { SxProps, Theme } from '@mui/material';

type DateRangePickerSx = {
    textFieldSx?: SxProps<Theme>;
    stackSx?: SxProps<Theme>;
};

type Props = {
    onDateRangeChange: (dateTime: DateRange<DateTime>) => void;
    styles?: DateRangePickerSx;
    valueStart: DateTime;
    valueEnd: DateTime;
};

const DateRangePickerInputs = ({ onDateRangeChange, styles = {}, valueStart, valueEnd }: Props) => {
    const { textFieldSx, stackSx } = styles;

    return (
        <DateRangePicker
            label="Date"
            onChange={onDateRangeChange}
            slotProps={{
                textField: {
                    sx: { width: '16rem', ...textFieldSx },
                },
            }}
            value={[valueStart, valueEnd]}
            sx={stackSx}
        />
    );
};

export default DateRangePickerInputs;
