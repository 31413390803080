import { UseMutationOptions } from 'react-query';
import { SegmentDefinitionInput } from 'domains/segments/types';
import { CuredApiPaths } from 'models/enums';
import useMutation from '../useMutation';
import MutationKeys from './keys';

export type SegmentPreviewResponse = {
    count: number;
};

/**
 * Gets the count for an audience based on a segment definition.
 * @param mutateOptions react-query useMutation option
 * @returns the react-query useMutation results
 * See https://react-query-v3.tanstack.com/reference/useMutation for options and return value
 */
const useSegmentPreviewCount = (
    mutateOptions?: UseMutationOptions<SegmentPreviewResponse, Error, SegmentDefinitionInput>
) =>
    useMutation<SegmentDefinitionInput, SegmentPreviewResponse>(
        MutationKeys.GET_SEGMENT_PREVIEW_COUNT,
        CuredApiPaths.SEGMENTS_PREVIEW_COUNT,
        mutateOptions
    );

export default useSegmentPreviewCount;
