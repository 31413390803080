import { Stack } from '@mui/material';
import { route as routeSelector } from 'state/selectors';
import { SegmentsActionState, EditSegmentsActionPayload } from 'domains/campaigns/types';
import Select from 'domains/core/components/Select';
import { CurationSegmentsAction, URLPaths } from 'models/enums';
import { useSelector } from 'react-redux';

const values = [
    {
        label: CurationSegmentsAction.SEND_EMAIL,
        value: CurationSegmentsAction.SEND_EMAIL,
    },
    {
        label: CurationSegmentsAction.EXIT_CURATION,
        value: CurationSegmentsAction.EXIT_CURATION,
    },
];

type Props = {
    segmentsActionState: SegmentsActionState;
    editSegmentsAction: (payload: EditSegmentsActionPayload) => void;
};

const CurationSegmentsMeetsActionForm = ({ segmentsActionState, editSegmentsAction }: Props) => {
    const { action } = segmentsActionState;
    const route = useSelector((state) => routeSelector(state));

    const onChange = (e: any) => {
        const value = e.target.value;

        editSegmentsAction({
            action: value,
        });
    };

    const getLabelBefore = () =>
        route === URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS_MEETS_ACTION
            ? `Select Action for 'Yes'`
            : `Select Action for 'No'`;

    const getLabelAfter = () =>
        route === URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS_MEETS_ACTION
            ? `Audience Meets Criteria`
            : `Audience does not Meet Criteria`;

    return (
        <Stack alignItems="center">
            <Select
                label={action ? getLabelBefore() : getLabelAfter()}
                labelStyles={{ textTransform: 'none' }}
                sx={{ textTransform: 'capitalize' }}
                value={action ?? ''}
                values={values}
                onChange={onChange}
            />
        </Stack>
    );
};
export default CurationSegmentsMeetsActionForm;
