import { Snackbar } from '@mui/material';
import Alert from 'domains/core/components/Alert';
import { useState } from 'react';

type Props = {
    successMessage: JSX.Element | string;
    autoHideDuration?: number | null;
};

const InfoSnackbar = ({ successMessage, autoHideDuration = 6000 }: Props) => {
    const [open, setOpen] = useState(true);

    const handleClose = () => setOpen(false);

    return (
        <Snackbar
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert onClose={handleClose} severity="info" variant="standard">
                {successMessage}
            </Alert>
        </Snackbar>
    );
};

export default InfoSnackbar;
