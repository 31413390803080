import { ResponsiveFunnel } from '@nivo/funnel';
import CustomTooltip from '../CustomTooltip';
import { graphColors } from 'colors';
import NoDataMessage from '../NoDataMessage';

export type FunnelDataItem = {
    id: string | number;
    label?: string;
    value: number;
};

const defaultHeight = '400px';

const FunnelChart = ({ data, height }: { data: FunnelDataItem[]; height?: string }) => {
    if (!data?.length) return <NoDataMessage />;

    const dataWithColors = data.map((datum, index) => ({
        ...datum,
        color: graphColors[index],
    }));

    const renderTooltip = (part: any) => (
        <CustomTooltip color={part.color} label={part.data.label} value={`${part.data.value.toLocaleString()}`} />
    );

    return (
        <div style={{ height: height ?? defaultHeight, width: '100%' }}>
            <ResponsiveFunnel
                afterSeparatorLength={100}
                afterSeparatorOffset={20}
                beforeSeparatorLength={100}
                beforeSeparatorOffset={20}
                borderWidth={20}
                colors={{ datum: 'color' }}
                currentBorderWidth={40}
                currentPartSizeExtension={10}
                data={dataWithColors}
                labelColor={{ from: 'color', modifiers: [['darker', 3]] }}
                margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                motionConfig="wobbly"
                tooltip={({ part }) => renderTooltip(part)}
                valueFormat=">-.3s"
            />
        </div>
    );
};

export default FunnelChart;
