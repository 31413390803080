import { CampaignChannels, CampaignKey, SendRecurrenceFrequency } from '../types';
import createCampaignSlice, { CampaignNodeType, CampaignState } from './createCampaignSlice';

export const temporaryInitialState: CampaignState = {
    campaignGraph: {
        nodes: [
            {
                id: '438cfb9c-11db-54cd-b924-259911b6813e',
                type: CampaignNodeType.META_DATA_NODE,
                name: '',
                contentPreviewNodeId: null,
            },
            { id: '267a4b2a-52f2-53a8-a4fc-baa20186d88e', type: CampaignNodeType.SEGMENT_NODE, sendIndex: 0 },
            {
                id: '51f72c60-cf7d-5afe-b855-46b1a33d8ed0',
                type: CampaignNodeType.CONTENT_NODE,
                sendIndex: 0,
                isAddOnSendClicked: false,
            },
            {
                id: '30facd24-a154-52f6-8f1c-1a49e0e12ab2',
                type: CampaignNodeType.SCHEDULE_NODE,
                sendIndex: 0,
                isRecommendedTimeEnabled: false,
                dateTimeError: false,
            },
            {
                id: '28cf22fd-91db-5708-a383-5f0c60cfd7f4',
                type: CampaignNodeType.DELAY_NODE,
                delay: null,
                unit: null,
            },
            {
                id: 'f41f9118-78b1-5e51-96bf-87610f2ddabd',
                type: CampaignNodeType.CONTENT_NODE,
                sendIndex: 1,
                isAddOnSendClicked: false,
            },
            {
                id: 'bdb900b6-5423-56fb-ada4-f8750b5b4110',
                type: CampaignNodeType.PUBLISH_NODE,
                isPublishClicked: false,
            },
        ],
        edges: [
            {
                id: 'bb47ba59-0280-5f58-a97f-1e4b1a348875',
                source: '438cfb9c-11db-54cd-b924-259911b6813e',
                target: '267a4b2a-52f2-53a8-a4fc-baa20186d88e',
            },
            {
                id: '4c9d092f-29b9-5f24-ab6b-1c14531ae4bd',
                source: '267a4b2a-52f2-53a8-a4fc-baa20186d88e',
                target: '51f72c60-cf7d-5afe-b855-46b1a33d8ed0',
            },
            {
                id: 'd9c68dac-7763-5aa2-893a-8793ad6b7327',
                source: '51f72c60-cf7d-5afe-b855-46b1a33d8ed0',
                target: '30facd24-a154-52f6-8f1c-1a49e0e12ab2',
            },
            {
                id: '2f09b6d7-df93-5b2f-84ad-e79597c49959',
                source: '30facd24-a154-52f6-8f1c-1a49e0e12ab2',
                target: '28cf22fd-91db-5708-a383-5f0c60cfd7f4',
            },
            {
                id: 'ab058302-ec28-515f-86e1-2240d0d7c1b6',
                source: '28cf22fd-91db-5708-a383-5f0c60cfd7f4',
                target: 'f41f9118-78b1-5e51-96bf-87610f2ddabd',
            },
            {
                id: '29acc942-6b42-52b5-8092-eedf131924fd',
                source: 'f41f9118-78b1-5e51-96bf-87610f2ddabd',
                target: 'bdb900b6-5423-56fb-ada4-f8750b5b4110',
            },
        ],
    },
    campaignTree: {
        sends: [
            {
                channel: CampaignChannels.EMAIL,
                name: '',
                preHeader: '',
                scheduledTimestamp: '',
                recurrenceFrequency: SendRecurrenceFrequency.DOES_NOT_REPEAT,
                senderProfileId: null,
                subjectLine: '',
                templateId: null,
                segmentId: null,
            },
            {
                channel: CampaignChannels.EMAIL,
                name: '',
                parentIndex: 0,
                preHeader: '',
                senderProfileId: null,
                subjectLine: '',
                templateId: null,
            },
        ],
    },
};

const emailSeries = createCampaignSlice(temporaryInitialState, CampaignKey.EMAIL_SERIES);

export const {
    addSend,
    deleteSend,
    editContentFieldsByNodeId,
    editDelayFieldsByNodeId,
    editName,
    setPublish,
    editScheduleFields,
    editSegmentId,
    resetState,
    setState,
    setContentPreviewNodeId,
    setScheduleDatetimeError,
} = emailSeries.actions;

export default emailSeries.reducer;
