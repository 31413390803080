import { useDispatch } from 'react-redux';
import { editMetadata as editMetadataActionCreator } from 'domains/campaigns/state/actionCreators';
import CurationNameInput from './LegacyCurationNameInput';

type Props = {
    actionType: string;
    curationName: string;
    isHidden: boolean;
};

const LegacyCurationNameInputWithState = ({ actionType, curationName, isHidden }: Props) => {
    const dispatch = useDispatch();
    const editMetadata = (curationName: string) => dispatch(editMetadataActionCreator(actionType, { curationName }));
    return <CurationNameInput curationName={curationName} editMetadata={editMetadata} isHidden={isHidden} />;
};

export default LegacyCurationNameInputWithState;
