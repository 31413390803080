import { SvgIconProps, SxProps, Theme } from '@mui/material';
import { Box, Typography } from '@mui/material';
import { CheckCircle, Close, RadioButtonUncheckedOutlined } from '@mui/icons-material';
import { CSSProperties } from 'react';
import { Handle, Position } from 'reactflow';
import { useSelector } from 'react-redux';
import theme from 'theme';
import Button from 'domains/core/components/Button/Button';
import ProgressCircleIcon from 'domains/core/components/SvgIcons/ProgressCircleIcon';
import ProgressCircleActiveIcon from 'domains/core/components/SvgIcons/ProgressCircleActiveIcon';
import { CurationNavState, URLPaths } from 'models/enums';
import { route as routeSelector } from 'state/selectors';

const getBorderColor = (isActive: boolean, isDisabled: boolean, isPreview: boolean, readonly: boolean) => {
    if (isPreview || readonly) {
        return 'primary.main';
    }
    if (isActive) {
        return 'action.active';
    }
    if (isDisabled) {
        return 'text.disabled';
    }
    return 'primary.main';
};

type StateIconProps = {
    navState: CurationNavState;
    isActive: boolean;
    readonly: boolean;
};

const StateIcon = ({ navState, isActive, readonly }: StateIconProps) => {
    const getIconStyles = (color: string) => ({
        alignItems: 'center',
        color,
        display: 'flex',
        height: isActive ? '2rem' : '1.75rem',
        justifyContent: 'end',
        marginRight: isActive ? '1rem' : '0.75rem',
        transition: 'margin-right 0.5s, width 0.5s, height 0.5s',
        width: isActive ? '2rem' : '1.75rem',
    });

    const iconsState = {
        [CurationNavState.PREVIEW]: <RadioButtonUncheckedOutlined sx={getIconStyles('primary.contrastText')} />,
        [CurationNavState.OPEN]: <RadioButtonUncheckedOutlined sx={getIconStyles('primary.contrastText')} />,
        [CurationNavState.IN_PROGRESS]: <ProgressCircleIcon sx={getIconStyles('primary.main')} />,
        [CurationNavState.IN_PROGRESS_CURRENT]: <ProgressCircleActiveIcon sx={getIconStyles('primary.main')} />,
        [CurationNavState.COMPLETED]: <CheckCircle sx={getIconStyles('secondary.main')} />,
    };
    return <>{readonly ? iconsState[CurationNavState.COMPLETED] : iconsState[navState]}</>;
};

type IconColor = 'action' | 'disabled' | 'primary';

const getIconColor = (isActive: boolean, isDisabled: boolean, isPreview: boolean, readonly: boolean): IconColor => {
    if (isPreview || readonly) {
        return 'primary';
    }
    if (isActive) {
        return 'action';
    }
    if (isDisabled) {
        return 'disabled';
    }
    return 'primary';
};

export type Props = {
    isConnectable: boolean;
    data: {
        bottomHandleVisible?: boolean;
        disabledHoverState: boolean;
        navState: CurationNavState;
        isDisabled: boolean;
        isMouseOnRemove: boolean;
        isRemoveEnabled: boolean;
        label: string;
        leftIcon: (props: SvgIconProps) => any;
        onClick: () => void;
        onClickRemove?: () => void;
        onMouseEnterRemove?: () => void;
        onMouseLeaveRemove?: () => void;
        readonly: boolean;
        routes: URLPaths[];
    };
    sourcePosition?: Position;
    targetPosition?: Position;
};

const NavigationNode = ({
    isConnectable,
    data: {
        bottomHandleVisible = true,
        disabledHoverState,
        navState,
        isDisabled,
        isMouseOnRemove,
        isRemoveEnabled,
        label,
        leftIcon,
        onClick,
        onClickRemove,
        onMouseEnterRemove,
        onMouseLeaveRemove,
        readonly,
        routes,
    },
    sourcePosition,
    targetPosition,
}: Props) => {
    const route = useSelector(routeSelector);
    const isActive = routes?.includes(route);
    const isPreview = navState === CurationNavState.PREVIEW;
    const handleClick = isDisabled ? () => {} : onClick;

    const navButtonStyles = {
        borderRadius: '6px',
        borderStyle: 'solid',
        borderWidth: isActive ? '0.188rem' : '0.125rem',
        borderColor: getBorderColor(isActive, isDisabled, isPreview, readonly),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fff',
        padding: '1.5rem 1.25rem',
        height: isActive ? '2rem' : '1.25rem',
        width: isActive ? '23rem' : '19.375rem',
        transition: 'width 0.5s, height 0.5s, margin 0.5s',
        '&:first-of-type': {
            marginTop: '0rem',
        },
        '&:last-child': {
            marginBottom: '0rem',
        },
        '&:hover': {
            backgroundColor:
                !isDisabled && !disabledHoverState && !isMouseOnRemove && !readonly ? 'rgb(238, 252, 248)' : '#FFFFFF',
            cursor: isDisabled || disabledHoverState ? 'initial' : 'pointer',
        },
    };

    const iconContainerStyles = {
        width: '1.75rem',
        height: '1.75rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: isActive ? '1rem' : '0.65rem',
        transition: 'margin-right 0.5s',
    };

    const iconStyles = {
        color: getIconColor(isActive, isDisabled, isPreview, readonly),
        sx: {
            height: isActive ? '2.188rem' : '1.75rem',
            width: isActive ? '2.188rem' : '1.75rem',
            transition: 'width 0.5s, height 0.5s',
        },
    };

    const typeContainer = {
        flexGrow: '1',
    };

    const typeStyles = {
        position: 'relative',
        display: 'flex',
        flexGrow: '1',
        alignItems: 'center',
        justifyContent: 'start',
        top: '-0.175rem',
        fontSize: isActive ? '1.75rem' : '1.5rem',
        color: isDisabled && !(isPreview || readonly) ? 'text.disabled' : 'primary.main',
        transition: 'font-size 0.5s, top',
    };

    const hiddenStyles: CSSProperties = {
        visibility: 'hidden',
    };

    const stateIconStyles: CSSProperties = {
        position: 'absolute',
        right: '1rem',
    };

    const buttonRemoveSx: SxProps<Theme> = {
        top: isActive ? '4rem' : '3.5rem',
        left: '2rem',
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '& p:hover': {
            color: theme.palette.action.selected,
        },
        '&:hover > span.MuiButton-endIcon > .MuiSvgIcon-root': {
            color: theme.palette.action.selected,
        },
    };

    return (
        <Box
            data-testid="curation-nav-button"
            onClick={disabledHoverState || readonly ? () => {} : handleClick}
            sx={navButtonStyles}
        >
            <Handle
                isConnectable={isConnectable}
                type="target"
                position={targetPosition ?? Position.Top}
                style={hiddenStyles}
            />
            <Box sx={iconContainerStyles}>{leftIcon(iconStyles)}</Box>{' '}
            <Box sx={typeContainer}>
                <Typography sx={typeStyles}>{label}</Typography>
            </Box>
            <Box sx={stateIconStyles}>
                <StateIcon navState={navState} isActive={isActive} readonly={readonly} />
            </Box>
            {bottomHandleVisible && (
                <Handle
                    isConnectable={isConnectable}
                    type="source"
                    position={sourcePosition ?? Position.Bottom}
                    style={hiddenStyles}
                />
            )}
            {isRemoveEnabled && !readonly && (
                <Button
                    size="small"
                    onClick={onClickRemove}
                    onMouseEnter={onMouseEnterRemove}
                    onMouseLeave={onMouseLeaveRemove}
                    endIcon={<Close />}
                    sx={buttonRemoveSx}
                >
                    Remove
                </Button>
            )}
        </Box>
    );
};

export default NavigationNode;
