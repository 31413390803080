import { useEffect } from 'react';
import { CuredApiPaths } from 'models/enums';
import Card from 'pages/InsightsPage/dashboards/Card';
import { FiltersForApiState } from 'pages/InsightsPage/dashboards/filtering/types';
import useFilteredData from 'pages/InsightsPage/dashboards/filtering/useFilteredData';
import LineGraph from 'pages/InsightsPage/visualizations/LineGraph';
import CustomTooltip from 'pages/InsightsPage/visualizations/CustomTooltip';

type Props = {
    filtersForApi: FiltersForApiState;
};

const TotalSendsOverTimeCard = ({ filtersForApi }: Props) => {
    const { getData, isError, isLoading, data } = useFilteredData({
        key: 'sms-dashboard/sends-over-time',
        path: CuredApiPaths.REPORT_SMS_MESSAGES_OVER_TIME,
    });

    useEffect(() => {
        getData(filtersForApi);
    }, [filtersForApi, getData]);

    const renderCustomTooltip = (point: any) => (
        <CustomTooltip
            color={point.serieColor}
            label={`send date - ${point.data.xFormatted}`}
            value={`number of sends - ${point.data.yFormatted}`}
        />
    );

    return (
        <Card isError={isError} isLoading={isLoading} label="Total Sends over Time">
            <LineGraph data={[data]} leftLegendLabel="total sends" renderCustomTooltip={renderCustomTooltip} />
        </Card>
    );
};

export default TotalSendsOverTimeCard;
