import { SwapCalls } from '@mui/icons-material';
import { useEffect } from 'react';
import { CustomCampaignSteps } from 'domains/campaigns/components/CustomCampaign/CustomCampaign';
import { setName } from 'domains/campaigns/components/CustomCampaign/customCampaignActions';
import CustomCampaignNameInput from 'domains/campaigns/components/CustomCampaign/CustomCampaignNameInput';
import { StateTypes } from 'domains/campaigns/components/CustomCampaign/customCampaignReducer';
import FormLayout from 'domains/core/components/FormLayout';

type Props = {
    campaignState: StateTypes;
    dispatchCampaignAction: React.Dispatch<any>;
    setIsContinueEnabled: (isContinueEnabled: boolean) => void;
    setNextStep: (step: CustomCampaignSteps) => void;
};

const CustomCampaignNameStep = ({
    campaignState,
    dispatchCampaignAction,
    setIsContinueEnabled,
    setNextStep,
}: Props) => {
    const { name } = campaignState;

    useEffect(() => {
        setNextStep(CustomCampaignSteps.SELECT_AUDIENCE);
        // The dependency array is empty because this useEffect should run exactly once after the initial rendering of the component.
        // The hook is not dependent on any props or state variables to re-run. This is intended to perform the initial setup based on
        // the `activeSendIndex` value when the component mounts without reacting to any further updates or re-renders.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setIsContinueEnabled(name?.length >= 3);
    }, [name, setIsContinueEnabled]);

    return (
        <FormLayout
            header="let's get going"
            stepName="curation"
            icon={<SwapCalls />}
            guidingText="Name your Curation. Then continue in the flow to set up your send. All it takes is a few steps. You've got this!"
        >
            <CustomCampaignNameInput
                name={campaignState.name}
                handleNameChange={(name: string) => dispatchCampaignAction(setName(name))}
            />
        </FormLayout>
    );
};

export default CustomCampaignNameStep;
