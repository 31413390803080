import { Box, CSSObject, Grid, Stack, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { Close } from '@mui/icons-material';
import CuredLogo from 'assets/images/svg/cured-logo-circle-green-no-text.svg';
import Button from 'domains/core/components/Button';
import HtmlRenderer from 'domains/core/components/HtmlRenderer';
import theme from 'theme';

const previewHeader: CSSObject = {
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(0.5),
};
const previewHeaderElements: CSSObject = {
    padding: `0 ${theme.spacing(1)}`,
};
const previewContent: CSSObject = {
    border: `${theme.spacing(0.25)} solid ${theme.palette.primary.main}`,
    borderRadius: '6px',
    boxShadow: `${theme.spacing(0.125)} ${theme.spacing(0.125)} 0 ${theme.palette.primary.main}`,
    height: '100%',
    overflow: 'scroll',
};

export type Props = {
    emailTemplateHTML?: string;
    fromAddress?: string;
    isCloseEnabled?: boolean;
    /** Set to true if this is rendered as an item inside a MUI Grid component. */
    isGridItem?: boolean;
    isOpen: boolean;
    onClose: () => void;
    preHeader?: string;
    /**
     * Highlights selected link by blurring the test of the html.
     */
    selectedLink?: string;
    subjectLine?: string;
    sx?: SxProps<Theme>;
    /**
     * Describes which email is being previewed. i.e. '${whichEmail} email preview'
     */
    whichEmail?: string;
};

const EmailPreviewContent = ({
    emailTemplateHTML,
    fromAddress,
    isCloseEnabled = true,
    isGridItem = false,
    isOpen,
    onClose,
    preHeader,
    selectedLink,
    subjectLine,
    sx,
    whichEmail = '',
}: Props) => {
    if (!isOpen) {
        return null;
    }
    const containerVisibilityStyles: CSSObject = {
        height: '100%',
        zIndex: `${isOpen ? '100' : '-1'}`,
        backgroundColor: '#fff',
        width: '100%',
    };
    return (
        <Grid item={isGridItem} container xs={isGridItem ? true : undefined} height="100%" sx={{ ...sx }}>
            <Box sx={containerVisibilityStyles}>
                <Grid item container xs sx={previewHeader}>
                    <Typography variant="overline" color={theme.palette.action.active} sx={previewHeaderElements}>
                        {`${whichEmail} email preview`}
                    </Typography>
                    {isCloseEnabled && (
                        <Button
                            variant="text"
                            size="small"
                            endIcon={<Close />}
                            onClick={onClose}
                            sx={previewHeaderElements}
                        >
                            Close Preview
                        </Button>
                    )}
                </Grid>
                <Grid item container sx={previewContent}>
                    {(fromAddress || preHeader || subjectLine) && (
                        <Grid item container my={2}>
                            <Grid item mx={2} my={2}>
                                <img src={CuredLogo} alt="avocado avatar" width="100%" />
                            </Grid>
                            <Grid item sm={10}>
                                <Stack rowGap={1}>
                                    <Typography variant="body2">{fromAddress ?? ''}</Typography>
                                    <Typography variant="overline" color="text.secondary" textTransform="none">
                                        {preHeader ?? ''}
                                    </Typography>
                                    <Typography variant="body2">{subjectLine ?? ''}</Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    )}
                    <Grid item xs height="100%">
                        <HtmlRenderer htmlContent={emailTemplateHTML ?? ''} selectedLink={selectedLink} />
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    );
};

export default EmailPreviewContent;
