import { IconButton, Stack, Tooltip } from '@mui/material';
import { EmailOutlined, GridOff, GridOn, PageviewOutlined } from '@mui/icons-material';
import { useEffect, useRef, useState } from 'react';
import BeePlugin from '@mailupinc/bee-plugin';
import useQueryParams from 'hooks/useQueryParams';
import { BeeConfig } from 'domains/content/types';
import PageHeader from 'domains/core/components/PageHeader';
import PageInfoIcon from 'domains/core/components/SvgIcons/PageInfoIcon';
import Button from 'domains/core/components/Button';
import SendIcon from 'domains/core/components/SvgIcons/SendIcon';
import SuccessSnackbar from 'domains/core/components/Snackbars/SuccessSnackbar';
import ContentDetailsPage from 'pages/ContentDetailsPage';

const beeStyles = {
    width: '100%',
    height: '100%',
    overflow: 'scroll',
    marginTop: '20px',
};

const DEFAULT_CONFIGURATION = {
    container: 'bee-plugin-container', // Identifies the id of div element that contains BEE Plugin.
    language: 'en-US',
    sidebarPosition: 'left',
};

type Props = {
    beeConfig: BeeConfig;
    beeJson: object;
    isSuccess: boolean;
    /**
     * When switching between creating and editing a template/landing page, isLoading will be true and cause the Bee Editor to restart.
     * For that particular use case, pass noRestart=true.
     */
    isNoRestart?: boolean;
    showCustomToolbar?: boolean;
    onAutoSave?: (beeJson: string) => void;
    onChange?: (beeJson: string, response: object) => void;
    onSave?: (beeJson: string) => void;
    openSendTestDialog?: () => void;
};

// TODO: rename this component ContentBuilder as it no longer only returns the BeePlugin
const BeeEditor = ({
    beeConfig,
    beeJson,
    isSuccess,
    isNoRestart = false,
    showCustomToolbar = true,
    onAutoSave,
    onChange,
    onSave,
    openSendTestDialog,
}: Props) => {
    const beeEditorRef = useRef(null);

    const query = useQueryParams();
    const id = query.get('id');
    const isTemplateDefault = id === 'default';

    const [isBeeLoaded, setIsBeeLoaded] = useState(false);
    const [isPreviewMode, setIsPreviewMode] = useState(false);
    const [isGridVisible, setIsGridVisible] = useState(false);
    const [isDetailsActive, setIsDetailsActive] = useState(false);
    const [isDetailsExitBlocked, setIsDetailsExitBlocked] = useState({ value: false, key: 0 });
    const [isDetailsSaveSuccess, setIsDetailsSaveSuccess] = useState(false);
    const [isDetailsTouched, setIsDetailsTouched] = useState(false);

    const onLoad = () => setIsBeeLoaded(true);

    useEffect(() => {
        const customCss = `${window.location.origin}/bee.css`;

        const beeFinalConfig = {
            ...DEFAULT_CONFIGURATION,
            onAutoSave,
            onChange,
            onLoad,
            onSave,
            onSend: openSendTestDialog,
            uid: beeConfig.client_uid,
            customCss,
            mergeTags: beeConfig.mergeTags,
            editorFonts: { ...beeConfig.editorFonts },
            autosave: beeConfig.autosave,
            ...beeConfig,
            defaultForm: {
                structure: {
                    title: 'Form title',
                    fields: {
                        firstName: { type: 'text', label: 'First Name' },
                        lastName: { type: 'text', label: 'Last Name' },
                        email: { type: 'email', label: 'Email' },
                        submit: { type: 'submit', label: '', attributes: { value: 'Submit' } },
                    },
                    layout: [['firstName', 'lastName', 'email', 'submit']],
                },
            },
        };

        if (isSuccess && !isNoRestart) {
            try {
                beeEditorRef.current = new BeePlugin(beeConfig);
                beeEditorRef.current.start(beeFinalConfig, beeJson);
            } catch (error) {}
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);

    const togglePreviewAndUpdateMode = (isPreview: boolean) => {
        if (!!beeEditorRef.current) {
            beeEditorRef.current.togglePreview();
        }
        setIsPreviewMode(isPreview);
    };

    const handleClickButton = (isPreview: boolean) => {
        setIsDetailsSaveSuccess(false);

        if (isPreview !== isPreviewMode) {
            togglePreviewAndUpdateMode(isPreview);
        }

        if (isDetailsTouched) {
            setIsDetailsExitBlocked((prevState) => ({ value: true, key: prevState.key + 1 }));
        } else {
            setIsDetailsActive(false);
        }
    };

    const editEmailButton = (
        <Tooltip title="Edit Email" placement="top">
            <span>
                <IconButton
                    aria-label="Edit Email"
                    size="large"
                    onClick={() => handleClickButton(false)}
                    disabled={!beeEditorRef.current || !isBeeLoaded}
                >
                    <EmailOutlined />
                </IconButton>
            </span>
        </Tooltip>
    );

    const beeEditorPreviewButton = (
        <Tooltip title="Preview" placement="top">
            <span>
                <IconButton
                    aria-label="Preview"
                    size="large"
                    onClick={() => handleClickButton(true)}
                    disabled={!beeEditorRef.current || !isBeeLoaded}
                >
                    <PageviewOutlined />
                </IconButton>
            </span>
        </Tooltip>
    );

    const renderToolbar = () => {
        const sendDetailsButton = (
            <Tooltip title="Send Details" placement="top">
                <IconButton aria-label="Send Details" size="large" onClick={() => setIsDetailsActive(true)}>
                    <PageInfoIcon />
                </IconButton>
            </Tooltip>
        );

        const sendTestButton = (
            <Tooltip title="Send Test" placement="top">
                <IconButton aria-label="Send Test" size="large" onClick={() => openSendTestDialog()}>
                    <SendIcon />
                </IconButton>
            </Tooltip>
        );

        if (isPreviewMode) {
            return (
                <PageHeader
                    icon={<PageviewOutlined fontSize="inherit" />}
                    header="preview"
                    headerOthers={
                        <Stack direction="row" justifyContent="space-between">
                            <Stack direction="row" alignItems="center" columnGap={1} ml={1}>
                                {editEmailButton}
                                {!isTemplateDefault && sendDetailsButton}
                                {sendTestButton}
                            </Stack>
                        </Stack>
                    }
                />
            );
        }

        const toggleGridVisibility = () => {
            !!beeEditorRef.current && beeEditorRef.current.toggleStructure();
            setIsGridVisible(!isGridVisible);
        };
        return (
            <PageHeader
                icon={<EmailOutlined fontSize="inherit" />}
                header="email"
                headerOthers={
                    <Stack direction="row" justifyContent="space-between" width="100%">
                        <Stack direction="row" alignItems="center" columnGap={1} ml={1}>
                            {!isTemplateDefault && sendDetailsButton}
                            {beeEditorPreviewButton}
                            <Tooltip title={isGridVisible ? 'Hide Grid' : 'Show Grid'} placement="top">
                                <span>
                                    <IconButton
                                        aria-label={isGridVisible ? 'Hide Grid' : 'Show Grid'}
                                        size="large"
                                        onClick={toggleGridVisibility}
                                        disabled={!beeEditorRef.current || !isBeeLoaded}
                                    >
                                        {isGridVisible ? <GridOff /> : <GridOn />}
                                    </IconButton>
                                </span>
                            </Tooltip>
                            {sendTestButton}
                        </Stack>
                        <Stack direction="row" alignItems="center" columnGap={1} ml={1}>
                            <Button
                                disabled={!beeEditorRef.current}
                                onClick={() => beeEditorRef.current.save()}
                                variant="outlined"
                            >
                                Save & Exit
                            </Button>
                        </Stack>
                    </Stack>
                }
            />
        );
    };

    const shouldRenderToolbar = showCustomToolbar && !isDetailsActive;

    return (
        <>
            {shouldRenderToolbar && renderToolbar()}
            <div
                // The id is required to render the plugin.
                id="bee-plugin-container"
                data-test="bee-plugin-container"
                style={{
                    ...beeStyles,
                    display: isDetailsActive ? 'none' : 'block',
                }}
                className="beeContainer"
            />
            {isDetailsActive && (
                <ContentDetailsPage
                    beeEditorPreviewButton={beeEditorPreviewButton}
                    editEmailButton={editEmailButton}
                    isDetailsExitBlocked={isDetailsExitBlocked}
                    isDetailsTouched={isDetailsTouched}
                    setIsDetailsExitBlocked={setIsDetailsExitBlocked}
                    setIsDetailsTouched={setIsDetailsTouched}
                    setIsDetailsActive={setIsDetailsActive}
                    setIsDetailsSaveSuccess={setIsDetailsSaveSuccess}
                />
            )}
            {isDetailsSaveSuccess && <SuccessSnackbar successMessage="Send Details Saved." />}
        </>
    );
};

export default BeeEditor;
