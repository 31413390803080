import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import LegacyCurationNameInputWithState from '../LegacyCurationNameInput/LegacyCurationNameInputWithState';
import { ContentState, MetadataState } from 'domains/campaigns/types';
import { editMetadata } from 'domains/campaigns/state/actionCreators';
import EmailPreviewContent from 'domains/content/components/EmailPreviewContent';
import { isTemplate } from 'domains/content/types';
import ErrorSnackbar from 'domains/core/components/Snackbars/ErrorSnackbar';
import SuccessDialog from 'domains/core/components/SuccessDialog';
import useSenderProfile from 'hooks/queries/useSenderProfile';
import useTemplate from 'hooks/queries/useTemplate';
import { URLPaths } from 'models/enums';
import { onLeaveCurationWorkflow } from 'state/appVisual/appVisualActions';
import { route as routeSelector } from 'state/selectors';
import Flow from 'domains/core/components/Flow/Flow';
import { Box } from '@mui/material';
import useTemplates from 'hooks/queries/useTemplates';
import useSegments from 'hooks/queries/useSegments';
import { recordSatismeterEvent } from 'helpers/injectSatismeter/injectSatismeter';

export type Props = {
    ButtonContinue: JSX.Element;
    createCampaignError: Error;
    editMetaDataActionType: string;
    isCreateCampaignError: boolean;
    isCreateCampaignLoading: boolean;
    isSuccessDialogOpen: boolean;
    mainRoute: string;
    NavBar: JSX.Element;
    Navigation: JSX.Element;
    CampaignFlowRouteLeavingGuard: JSX.Element;
    resetCampaignActionType: string;
    campaignContentsSelector: (state: any) => ContentState[];
    campaignMetadataSelector: (state: any) => MetadataState;
    selectIsCampaignRouteDisabled: (state: any) => (route: URLPaths) => boolean;
    setIsSuccessDialogOpen: (isOpen: boolean) => void;
};

const CampaignFlow = ({
    ButtonContinue,
    createCampaignError,
    editMetaDataActionType,
    isCreateCampaignError,
    isCreateCampaignLoading,
    isSuccessDialogOpen,
    mainRoute,
    NavBar,
    Navigation,
    CampaignFlowRouteLeavingGuard,
    resetCampaignActionType,
    campaignContentsSelector,
    campaignMetadataSelector,
    selectIsCampaignRouteDisabled,
    setIsSuccessDialogOpen,
}: Props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const route = useSelector(routeSelector);
    const campaignContents = useSelector(campaignContentsSelector);
    const { curationName, previewContentId } = useSelector(campaignMetadataSelector);
    const isCurationRouteDisabled = useSelector(selectIsCampaignRouteDisabled);

    const {
        senderProfileId: previewSenderProfileId,
        templateId: previewTemplateId,
        preheader: previewPreheader,
        subjectLine: previewSubjectLine,
    } = useMemo(() => campaignContents.find((_, index) => index === previewContentId) || ({} as ContentState), [
        campaignContents,
        previewContentId,
    ]);

    // previewContentId can be 0 so must return true while checking for falsy values
    const isPreviewContentIdSet = previewContentId !== null && previewContentId >= 0;

    const { data: previewSenderProfile } = useSenderProfile(previewSenderProfileId, {
        enabled: Boolean(previewSenderProfileId),
    });

    const { data: previewTemplate } = useTemplate({
        templateId: `${previewTemplateId}`,
        reactQueryOptions: {
            enabled: isPreviewContentIdSet,
        },
    });

    useSegments(); // Pre-cache for upcoming form (CurationAudienceForm)
    useTemplates(); // Pre-cache for upcoming form (CurationContentForm)

    useEffect(() => {
        // If the user manually routes to a page that is disabled (i.e refreshes, or changes url), then redirect to the main curation page
        if (isCurationRouteDisabled(route)) {
            history.push(mainRoute);
            dispatch({ type: resetCampaignActionType });
        }

        // Close the preview so that on the next content form, the preview does not automatically open when a template is selected.
        if (route.includes(mainRoute)) {
            dispatch(editMetadata(editMetaDataActionType, { previewContentId: null }));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [route]);

    const handleExitCurationWorkflow = (path: string) => {
        history.push(path);
        dispatch(onLeaveCurationWorkflow());
        dispatch({ type: resetCampaignActionType });
        recordSatismeterEvent('publish_curation_popup');
    };

    return (
        <Flow
            feedback={
                <>
                    {CampaignFlowRouteLeavingGuard}
                    {isCreateCampaignError && !isCreateCampaignLoading && (
                        <ErrorSnackbar errorMessage={createCampaignError.message} />
                    )}
                    <SuccessDialog
                        title="high five!"
                        body="Nice work, you've successfully scheduled your Curation. Once it sends, visit Insights to monitor its performance."
                        isOpen={isSuccessDialogOpen}
                        setIsOpen={setIsSuccessDialogOpen}
                        onClose={() => setIsSuccessDialogOpen(false)}
                        buttonOneText="insights"
                        buttonTwoText="curations"
                        buttonOneOnClick={() => handleExitCurationWorkflow(URLPaths.INSIGHTS)}
                        buttonTwoOnClick={() => handleExitCurationWorkflow(URLPaths.CURATIONS)}
                    />
                </>
            }
            topLeft={
                <LegacyCurationNameInputWithState
                    actionType={editMetaDataActionType}
                    curationName={curationName}
                    isHidden={route === mainRoute}
                />
            }
            topRight={ButtonContinue}
            bottomLeft={Navigation}
            bottomRight={
                <>
                    <EmailPreviewContent
                        emailTemplateHTML={isTemplate(previewTemplate) && previewTemplate.html}
                        fromAddress={previewSenderProfile?.from_address}
                        isGridItem
                        isOpen={isPreviewContentIdSet}
                        preHeader={previewPreheader}
                        subjectLine={previewSubjectLine}
                        onClose={() => dispatch(editMetadata(editMetaDataActionType, { previewContentId: null }))}
                    />
                    <Box
                        data-testid={isPreviewContentIdSet ? 'preview' : ''}
                        display={isPreviewContentIdSet ? 'none' : 'block'}
                        height="100%"
                    >
                        {NavBar}
                    </Box>
                </>
            }
        />
    );
};

export default CampaignFlow;
