import BarGraph from 'pages/InsightsPage/visualizations/BarGraph';
import Card from 'pages/InsightsPage/dashboards/Card';
import useContactAge from './useContactAge';

const AgeCard = () => {
    const { data, isError, isLoading } = useContactAge();

    return (
        <Card isError={isError} isLoading={isLoading} label="Age">
            <BarGraph bottomLegendLabel="age range" data={data} leftLegendLabel="number of contacts" />
        </Card>
    );
};

export default AgeCard;
