import { Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useState } from 'react';
import { FilterCategory } from 'models/enums';
import Select, { Value } from 'domains/core/components/Select';
import { Send } from 'domains/campaigns/types';
import {
    RecordTypeFilter,
    SegmentFilter,
    SegmentFilterCategorySpecificField,
    SegmentFilterEngagementFields,
    SegmentFilterEventFields,
    SegmentFilterState,
} from 'domains/segments/types';
import ActivityFilter from './ActivityFilter';
import DemographicFilter from './DemographicFilter';
import EventFilter from './EventFilter';
import DeleteFilter from './DeleteFilter';
import IncludeNullValuesCheckbox from './IncludeNullValuesCheckbox';
import { useFlags } from 'launchdarkly-react-client-sdk';

const filterCategories: Value[] = Object.values(FilterCategory).map((category) => ({
    label: category,
    value: category,
}));
const matchTypes = ['AND', 'OR'];

export type Props = {
    eventRecordTypeFilters: RecordTypeFilter[];
    demographicFilters: SegmentFilter[];
    engagementFilters: SegmentFilter[];
    filter: SegmentFilterState<SegmentFilterCategorySpecificField>;
    hideMatchType: boolean;
    index: number;
    sends: Send[];
    value: any;
    handleChange: (e: any) => void;
    handleDatetimeError: (filterId: string, error: boolean) => void;
    removeFilter: (index: number) => void;
    updateFilter: (
        index: number,
        filter: SegmentFilterState<SegmentFilterCategorySpecificField>,
        resetPreview?: boolean
    ) => void;
};

const Filter = ({
    eventRecordTypeFilters,
    demographicFilters,
    engagementFilters,
    filter,
    hideMatchType,
    index,
    sends,
    value,
    handleChange,
    handleDatetimeError,
    removeFilter,
    updateFilter,
}: Props) => {
    const { nullValuesSegmentation: hasNullValuesSegmentation } = useFlags();

    const isCategoryActivity = Boolean(filter.category === FilterCategory.CONTACT_ACTIVITY);
    const isCategoryDetails = Boolean(filter.category === FilterCategory.CONTACT_DETAILS);
    const isCategoryEvent = Boolean(filter.category === FilterCategory.CONTACT_EVENTS);

    const [isFilterHighlighted, setIsFilterHighlighted] = useState(false);
    const [isOperatorValueHighlighted, setIsOperatorValueHighlighted] = useState(false);

    const updateSegmentFilter = (
        index: number,
        filter: SegmentFilterState<SegmentFilterCategorySpecificField>,
        resetPreview?: boolean
    ) => updateFilter(index, filter, resetPreview);

    return (
        <Stack>
            <Stack direction="row">
                <Stack
                    columnGap={2}
                    direction="row"
                    key={filter.id}
                    sx={{
                        ...(isFilterHighlighted && {
                            backgroundColor: 'action.hover',
                        }),
                        border: '1px solid',
                        borderColor: 'other.divider',
                        borderRadius: '6px',
                        padding: '32px 22px',
                    }}
                >
                    <Stack order="1" rowGap={1.5}>
                        <Select
                            data-test="category-select"
                            data-testid="category-select"
                            label="Filter type"
                            sx={{ textTransform: 'capitalize', width: '12rem' }}
                            value={filter.category ?? ''}
                            values={filterCategories}
                            onChange={(e: any) => {
                                updateSegmentFilter(index, {
                                    ...filter,
                                    category: e.target.value,
                                    categorySpecificFields: {
                                        value: '',
                                    },
                                    name: '',
                                    operator: null,
                                    autocompleteValue: null,
                                    autocompleteInputValue: '',
                                });
                            }}
                        />
                        {hasNullValuesSegmentation && isCategoryDetails && (
                            <IncludeNullValuesCheckbox
                                isNullValuesIncluded={filter.includeNullValues}
                                updateFilter={() => {
                                    updateSegmentFilter(index, {
                                        ...filter,
                                        includeNullValues: !filter.includeNullValues,
                                    });
                                }}
                            />
                        )}
                    </Stack>
                    {isCategoryActivity && (
                        <ActivityFilter
                            filter={filter as SegmentFilterState<SegmentFilterEngagementFields>}
                            index={index}
                            engagementFilters={engagementFilters}
                            sends={sends}
                            updateSegmentFilter={updateSegmentFilter}
                        />
                    )}
                    {isCategoryDetails && (
                        <DemographicFilter
                            filter={filter}
                            filterOptions={demographicFilters}
                            index={index}
                            handleDatetimeError={handleDatetimeError}
                            updateSegmentFilter={updateSegmentFilter}
                        />
                    )}
                    {isCategoryEvent && (
                        <EventFilter
                            eventRecordTypeFilters={eventRecordTypeFilters}
                            filter={filter as SegmentFilterState<SegmentFilterEventFields>}
                            index={index}
                            isOperatorValueHighlighted={isOperatorValueHighlighted}
                            handleDatetimeError={handleDatetimeError}
                            updateSegmentFilter={updateSegmentFilter}
                        />
                    )}
                    <DeleteFilter
                        filter={filter}
                        index={index}
                        isCategoryEvent={isCategoryEvent}
                        removeFilter={removeFilter}
                        setIsFilterHighlighted={setIsFilterHighlighted}
                        setIsOperatorValueHighlighted={setIsOperatorValueHighlighted}
                        updateSegmentFilter={updateSegmentFilter}
                    />
                </Stack>
            </Stack>
            {!hideMatchType && (
                <ToggleButtonGroup
                    aria-label="match type"
                    exclusive
                    onChange={handleChange}
                    style={{ margin: '16px' }}
                    value={value}
                >
                    {matchTypes.map((type) => (
                        <ToggleButton
                            aria-label={type}
                            key={type}
                            name="matchType"
                            sx={{ textTransform: 'lowercase' }}
                            value={type}
                        >
                            {type}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            )}
        </Stack>
    );
};

export default Filter;
