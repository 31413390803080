import { useState } from 'react';
import { useQueryClient } from 'react-query';
import TextField from 'domains/core/components/TextField';
import useUpdateCampaign from 'hooks/mutations/useUpdateCampaign';
import QueryKeys from 'hooks/queries/keys';
import { Campaign } from 'domains/campaigns/types';

type Props = {
    id: string;
    name: string;
    onSave?: () => void;
};

// todo: consolidate with CurationNameInput
const CampaignNameInput = ({ id, name, onSave }: Props) => {
    const queryClient = useQueryClient();
    const [value, setValue] = useState(name);
    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value);
    const { mutate } = useUpdateCampaign(id, {
        onMutate: async (newCampaign) => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(QueryKeys.CAMPAIGNS);

            // Snapshot the previous value
            const previousCampaigns: Campaign[] = queryClient.getQueryData(QueryKeys.CAMPAIGNS);

            // Optimistically update to the new value
            const newCampaigns = previousCampaigns.map((previousCampaign) =>
                previousCampaign.id === id ? { ...previousCampaign, name: value } : previousCampaign
            );
            queryClient.setQueryData(QueryKeys.CAMPAIGNS, () => newCampaigns);

            // Return a context object with the snapshotted value
            return { previousCampaigns };
        },
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, newCampaign, context) => {
            // @ts-ignore
            queryClient.setQueryData(QueryKeys.CAMPAIGNS, context.previousCampaigns);
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(QueryKeys.CAMPAIGNS);
        },
        onSuccess: onSave,
    });

    return (
        <TextField
            data-testid="update-name-input"
            withClickAway
            onCancel={() => setValue(name)}
            onClickAway={() => mutate({ name: value })}
            label="Curation name"
            value={value}
            onChange={handleNameChange}
            width="100%"
            focused
            hidePencilUntilHovered
        />
    );
};

export default CampaignNameInput;
