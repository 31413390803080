import { AttachMoney } from '@mui/icons-material';
import { Stack } from '@mui/material';
import TextField from 'domains/core/components/TextField';
import InputLabel from 'domains/core/components/InputLabel';

type Props = {
    appointmentValue: number;
    resetAppointmentValue: () => void;
    setAppointmentValue: (attendRate: number) => void;
};

const ValueCalculatorConversionValueForm = ({
    appointmentValue,
    resetAppointmentValue,
    setAppointmentValue,
}: Props) => (
    <Stack rowGap={4}>
        <TextField
            label={
                <InputLabel
                    label="Conversion value"
                    labelIconTooltipText="The value of the held appointment or completed event."
                />
            }
            width="100%"
            type="number"
            value={appointmentValue !== 0 ? appointmentValue : ''}
            onBlur={() => !appointmentValue && resetAppointmentValue()}
            onChange={(e) => setAppointmentValue(Number(e.target.value))}
            leadingIcon={<AttachMoney />}
        />
    </Stack>
);

export default ValueCalculatorConversionValueForm;
