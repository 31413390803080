import { DelayNode } from 'domains/campaigns/state/createCampaignSlice';
import { DateTime } from 'luxon';

export const getDelayValue = (timeDifference: number): number =>
    timeDifference % 24 ? timeDifference : timeDifference / 24;

export const getDelayUnit = (timeDifference: number): string => (timeDifference % 24 ? 'hours' : 'days');

export const getTimeDifferenceInHours = (firstTime: string, secondTime: string): number => {
    const firstDate = DateTime.fromISO(firstTime);
    const secondDate = DateTime.fromISO(secondTime);

    // Handle small rounding differences by calculating the time difference then rounding it.
    const timeDifference = parseFloat(Math.abs(secondDate.diff(firstDate, 'hours').as('hours')).toFixed(2));

    return timeDifference;
};

export const getTimeDifference = (firstTime: string, secondTime: string) => {
    const timeDifference = getTimeDifferenceInHours(firstTime, secondTime);

    if (timeDifference === 1) {
        return '1 hour';
    } else if (timeDifference < 24) {
        return `${timeDifference} hours`;
    } else if (timeDifference === 24) {
        return 'one day';
    } else if (timeDifference > 24) {
        let timeString = '';

        const dayCount = Math.floor(timeDifference / 24);

        timeString += `${dayCount}`;
        if (dayCount === 1) {
            timeString += ' day';
        } else {
            timeString += ' days';
        }

        const hourCount = timeDifference % 24;
        if (hourCount === 0) {
            return timeString;
        } else if (hourCount === 1) {
            timeString += ' and 1 hour';
        } else if (hourCount > 1) {
            timeString += ` and ${hourCount} hours`;
        } else {
            return timeString;
        }

        return timeString;
    } else {
        return '';
    }
};

export const getDelayedSendTime = (timestamp: string, delayFields: DelayNode) => {
    const { delay, unit } = delayFields;
    const delayUnit = unit.slice(0, -1);
    const delayValue = delay;

    return DateTime.fromISO(timestamp || '')
        .plus({ [delayUnit]: delayValue })
        .toISO();
};

export const getFormattedParentAudienceDelayInterval = (delay: number | string, unit: string) =>
    !!delay && !!unit ? `${delay} ${unit.slice(0, -1)}` : null;
