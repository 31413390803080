import { useState } from 'react';
import { Props as ContactsUploadDialogProps } from 'domains/contacts/components/ContactsUploadDialog';
import { Props as FileUploadButtonProps } from 'domains/contacts/components/FileUploadButton';
import useConfig from 'hooks/useConfig';
import useGetAxiosInstance from 'hooks/useGetAxiosInstance';
import { ApiMethods, CuredApiPaths } from 'models/enums';
import { AxiosError } from 'axios';

type ErrorResponse = {
    message?: string;
    errorCode?: string;
};

/**
 * Returns the props for ContactsUploadDialog and FileUploadButton.
 */
const useContactsFileUploadProps = (): ContactsUploadDialogProps & FileUploadButtonProps => {
    const [snackbarKey, setSnackbarKey] = useState(0);
    const [fileUploadError, setIsFileUploadError] = useState('');
    const [fileUploadFinish, setIsFileUploadFinish] = useState(false);
    const [isFileUploadDialogOpen, setIsFileUploadDialogOpen] = useState(false);
    const [dataCategory, setDataCategory] = useState('');
    const [isFileUploading, setIsFileUploading] = useState(false);
    const {
        config: { API_URL },
    } = useConfig();
    const getApiInstance = useGetAxiosInstance(ApiMethods.POST);

    const dataUploadFinishMessage =
        'We have successfully received your data. Valid uploads will process within two hours. If there are any issues, you will be notified.';
    const otherDataUploadFinishMessage =
        'We have successfully received your data. Your Cured contact will be in touch with timelines.';

    const fileUploadFinishMessage = dataCategory === 'other' ? otherDataUploadFinishMessage : dataUploadFinishMessage;

    const handleSetIsFileUploadDialogOpen = (isOpen: boolean) => {
        if (isOpen) {
            setIsFileUploadError('');
            setIsFileUploadFinish(false);
            setSnackbarKey(snackbarKey + 1);
            setDataCategory('');
        }
        setIsFileUploadDialogOpen(isOpen);
    };

    const handleUpload = async (uploader: any, s3Path: string) => {
        const file = uploader.current.files[0];

        if (!file) {
            setIsFileUploadError('No file selected');
            handleSetIsFileUploadDialogOpen(false);
            return;
        }
        const formData = new FormData();

        formData.append('fileObject', file);
        formData.append('s3Path', s3Path);

        setIsFileUploading(true);
        handleSetIsFileUploadDialogOpen(false);

        const apiInstance = await getApiInstance();
        const headers = {
            'Content-Type': 'multipart/form-data',
        };

        try {
            const response = await apiInstance.post(`${API_URL}${CuredApiPaths.TOKENS_UPLOAD}`, formData, {
                headers,
            });

            if (response.status === 200) {
                setIsFileUploadFinish(true);
            }

            setIsFileUploading(false);
        } catch (error) {
            setIsFileUploading(false);
            const errorMessage =
                ((error as AxiosError).response?.data as ErrorResponse)?.message ||
                'Unexpected error occured during file upload';
            setIsFileUploadError(errorMessage);
        }
    };

    return {
        dataCategory,
        fileUploadError,
        fileUploadFinish,
        fileUploadFinishMessage,
        handleUpload,
        isFileUploadDialogOpen,
        isFileUploading,
        setDataCategory,
        setIsFileUploadDialogOpen: handleSetIsFileUploadDialogOpen,
        setIsFileUploadError,
        setIsFileUploadFinish,
        snackbarKey,
    };
};

export default useContactsFileUploadProps;
