import { CustomCampaignSteps } from 'domains/campaigns/components/CustomCampaign/CustomCampaign';
import { StateTypes } from 'domains/campaigns/components/CustomCampaign/customCampaignReducer';
import CustomCampaignAudienceStep from './steps/CustomCampaignAudienceStep';
import CustomCampaignContentStep from './steps/CustomCampaignContentStep';
import CustomCampaignNameStep from './steps/CustomCampaignNameStep';
import CustomCampaignScheduleStep from './steps/CustomCampaignScheduleStep';
import CustomCampaignChooseNextStep from './steps/CustomCampaignChooseNextStep';
import CustomCampaignPublishStep from './steps/CustomCampaignPublishStep';
import CustomCampaignTimeDelayStep from './steps/CustomCampaignTimeDelayStep';
import CustomCampaignChooseSendTypeStep from './steps/CustomCampaignChooseSendTypeStep';
import CustomCampaignSplitCriteriaStep from './steps/CustomCampaignSplitCriteriaStep';
import CustomCampaignChooseSplitAction from './steps/CustomCampaignChooseSplitAction';

type Props = {
    activeSendIndex: number;
    activeStep: CustomCampaignSteps;
    campaignState: StateTypes;
    contentPreviewId?: number;
    dispatchCampaignAction: React.Dispatch<any>;
    setActiveSendIndex: (index: number) => void;
    setActiveStep: (step: CustomCampaignSteps) => void;
    setContentPreviewId: (id: number) => void;
    setIsContinueEnabled: (isContinueEnabled: boolean) => void;
    setNextStep: (step: CustomCampaignSteps) => void;
};

const CustomCampaignForm = ({
    activeSendIndex,
    activeStep,
    campaignState,
    contentPreviewId,
    dispatchCampaignAction,
    setActiveSendIndex,
    setActiveStep,
    setContentPreviewId,
    setIsContinueEnabled,
    setNextStep,
}: Props) => {
    switch (activeStep) {
        case CustomCampaignSteps.NAME_CAMPAIGN:
            return (
                <CustomCampaignNameStep
                    campaignState={campaignState}
                    dispatchCampaignAction={dispatchCampaignAction}
                    setIsContinueEnabled={setIsContinueEnabled}
                    setNextStep={setNextStep}
                />
            );
        case CustomCampaignSteps.SELECT_AUDIENCE:
            return (
                <CustomCampaignAudienceStep
                    campaignState={campaignState}
                    dispatchCampaignAction={dispatchCampaignAction}
                    setIsContinueEnabled={setIsContinueEnabled}
                    setNextStep={setNextStep}
                />
            );
        case CustomCampaignSteps.SELECT_CONTENT:
            return (
                <CustomCampaignContentStep
                    activeSendIndex={activeSendIndex}
                    campaignState={campaignState}
                    contentPreviewId={contentPreviewId}
                    dispatchCampaignAction={dispatchCampaignAction}
                    setContentPreviewId={setContentPreviewId}
                    setIsContinueEnabled={setIsContinueEnabled}
                    setNextStep={setNextStep}
                />
            );
        case CustomCampaignSteps.SCHEDULE_CONTENT:
            return (
                <CustomCampaignScheduleStep
                    activeSendIndex={activeSendIndex}
                    campaignState={campaignState}
                    dispatchCampaignAction={dispatchCampaignAction}
                    setIsContinueEnabled={setIsContinueEnabled}
                    setNextStep={setNextStep}
                />
            );
        case CustomCampaignSteps.CHOOSE_NEXT_STEP:
            return (
                <CustomCampaignChooseNextStep
                    activeSendIndex={activeSendIndex}
                    campaignState={campaignState}
                    dispatchCampaignAction={dispatchCampaignAction}
                    setActiveSendIndex={setActiveSendIndex}
                    setActiveStep={setActiveStep}
                />
            );
        case CustomCampaignSteps.SET_TIME_DELAY:
            return (
                <CustomCampaignTimeDelayStep
                    activeSendIndex={activeSendIndex}
                    campaignState={campaignState}
                    dispatchCampaignAction={dispatchCampaignAction}
                    setIsContinueEnabled={setIsContinueEnabled}
                    setNextStep={setNextStep}
                />
            );
        case CustomCampaignSteps.CHOOSE_SEND_TYPE:
            return (
                <CustomCampaignChooseSendTypeStep
                    activeSendIndex={activeSendIndex}
                    campaignState={campaignState}
                    dispatchCampaignAction={dispatchCampaignAction}
                    setActiveStep={setActiveStep}
                />
            );
        case CustomCampaignSteps.CONDITIONAL_SPLIT:
            return (
                <CustomCampaignSplitCriteriaStep
                    activeSendIndex={activeSendIndex}
                    campaignState={campaignState}
                    dispatchCampaignAction={dispatchCampaignAction}
                    setIsContinueEnabled={setIsContinueEnabled}
                    setNextStep={setNextStep}
                />
            );
        case CustomCampaignSteps.SELECT_PATH_ACTION:
            return (
                <CustomCampaignChooseSplitAction
                    activeSendIndex={activeSendIndex}
                    campaignState={campaignState}
                    dispatchCampaignAction={dispatchCampaignAction}
                    setActiveSendIndex={setActiveSendIndex}
                    setActiveStep={setActiveStep}
                />
            );
        case CustomCampaignSteps.PUBLISH_CAMPAIGN:
            return <CustomCampaignPublishStep />;
        default:
            return <div>This step is invalid!</div>;
    }
};

export default CustomCampaignForm;
