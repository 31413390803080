import { Stack } from '@mui/material';
import CurationAudienceDropdown from 'domains/campaigns/components/CurationAudienceDropdown';
import { CampaignChannels } from 'domains/campaigns/types';

type Props = {
    editAudience: (audienceId: number) => void;
    segmentId: number;
};

const CurationAudienceForm = ({ editAudience, segmentId }: Props) => (
    <Stack alignItems="center">
        <CurationAudienceDropdown audienceId={segmentId} channel={CampaignChannels.EMAIL} editAudience={editAudience} />
    </Stack>
);
export default CurationAudienceForm;
