import { useSelector } from 'react-redux';
import { Location } from 'history';
import RouteLeavingGuard, { DialogProps } from 'domains/core/components/RouteLeavingGuard';
import { URLPaths } from 'models/enums';
import { selectIsValueFlowComplete } from 'domains/value/state/selectors';

const dialogProps: DialogProps = {
    title: 'Changes will be lost',
    contentText: "This will take you out of the value calculation process and any changes you've made will be lost.",
};

const ValueFlowRouteLeavingGuard = () => {
    const valueFlowComplete = useSelector(selectIsValueFlowComplete);

    const shouldBlockNavigation = (location: Location): boolean => {
        const userTryingToNavigateOutOfValueFlow = !location.pathname.includes(URLPaths.VALUE_CREATE);
        return userTryingToNavigateOutOfValueFlow && !valueFlowComplete;
    };

    return <RouteLeavingGuard dialogProps={dialogProps} shouldBlockNavigation={shouldBlockNavigation} />;
};

export default ValueFlowRouteLeavingGuard;
