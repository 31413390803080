import { Checkbox, FormControlLabel } from '@mui/material';
import { FilterValuesState } from '../types';

type Props = {
    filterValues: FilterValuesState;
    setFilterValues: (filters: FilterValuesState) => void;
};

const IncludeUnsubsFilter = ({ filterValues, setFilterValues }: Props) => (
    <FormControlLabel
        control={
            <Checkbox
                checked={filterValues.includesUnsubscribes}
                color="secondary"
                size="small"
                onChange={(e) =>
                    setFilterValues({
                        ...filterValues,
                        includesUnsubscribes: e.target.checked,
                    })
                }
            />
        }
        label="Include unsubscribes"
        sx={{
            // Accounting for the 9px padding around the checkbox
            margin: '-9px',
            '.MuiFormControlLabel-label': {
                fontSize: '1rem',
            },
        }}
    />
);

export default IncludeUnsubsFilter;
