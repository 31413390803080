import { CurationNavState, URLPaths } from 'models/enums';
import { Action } from 'models/types';
import { ROUTE_CHANGE } from 'state/constants';
import * as actionTypes from 'domains/campaigns/state/actionTypes';
import { getCurationNavState } from 'domains/campaigns/state/utils';
import { SegmentsState } from 'domains/campaigns/types';

export const initialState: SegmentsState = {
    segments: [
        {
            name: null,
            operator: null,
            value: null,
        },
    ],
    isComplete: false,
    navState: CurationNavState.PREVIEW,
};

export const isFormComplete = (state: SegmentsState) =>
    Boolean(state.segments[0].name) && Boolean(state.segments[0].operator);

const isFormInProgress = (state: SegmentsState) =>
    Boolean(state.segments[0].name) || Boolean(state.segments[0].operator);

export const segmentsReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case actionTypes.CONDITIONAL_SPLIT_EDIT_SEGMENTS:
            const stateAfterEdit = {
                ...state,
                segments: action.payload.segments,
            };
            return {
                ...stateAfterEdit,
                navState: getCurationNavState(
                    isFormComplete(stateAfterEdit),
                    isFormInProgress(stateAfterEdit),
                    URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS,
                    undefined,
                    URLPaths.CURATIONS_CONDITIONAL_SPLIT
                ),
                isComplete: isFormComplete(stateAfterEdit),
            };
        case ROUTE_CHANGE:
            return {
                ...state,
                navState: getCurationNavState(
                    isFormComplete(state),
                    isFormInProgress(state),
                    URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS,
                    action,
                    URLPaths.CURATIONS_CONDITIONAL_SPLIT
                ),
            };
        case actionTypes.CONDITIONAL_SPLIT_RESET_CURATION:
            return initialState;
        default:
            return state;
    }
};

export default segmentsReducer;
