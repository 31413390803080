import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CallSplit } from '@mui/icons-material';
import { CurationSegmentsAction, CurationStep } from 'models/enums';
import { SegmentsActionState, EditSegmentsActionPayload } from 'domains/campaigns/types';
import FormLayout, { Props as FormLayoutProps } from 'domains/core/components/FormLayout';
import {
    editSegmentsAction as editSegmentsActionActionCreator,
    editContent as editContentActionCreator,
} from 'domains/campaigns/state/actionCreators';
import { initialState as initialContentState } from 'domains/campaigns/state/conditionalSplit/content/contentReducer';
import CurationSegmentsActionForm from './CurationSegmentsActionForm';

type Props = {
    segmentsActionSelector: (state: any) => SegmentsActionState;
    editSegmentsActionActionType: string;
    editContentActionType: string;
    formLayoutProps?: Partial<FormLayoutProps>;
};

const CurationSegmentsActionPage = ({
    segmentsActionSelector,
    editSegmentsActionActionType,
    editContentActionType,
    formLayoutProps,
}: Props) => {
    const dispatch = useDispatch();
    const segmentsActionState = useSelector(segmentsActionSelector);

    const editSegmentsAction = (segmentsAction: EditSegmentsActionPayload) => {
        dispatch(editSegmentsActionActionCreator(editSegmentsActionActionType, segmentsAction));
    };

    useEffect(() => {
        if (segmentsActionState.action === CurationSegmentsAction.EXIT_CURATION) {
            dispatch(editContentActionCreator(editContentActionType, initialContentState));
        }
    }, [dispatch, editContentActionType, segmentsActionState.action]);

    return (
        <FormLayout
            header="select path action"
            icon={<CallSplit />}
            stepName={CurationStep.SEGMENTS}
            guidingText="Choose the next step for your audience. Keeping your Curation goals in mind, select the action that supports your objective."
            {...formLayoutProps}
        >
            <CurationSegmentsActionForm
                segmentsActionState={segmentsActionState}
                editSegmentsAction={editSegmentsAction}
            />
        </FormLayout>
    );
};

export default CurationSegmentsActionPage;
