import { Grid, Typography } from '@mui/material';
import { ReactNode } from 'react';
import Button from 'domains/core/components/Button';

const wrapperStyles = {
    border: '1px solid',
    borderColor: 'other.divider',
    borderRadius: 1,
    padding: 3,
};

type Props = {
    children: ReactNode;
    disabled?: boolean;
    handleApplyFilters: () => void;
};

const FiltersWrapper = ({ children, disabled, handleApplyFilters }: Props) => (
    <Grid item xs={12} sx={wrapperStyles}>
        <Typography variant="body1">Dashboard Filters</Typography>
        {children}
        <Button disabled={disabled} variant="outlined" onClick={handleApplyFilters}>
            Apply filters
        </Button>
    </Grid>
);

export default FiltersWrapper;
