import Card from 'pages/InsightsPage/dashboards/Card';
import LineGraph from 'pages/InsightsPage/visualizations/LineGraph';
import useContactSubscribes from './useContactSubs';

const SubscribersOverTimeCard = () => {
    const { data, isError, isLoading } = useContactSubscribes();

    return (
        <Card isError={isError} isLoading={isLoading} label="Email Subscribers over Time">
            <LineGraph
                data={[data]}
                leftLegendLabel="new subscribers"
                tickValues="every 2 months"
                tooltipLabel="rolling sum"
            />
        </Card>
    );
};

export default SubscribersOverTimeCard;
