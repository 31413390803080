import { CuredApiPaths } from '../../models/enums';
import { UseMutationOptions } from 'react-query';
import { PathWithID } from '../../models/types';
import { ApiMethods } from '../../models/enums';
import useMutation from '../useMutation';
import MutationKeys from './keys';

const useDeleteCampaign = (campaignId: string, mutateOptions?: UseMutationOptions<unknown, Error>) =>
    useMutation(
        MutationKeys.DELETE_CAMPAIGN,
        new PathWithID(CuredApiPaths.CAMPAIGNS, campaignId),
        mutateOptions,
        ApiMethods.DELETE
    );

export default useDeleteCampaign;
