import { ResponsiveBar } from '@nivo/bar';
import { format } from 'd3';
import { graphColors } from 'colors';
import CustomTooltip from '../CustomTooltip';
import NoDataMessage from '../NoDataMessage';

type DataItem = {
    id: string | number;
    label?: string;
    value: number;
};

export type BarGraphProps = {
    bottomLegendLabel?: string;
    data: DataItem[];
    leftLegendLabel: string;
    useSingleColor?: boolean;
    valueFormat?: string;
};

const BarGraph = ({ bottomLegendLabel, data, leftLegendLabel, useSingleColor = true, valueFormat }: BarGraphProps) => {
    const hasNonZeroData = data?.map((datum) => datum.value).some((datum) => datum !== 0);
    if (!data || !hasNonZeroData) return <NoDataMessage />;

    const dataWithColors = data.map((datum: DataItem, index: number) => ({
        ...datum,
        color: useSingleColor ? graphColors[0] : graphColors[index],
    }));

    const getFormattedValue = (value: any) => {
        if (valueFormat === 'currency') return `$${value}`;

        if (typeof value === 'number') {
            if (value.toString().length > 5) return format('~s')(value);
            return value.toLocaleString();
        }

        return value;
    };

    const renderTooltip = (datum: any) => (
        <CustomTooltip
            color={datum.color}
            label={`${bottomLegendLabel ? `${bottomLegendLabel}: ` : ''}${datum.indexValue}`}
            value={`${leftLegendLabel}: ${getFormattedValue(datum.value)}`}
        />
    );

    return (
        <div style={{ height: '400px', width: '100%' }}>
            <ResponsiveBar
                axisBottom={{
                    legend: bottomLegendLabel,
                    legendOffset: bottomLegendLabel ? 40 : 0,
                    legendPosition: 'middle',
                    tickSize: 0,
                }}
                axisLeft={{
                    tickSize: 0,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: leftLegendLabel,
                    legendPosition: 'middle',
                    legendOffset: -50,
                    truncateTickAt: 0,
                    format: (value) => getFormattedValue(value),
                }}
                borderWidth={2}
                borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.2]],
                }}
                colors={{ datum: 'data.color' }}
                data={dataWithColors}
                enableGridY={false}
                label={(label) => getFormattedValue(label.value)}
                margin={{
                    top: 16,
                    right: 16,
                    bottom: bottomLegendLabel ? 60 : 32,
                    left: 70,
                }}
                tooltip={(datum) => renderTooltip(datum)}
            />
        </div>
    );
};

export default BarGraph;
