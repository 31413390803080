import { CurationNavState, URLPaths } from 'models/enums';
import { Action } from 'models/types';
import { ROUTE_CHANGE } from 'state/constants';
import * as actionTypes from 'domains/campaigns/state/actionTypes';
import { getCurationNavState } from 'domains/campaigns/state/utils';
import { SegmentsActionState } from 'domains/campaigns/types';

export const initialState: SegmentsActionState = {
    navState: CurationNavState.PREVIEW,
    isComplete: false,
    action: null,
};

export const isFormComplete = (state: SegmentsActionState) => Boolean(state.action);

export const segmentsMeetsNotActionReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case actionTypes.CONDITIONAL_SPLIT_EDIT_SEGMENTS_MEETS_NOT_ACTION:
            const stateAfterEdit = {
                ...state,
                action: action.payload.action,
            };
            const isComplete = isFormComplete(stateAfterEdit);
            return {
                ...stateAfterEdit,
                navState: getCurationNavState(
                    isComplete,
                    false,
                    URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS_MEETS_NOT_ACTION,
                    undefined,
                    URLPaths.CURATIONS_CONDITIONAL_SPLIT
                ),
                isComplete: isComplete,
            };
        case ROUTE_CHANGE:
            return {
                ...state,
                navState: getCurationNavState(
                    isFormComplete(state),
                    false,
                    URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS_MEETS_NOT_ACTION,
                    action,
                    URLPaths.CURATIONS_CONDITIONAL_SPLIT
                ),
            };
        case actionTypes.CONDITIONAL_SPLIT_RESET_CURATION:
            return initialState;
        default:
            return state;
    }
};

export default segmentsMeetsNotActionReducer;
