import { UseQueryOptions } from 'react-query';
import useQuery from 'hooks/useQuery';
import { CuredApiPaths, SortDirection } from 'models/enums';
import QueryKeys from './keys';

type UseContactsParams = {
    downloadCSV?: Boolean;
    options?: {
        limit?: string;
        sortColumn?: string;
        sortDirection?: SortDirection;
        segmentId?: number;
    };
    reactQueryOptions?: UseQueryOptions<unknown[], Error>;
};

const useContacts = ({ downloadCSV = false, options = {}, reactQueryOptions }: UseContactsParams = {}) => {
    const { limit, sortColumn, sortDirection, segmentId } = options;

    const queryKey = downloadCSV ? QueryKeys.CONTACTS_DOWNLOAD(segmentId) : QueryKeys.CONTACTS(segmentId);

    const params = {
        ...(segmentId && { segmentId }),
        ...(sortColumn && sortDirection && { sort: `${sortColumn}:${sortDirection}` }),
        ...(limit && { limit }),
    };

    const headers = {
        ...(downloadCSV && { Accept: 'text/csv' }),
    };

    const axiosOptions = {
        ...(Object.keys(params).length && { params }),
        ...(Object.keys(headers).length && { headers }),
    };

    return useQuery(queryKey, CuredApiPaths.CONTACTS, reactQueryOptions, axiosOptions);
};

export default useContacts;
