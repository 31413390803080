import { useState } from 'react';
import { Campaign } from 'domains/campaigns/types';
import EmailPreviewContent from 'domains/content/components/EmailPreviewContent';
import useSenderProfile from 'hooks/queries/useSenderProfile';
import CampaignDetailsLayout from '../CampaignDetailsLayout';
import CurationListAndFirstAudience from '../CurationListAndFirstAudience';
import CustomCampaignDetails from './CustomCampaignDetails/CustomCampaignDetails';
import CustomCampaignDetailsNavigation from './CustomCampaignDetailsNavigation';

type Props = {
    campaign: Campaign;
};

const CustomCampaignDetailsPage = ({ campaign }: Props) => {
    const [contentPreviewId, setContentPreviewId] = useState<number | null>();
    const closePreview = () => setContentPreviewId(null);

    const previewContent = !!contentPreviewId && campaign.sends.find((send) => send.id === contentPreviewId);

    const isPreviewOpen = Boolean(previewContent);

    const { data: previewContentSenderProfile } = useSenderProfile(previewContent?.emailSenderProfileId, {
        enabled: isPreviewOpen,
    });

    return (
        <CampaignDetailsLayout
            left={
                <>
                    <CurationListAndFirstAudience campaign={campaign} onChange={closePreview} />
                    <CustomCampaignDetails
                        campaign={campaign}
                        contentPreviewNodeId={contentPreviewId}
                        handlePreviewClick={setContentPreviewId}
                    />
                </>
            }
            right={
                <>
                    <EmailPreviewContent
                        emailTemplateHTML={previewContent?.contentSnapshot}
                        fromAddress={previewContentSenderProfile?.from_address}
                        isGridItem
                        isOpen={isPreviewOpen}
                        preHeader={previewContent?.preHeader}
                        subjectLine={previewContent?.subjectLine}
                        onClose={closePreview}
                    />
                    <CustomCampaignDetailsNavigation campaign={campaign} isPreviewOpen={isPreviewOpen} />
                </>
            }
        />
    );
};

export default CustomCampaignDetailsPage;
