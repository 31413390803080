import axios, { AxiosInstance, Method } from 'axios';
import useConfig from 'hooks/useConfig';
import { useCustomAuth } from 'auth/AuthContext';
import { AuthConstants } from 'models/enums';

/**
 * Gets a function to get the axios instance.
 * @param method the http method
 * @param options the axios options
 * @param authenticated whether or not to get a token and pass the Authorization header
 * @returns an async function that returns an axios instance
 */
const useGetAxiosInstance = (method: Method, options: any = {}, authenticated: boolean = true) => {
    const { getAccessTokenSilently, loginWithRedirect, isInnovaccerLogin, user, logout } = useCustomAuth();
    const {
        config: { API_URL },
    } = useConfig();

    const getApiInstance = async (): Promise<AxiosInstance> => {
        let accessToken;
        let tenantId;

        if (authenticated) {
            try {
                accessToken = await getAccessTokenSilently();
                if (isInnovaccerLogin) {
                    tenantId = user[AuthConstants.UAM_TENANT_ID];
                }
            } catch (e) {
                if ((e as any).error === 'login_required') {
                    loginWithRedirect();
                }
                if ((e as any).error === 'consent_required') {
                    loginWithRedirect();
                }
                throw e;
            }
        }

        const instance = axios.create({
            method,
            baseURL: API_URL,
            headers: {
                'Content-Type': 'application/json',
                ...(authenticated && { Authorization: `Bearer ${accessToken}` }),
                ...(isInnovaccerLogin && {
                    [AuthConstants.INNOVACCER_TENANT_HEADER]: tenantId,
                }),
                ...options?.headers,
            },
        });

        instance.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response.status === 401 && isInnovaccerLogin) {
                    logout({ returnTo: AuthConstants.SESSION_EXPIRED });
                }
                return Promise.reject(error);
            }
        );
        return instance;
    };
    return getApiInstance;
};

export default useGetAxiosInstance;
