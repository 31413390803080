import { useDispatch } from 'react-redux';
import CurationNameInput from './CurationNameInput';
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';

type Props = {
    editNameActionCreator: ActionCreatorWithOptionalPayload<string, string>;
    curationName: string;
    isHidden: boolean;
};

const CurationNameInputWithState = ({ curationName, editNameActionCreator, isHidden }: Props) => {
    const dispatch = useDispatch();
    const editName = (name: string) => dispatch(editNameActionCreator(name));

    return <CurationNameInput curationName={curationName} editName={editName} isHidden={isHidden} />;
};

export default CurationNameInputWithState;
