import { InfoOutlined } from '@mui/icons-material';
import { Stack, Tooltip } from '@mui/material';

export type Props = {
    label: React.ReactNode;
    labelIcon?: React.ReactNode;
    labelIconTooltipText?: React.ReactNode;
};

const InputLabel = ({ label, labelIcon = <InfoOutlined fontSize="inherit" />, labelIconTooltipText }: Props) =>
    labelIconTooltipText ? (
        <Stack direction="row" alignItems="center" columnGap={0.75}>
            {label}
            <Tooltip
                title={labelIconTooltipText}
                sx={{
                    // Setting pointerEvents: 'auto' allows the Tooltip to show on hover if it's used in the label.
                    pointerEvents: 'auto',
                }}
            >
                <Stack sx={{ fontSize: 14, marginTop: 0.5 }}>{labelIcon ?? <InfoOutlined fontSize="inherit" />}</Stack>
            </Tooltip>
        </Stack>
    ) : (
        <>{label}</>
    );

export default InputLabel;
