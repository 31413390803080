import { combineReducers } from 'redux';
import conditionalSplitReducer from './conditionalSplit';
import smsReducer from './sms';
import emailSeries from './emailSeries';
import singleSend from './singleSend';

const curationReducer = combineReducers({
    conditionalSplit: conditionalSplitReducer,
    singleSend: singleSend,
    sms: smsReducer,
    emailSeries: emailSeries,
    // todo add campaignDetails from createSlice
});

export default curationReducer;
