import { useEffect } from 'react';
import { CuredApiPaths } from 'models/enums';
import Card from 'pages/InsightsPage/dashboards/Card';
import { FiltersForApiState } from 'pages/InsightsPage/dashboards/filtering/types';
import useFilteredData from 'pages/InsightsPage/dashboards/filtering/useFilteredData';
import LineGraph from 'pages/InsightsPage/visualizations/LineGraph';
import CustomTooltip from 'pages/InsightsPage/visualizations/CustomTooltip';

type Props = {
    filtersForApi: FiltersForApiState;
};

const UniqueClicksByDayCard = ({ filtersForApi }: Props) => {
    const { getData, isError, isLoading, data } = useFilteredData({
        key: 'value-overview-dashboard/stats',
        path: CuredApiPaths.REPORT_VALUE_UNIQUE_CLICKS,
    });

    useEffect(() => {
        getData(filtersForApi);
    }, [filtersForApi, getData]);

    const bottomLegendLabel = 'days from send';
    const leftLegendLabel = 'cumulative unique clicks';

    return (
        <Card
            isError={isError}
            isLoading={isLoading}
            label="Unique Clicks by Days from Send"
            tooltip="Qualification criteria showcasing 90 days from Curation send date"
        >
            <LineGraph
                bottomLegendLabel={bottomLegendLabel}
                data={[data]}
                leftLegendLabel={leftLegendLabel}
                renderCustomTooltip={(point: any) => (
                    <CustomTooltip
                        color={point.serieColor}
                        label={`${bottomLegendLabel}: ${point.data.x}`}
                        value={`${leftLegendLabel}: ${point.data.y}`}
                    />
                )}
                scale="linear"
            />
        </Card>
    );
};

export default UniqueClicksByDayCard;
