import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import CircularProgress from '@mui/material/CircularProgress';
import { Stack } from '@mui/material';
import { useInnovaccerLogin } from 'auth/AuthContext';
import axios from 'axios';
import useConfig from 'hooks/useConfig';
import { AuthConstants, CuredApiPaths } from 'models/enums';
import InnovaccerLoginError from './InnovaccerLoginError';

const InnovaccerLogin = () => {
    const { tenantId, authCode } = useParams<{ tenantId: string; authCode: string }>();

    const { saveInnovaccerCreds } = useInnovaccerLogin();

    const [innovaccerLoginError, setInnovaccerLoginError] = useState(false);
    const [innovaccerRedirectUrl, setInnovaccerRedirectUrl] = useState('');

    const isSessionExpired = authCode === AuthConstants.SESSION_EXPIRED;

    const {
        config: { API_URL },
    } = useConfig();

    useEffect(() => {
        async function triggerLogin() {
            try {
                const userLoginDetails: any = await axios.post(`${API_URL}${CuredApiPaths.INNOVACCER_LOGIN}`, {
                    tenantId,
                    authCode,
                });

                if (userLoginDetails.status === 200) {
                    const loginData = userLoginDetails.data;

                    const accessToken = loginData[AuthConstants.ACCESS_TOKEN];
                    const redirectUrl = loginData[AuthConstants.REDIRECT_URL];
                    const uamTenantId = loginData[AuthConstants.UAM_TENANT_ID];
                    const user = loginData[AuthConstants.USER];

                    user[AuthConstants.UAM_TENANT_ID] = uamTenantId;
                    user[AuthConstants.REDIRECT_URL] = redirectUrl;

                    saveInnovaccerCreds(accessToken, user);
                } else {
                    setInnovaccerLoginError(true);
                }
            } catch (err) {
                setInnovaccerRedirectUrl((err as any).response?.data?.redirectUrl ?? '');
                setInnovaccerLoginError(true);
            }
        }
        triggerLogin();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderScreen = () => {
        if (innovaccerLoginError) {
            return <InnovaccerLoginError redirectUrl={innovaccerRedirectUrl} isSessionExpired={isSessionExpired} />;
        }
        return <CircularProgress />;
    };

    return (
        <Stack direction="column" justifyContent="center" alignItems="center" flex={1}>
            {renderScreen()}
        </Stack>
    );
};

export default InnovaccerLogin;
