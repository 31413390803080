import { CuredApiPaths } from '../../models/enums';
import { UseMutationOptions } from 'react-query';
import useMutation from '../useMutation';
import MutationKeys from './keys';

export type ValueAssumptionInput = {
    appointmentValue: number; // (0 - 1000000)
    attendRate: number; // (0-1) eg: 0.01
    clickUrl: string;
    contactEventRecordType: string;
    scheduleRate: number; // (0-1) eg: 0.01
    sendId: number;
    specialty: string;
    uniqueClickRate: number; // (0-1) eg: 0.01
};

/**
 * Creates a click appointment value assumption.
 * @param mutateOptions react-query useMutation option
 * @returns the react-query useMutation results
 * See https://react-query-v3.tanstack.com/reference/useMutation for options and return value
 */
const useCreateClickAppointmentValueAssumption = (
    mutateOptions?: UseMutationOptions<unknown, Error, ValueAssumptionInput>
) =>
    useMutation<ValueAssumptionInput>(
        MutationKeys.CREATE_CLICK_APPOINTMENT_VALUE_ASSUMPTION,
        CuredApiPaths.CLICK_APPOINTMENT_VALUE_ASSUMPTIONS,
        mutateOptions
    );

export default useCreateClickAppointmentValueAssumption;
