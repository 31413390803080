import { useDispatch, useSelector } from 'react-redux';
import FormLayout, { Props as FormLayoutProps } from 'domains/core/components/FormLayout';
import ContentIcon from 'domains/core/components/SvgIcons/ContentIcon';
import { ContentFields, MetaDataFields } from 'domains/campaigns/types';
import { EditContentPayload } from 'domains/campaigns/state/createCampaignSlice';
import { CurationStep } from 'models/enums';
import { RootState } from 'state';
import CurationContentForm from './CurationContentForm';
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';

type Props = {
    editContentActionCreator: ActionCreatorWithOptionalPayload<EditContentPayload, string>;
    nodeId: string;
    setPreviewContentNodeIdActionCreator: ActionCreatorWithOptionalPayload<string, string>;
    formLayoutProps?: Partial<FormLayoutProps>;
    selectContentFields: (state: RootState) => ContentFields;
    selectMetadataFields: (state: RootState) => MetaDataFields;
};

export const CurationContentPage = ({
    editContentActionCreator,
    nodeId,
    setPreviewContentNodeIdActionCreator,
    formLayoutProps,
    selectContentFields,
    selectMetadataFields,
}: Props) => {
    const dispatch = useDispatch();

    const editContent = (payload: EditContentPayload) => dispatch(editContentActionCreator(payload));
    const setContentPreviewNodeId = (contentPreviewNodeId: string) =>
        dispatch(setPreviewContentNodeIdActionCreator(contentPreviewNodeId));

    const contentFields = useSelector(selectContentFields);
    const contentPreviewNodeId = useSelector(selectMetadataFields).contentPreviewNodeId;

    return (
        <FormLayout
            header="select content"
            icon={<ContentIcon />}
            stepName={CurationStep.EMAIL}
            guidingText="Focus on the goal of the customer interaction and choose content from the dropdown that supports your objective."
            {...formLayoutProps}
        >
            <CurationContentForm
                contentFields={contentFields}
                editContent={editContent}
                nodeId={nodeId}
                contentPreviewNodeId={contentPreviewNodeId}
                setContentPreviewNodeId={setContentPreviewNodeId}
            />
        </FormLayout>
    );
};

export default CurationContentPage;
