import {
    ContentModalContentType,
    ContentTypeForComparison,
    LandingPage,
    Template,
    isTemplate as isTemplateTypeGuard,
} from 'domains/content/types';
import { URLPaths } from 'models/enums';

export const extractContentId = (id?: number) => (id ? Number(String(id).split(':')[1]) : null);

export const getContentCreatePath = (isEmail: boolean, id: number, isCopy?: boolean) => {
    const basePath = isEmail ? URLPaths.CONTENT_CREATE_EMAIL : URLPaths.CONTENT_CREATE_PAGE;
    const copyQuery = isCopy ? '&isCopy=true' : '';
    return `${basePath}?id=${id}${copyQuery}`;
};

export const getContentType = (content: ContentModalContentType, hasContentFoldersFeature: boolean) => {
    if (!content) return;
    if (hasContentFoldersFeature) return content?.type;
    return isTemplateTypeGuard(content as Template | LandingPage)
        ? ContentTypeForComparison.EMAIL
        : ContentTypeForComparison.LANDING_PAGE;
};

export const getIsFolderContentActive = (folderId: number) => folderId !== -1;
