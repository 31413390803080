import { UseMutationOptions } from 'react-query';
import useMutation from 'hooks/useMutation';
import { CuredApiPaths } from 'models/enums';
import { CreateLandingPageInput } from 'domains/content/types';
import MutationKeys from './keys';

/**
 * Creates a landing page.
 * @param mutateOptions react-query useMutation option
 * @returns the react-query useMutation results
 * See https://react-query-v3.tanstack.com/reference/useMutation for options and return value
 */
const useCreateLandingPage = (mutateOptions: UseMutationOptions<unknown, Error, CreateLandingPageInput>) =>
    useMutation<CreateLandingPageInput>(MutationKeys.CREATE_LANDING_PAGE, CuredApiPaths.LANDING_PAGES, mutateOptions);

export default useCreateLandingPage;
