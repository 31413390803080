import { UseQueryOptions } from 'react-query';
import useQuery from 'hooks/useQuery';
import { CuredApiPaths } from 'models/enums';
import QueryKeys from './keys';
import { FolderLineage } from 'domains/content/types';

type UseGetFolderLineageParams = {
    folderId?: number;
    reactQueryOptions?: UseQueryOptions<FolderLineage[], Error>;
};

const useGetFolderLineage = ({ folderId, reactQueryOptions }: UseGetFolderLineageParams = {}) =>
    useQuery(QueryKeys.FOLDER_LINEAGE(folderId), `${CuredApiPaths.FOLDER_LINEAGE}/${folderId}`, reactQueryOptions);

export default useGetFolderLineage;
