import { UseQueryOptions } from 'react-query';
import { CuredApiPaths } from 'models/enums';
import useQuery from 'hooks/useQuery';

const useContactLocation = (reactQueryOptions?: UseQueryOptions<any, Error>) => {
    const path = CuredApiPaths.REPORT_CONTACTS_LOCATIONS;

    return useQuery('contacts-locations', path, reactQueryOptions);
};

export default useContactLocation;
