import { UseMutationOptions } from 'react-query';
import { SegmentDefinitionInput } from 'domains/segments/types';
import { CuredApiPaths } from 'models/enums';
import useMutation from '../useMutation';
import MutationKeys from './keys';

export type SegmentPostBody = {
    name: string;
    segmentDefinition: SegmentDefinitionInput;
};

/**
 * Creates a segment.
 * @param mutateOptions react-query useMutation option
 * @returns the react-query useMutation results
 * See https://react-query-v3.tanstack.com/reference/useMutation for options and return value
 */
const useCreateSegment = (mutateOptions?: UseMutationOptions<null, Error, SegmentPostBody>) =>
    useMutation<SegmentPostBody, null>(MutationKeys.CREATE_SEGMENT, CuredApiPaths.SEGMENTS, mutateOptions);

export default useCreateSegment;
