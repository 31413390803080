import CustomCampaign from 'domains/campaigns/components/CustomCampaign/CustomCampaign';
import { customCurationReducer, initialState } from 'domains/campaigns/components/CustomCampaign/customCampaignReducer';
import { useReducer } from 'react';

const CustomPage = () => {
    const [state, dispatch] = useReducer(customCurationReducer, initialState);

    return <CustomCampaign campaignState={state} dispatchCampaignAction={dispatch} />;
};

export default CustomPage;
