import React, { useState } from 'react';
import { Snackbar } from '@mui/material';
import Alert from '../../Alert';

type Props = {
    errorMessage: string;
};

const ErrorSnackbar = ({ errorMessage }: Props) => {
    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert onClose={handleClose} severity="error">
                {errorMessage}
            </Alert>
        </Snackbar>
    );
};

export default ErrorSnackbar;
