import { useState } from 'react';
import { ContentModalContentType, ContentTypeEnumForBackend } from 'domains/content/types';
import ErrorSnackbar from 'domains/core/components/Snackbars/ErrorSnackbar';
import SuccessSnackbar from 'domains/core/components/Snackbars/SuccessSnackbar';
import useRenameContent from 'hooks/mutations/useRenameContent';
import FolderNamingDialog from '../FolderNamingDialog';

type Props = {
    activeContent: ContentModalContentType;
    handleModalClose: () => void;
    isOpen: boolean;
    refetch: () => void;
    resetActiveContent: () => void;
};

const RenameContentModal = ({ activeContent, handleModalClose, isOpen, refetch, resetActiveContent }: Props) => {
    const [errorMessage, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const { mutate: renameContent, isLoading: isRenameLoading } = useRenameContent();

    const renameContentWrapper = (name: string) => {
        setError('');
        setSuccessMessage('');
        const body = {
            name: name,
            id: activeContent?.id,
            type: ContentTypeEnumForBackend.Folder,
        };

        renameContent(body, {
            onSuccess: () => {
                setSuccessMessage(`"${activeContent.name}" renamed to ${name}`);
                resetActiveContent();
                refetch();
            },
            onError: (error: Error) => {
                setError(error.message);
                resetActiveContent();
            },
        });
        handleModalClose();
    };

    return (
        <>
            {errorMessage && <ErrorSnackbar errorMessage={errorMessage} />}
            {successMessage && <SuccessSnackbar successMessage={successMessage} />}
            <FolderNamingDialog
                initialValue={activeContent?.name}
                isDialogVisible={isOpen}
                disableActionButton={isRenameLoading}
                key="rename-content-dialog"
                onClose={handleModalClose}
                onSubmit={renameContentWrapper}
                submitButtonText="Rename"
                title="rename"
            />
        </>
    );
};

export default RenameContentModal;
