import { SvgIconProps } from '@mui/material';
import { Box, Typography } from '@mui/material';
import { CheckCircle, RadioButtonUncheckedOutlined } from '@mui/icons-material';
import ProgressCircleIcon from 'domains/core/components/SvgIcons/ProgressCircleIcon';
import ProgressCircleActiveIcon from 'domains/core/components/SvgIcons/ProgressCircleActiveIcon';
import { useSelector } from 'react-redux';
import { Handle, Position } from 'reactflow';
import { CurationState } from 'domains/campaigns/types';
import { CurationNavState } from 'models/enums';
import { URLPaths } from 'models/enums';
import { route as routeSelector } from 'state/selectors';
import { CSSProperties } from 'react';

const getBorderColor = (isActive: boolean, isDisabled: boolean, isPreview: boolean, readonly: boolean) => {
    if (isPreview || readonly) {
        return 'primary.main';
    }
    if (isActive) {
        return 'action.active';
    }
    if (isDisabled) {
        return 'text.disabled';
    }
    return 'primary.main';
};

type StateIconProps = {
    navState: CurationNavState;
    isActive: boolean;
    readonly: boolean;
};

const StateIcon = ({ navState, isActive, readonly }: StateIconProps) => {
    const stateIconStyles = {
        width: isActive ? '2rem' : '1.25rem',
        height: isActive ? '2rem' : '1.25rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
        marginRight: isActive ? '1rem' : '0.75rem',
        transition: 'margin-right 0.5s, width 0.5s, height 0.5s',
    };
    const iconsState = {
        [CurationNavState.PREVIEW]: (
            <RadioButtonUncheckedOutlined sx={{ ...{ color: '#FFFFFF' }, ...stateIconStyles }} />
        ),
        [CurationNavState.OPEN]: <RadioButtonUncheckedOutlined sx={{ ...{ color: '#FFFFFF' }, ...stateIconStyles }} />,
        [CurationNavState.IN_PROGRESS]: <ProgressCircleIcon sx={{ ...{ color: '#123133' }, ...stateIconStyles }} />,
        [CurationNavState.IN_PROGRESS_CURRENT]: (
            <ProgressCircleActiveIcon sx={{ ...{ color: '#123133' }, ...stateIconStyles }} />
        ),
        [CurationNavState.COMPLETED]: <CheckCircle sx={{ ...{ color: '#00845B' }, ...stateIconStyles }} />,
    };
    return <>{readonly ? iconsState[CurationNavState.COMPLETED] : iconsState[navState]}</>;
};

type IconColor = 'action' | 'disabled' | 'primary';

const getIconColor = (isActive: boolean, isDisabled: boolean, isPreview: boolean, readonly: boolean): IconColor => {
    if (isPreview || readonly) {
        return 'primary';
    }
    if (isActive) {
        return 'action';
    }
    if (isDisabled) {
        return 'disabled';
    }
    return 'primary';
};

// If multiple routes are under the same nav button, we need to calculate the state based on all routes under that nav button.
const getNavStateFromNavStates = (navStates: CurationNavState[]): CurationNavState => {
    if (navStates.every((navStates) => navStates === CurationNavState.PREVIEW)) {
        return CurationNavState.PREVIEW;
    }
    if (navStates.some((navState) => navState === CurationNavState.IN_PROGRESS_CURRENT)) {
        return CurationNavState.IN_PROGRESS_CURRENT;
    }
    if (navStates.some((navState) => navState === CurationNavState.IN_PROGRESS)) {
        return CurationNavState.IN_PROGRESS;
    }
    if (navStates.every((navState) => navState === CurationNavState.OPEN)) {
        return CurationNavState.OPEN;
    }
    if (navStates.every((navState) => navState === CurationNavState.COMPLETED)) {
        return CurationNavState.COMPLETED;
    }
};

export type Props = {
    isConnectable: boolean;
    data: {
        bottomHandleVisible?: boolean;
        disabledHoverState: boolean;
        getCurationStateByRoute: (route: URLPaths) => CurationState;
        isDisabled: boolean;
        label: string;
        leftIcon: (props: SvgIconProps) => any;
        onClick: () => void;
        readonly: boolean;
        routes: URLPaths[];
    };
    sourcePosition?: Position;
    targetPosition?: Position;
};

const NavigationNode = ({
    isConnectable,
    data: {
        bottomHandleVisible = true,
        disabledHoverState,
        getCurationStateByRoute,
        isDisabled,
        label,
        leftIcon,
        onClick,
        readonly,
        routes,
    },
    sourcePosition,
    targetPosition,
}: Props) => {
    const route = useSelector(routeSelector);

    const routeStates = routes.map(getCurationStateByRoute);
    const navState = getNavStateFromNavStates(routeStates.map((routeState) => routeState.navState));
    const isActive = routes.includes(route);
    const isPreview = navState === CurationNavState.PREVIEW;
    const handleClick = isDisabled ? () => {} : onClick;

    const navButtonStyles = {
        borderRadius: '6px',
        borderStyle: 'solid',
        borderWidth: isActive ? '0.188rem' : '0.125rem',
        borderColor: getBorderColor(isActive, isDisabled, isPreview, readonly),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fff',
        padding: '1.5rem 1.25rem',
        height: isActive ? '2rem' : '1.25rem',
        width: isActive ? '23.75rem' : '19.375rem',
        transition: 'width 0.5s, height 0.5s, margin 0.5s',
        '&:first-of-type': {
            marginTop: '0rem',
        },
        '&:last-child': {
            marginBottom: '0rem',
        },
        '&:hover': {
            backgroundColor: !isDisabled && !disabledHoverState ? 'rgb(238, 252, 248)' : '#FFFFFF',
            cursor: isDisabled || disabledHoverState ? 'initial' : 'pointer',
        },
    };

    const iconContainerStyles = {
        width: '1.75rem',
        height: '1.75rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: isActive ? '1rem' : '0.65rem',
        transition: 'margin-right 0.5s',
    };

    const iconStyles = {
        color: getIconColor(isActive, isDisabled, isPreview, readonly),
        sx: {
            height: isActive ? '2.188rem' : '1.75rem',
            width: isActive ? '2.188rem' : '1.75rem',
            transition: 'width 0.5s, height 0.5s',
        },
    };

    const typeContainer = {
        flexGrow: '1',
    };

    const typeStyles = {
        position: 'relative',
        display: 'flex',
        flexGrow: '1',
        alignItems: 'center',
        justifyContent: 'start',
        top: '-0.175rem',
        fontSize: isActive ? '1.75rem' : '1.5rem',
        color: isDisabled && !(isPreview || readonly) ? 'text.disabled' : 'primary.main',
        transition: 'font-size 0.5s, top',
    };

    const hiddenStyles: CSSProperties = {
        visibility: 'hidden',
    };

    const stateIconStyles: CSSProperties = {
        position: 'absolute',
        right: '1rem',
    };

    return (
        <Box
            data-testid="curation-nav-button"
            onClick={disabledHoverState || readonly ? () => {} : handleClick}
            sx={navButtonStyles}
        >
            <Handle
                isConnectable={isConnectable}
                type="target"
                position={targetPosition ?? Position.Top}
                style={hiddenStyles}
            />
            <Box sx={iconContainerStyles}>{leftIcon(iconStyles)}</Box>{' '}
            <Box sx={typeContainer}>
                <Typography sx={typeStyles}>{label}</Typography>
            </Box>
            <Box sx={stateIconStyles}>
                <StateIcon navState={navState} isActive={isActive} readonly={readonly} />
            </Box>
            {bottomHandleVisible && (
                <Handle
                    isConnectable={isConnectable}
                    type="source"
                    position={sourcePosition ?? Position.Bottom}
                    style={hiddenStyles}
                />
            )}
        </Box>
    );
};

export default NavigationNode;
