import { Box } from '@mui/material';
import TextField from 'domains/core/components/TextField';

export type Props = {
    isHidden: boolean;
};

export type ReduxProps = {
    curationName: string;
    editMetadata: (curationName: string) => void;
};

// todo: consolidate with CampaignNameInput
const CurationNameInput = ({ curationName, editMetadata, isHidden }: ReduxProps & Props) => {
    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => editMetadata(e.target.value);
    if (isHidden) {
        return <Box />;
    }
    return (
        <TextField
            counterCurrent={curationName.length ?? 0}
            counterMax={255}
            data-testid="update-name-input"
            focused
            label="Name"
            value={curationName}
            width="50%"
            withClickAway
            withCounter
            onChange={handleNameChange}
        />
    );
};

export default CurationNameInput;
