import { useDispatch, useSelector } from 'react-redux';
import { SupervisedUserCircleOutlined } from '@mui/icons-material';
import { CurationStep } from 'models/enums';
import FormLayout from 'domains/core/components/FormLayout';
import { AudienceState } from 'domains/campaigns/types';
import { editAudience as editAudienceActionCreator } from 'domains/campaigns/state/actionCreators';
import CurationAudienceForm from './CurationAudienceForm';

type Props = {
    audienceSelector: (state: any) => AudienceState;
    editAudienceActionType: string;
};

const CurationAudiencePage = ({ audienceSelector, editAudienceActionType }: Props) => {
    const dispatch = useDispatch();
    const audienceState = useSelector(audienceSelector);
    const editAudience = (audienceId: number) =>
        dispatch(editAudienceActionCreator(editAudienceActionType, { audienceId }));
    return (
        <FormLayout
            header="select audience"
            icon={<SupervisedUserCircleOutlined />}
            guidingText="Select an audience from the drop down to choose the contacts you want to connect with in this Curation."
            stepName={CurationStep.SEGMENT}
        >
            <CurationAudienceForm audienceState={audienceState} editAudience={editAudience} />
        </FormLayout>
    );
};

export default CurationAudiencePage;
