import { Box, Typography } from '@mui/material';
import { EventSelector, SegmentDefinition, SegmentDemographic, SegmentEvent } from 'domains/segments/types';
import Conditions from './Conditions';
import MatchTypeChip from '../MatchTypeChip';
import { secondarySpanProps } from '../FilterDetails/FilterDetails';

type Props = {
    details: SegmentEvent[];
    matchType: SegmentDefinition['matchType'];
    shouldShowMatchType: boolean;
};

const EventDetails = ({ details, matchType, shouldShowMatchType }: Props) => {
    const getSelectorText = (selector: SegmentEvent['selector'], operator?: SegmentEvent['operator']) => {
        const isAnySelector = selector === 'any';
        if (isAnySelector && (!operator || operator === 'exists')) return EventSelector.INCLUDES;
        if (isAnySelector && operator === 'not exists') return EventSelector.DOES_NOT_INCLUDE;
        // TODO: handle upcoming possible selectors (most recent, next, last, etc) with switch/case
    };

    return (
        <>
            {details.map(({ conditions, operator, selector }: SegmentEvent, index) => {
                const recordType = conditions.filter(
                    (condition: SegmentDemographic) => condition.name === 'record_type'
                )[0];
                const conditionsWithoutRecordType = conditions.filter(
                    (condition: SegmentDemographic) => condition.name !== 'record_type'
                );

                return (
                    <Box data-testid="filter" key={index} textAlign="right">
                        {(shouldShowMatchType || index !== 0) && <MatchTypeChip matchType={matchType} />}
                        <Typography component="span">
                            <Typography {...secondarySpanProps}>Contact Event </Typography>
                            {getSelectorText(selector, operator)}
                            {recordType?.value && (
                                <Typography {...secondarySpanProps}>
                                    <> {recordType.value}</>
                                </Typography>
                            )}
                        </Typography>
                        <Conditions conditions={conditionsWithoutRecordType} />
                    </Box>
                );
            })}
        </>
    );
};

export default EventDetails;
