import { UseQueryOptions } from 'react-query';
import { CampaignChannels } from 'domains/campaigns/types';
import { CuredApiPaths } from 'models/enums';
import useQuery from 'hooks/useQuery';
import QueryKeys from './keys';

export type UseAudienceCountResponse = {
    count: number;
};

/**
 * A hook that returns the number of contacts for a given audience.
 * See https://react-query.tanstack.com/reference/useQuery for docs on the options and return object.
 * @param audienceId the id of the audience
 * @param reactQueryOptions the options for the react-query hook
 * @returns a react-query object
 */
// TODO: Retype audienceId from 'string' to 'number' to not need type conversion when calling this hook every time
const useAudienceCount = (
    audienceId: string,
    channel?: CampaignChannels,
    reactQueryOptions?: UseQueryOptions<UseAudienceCountResponse, Error>
) => {
    let audienceCountUri = `${CuredApiPaths.SEGMENTS}/${audienceId}/count`;
    if (channel) {
        audienceCountUri += `/${channel.toLowerCase()}`;
    }
    return useQuery(QueryKeys.AUDIENCE_COUNT(audienceId, channel?.toString()), audienceCountUri, reactQueryOptions);
};

export default useAudienceCount;
