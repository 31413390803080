import EmailPreviewContent from 'domains/content/components/EmailPreviewContent';
import { isTemplate } from 'domains/content/types';
import useSenderProfile from 'hooks/queries/useSenderProfile';
import useTemplate from 'hooks/queries/useTemplate';
import { StateTypes } from '../customCampaignReducer';

type Props = {
    activeSendIndex: number;
    campaignState: StateTypes;
    contentPreviewId?: number;
    setContentPreviewId: (id: number) => void;
};

const CustomCampaignContentPreview = ({
    activeSendIndex,
    campaignState,
    contentPreviewId,
    setContentPreviewId,
}: Props) => {
    const { data: previewTemplate } = useTemplate({
        templateId: `${contentPreviewId}`,
        reactQueryOptions: {
            enabled: !!contentPreviewId,
        },
    });

    const currentSend = campaignState.sends[activeSendIndex];

    const { data: previewSenderProfile } = useSenderProfile(currentSend?.senderProfileId, {
        enabled: !!currentSend?.senderProfileId,
    });

    return (
        <EmailPreviewContent
            emailTemplateHTML={isTemplate(previewTemplate) && previewTemplate.html}
            fromAddress={previewSenderProfile?.from_address}
            isGridItem
            isOpen={!!contentPreviewId}
            preHeader={currentSend?.preHeader}
            subjectLine={currentSend?.subjectLine}
            onClose={() => setContentPreviewId(null)}
        />
    );
};

export default CustomCampaignContentPreview;
