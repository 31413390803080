import { DateTime } from 'luxon';
import { DelayFields, Send, SendRecurrenceFrequency } from './types';
import { getFormattedParentAudienceDelayInterval } from './pages/CurationsPage/CampaignDetailsPage/timeFormatting';
import { Detail } from './components/CampaignDetails/CampaignDetails';
import { CurationSegments } from 'models/enums';
import { Segment } from 'domains/segments/types';

export const getSendDatetimeError = (datetime: DateTime, isDate: boolean) => {
    if (!datetime?.isValid) {
        return `Please enter a valid ${isDate ? 'date' : 'time'}.`;
    }

    if (!(datetime <= DateTime.now().plus({ month: 6 }))) {
        return `Please enter a ${isDate ? 'date' : 'time'} within the next 6 months.`;
    }

    if (!(datetime >= DateTime.now().minus({ hour: 1 }))) {
        return 'A Curation cannot be scheduled in the past.';
    }
};

export const getEndDateTimeError = (endDatetime: DateTime, sendDatetime: DateTime) => {
    if (!endDatetime?.isValid) {
        return 'Please enter a valid date and time.';
    }

    if (!(endDatetime >= sendDatetime)) {
        return 'A Curation cannot end before the send date.';
    }
};

export const minDate = () => DateTime.now().minus({ hour: 1 });
export const maxDate = () => DateTime.now().plus({ month: 6 });

export const minTime = (sendDatetime: string) =>
    DateTime.fromISO(sendDatetime).hasSame(minDate(), 'day') ? minDate() : null;
export const maxTime = (sendDatetime: string) =>
    DateTime.fromISO(sendDatetime).hasSame(maxDate(), 'day') ? DateTime.now() : null;

export const getRecurrenceLabel = (recurrenceFrequency: SendRecurrenceFrequency, sendDatetime?: string) => {
    if (recurrenceFrequency === SendRecurrenceFrequency.CONTINUOUS) {
        return `continual`;
    }
    if (recurrenceFrequency === SendRecurrenceFrequency.EVERY_WEEKDAY) {
        return `${recurrenceFrequency} (Monday - Friday)`;
    }
    if (recurrenceFrequency === SendRecurrenceFrequency.WEEKLY && sendDatetime) {
        return `weekly on ${DateTime.fromISO(sendDatetime).toFormat('cccc')}`;
    }
    return recurrenceFrequency ?? '';
};

export const getIsRecurrenceContinuous = (recurrenceFrequency: SendRecurrenceFrequency) =>
    recurrenceFrequency === SendRecurrenceFrequency.CONTINUOUS;

export const getIsRecurrenceEnabled = (recurrenceFrequency: SendRecurrenceFrequency) =>
    Boolean(recurrenceFrequency) && recurrenceFrequency !== SendRecurrenceFrequency.DOES_NOT_REPEAT;

type GetCriteriaDetailProps = {
    contentId?: number;
    delayFields?: DelayFields; // pass for sends before save
    delayInterval?: string; // pass for saved sends
    firstEmailRecurrenceFrequency?: SendRecurrenceFrequency;
    scheduledTimestamp?: string;
};
const getCriteriaDetail = ({
    contentId,
    delayFields,
    delayInterval,
    firstEmailRecurrenceFrequency,
    scheduledTimestamp,
}: GetCriteriaDetailProps) => {
    const isEmail = !!contentId;
    if (isEmail) {
        const parentAudienceDelayInterval =
            delayInterval ?? getFormattedParentAudienceDelayInterval(delayFields?.delay, delayFields?.unit);

        return {
            contentNodeId: `${contentId}`,
            contentId,
            firstEmailRecurrenceFrequency,
            parentAudienceDelayInterval,
            scheduledTimestamp,
        };
    }

    return { description: 'exit curation' };
};

type SendWithDelayFields = Partial<Send> & {
    delayFields?: DelayFields;
};
type GetConditionalSplitCurationDetailsProps = {
    firstSend: SendWithDelayFields;
    noCriteriaSend: SendWithDelayFields;
    segments: Segment[];
    yesCriteriaSend: SendWithDelayFields;
};
export const getConditionalSplitCurationDetails = ({
    firstSend,
    noCriteriaSend,
    segments,
    yesCriteriaSend,
}: GetConditionalSplitCurationDetailsProps): Detail[] => {
    const isSegmentsDidOpen =
        // This check is for campaigns before save
        segments[0]?.name === 'opened_sends' ||
        // This check is for saved campaigns
        // (1st segment is user-generated, 2nd/3rd segment is system-generated based on conditional split criteria)
        segments[1]?.segmentDefinition?.engagement[0]?.name === 'opened_sends';
    const splitDescription = `${isSegmentsDidOpen ? CurationSegments.DID_OPEN : CurationSegments.DID_CLICK} email`;

    const initialSendDetail = {
        contentNodeId: `${firstSend.id}`,
        contentId: firstSend.id,
        recurrenceFrequency: firstSend.recurrenceFrequency,
        scheduledEndTimestamp: firstSend.scheduledEndTimestamp,
        scheduledTimestamp: firstSend.scheduledTimestamp,
    };
    const splitDetail = { header: 'Conditional Split', description: splitDescription };
    const meetsCriteriaDetail = getCriteriaDetail({
        contentId: yesCriteriaSend?.id,
        delayFields: yesCriteriaSend?.delayFields,
        delayInterval: yesCriteriaSend?.parentAudienceDelayInterval,
        firstEmailRecurrenceFrequency: firstSend.recurrenceFrequency,
        scheduledTimestamp: yesCriteriaSend?.scheduledTimestamp,
    });
    const doesNotMeetCriteriaDetail = getCriteriaDetail({
        contentId: noCriteriaSend?.id,
        delayFields: noCriteriaSend?.delayFields,
        delayInterval: noCriteriaSend?.parentAudienceDelayInterval,
        firstEmailRecurrenceFrequency: firstSend.recurrenceFrequency,
        scheduledTimestamp: noCriteriaSend?.scheduledTimestamp,
    });

    return [
        initialSendDetail,
        splitDetail,
        {
            header: 'Meets Criteria',
            ...meetsCriteriaDetail,
        },
        {
            header: 'Does Not Meet Criteria',
            ...doesNotMeetCriteriaDetail,
        },
    ];
};
