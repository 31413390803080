import { AudienceStatus, URLPaths } from 'models/enums';

export type DefaultClickAppointmentAssumption = {
    appointmentValue: number;
    attendRate: number;
    scheduleRate: number;
    uniqueClickRate: number;
};

export type ClickAppointmentValue = {
    sendId: number;
    contactEventRecordType?: string;
} & DefaultClickAppointmentAssumption;

export type ClickApptValueAssumption = {
    sendName: string;
    audienceStatus: AudienceStatus;
    clickUrl: string;
    specialty: ClickApptAssumptionSpecialty;
} & ClickAppointmentValue;

export type AppointmentValueResponse = {
    estimatedValue: number;
    contactCount: number;
};

export enum ClickApptAssumptionSpecialty {
    CARDIOLOGY = 'cardiology',
    DENTISTRY = 'dentistry',
    DERMATOLOGY = 'dermatology',
    ENDOCRINOLOGY = 'endocrinology',
    NEUROLOGY = 'neurology',
    OBGYN = 'ob/gyn',
    ONCOLOGY = 'oncology',
    OPHTHALMOLOGY = 'ophthalmology',
    OPTOMETRY = 'optometry',
    ORTHOPEDICS = 'orthopedics',
    PEDIATRICS = 'pediatrics',
    PRIMARYCARE = 'primary care',
    OTHER = 'other',
}

export enum ValueCalculatorStep {
    VALUE = 'value',
    QUALIFICATION_CRITERIA = 'qualification criteria',
    CONVERSION_METRIC = 'conversion metric',
    CONVERSION_VALUE = 'conversion value',
}

export const ValueCalculatorRoutes = [
    URLPaths.VALUE_SEND_SPECIALTY,
    URLPaths.VALUE_CONVERSION_EVENT,
    URLPaths.VALUE_CONVERSION_METRIC,
    URLPaths.VALUE_CONVERSION_VALUE,
];

export const defaultAssumptionValuesBySpecialty: Record<
    ClickApptAssumptionSpecialty,
    DefaultClickAppointmentAssumption
> = {
    [ClickApptAssumptionSpecialty.CARDIOLOGY]: {
        appointmentValue: 335,
        attendRate: 0.7695,
        scheduleRate: 0.8,
        uniqueClickRate: 0.037,
    },
    [ClickApptAssumptionSpecialty.DENTISTRY]: {
        appointmentValue: 290,
        attendRate: 0.85,
        scheduleRate: 0.8,
        uniqueClickRate: 0.035,
    },
    [ClickApptAssumptionSpecialty.DERMATOLOGY]: {
        appointmentValue: 268,
        attendRate: 0.7,
        scheduleRate: 0.8,
        uniqueClickRate: 0.0301,
    },
    [ClickApptAssumptionSpecialty.ENDOCRINOLOGY]: {
        appointmentValue: 217,
        attendRate: 0.86,
        scheduleRate: 0.8,
        uniqueClickRate: 0.0301,
    },
    [ClickApptAssumptionSpecialty.NEUROLOGY]: {
        appointmentValue: 315,
        attendRate: 0.74,
        scheduleRate: 0.8,
        uniqueClickRate: 0.0301,
    },
    [ClickApptAssumptionSpecialty.OBGYN]: {
        appointmentValue: 280,
        attendRate: 0.82,
        scheduleRate: 0.8,
        uniqueClickRate: 0.0301,
    },
    [ClickApptAssumptionSpecialty.ONCOLOGY]: {
        appointmentValue: 198,
        attendRate: 0.75,
        scheduleRate: 0.8,
        uniqueClickRate: 0.0307,
    },
    [ClickApptAssumptionSpecialty.OPHTHALMOLOGY]: {
        appointmentValue: 307,
        attendRate: 0.78,
        scheduleRate: 0.8,
        uniqueClickRate: 0.025,
    },
    [ClickApptAssumptionSpecialty.OPTOMETRY]: {
        appointmentValue: 200,
        attendRate: 0.75,
        scheduleRate: 0.8,
        uniqueClickRate: 0.09,
    },
    [ClickApptAssumptionSpecialty.ORTHOPEDICS]: {
        appointmentValue: 419,
        attendRate: 0.885,
        scheduleRate: 0.8,
        uniqueClickRate: 0.034,
    },
    [ClickApptAssumptionSpecialty.PEDIATRICS]: {
        appointmentValue: 169,
        attendRate: 0.7,
        scheduleRate: 0.8,
        uniqueClickRate: 0.025,
    },
    [ClickApptAssumptionSpecialty.PRIMARYCARE]: {
        appointmentValue: 186,
        attendRate: 0.81,
        scheduleRate: 0.8,
        uniqueClickRate: 0.0362,
    },
    [ClickApptAssumptionSpecialty.OTHER]: {
        appointmentValue: 265,
        attendRate: 0.7845,
        scheduleRate: 0.8,
        uniqueClickRate: 0.0366,
    },
};
