export const injectSatismeter = (SATISMETER_ENDPOINT: string) => {
    (function () {
        (window as any).satismeter =
            (window as any).satismeter ||
            function () {
                ((window as any).satismeter.q = (window as any).satismeter.q || []).push(arguments);
            };
        (window as any).satismeter.l = 1 * +new Date();
        var script = document.createElement('script');
        var parent = document.getElementsByTagName('script')[0].parentNode;
        (script as any).async = 1;
        script.src = SATISMETER_ENDPOINT;
        parent.appendChild(script);
    })();
};

export const recordSatismeterEvent = (event: string) => {
    if ((window as any).satismeter) {
        (window as any).satismeter('track', { event: event });
    }
};
