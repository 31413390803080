import { Chip, Stack, SxProps, Theme } from '@mui/material';
import { Check, Close } from '@mui/icons-material';
import { SQLType } from 'models/enums';
import Autocomplete from 'domains/core/components/Autocomplete';
import { Value } from 'domains/core/components/Select';
import { getInputType } from 'domains/segments/pages/SegmentCreatePage/Filter/utils';
import { greyChipStyles } from 'domains/core/components/Chip/Chip';

type AutocompleteInputSx = {
    sxAutocomplete?: SxProps<Theme>;
    sxChip?: SxProps<Theme>;
    sxOption?: SxProps<Theme>;
};

type Props = {
    autocompleteInputValue: string;
    autocompleteValue: any;
    error?: boolean;
    helperText?: string;
    freeSolo?: boolean;
    label?: string;
    multiple?: boolean;
    onChange: (autocompleteValue: any, resetPreview?: boolean) => void;
    onInputChange: (autocompleteInputValue: string) => void;
    options: Value[];
    placeholderName: string;
    styles?: AutocompleteInputSx;
    type: string | SQLType;
};

const AutocompleteInput = ({
    autocompleteInputValue,
    autocompleteValue,
    error,
    helperText,
    freeSolo,
    label,
    multiple,
    onChange,
    onInputChange,
    options,
    placeholderName,
    styles = {},
    type,
}: Props) => {
    const { sxAutocomplete = {}, sxChip = {}, sxOption = {} } = styles;

    return (
        <Autocomplete
            {...{ error }}
            {...{ freeSolo }}
            {...{ helperText }}
            disableCloseOnSelect={multiple}
            getOptionLabel={(option) => option?.label ?? ''}
            inputValue={autocompleteInputValue ?? ''}
            isOptionEqualToValue={(option, value) => option.value === value?.value}
            label={label ?? placeholderName}
            onChange={(_, value) => onChange(value)}
            onInputChange={(_, inputValue, reason) => {
                if (reason !== 'reset') {
                    onInputChange(inputValue);
                }
            }}
            options={options ?? []}
            placeholder={`Enter a value for ${placeholderName}`}
            sx={{ width: '16em', ...sxAutocomplete }}
            type={getInputType(type)}
            value={autocompleteValue}
            {...(multiple && {
                disableCloseOnSelect: true,
                multiple: true,
                renderOption: (props, option, { selected }) => (
                    <li {...props}>
                        <Stack direction="row" justifyContent="space-between" width="100%" sx={sxOption}>
                            {option.label}
                            {selected && <Check color="action" />}
                        </Stack>
                    </li>
                ),
                renderTags: (value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            deleteIcon={<Close />}
                            label={option.label}
                            {...getTagProps({ index })}
                            sx={{ ...greyChipStyles, ...sxChip }}
                        />
                    )),
            })}
        />
    );
};

export default AutocompleteInput;
