import { useDispatch, useSelector } from 'react-redux';
import FormLayout, { Props as FormLayoutProps } from 'domains/core/components/FormLayout';
import { ScheduleFields } from 'domains/campaigns/types';
import ContentIcon from 'domains/core/components/SvgIcons/ContentIcon';
import { CurationStep } from 'models/enums';
import CurationScheduleForm from './CurationScheduleForm';
import { RootState } from 'state';
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';

/**
 * Single send and time delay curations have the same basic Redux store shape and require
 * editScheduleFieldsActionCreator and setScheduleDatetimeErrorActionCreator.
 * Conditional split curations currently have a different Redux store shape and do not use redux-toolkit, so
 * legacyEditSchedule and legacySetDatetimeError were added as a stopgap to allow CS curations to use this file.
 *
 * TLDR; you must pass editScheduleFieldsActionCreator and setScheduleDatetimeErrorActionCreator to use this component.
 * If working in CS code, you must pass legacyEditSchedule and legacySetDatetimeError.
 */

type Props = {
    editScheduleFieldsActionCreator?: ActionCreatorWithOptionalPayload<Partial<ScheduleFields>, string>;
    setScheduleDatetimeErrorActionCreator?: ActionCreatorWithOptionalPayload<boolean, string>;
    allowRecurrence?: boolean;
    formLayoutProps?: Partial<FormLayoutProps>;
    legacyEditSchedule?: any;
    legacySetDatetimeError?: any;
    selectScheduleFields: (state: RootState) => ScheduleFields;
};

export const CurationSchedulePage = ({
    editScheduleFieldsActionCreator,
    setScheduleDatetimeErrorActionCreator,
    allowRecurrence,
    formLayoutProps,
    legacyEditSchedule,
    legacySetDatetimeError,
    selectScheduleFields,
}: Props) => {
    const dispatch = useDispatch();

    const editSchedule = (scheduleFields: Partial<ScheduleFields>) =>
        dispatch(editScheduleFieldsActionCreator(scheduleFields));
    const setScheduleDatetimeError = (isError: boolean) => dispatch(setScheduleDatetimeErrorActionCreator(isError));

    const scheduleFields = useSelector(selectScheduleFields);

    return (
        <FormLayout
            data-testid="curation-schedule-form-layout"
            header="schedule content"
            icon={<ContentIcon />}
            stepName={CurationStep.EMAIL}
            guidingText="Set the date and time your first email will send. Have your audience in mind to optimize your send time."
            {...formLayoutProps}
        >
            <CurationScheduleForm
                scheduleFields={scheduleFields}
                editSchedule={legacyEditSchedule ?? editSchedule}
                setDatetimeError={legacySetDatetimeError ?? setScheduleDatetimeError}
                allowRecurrence={allowRecurrence}
            />
        </FormLayout>
    );
};

export default CurationSchedulePage;
