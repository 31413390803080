import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const UpdateIcon = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <path
            d="M3.97076 21.9437C3.66999 22.1183 3.37577 22.0998 3.0881 21.8883C2.80042 21.6767 2.69396 21.4006 2.7687 21.0598L4.77865 10.5714C4.88584 9.96955 5.21875 9.56141 5.77739 9.34695C6.33603 9.13249 6.85166 9.19901 7.32426 9.54651L13.542 14.0709C14.0324 14.4156 14.2602 14.8879 14.2256 15.4876C14.1909 16.0874 13.9042 16.5295 13.3655 16.814L3.97076 21.9437ZM4.73284 19.6312L12.6449 15.3344L6.38392 10.707L4.73284 19.6312Z"
            fill="white"
        />
        <path
            d="M13.2406 6.85182L12.7209 6.06654L11.8153 5.80846L12.6005 5.28873L12.8586 4.38309L13.3784 5.16837L14.284 5.42646L13.4987 5.94619L13.2406 6.85182Z"
            fill="white"
        />
        <path
            d="M18.4859 9.86354L17.9661 9.07826L17.0605 8.82018L17.8458 8.30045L18.1039 7.39481L18.6236 8.18009L19.5292 8.43817L18.744 8.95791L18.4859 9.86354Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.49929 3.98135C7.59028 3.58741 7.98339 3.34182 8.37733 3.43281C9.83404 3.76926 10.8026 4.67145 11.0697 5.86512C11.3298 7.02792 10.8799 8.27575 9.9252 9.25296C9.64265 9.54215 9.17916 9.54754 8.88996 9.26499C8.60077 8.98244 8.59538 8.51895 8.87793 8.22976C9.5735 7.51783 9.76825 6.75422 9.64085 6.18477C9.52037 5.64618 9.06931 5.09532 8.04783 4.85939C7.65389 4.7684 7.4083 4.37529 7.49929 3.98135Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.0193 9.45605C13.5006 9.93083 12.982 10.4056 12.4902 10.8117C12.1785 11.0692 11.7171 11.0252 11.4596 10.7135C11.2021 10.4017 11.2461 9.94031 11.5578 9.68284C12.0216 9.2998 12.4916 8.86938 12.9923 8.4109C13.2502 8.17473 13.5163 7.93112 13.7938 7.6827C14.5893 6.97053 15.4526 6.2445 16.3677 5.68039C17.2832 5.11606 18.2915 4.68782 19.3769 4.61743C20.4807 4.54584 21.6009 4.84874 22.7136 5.63875C23.0433 5.8728 23.1208 6.32979 22.8868 6.65947C22.6527 6.98915 22.1957 7.06666 21.8661 6.83261C21.0061 6.22206 20.2148 6.0303 19.4716 6.0785C18.71 6.12789 17.9356 6.43388 17.136 6.92676C16.3361 7.41986 15.5518 8.07402 14.7703 8.77358C14.522 8.99588 14.2706 9.22597 14.0193 9.45605Z"
            fill="white"
        />
        <path
            d="M15.1085 4.37457L14.4491 3.37824L13.3001 3.05079L14.2964 2.39138L14.6239 1.24234L15.2833 2.23867L16.4323 2.56612L15.436 3.22554L15.1085 4.37457Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.3709 12.2322C15.9699 12.3355 15.6154 12.6877 15.4219 13.0246C15.2206 13.3753 14.7732 13.4963 14.4226 13.295C14.0719 13.0936 13.9509 12.6462 14.1522 12.2956C14.4607 11.7583 15.0891 11.0504 16.0058 10.8143C17.0005 10.5582 18.0885 10.9141 19.1331 12.0546C19.4062 12.3528 19.3859 12.8159 19.0877 13.0889C18.7896 13.362 18.3265 13.3417 18.0534 13.0435C17.2578 12.1748 16.6936 12.1491 16.3709 12.2322Z"
            fill="white"
        />
    </SvgIcon>
);
export default UpdateIcon;
