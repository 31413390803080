import { UseQueryOptions } from 'react-query';
import { CuredApiPaths } from 'models/enums';
import useQuery from 'hooks/useQuery';

const useContactSex = (reactQueryOptions?: UseQueryOptions<any, Error>) => {
    const path = CuredApiPaths.REPORT_CONTACTS_GENDER;

    return useQuery('contacts-sex', path, reactQueryOptions);
};

export default useContactSex;
