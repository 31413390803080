import { useDispatch, useSelector } from 'react-redux';
import { Location } from 'history';
import RouteLeavingGuard from 'domains/core/components/RouteLeavingGuard';
import { RootState } from 'state';
import { onLeaveCurationWorkflow } from 'state/appVisual/appVisualActions';
import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import { campaignDialogProps } from 'domains/core/components/RouteLeavingGuard/RouteLeavingGuard';

type Props = {
    mainRoute: string;
    resetCampaignActionType?: string;
    actionCreatorResetState?: ActionCreatorWithoutPayload<string>;
    selectIsCampaignComplete: (state: RootState) => boolean;
};

const CampaignFlowRouteLeavingGuard = ({
    mainRoute,
    resetCampaignActionType,
    actionCreatorResetState,
    selectIsCampaignComplete,
}: Props) => {
    const dispatch = useDispatch();
    const campaignComplete = useSelector(selectIsCampaignComplete);

    const shouldBlockNavigation = (location: Location): boolean => {
        const userTryingToNavigateOutOfCampaignFlow = !location.pathname.includes(mainRoute);
        return userTryingToNavigateOutOfCampaignFlow && !campaignComplete;
    };

    return (
        <RouteLeavingGuard
            dialogProps={campaignDialogProps}
            shouldBlockNavigation={shouldBlockNavigation}
            onConfirm={() => {
                resetCampaignActionType && dispatch({ type: resetCampaignActionType });
                actionCreatorResetState && dispatch(actionCreatorResetState());
                dispatch(onLeaveCurationWorkflow());
            }}
        />
    );
};

export default CampaignFlowRouteLeavingGuard;
