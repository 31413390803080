import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import Big from 'big.js';
import {
    ClickAppointmentValue,
    ClickApptAssumptionSpecialty,
    defaultAssumptionValuesBySpecialty,
} from 'domains/value/types';

export type ValueState = {
    clickUrl: string;
    specialty: ClickApptAssumptionSpecialty;
    isPreviewVisible: boolean;
    isSaved: boolean;
    previewClickUrl: string;
} & ClickAppointmentValue;

export const initialState: ValueState = {
    appointmentValue: null,
    attendRate: null,
    clickUrl: null,
    isPreviewVisible: false,
    isSaved: false,
    previewClickUrl: null,
    scheduleRate: null,
    sendId: null,
    specialty: null,
    uniqueClickRate: null,
    contactEventRecordType: null,
};

const valueSlice = createSlice({
    name: 'value',
    initialState,
    reducers: {
        reset() {
            return initialState;
        },
        resetAppointmentValue(state) {
            state.appointmentValue = defaultAssumptionValuesBySpecialty[state.specialty].appointmentValue;
        },
        resetAttendRate(state) {
            state.attendRate = defaultAssumptionValuesBySpecialty[state.specialty].attendRate;
        },
        resetScheduleRate(state) {
            state.scheduleRate = defaultAssumptionValuesBySpecialty[state.specialty].scheduleRate;
        },
        resetUniqueClickRate(state) {
            state.uniqueClickRate = defaultAssumptionValuesBySpecialty[state.specialty].uniqueClickRate;
        },
        setAppointmentValue(state, action: PayloadAction<number>) {
            state.appointmentValue = action.payload;
        },
        setAttendRate(state, action: PayloadAction<number>) {
            state.attendRate = Big(action.payload).div(100).toNumber();
        },
        setClickUrl(state, action: PayloadAction<string>) {
            state.clickUrl = action.payload;
        },
        setContactEventRecordType(state, action: PayloadAction<string>) {
            state.contactEventRecordType = action.payload;
        },
        setUniqueClickRate(state, action: PayloadAction<number>) {
            state.uniqueClickRate = Big(action.payload).div(100).toNumber();
        },
        setIsPreviewVisible(state, action: PayloadAction<boolean>) {
            state.isPreviewVisible = action.payload;
        },
        setIsSaved(state, action: PayloadAction<boolean>) {
            state.isSaved = action.payload;
        },
        setPreviewClickUrl(state, action: PayloadAction<string>) {
            state.previewClickUrl = action.payload;
        },
        setScheduleRate(state, action: PayloadAction<number>) {
            state.scheduleRate = Big(action.payload).div(100).toNumber();
        },
        setSendId(state, action: PayloadAction<number>) {
            state.sendId = action.payload;
        },
        setSpecialty(state, action: PayloadAction<ClickApptAssumptionSpecialty>) {
            const newSpecialty = action.payload;
            state.specialty = newSpecialty;
            if (newSpecialty) {
                state.appointmentValue = defaultAssumptionValuesBySpecialty[newSpecialty].appointmentValue;
                state.attendRate = defaultAssumptionValuesBySpecialty[newSpecialty].attendRate;
                state.uniqueClickRate = defaultAssumptionValuesBySpecialty[newSpecialty].uniqueClickRate;
                state.scheduleRate = defaultAssumptionValuesBySpecialty[newSpecialty].scheduleRate;
            }
        },
    },
});

export const {
    reset,
    resetAppointmentValue,
    resetAttendRate,
    resetScheduleRate,
    resetUniqueClickRate,
    setAppointmentValue,
    setAttendRate,
    setClickUrl,
    setContactEventRecordType,
    setUniqueClickRate,
    setIsPreviewVisible,
    setIsSaved,
    setPreviewClickUrl,
    setScheduleRate,
    setSendId,
    setSpecialty,
} = valueSlice.actions;

export default valueSlice.reducer;
