import { Box } from '@mui/material';
import DateFilter from '../../filtering/DateFilter';
import IncludeUnsubsFilter from '../../filtering/IncludeUnsubsFilter';
import { FilterValuesState } from '../../filtering/types';
import FiltersWrapper from '../../filtering/FiltersWrapper/FiltersWrapper';
import { getIsValidDateRange, getIsValidRelativeTimeframe } from '../../filtering/utils';

type Props = {
    filterValues: FilterValuesState;
    handleApplyFilters: () => void;
    isRelativeTimeframe?: boolean;
    setFilterValues: (filters: FilterValuesState) => void;
    setIsRelativeTimeframe?: (isRelativeTimeframe: boolean) => void;
};

const Filters = ({
    filterValues,
    handleApplyFilters,
    isRelativeTimeframe,
    setFilterValues,
    setIsRelativeTimeframe,
}: Props) => {
    const isValidDateRange = getIsValidDateRange(filterValues?.filterTimeframe);
    const isValidRelativeTimeframe = getIsValidRelativeTimeframe(filterValues?.filterRelativeTimeframe);
    const hasInvalidFilters = isRelativeTimeframe ? !isValidRelativeTimeframe : !isValidDateRange;

    return (
        <FiltersWrapper disabled={hasInvalidFilters} handleApplyFilters={handleApplyFilters}>
            <DateFilter
                filterValues={filterValues}
                isRelativeTimeframe={isRelativeTimeframe}
                setFilterValues={setFilterValues}
                setIsRelativeTimeframe={setIsRelativeTimeframe}
            />
            <Box mt={1.5} mb={2}>
                <IncludeUnsubsFilter filterValues={filterValues} setFilterValues={setFilterValues} />
            </Box>
        </FiltersWrapper>
    );
};

export default Filters;
