// Based off of https://michaelchan-13570.medium.com/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
import { Location } from 'history';
import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useHistory } from 'react-router';
import Button from 'domains/core/components/Button';

export const campaignDialogProps: DialogProps = {
    title: 'Changes will be lost',
    contentText: "This will take you out of the Curation process and any changes you've made will be lost.",
};

export type DialogProps = {
    title: string;
    contentText: string;
    cancelButtonText?: string;
    confirmButtonText?: string;
};

type Props = {
    dialogProps: DialogProps;
    onCancel?: () => void;
    onConfirm?: () => void;
    shouldBlockNavigation: (location: Location) => boolean;
    when?: boolean | { value: boolean; key: number };
};
const RouteLeavingGuard = ({ dialogProps, onCancel, onConfirm, shouldBlockNavigation, when }: Props) => {
    const history = useHistory();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isNavigationSet, setIsNavigationSet] = useState(false);
    const [lastLocation, setLastLocation] = useState<Location | null>(null);

    const isWhenBoolean = typeof when === 'boolean';
    const isExitOnCancel = !isWhenBoolean && lastLocation;

    const handleBlockedNavigation = (nextLocation: Location): boolean => {
        if (!isNavigationSet && shouldBlockNavigation(nextLocation)) {
            setIsModalOpen(true);
            setLastLocation(nextLocation);
            return false;
        }
        return true;
    };

    const handleCancel = () => {
        setIsModalOpen(false);

        if (onCancel) {
            setIsNavigationSet(true);
            onCancel();
        } else if (isExitOnCancel) {
            setIsNavigationSet(false);
        }
    };

    const handleConfirm = () => {
        setIsModalOpen(false);
        setIsNavigationSet(true);

        if (onConfirm) {
            onConfirm();
        }
    };

    useEffect(() => {
        if (isNavigationSet && lastLocation) {
            history.push(`${lastLocation.pathname}${lastLocation.search}`);
        }
        if (!isWhenBoolean && when?.value) {
            setIsModalOpen(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isNavigationSet, lastLocation, when]);

    const { cancelButtonText = 'Cancel', confirmButtonText = 'Confirm', contentText, title } = dialogProps;
    const promptWhen = isWhenBoolean && { when };

    return (
        <>
            <Prompt message={handleBlockedNavigation} {...promptWhen} />
            <Dialog maxWidth="sm" fullWidth open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">{contentText}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={handleCancel}>
                        {cancelButtonText}
                    </Button>
                    <Button variant="contained" onClick={handleConfirm}>
                        {confirmButtonText}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
export default RouteLeavingGuard;
