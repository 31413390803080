import { Box, Stack } from '@mui/material';
import { URLPaths } from 'models/enums';
import { ClickAppointmentValue, ClickApptAssumptionSpecialty } from 'domains/value/types';
import ForecastedValue1 from 'assets/images/svg/forecasted-value-1.svg';
import ForecastedValue2 from 'assets/images/svg/forecasted-value-2.svg';
import ForecastedValue3 from 'assets/images/svg/forecasted-value-3.svg';
import ForecastedValue4 from 'assets/images/svg/forecasted-value-4.svg';
import ForecastedValue5 from 'assets/images/svg/forecasted-value-5.svg';
import ForecastedValueCalculation from './ForecastedValueCalculation';

type ForecastedValueImageProps = {
    src: string;
    stepNumber: number;
};

const ForecastedValueImage = ({ src, stepNumber }: ForecastedValueImageProps) => (
    <img src={src} alt={`forecasted value: step ${stepNumber}`} width="100%" />
);

const getImage = (
    appointmentValue: number,
    sendId: number,
    specialty: ClickApptAssumptionSpecialty,
    route: URLPaths
) => {
    if (route === URLPaths.VALUE_SEND_SPECIALTY) {
        if (!specialty && !sendId) {
            return <ForecastedValueImage src={ForecastedValue1} stepNumber={1} />;
        }
        if ((specialty && !sendId) || (!specialty && sendId)) {
            return <ForecastedValueImage src={ForecastedValue2} stepNumber={2} />;
        }
        return <ForecastedValueImage src={ForecastedValue3} stepNumber={3} />;
    }

    if (route === URLPaths.VALUE_CONVERSION_EVENT) {
        return null;
    }

    if (route === URLPaths.VALUE_CONVERSION_METRIC) {
        return <ForecastedValueImage src={ForecastedValue4} stepNumber={4} />;
    }

    if (route === URLPaths.VALUE_CONVERSION_VALUE) {
        if (!appointmentValue) {
            return <ForecastedValueImage src={ForecastedValue4} stepNumber={4} />;
        }
        return <ForecastedValueImage src={ForecastedValue5} stepNumber={5} />;
    }

    if (appointmentValue && sendId && specialty) {
        return <ForecastedValueImage src={ForecastedValue5} stepNumber={5} />;
    }
};

export type Props = {
    specialty: ClickApptAssumptionSpecialty;
    isCalculationEnabled?: boolean;
    route?: URLPaths;
} & ClickAppointmentValue;

const ForecastedValue = ({
    appointmentValue,
    attendRate,
    scheduleRate,
    sendId,
    specialty,
    uniqueClickRate,
    isCalculationEnabled,
    route,
}: Props) => (
    <Box display="grid" gridTemplateRows="repeat(6, 1fr)" gridTemplateColumns="repeat(6, 1fr)">
        <Stack gridColumn="1/7" gridRow="1/7" alignItems="end">
            {getImage(appointmentValue, sendId, specialty, route)}
        </Stack>
        <Stack gridColumn="4/7" gridRow="1/5" justifyContent="center" alignItems="center">
            {isCalculationEnabled && (
                <ForecastedValueCalculation
                    sendId={sendId}
                    appointmentValue={appointmentValue}
                    attendRate={attendRate}
                    scheduleRate={scheduleRate}
                    uniqueClickRate={uniqueClickRate}
                />
            )}
        </Stack>
    </Box>
);
export default ForecastedValue;
