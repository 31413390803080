import { useCallback, useMemo, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Link, Stack, Typography } from '@mui/material';
import { MailOutline, Wysiwyg, Folder } from '@mui/icons-material';
import {
    Content,
    ContentTypeForComparison,
    LandingPage,
    Template,
    ContentModalTypes,
    ContentModalContentType,
} from 'domains/content/types';
import Table, { ColumnOption, renderDate } from 'domains/core/components/Table';
import useGetFolderLineage from 'hooks/queries/useGetFolderLineage';
import useContentQueryManager from 'hooks/useContentQueryParams';
import { extractContentId, getContentCreatePath, getContentType, getIsFolderContentActive } from './utils';
import useGetMoreOptionsCell from './useGetMoreOptionsCell';
import DeleteContentModal from '../DeleteContentModal';
import ContentPageFolderEmptyState from './ContentPageFolderEmptyState';
import FolderBreadcrumb from '../FolderBreadcrumb';
import RenameContentModal from '../RenameContentModal';

export type Props<ContentType> = {
    contentQuery: UseQueryResult<ContentType[], unknown>;
    shouldShowEmptyTable?: Boolean;
};

const ContentTable = <ContentType extends Template | LandingPage | Content>({
    contentQuery: { data: queryData, isLoading: isQueryLoading, isRefetching, refetch: refetchTableData },
    shouldShowEmptyTable,
}: Props<ContentType>) => {
    const { contentFolder: hasContentFoldersFeature } = useFlags();

    const history = useHistory();

    const { changeViewParams, folderId } = useContentQueryManager();

    const folderContentIsActive = getIsFolderContentActive(folderId);
    const { data: folderBreadcrumbData, refetch: refetchFolderBreadcrumbData } = useGetFolderLineage({
        folderId,
        reactQueryOptions: {
            enabled: folderContentIsActive,
        },
    });

    const [activeContent, setActiveContent] = useState(null);
    const [activeModal, setActiveModal] = useState('');

    /**
     * If the hasContentFoldersFeature is true, then we prepend the id with the type since
     * we can have a template, landing page, and folder all with the same id.
     */
    const data: object[] = useMemo(
        () =>
            queryData?.map((content: ContentType) => ({
                ...content,
                dateCreated: new Date(content.createdTimestamp),
                dateUpdated: new Date(content.lastModifiedTimestamp),
                id: hasContentFoldersFeature ? `${content.type}:${content.id}` : content.id,
            })),
        [queryData, hasContentFoldersFeature]
    );

    const renderContentIcon = (val: any) => {
        if (val.type === ContentTypeForComparison.EMAIL) return <MailOutline color="info" />;
        if (val.type === ContentTypeForComparison.LANDING_PAGE) return <Wysiwyg />;
        if (val.type === ContentTypeForComparison.FOLDER) return <Folder color="primary" />;
    };

    const handleModalOpen = useCallback(
        (content: ContentModalContentType, modalType: ContentModalTypes) => {
            setActiveContent(content);
            setActiveModal(modalType);
        },
        [setActiveContent, setActiveModal]
    );

    const columns: ColumnOption[] = useMemo(() => {
        const renderNameCell = (val: any) => {
            const name = `${val.row.original.isLayout ? 'TEMPLATE - ' : ''}${val.cell.value}`;
            if (hasContentFoldersFeature) {
                return (
                    <Stack alignItems="center" direction="row" spacing={2} color="warning.dark">
                        {renderContentIcon(val.row.original)}
                        <Typography>{name}</Typography>
                    </Stack>
                );
            }
            const type = getContentType(val.row.original, hasContentFoldersFeature);
            const isTemplate = type === ContentTypeForComparison.EMAIL;
            return <Link href={getContentCreatePath(isTemplate, val.row.original.id)}>{name}</Link>;
        };

        const nameColumn: ColumnOption = {
            Header: 'Name',
            accessor: 'name',
            sortType: 'string',
            align: 'left',
            Cell: (val: any) => renderNameCell(val),
        };
        const dateCreatedColumn: ColumnOption = {
            Header: 'Date Created',
            accessor: 'dateCreated',
            sortType: 'datetime',
            Cell: renderDate,
        };
        const dateUpdatedColumn: ColumnOption = {
            Header: 'Date Updated',
            accessor: 'dateUpdated',
            sortType: 'datetime',
            Cell: renderDate,
        };
        const typeColumn: ColumnOption = {
            Header: 'Type',
            accessor: 'type',
            Cell: (val: any) => {
                const type = getContentType(val.row.original, hasContentFoldersFeature);
                return type.toLowerCase();
            },
        };
        const moreOptionsColumn: ColumnOption = {
            Header: '',
            accessor: 'id',
            align: 'right',
            disableSortBy: true,
            Cell: (val: any) => {
                const type = getContentType(val.row.original, hasContentFoldersFeature);
                const isTemplate = type === ContentTypeForComparison.EMAIL;
                return useGetMoreOptionsCell({ isTemplate, handleModalOpen, val });
            },
        };

        if (hasContentFoldersFeature) {
            return [nameColumn, typeColumn, dateCreatedColumn, dateUpdatedColumn, moreOptionsColumn];
        }

        return [nameColumn, dateCreatedColumn, dateUpdatedColumn, typeColumn, moreOptionsColumn];
    }, [handleModalOpen, hasContentFoldersFeature]);

    // TODO: update to use real row type here
    const onRowClick = (row: any) => {
        const id = extractContentId(row.original.id);
        if (row.original.type === ContentTypeForComparison.FOLDER) {
            changeViewParams({ newFolderId: id });
        } else {
            const path = getContentCreatePath(row.original.type === ContentTypeForComparison.EMAIL, id);
            history.push(path);
        }
    };

    return (
        <>
            <DeleteContentModal
                activeContent={activeContent}
                handleModalClose={() => setActiveModal('')}
                isOpen={activeModal === ContentModalTypes.DELETE}
                refetch={refetchTableData}
                resetActiveContent={() => setActiveContent(null)}
            />
            <RenameContentModal
                activeContent={activeContent}
                handleModalClose={() => setActiveModal('')}
                isOpen={activeModal === ContentModalTypes.RENAME}
                refetch={folderContentIsActive ? refetchFolderBreadcrumbData : refetchTableData}
                resetActiveContent={() => setActiveContent(null)}
            />
            {hasContentFoldersFeature && (
                <FolderBreadcrumb
                    data={folderBreadcrumbData}
                    handleModalOpen={handleModalOpen}
                    showDeleteFolderOption={shouldShowEmptyTable}
                />
            )}
            {!isQueryLoading && (
                <Table
                    data={data}
                    columns={columns}
                    defaultSortingRules={[{ id: 'dateUpdated', desc: true }]}
                    isRefetching={isRefetching}
                    {...(hasContentFoldersFeature && { onRowClick })}
                    refetch={refetchTableData}
                />
            )}
            {shouldShowEmptyTable && <ContentPageFolderEmptyState />}
        </>
    );
};

export default ContentTable;
