import { useMemo } from 'react';
import { Box, Chip, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { getNumberWithCommas } from 'utils';
import { Segment } from 'domains/segments/types';
import { hasInvalidRelativeDates } from 'domains/segments/utils';
import { CampaignChannels } from 'domains/campaigns/types';
import { InfoOutlined } from '@mui/icons-material';
import { RecordType } from 'models/enums';
import { greyChipStyles } from 'domains/core/components/Chip/Chip';
import useAudienceCount from 'hooks/queries/useAudienceCount';
import useFeatureSettings from 'hooks/queries/useFeatureSettings';

const chipStyles = { ...greyChipStyles, textTransform: 'capitalize' };

type CommonAudienceProps = {
    count: number;
    isError: boolean;
    isLoading: boolean;
    channel?: CampaignChannels;
};

const AudienceCount = ({ count, isError, isLoading, channel }: CommonAudienceProps) => {
    const getDataTestAttribute = (channel?: CampaignChannels, suffix: string = 'segment-count'): string =>
        `${channel ? channel.toLowerCase() + '-' : ''}${suffix}`;

    const getChannelLabel = (channel?: CampaignChannels): string => {
        if (!channel) return '';
        if (channel === CampaignChannels.SMS) return `${channel} `;
        return `${channel[0].toUpperCase()}${channel.slice(1).toLowerCase()} `;
    };

    const getTooltipTitle = (channel?: CampaignChannels): string => {
        switch (channel) {
            case CampaignChannels.EMAIL:
                return 'This count filters out unsubscribed and invalid email addresses';
            case CampaignChannels.SMS:
                return 'This count filters out opted out and invalid phone numbers';
            default:
                return '';
        }
    };

    const channelLabel = getChannelLabel(channel);
    const countLabelSuffix = count === 1 ? 'Contact' : 'Contacts';
    const countLabel = ` ${channelLabel}${countLabelSuffix}`;

    const errorLabel = channel ? `${channelLabel}count not available` : 'Count not available';

    return (
        <Stack direction="row" justifyContent="flex-start">
            {isError && <WarningIcon sx={{ color: 'warning.main', mr: 1 }} />}
            {isError ? (
                <Typography variant="body2" width="100%">
                    {errorLabel}
                </Typography>
            ) : isLoading ? (
                <Typography variant="body2" width="100%">
                    <Skeleton data-testid="segment-count-skeleton" />
                </Typography>
            ) : (
                <Stack direction="row" alignItems="center" columnGap={0.5}>
                    <Typography variant="body2" width="100%">
                        <span data-test={getDataTestAttribute(channel)}>{getNumberWithCommas(count)}</span>
                        {countLabel}
                    </Typography>
                    {channel && (
                        <Tooltip
                            title={getTooltipTitle(channel)}
                            sx={{ pointerEvents: 'auto' }}
                            // Setting pointerEvents: 'auto' allows the Tooltip to show on hover if it's used in the label.
                        >
                            <InfoOutlined sx={{ fontSize: 14, mt: 0.5 }} />
                        </Tooltip>
                    )}
                </Stack>
            )}
        </Stack>
    );
};

type Props = {
    segment: Segment;
};

const AudienceDetails = ({ segment }: Props) => {
    const { id, segmentDefinition } = segment;

    const { data: featureSettings, isSuccess: isFeatureSettingsSuccess } = useFeatureSettings();
    const isSMSEnabled = isFeatureSettingsSuccess && featureSettings?.sms_enabled;

    const { data: allCountData, isError, isLoading } = useAudienceCount(id.toString(), undefined, {
        enabled: !hasInvalidRelativeDates(segment),
    });
    const { data: emailCountData, isError: isEmailCountError, isLoading: isEmailCountLoading } = useAudienceCount(
        segment.id.toString(),
        CampaignChannels.EMAIL,
        {
            enabled: !hasInvalidRelativeDates(segment),
        }
    );
    const { data: SMSCountData, isError: isSMSCountError, isLoading: isSMSCountLoading } = useAudienceCount(
        segment.id.toString(),
        CampaignChannels.SMS,
        {
            enabled: !hasInvalidRelativeDates(segment) && isSMSEnabled,
        }
    );

    const recordTypeChips = useMemo(() => {
        const contactRecordType = segmentDefinition.contactRecordType;

        if (
            !contactRecordType ||
            contactRecordType.length === 0 ||
            contactRecordType.includes(RecordType.ALL_CONTACTS)
        ) {
            return <Chip label={RecordType.ALL_CONTACTS} sx={chipStyles} />;
        }

        return (
            <Stack direction="row" flexWrap="wrap" spacing={1}>
                {contactRecordType.map((recordType, idx) => (
                    <Chip key={recordType + idx} label={recordType} sx={chipStyles} />
                ))}
            </Stack>
        );
    }, [segmentDefinition.contactRecordType]);

    return (
        <>
            <Typography>Audience Details</Typography>
            <AudienceCount
                count={allCountData?.count}
                isError={!isLoading && (!allCountData || isError)}
                isLoading={isLoading}
            />
            <AudienceCount
                channel={CampaignChannels.EMAIL}
                count={emailCountData?.count}
                isError={!isEmailCountLoading && (!emailCountData || isEmailCountError)}
                isLoading={isEmailCountLoading}
            />
            {isSMSEnabled && (
                <AudienceCount
                    channel={CampaignChannels.SMS}
                    count={SMSCountData?.count}
                    isError={!isSMSCountLoading && (!SMSCountData || isSMSCountError)}
                    isLoading={isSMSCountLoading}
                />
            )}
            <Box mt={1}>{recordTypeChips}</Box>
        </>
    );
};

export default AudienceDetails;
