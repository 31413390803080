import {
    EngagementAPIOperator,
    EngagementName,
    EngagementUIOperator,
    Segment,
    SegmentDemographic,
    SegmentEngagement,
} from 'domains/segments/types';
import { getInclusionOperatorFromAPIToUI } from 'domains/segments/utils';

const engagmentNegativeDescriptions = {
    [EngagementName.RECEIVED_SENDS]: EngagementUIOperator.DID_NOT_RECEIVE,
    [EngagementName.CLICKED_SENDS]: EngagementUIOperator.DID_NOT_CLICK,
    [EngagementName.OPENED_SENDS]: EngagementUIOperator.DID_NOT_OPEN,
};

const engagementDescriptions = {
    [EngagementName.RECEIVED_SENDS]: EngagementUIOperator.DID_RECEIVE,
    [EngagementName.CLICKED_SENDS]: EngagementUIOperator.DID_CLICK,
    [EngagementName.OPENED_SENDS]: EngagementUIOperator.DID_OPEN,
};

const getEngagementDescription = (engagementSegments: SegmentEngagement[]) =>
    engagementSegments.map((segment) => {
        const isNegativeOperator = segment.operator === EngagementAPIOperator.DOES_NOT_CONTAIN;
        if (isNegativeOperator) return `${engagmentNegativeDescriptions[segment.name]} email`;

        return `${engagementDescriptions[segment.name]} email`;
    });

const getDemographicDescription = (demographicSegments: SegmentDemographic[]) =>
    demographicSegments.map((segment) => {
        const { name, operator, value } = segment;
        const operatorForDisplay = getInclusionOperatorFromAPIToUI(operator);
        const valueForDisplay = Array.isArray(value) ? value.map((string) => ` ${string}`) : value;
        return `${name} ${operatorForDisplay} ${valueForDisplay}`;
    });

export const getSplitDescription = (segmentData: Segment) => {
    // TODO: update this to account for multiple segments of different categories within a single split
    const engagementSegments = segmentData?.segmentDefinition?.engagement;
    if (engagementSegments?.length) return getEngagementDescription(engagementSegments);

    const demographicSegments = segmentData?.segmentDefinition?.demographic;
    if (demographicSegments?.length) return getDemographicDescription(demographicSegments);
};
