import React from 'react';
import { Typography, TypographyProps } from '@mui/material';

/**
 * A wrapper around MUI's Typography component that defaults the text props that are common across
 * all TableHead TableCells.
 *
 * @returns The React node created by this component.
 */
const TableHeadText = ({ children, ...otherProps }: TypographyProps) => (
    <Typography
        sx={{ textTransform: 'capitalize' }}
        variant="body1"
        color="textPrimary"
        component="span"
        {...otherProps}
    >
        {children}
    </Typography>
);

export default TableHeadText;
