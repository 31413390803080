import { UseMutationOptions } from 'react-query';
import useMutation from 'hooks/useMutation';
import { ApiMethods, CuredApiPaths } from 'models/enums';
import { PathWithID } from 'models/types';
import { UpdateLandingPageInput } from 'domains/content/types';
import MutationKeys from './keys';

const useUpdateLandingPage = (
    landingPageId: string,
    mutateOptions: UseMutationOptions<unknown, Error, UpdateLandingPageInput>
) =>
    useMutation<UpdateLandingPageInput>(
        MutationKeys.UPDATE_LANDING_PAGE,
        new PathWithID(CuredApiPaths.LANDING_PAGES, Number(landingPageId)),
        mutateOptions,
        ApiMethods.PUT
    );

export default useUpdateLandingPage;
