import React from 'react';
import Button from 'domains/core/components/Button';
import { useAuth0Login } from 'auth/AuthContext';

/**
 * Login button configured with Auth0 authentication.
 *
 * @returns The React node created by this component.
 */
const LoginButton = () => {
    const { loginWithRedirect, setAuth0Provider } = useAuth0Login();

    const triggerLogin = () => {
        setAuth0Provider();
        loginWithRedirect();
    };

    return (
        <Button variant="contained" onClick={() => triggerLogin()}>
            Log In
        </Button>
    );
};

export default LoginButton;
