import { DateTime } from 'luxon';
import {
    SegmentFilterValue,
    DateRange,
    isDateRangeType,
    RelativeDateRangeValueAndUnit,
    BooleanOperator,
    DateOperator,
    NumericOperator,
    TimestampOperator,
    PercentileOperator,
    DateRangeOperator,
    InclusionUIOperator,
    DemographicContainsOperator,
    NullOperator,
} from 'domains/segments/types';
import { SQLType } from 'models/enums';

export const getOperatorValues = (sqlType: SQLType) => {
    const operatorMap: Record<SQLType, any[]> = {
        [SQLType.BOOLEAN]: [...Object.values(BooleanOperator), ...Object.values(NullOperator)],
        [SQLType.CHARACTER_VARYING]: [
            ...Object.values(BooleanOperator),
            ...Object.values(InclusionUIOperator),
            ...Object.values(NullOperator),
        ],
        [SQLType.DATE]: [
            ...Object.values(DateOperator).filter(
                (dateOperator) =>
                    dateOperator !== DateOperator.AFTER_OR_ON && dateOperator !== DateOperator.BEFORE_OR_ON
            ),
            ...Object.values(DateRangeOperator),
            ...Object.values(TimestampOperator),
            ...Object.values(NullOperator),
        ],
        [SQLType.DOUBLE_PRECISION]: [
            ...Object.values(BooleanOperator),
            ...Object.values(NumericOperator),
            ...Object.values(PercentileOperator),
            ...Object.values(NullOperator),
        ],
        [SQLType.INTEGER]: [
            ...Object.values(BooleanOperator),
            ...Object.values(NumericOperator),
            ...Object.values(PercentileOperator),
            ...Object.values(NullOperator),
        ],
        [SQLType.TIMESTAMP_WITH_TIME_ZONE]: [
            ...Object.values(DateRangeOperator),
            ...Object.values(TimestampOperator),
            ...Object.values(NullOperator),
        ],
        [SQLType.CHARACTER_VARYING_ARRAY]: [
            ...Object.values(DemographicContainsOperator),
            ...Object.values(NullOperator),
        ],
    };

    return (operatorMap[sqlType] || []).map((operator) => ({ label: operator, value: operator }));
};

export const getInitialDateValue = (segmentFilterValue: SegmentFilterValue): string => {
    if (typeof segmentFilterValue === 'boolean') {
        return DateTime.now().toISO();
    }
    const filterValueDate = DateTime.fromISO(segmentFilterValue as string);
    const filterValueIsValidDate = filterValueDate.isValid;

    // If segment filter already has a value from the copy audience option, then use that as the value for the DatePicker.
    return filterValueIsValidDate ? filterValueDate.toISO() : DateTime.now().toISO();
};

export const getInitialDateRangeValue = (
    segmentFilterValue: SegmentFilterValue,
    dateRangeValueKey: keyof DateRange
): string => {
    const filterValueDate =
        isDateRangeType(segmentFilterValue) && DateTime.fromISO(segmentFilterValue[dateRangeValueKey]);

    // If segment filter already has a value from the copy audience option, then use that as the value for the DatePicker.
    if (filterValueDate.isValid) {
        return filterValueDate.toISO();
    } else if (dateRangeValueKey === 'start') {
        // return today at 12am
        return DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISO();
    } else if (dateRangeValueKey === 'end') {
        // return today at just before midnight
        return DateTime.now().set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).toISO();
    }
};

export const getRelativeDateRangeOffset = (
    direction: 'the next' | 'the last',
    duration: number,
    unit: RelativeDateRangeValueAndUnit['unit'] = 'day'
): RelativeDateRangeValueAndUnit => {
    if (!direction || !duration) {
        return {
            value: null,
            unit,
        };
    }
    return {
        value: direction === 'the next' ? 0 : -duration,
        unit,
    };
};

const numericSQLTypes = [SQLType.DOUBLE_PRECISION, SQLType.INTEGER];

export const getNumericOrStringValue = (value: number | string, sqlType: SQLType) =>
    numericSQLTypes.includes(sqlType) && typeof value === 'string' ? Number(value) : value;

export const getInputType = (type: string | SQLType) =>
    numericSQLTypes.includes(type as SQLType) ? 'number' : 'string';
