import { Stack, Typography } from '@mui/material';

type Props = {
    icon: React.ReactNode;
    header: React.ReactNode;
    headerOthers?: React.ReactNode;
};

const PageHeader = ({ icon, header, headerOthers }: Props) => (
    <Stack direction="row" alignItems="start" columnGap={2} width="100%">
        <Typography variant="h4" color="primary.main" mt={0.5}>
            {icon}
        </Typography>
        {typeof header === 'string' ? (
            <Typography color="primary.main" variant="h4">
                {header}
            </Typography>
        ) : (
            header
        )}
        {headerOthers && (
            <Stack direction="row" alignSelf="center" width="100%">
                {headerOthers}
            </Stack>
        )}
    </Stack>
);

export default PageHeader;
