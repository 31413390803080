import { UseQueryOptions } from 'react-query';
import useQuery from 'hooks/useQuery';
import { CuredApiPaths } from 'models/enums';
import { SendForFilter } from '../../filtering/types';

export type ValueFilterReturnData = {
    specialties: string[];
    sends: SendForFilter[];
};

const useGetFilterData = (reactQueryOptions?: UseQueryOptions<any, Error>) => {
    const path = CuredApiPaths.REPORT_VALUE_FILTERS;

    return useQuery('value-overview-dashboard/filter-data', path, reactQueryOptions);
};

export default useGetFilterData;
