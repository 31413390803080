import { useEffect } from 'react';
import { CuredApiPaths } from 'models/enums';
import Card from 'pages/InsightsPage/dashboards/Card';
import { FiltersForApiState } from 'pages/InsightsPage/dashboards/filtering/types';
import useFilteredData from 'pages/InsightsPage/dashboards/filtering/useFilteredData';
import BarGraph from 'pages/InsightsPage/visualizations/BarGraph';
import { BarGraphProps } from 'pages/InsightsPage/visualizations/BarGraph/BarGraph';

type Props = {
    filtersForApi: FiltersForApiState;
};

const UniqueOpensCard = ({ filtersForApi }: Props) => {
    const { getData, isError, isLoading, data } = useFilteredData({
        key: 'curations-dashboard/unique-opens',
        path: CuredApiPaths.REPORT_CURATION_UNIQUE_OPENS,
    });

    useEffect(() => {
        getData(filtersForApi);
    }, [filtersForApi, getData]);

    return (
        <Card
            isError={isError}
            isLoading={isLoading}
            label="Unique Opens by Hour of Day"
            tooltip="24-hour clock displayed in Central Time"
        >
            <BarGraph
                bottomLegendLabel="hour of day"
                data={data as BarGraphProps['data']}
                leftLegendLabel="unique opens"
            />
        </Card>
    );
};

export default UniqueOpensCard;
