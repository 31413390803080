import { useEffect, useState } from 'react';
import { AutoGraph } from '@mui/icons-material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PageLayout from 'domains/core/components/PageLayout';
import Select from 'domains/core/components/Select';
import ErrorSnackbar from 'domains/core/components/Snackbars/ErrorSnackbar';
import useDataVisDashboardUrl from 'hooks/queries/useDataVisDashboardUrl';
import useFeatureSettings from 'hooks/queries/useFeatureSettings';
import ContactsDashboard from './dashboards/ContactsDashboard';
import CurationsDashboard from './dashboards/CurationsDashboard';
import ExecutiveOverviewDashboard from './dashboards/ExecutiveOverviewDashboard';
import ValueOverviewDashboard from './dashboards/ValueOverviewDashboard';
import PropensityDashboard from './dashboards/PropensityDashboard';
import SMSDashboard from './dashboards/SMSDashboard';
import LookerEmbed from './LookerEmbed';
import PowerBIEmbed from './PowerBIEmbed';

enum DashboardLabels {
    EXECUTIVE_OVERVIEW = 'executive overview',
    CONTACTS = 'contacts',
    CURATIONS = 'curations',
    VALUE_OVERVIEW = 'value overview',
    PROPENSITY_SCORING = 'propensity scoring',
    SMS = 'sms',
}

const InsightsPage = () => {
    const [dashboard, setDashboard] = useState<string>('');

    const { data: featureSettings } = useFeatureSettings();
    const { powerbiEnabled: isPowerBIEnabled, dashboardsPoc: hasNativeDashboardsFeature } = useFlags();

    const getDashboardLabel = (dashboard: string) => {
        if (dashboard === DashboardLabels.SMS) return dashboard.toUpperCase();
        return dashboard;
    };

    const dashboardOptions = featureSettings?.enabled_dashboards?.map((dashboard: string) => ({
        label: getDashboardLabel(dashboard),
        value: dashboard,
    }));

    useEffect(() => {
        if (dashboardOptions?.length && !dashboard) {
            setDashboard(dashboardOptions[0].label);
        }
    }, [dashboard, dashboardOptions, featureSettings]);

    const shouldRenderNativeDashboard = hasNativeDashboardsFeature;

    const { data, isSuccess, error, isError } = useDataVisDashboardUrl(isPowerBIEnabled, dashboard, {
        enabled: Boolean(dashboard) && !shouldRenderNativeDashboard,
    });

    const renderNativeDashboard = () => {
        if (dashboard === DashboardLabels.CONTACTS) return <ContactsDashboard />;
        if (dashboard === DashboardLabels.CURATIONS) return <CurationsDashboard />;
        if (dashboard === DashboardLabels.EXECUTIVE_OVERVIEW) return <ExecutiveOverviewDashboard />;
        if (dashboard === DashboardLabels.VALUE_OVERVIEW) return <ValueOverviewDashboard />;
        if (dashboard === DashboardLabels.PROPENSITY_SCORING) return <PropensityDashboard />;
        if (dashboard === DashboardLabels.SMS) return <SMSDashboard />;
    };

    const renderDashboard = () => {
        if (isPowerBIEnabled) {
            const embedToken = data?.reportDetails?.embedToken?.token;
            const embedUrl = data?.reportDetails?.embedUrl?.embedUrl;
            return <PowerBIEmbed embedURL={embedUrl} embedToken={embedToken} />;
        }
        return <LookerEmbed lookerEmbedURL={data.url} />;
    };

    return (
        <PageLayout
            header="insights"
            headerIcon={<AutoGraph fontSize="inherit" />}
            headerAction={
                <Select
                    fullWidth={false}
                    label="Dashboards"
                    sx={{ textTransform: 'capitalize', width: '12rem' }}
                    value={dashboard}
                    values={dashboardOptions}
                    onChange={(e: any) => setDashboard(e.target.value)}
                />
            }
        >
            {isError && <ErrorSnackbar errorMessage={error.message} />}
            {shouldRenderNativeDashboard && renderNativeDashboard()}
            {isSuccess && !shouldRenderNativeDashboard && renderDashboard()}
        </PageLayout>
    );
};

export default InsightsPage;
