import { CurationNavState, URLPaths } from 'models/enums';
import { Action } from 'models/types';
import { ROUTE_CHANGE } from 'state/constants';
import * as actionTypes from 'domains/campaigns/state/actionTypes';
import { getCurationNavState } from 'domains/campaigns/state/utils';
import { DelayState } from 'domains/campaigns/types';

export const initialState: DelayState = {
    navState: CurationNavState.PREVIEW,
    isComplete: false,
    delay: null,
    unit: null,
    errorMessage: '',
};

const isFormComplete = (state: DelayState) => Boolean(state.delay) && Boolean(state.unit) && !state.errorMessage;

const isFormInProgress = (state: DelayState) => Boolean(state.delay) || Boolean(state.unit);

export const delayReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case actionTypes.CONDITIONAL_SPLIT_EDIT_DELAY:
            const stateAfterEdit = {
                ...state,
                delay: action.payload.delay,
                unit: action.payload.unit,
                errorMessage: action.payload.errorMessage,
            };
            const isComplete = isFormComplete(stateAfterEdit);
            return {
                ...stateAfterEdit,
                navState: getCurationNavState(
                    isComplete,
                    isFormInProgress(stateAfterEdit),
                    URLPaths.CURATIONS_CONDITIONAL_SPLIT_DELAY,
                    undefined,
                    URLPaths.CURATIONS_CONDITIONAL_SPLIT
                ),
                isComplete: isComplete,
            };
        case ROUTE_CHANGE:
            return {
                ...state,
                navState: getCurationNavState(
                    isFormComplete(state),
                    false,
                    URLPaths.CURATIONS_CONDITIONAL_SPLIT_DELAY,
                    action,
                    URLPaths.CURATIONS_CONDITIONAL_SPLIT
                ),
            };
        case actionTypes.CONDITIONAL_SPLIT_RESET_CURATION:
            return initialState;
        default:
            return state;
    }
};

export default delayReducer;
