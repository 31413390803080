import WarningIcon from '@mui/icons-material/Warning';
import { Typography, Skeleton } from '@mui/material';
import ProgressCircleIcon from 'domains/core/components/SvgIcons/ProgressCircleIcon';
import ProgressCircleCheckedIcon from 'domains/core/components/SvgIcons/ProgressCircleCheckedIcon';
import { getNumberWithCommas } from 'utils';
import { SegmentPreviewResponse } from 'hooks/mutations/useSegmentPreviewCount';

export type Props = {
    data: SegmentPreviewResponse;
    error: Error;
    loading: boolean;
    isAudiencePreviewed: boolean;
    isError: boolean;
};

const PreviewCount = ({ data, error, isError, loading, isAudiencePreviewed }: Props) => {
    if (isAudiencePreviewed) {
        if (loading) {
            return (
                <>
                    <ProgressCircleIcon data-testid="progress-icon" sx={{ color: 'primary.main', mr: 1 }} />
                    <Skeleton data-testid="skeleton" width={80} />
                </>
            );
        }
        if (isError) {
            return (
                <>
                    <WarningIcon sx={{ color: 'warning.main', mr: 1 }} />
                    <Typography variant="body2">{error.message}</Typography>
                </>
            );
        }
        if (data) {
            return (
                <>
                    <ProgressCircleCheckedIcon sx={{ color: 'action.active', mr: 1 }} />
                    <Typography variant="body2">Count: {getNumberWithCommas(data.count)}</Typography>
                </>
            );
        }
    }
    return (
        <>
            <ProgressCircleIcon sx={{ color: 'action.disabled', mr: 1 }} />
            <Typography variant="body2" color="action.disabled">
                Count
            </Typography>
        </>
    );
};

export default PreviewCount;
