import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton as MuiIconButton, Theme, Tooltip } from '@mui/material';
import { SxProps } from '@mui/system';

export type Props = {
    /**
     * The title will be used as the aria label if this prop is not provided. If there is no title, there should be a value for this prop.
     */
    ariaLabel?: string;
    /**
     * Pass this to style the wrapper for IconButton
     */
    buttonWrapperCss?: object;
    handleClick: () => void;
    Icon?: any; // A component from https://material-ui.com/components/icons (https://material-ui.com/components/icons/#usage)
    iconSx?: SxProps<Theme>;
    sx?: SxProps<Theme>;
    title?: string;
    [other: string]: any;
};

const IconButton = ({
    ariaLabel: ariaLabelProp,
    buttonWrapperCss,
    handleClick,
    Icon = VisibilityIcon,
    iconSx,
    sx,
    title,
    ...other
}: Props) => {
    const ariaLabel = ariaLabelProp ?? title?.toLowerCase();
    return (
        <Tooltip title={title ?? ''} aria-label={ariaLabel} sx={sx}>
            <span style={buttonWrapperCss}>
                <MuiIconButton aria-label={ariaLabel} onClick={handleClick} {...other}>
                    <Icon sx={iconSx} />
                </MuiIconButton>
            </span>
        </Tooltip>
    );
};

export default IconButton;
