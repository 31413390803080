import { CuredApiPaths } from 'models/enums';
import { PathWithID } from 'models/types';
import { ApiMethods } from 'models/enums';
import { UseMutationOptions } from 'react-query';
import useMutation from '../useMutation';
import MutationKeys from './keys';

export type UpdateSegmentMutationInput = {
    name?: string;
    isDisabled?: boolean;
};

const useUpdateSegment = (
    segmentId: number,
    mutateOptions?: UseMutationOptions<unknown, Error, UpdateSegmentMutationInput>
) =>
    useMutation<UpdateSegmentMutationInput>(
        MutationKeys.UPDATE_SEGMENT(segmentId),
        new PathWithID(CuredApiPaths.SEGMENTS, segmentId),
        mutateOptions,
        ApiMethods.PATCH
    );

export default useUpdateSegment;
