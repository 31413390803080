import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Button from 'domains/core/components/Button';
import { URLPaths } from 'models/enums';
import { route as routeSelector } from 'state/selectors';
import { ButtonProps } from '@mui/material';
import { RootState } from 'state';

export type Props = {
    routes: string[];
    selectIsRouteDisabled: (state: RootState) => (route: string) => boolean;
    buttonTextConditional?: string;
    routeConditional?: string;
    routeConditionalDisabled?: (state: any) => boolean;
    handleRouteConditional?: () => void;
};

const LegacyButtonContinue = ({
    routes,
    selectIsRouteDisabled,
    buttonTextConditional,
    routeConditional,
    routeConditionalDisabled,
    handleRouteConditional,
    ...props
}: Props & ButtonProps) => {
    const history = useHistory();
    const route = useSelector(routeSelector);
    const continueButtonText = route === routeConditional ? buttonTextConditional : 'Continue';

    const isRouteDisabled = useSelector(selectIsRouteDisabled);
    const isRouteConditionalDisabled = useSelector(routeConditionalDisabled ?? ((state: any) => false));

    const getNextRoute = (currentRoute: URLPaths): string => {
        const currentRouteIndex = routes.indexOf(currentRoute);
        const nextRouteIndex = currentRouteIndex + 1;
        return routes[nextRouteIndex];
    };

    const handleClick = () => (route === routeConditional ? handleRouteConditional() : history.push(nextRoute));

    const nextRoute = getNextRoute(route);
    const isNextRouteDisabled = Boolean(nextRoute) && isRouteDisabled(nextRoute);

    const isRouteConditionalAndIsDisabled = route === routeConditional ? isRouteConditionalDisabled : false;

    return (
        <Button
            disabled={isNextRouteDisabled || isRouteConditionalAndIsDisabled}
            onClick={handleClick}
            variant="outlined"
            {...props}
        >
            {continueButtonText}
        </Button>
    );
};

export default LegacyButtonContinue;
