import { Stack } from '@mui/material';
import { Send } from 'domains/campaigns/types';
import Autocomplete from 'domains/core/components/Autocomplete';
import { AutocompleteOption } from 'domains/core/components/Autocomplete/Autocomplete';
import AutocompleteSend from 'domains/value/components/AutocompleteSend';
import { ClickApptAssumptionSpecialty } from 'domains/value/types';
import { useState } from 'react';

type Props = {
    sendId: Send['id'];
    specialty: ClickApptAssumptionSpecialty;
    isPreviewVisible: boolean;
    setSendId: (sendId: Send['id']) => void;
    setSpecialty: (specialty: ClickApptAssumptionSpecialty) => void;
    onClickEmailPreview: () => void;
};

const optionsSpecialty: AutocompleteOption<ClickApptAssumptionSpecialty>[] = Object.values(
    ClickApptAssumptionSpecialty
).map((specialty) => ({
    label: specialty,
    value: specialty,
}));

const ValueCalculatorSendSpecialtyForm = ({
    sendId,
    specialty,
    isPreviewVisible,
    setSendId,
    setSpecialty,
    onClickEmailPreview,
}: Props) => {
    const [sendInput, setSendInput] = useState('');
    const [specialtyInput, setSpecialtyInput] = useState('');

    return (
        <Stack rowGap={4}>
            <AutocompleteSend
                value={sendId}
                inputValue={sendInput}
                isPreviewVisible={isPreviewVisible}
                onChange={(_e, option) => setSendId(option?.value)}
                onInputChange={(_e, value) => setSendInput(value)}
                onClickEmailPreview={onClickEmailPreview}
                labelIconTooltipText="Pick from any scheduled send."
            />
            <Autocomplete
                value={specialty ?? null}
                inputValue={specialtyInput}
                options={optionsSpecialty}
                isOptionEqualToValue={(option, value) => option.value === value}
                onChange={(_e, option) => setSpecialty(option?.value)}
                onInputChange={(_e, value) => setSpecialtyInput(value)}
                label="Select specialty"
                labelIconTooltipText="The specialty determines default values for your calculation."
                placeholder="Select specialty"
                type="string"
            />
        </Stack>
    );
};

export default ValueCalculatorSendSpecialtyForm;
