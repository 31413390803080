import { Box, Grid, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Button from 'domains/core/components/Button';
import smsCurationImage from 'assets/images/svg/curation/sms-curation.svg';
import theme from 'theme';

export type Props = {
    header: string;
    stepName: string;
    guidingText: string;
    canContinue: boolean;
    children?: JSX.Element;
    continueButtonText?: string;
    continueButtonIcon?: React.ReactNode;
    onContinue?: () => void;
};

const useStyles = makeStyles({
    centerOnMobile: {
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
        },
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end',
        },
    },
});

/**
 * Layout for curation. Form on the left, image on the right.
 *
 * @param header The title shown at the top of the layout.
 * @param stepName Text above the page's heading to describe the step of the curation process (curation, audience, etc.)
 * @param guidingText Instructions for the user, shown below the header.
 * @param canContinue Controls whether the continue button is disabled or enabled.
 * @param children The children placed inside the form.
 * @param continueButtonText The optional parameter for setting the text inside the continue button.
 * @param onContinue The optional parameter for changing the logic executed in the continue button.
 *
 * @returnsThe React node created by this component.
 */
const CurationFormImageLayout = ({
    header,
    stepName,
    guidingText,
    canContinue,
    children,
    continueButtonText = 'Continue',
    continueButtonIcon,
    onContinue = undefined,
}: Props) => {
    const classes = useStyles();
    const hiddenXlDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xl'));
    const hiddenMdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    return (
        <Grid container>
            {!hiddenXlDown && <Grid item xl={1} />}
            <Grid item xs={12} md={6} xl={4}>
                <Stack alignItems="center" width="100%">
                    <Typography variant="caption" align="center" color="secondary.dark" mb={1}>
                        {stepName}
                    </Typography>
                    <Typography variant="h3" align="center" mb={3}>
                        {header}
                    </Typography>
                    <Typography variant="body1" align="center" mb={6}>
                        {guidingText}
                    </Typography>
                    <Box mb={6}>{children}</Box>
                </Stack>
            </Grid>
            {!hiddenXlDown && <Grid item xl={1} />}
            <Grid item xs={12} sm={12} md={6} xl={6}>
                <Stack direction="row" justifyContent="flex-end" width="100%" pb={8} className={classes.centerOnMobile}>
                    <Button
                        variant="outlined"
                        disabled={!canContinue}
                        endIcon={continueButtonIcon}
                        onClick={onContinue}
                    >
                        {continueButtonText}
                    </Button>
                </Stack>
                {!hiddenMdDown && (
                    <Stack direction="row" justifyContent="center" pl={2}>
                        <Stack direction="row" justifyContent="center" height="100%" width="100%" maxWidth="48rem">
                            <img src={smsCurationImage} alt="sms curation" width="75%" />
                        </Stack>
                    </Stack>
                )}
            </Grid>
        </Grid>
    );
};

export default CurationFormImageLayout;
