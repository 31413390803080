import { useEffect } from 'react';
import { CuredApiPaths } from 'models/enums';
import Card from 'pages/InsightsPage/dashboards/Card';
import { FiltersForApiState } from 'pages/InsightsPage/dashboards/filtering/types';
import useFilteredData from 'pages/InsightsPage/dashboards/filtering/useFilteredData';
import BarGraph from 'pages/InsightsPage/visualizations/BarGraph';
import { BarGraphProps } from 'pages/InsightsPage/visualizations/BarGraph/BarGraph';

type Props = {
    filtersForApi: FiltersForApiState;
};

const UniqueClicksCard = ({ filtersForApi }: Props) => {
    const { getData, isError, isLoading, data } = useFilteredData({
        key: 'curations-dashboard/unique-clicks',
        path: CuredApiPaths.REPORT_CURATION_UNIQUE_CLICKS,
    });

    useEffect(() => {
        getData(filtersForApi);
    }, [filtersForApi, getData]);

    return (
        <Card isError={isError} isLoading={isLoading} label="Unique Clicks by Day of the Week">
            <BarGraph
                bottomLegendLabel="day of week"
                data={data as BarGraphProps['data']}
                leftLegendLabel="unique clicks"
            />
        </Card>
    );
};

export default UniqueClicksCard;
