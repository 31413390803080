import { CuredApiPaths } from 'models/enums';
import { PathWithID } from 'models/types';
import { Send } from 'domains/campaigns/types';

import { UseQueryOptions } from 'react-query';
import useQuery from '../useQuery';
import QueryKeys from './keys';

const useSendById = (sendId: number, reactQueryOptions?: UseQueryOptions<Send, Error>) =>
    useQuery(QueryKeys.SEND_ID(`${sendId}`), new PathWithID(CuredApiPaths.SENDS, sendId), reactQueryOptions);

export default useSendById;
