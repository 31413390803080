import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { createSelector } from '@reduxjs/toolkit';
import { Stack } from '@mui/system';
import { URLPaths } from 'models/enums';
import useSendById from 'hooks/queries/useSendById';
import useCreateClickAppointmentValueAssumption, {
    ValueAssumptionInput,
} from 'hooks/mutations/useCreateClickAppointmentValueAssumption';
import Button from 'domains/core/components/Button';
import LegacyButtonContinue from 'domains/core/components/LegacyButtonContinue';
import Flow from 'domains/core/components/Flow';
import EmailPreviewContent from 'domains/content/components/EmailPreviewContent';
import { ValueCalculatorRoutes } from 'domains/value/types';
import ValueCalculatorNav from 'domains/value/pages/ValueCalculatorNav';
import {
    isAppointmentValueInRange,
    selectIsSaveDisabled,
    selectIsValueRouteDisabled,
} from 'domains/value/state/selectors';
import ForecastedValue from 'domains/value/components/ForecastedValue';
import ValueFlowRouteLeavingGuard from './ValueFlowRouteLeavingGuard';
import ErrorSnackbar from 'domains/core/components/Snackbars/ErrorSnackbar';
import SuccessDialog from 'domains/core/components/SuccessDialog';
import { useEffect, useState } from 'react';
import { setIsPreviewVisible, setIsSaved, reset } from 'domains/value/state/value';
import { route as routeSelector } from 'state/selectors';
import { ArrowBack } from '@mui/icons-material';
import { recordSatismeterEvent } from 'helpers/injectSatismeter/injectSatismeter';

const selectValueState = (state: any) => state.value;
const selector = createSelector(
    routeSelector,
    selectIsValueRouteDisabled,
    selectIsSaveDisabled,
    selectValueState,
    (route, isRouteDisabled, isSaveDisabled, valueState) => ({
        route,
        isRouteDisabled,
        isSaveDisabled,
        valueState,
    })
);

const ValueCalculatorFlow = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { route, isRouteDisabled, isSaveDisabled, valueState } = useSelector(selector);
    const {
        appointmentValue,
        attendRate,
        clickUrl,
        contactEventRecordType,
        isPreviewVisible,
        previewClickUrl,
        scheduleRate,
        sendId,
        specialty,
        uniqueClickRate,
    } = valueState;
    const { data: send } = useSendById(sendId, {
        enabled: Boolean(sendId),
    });
    const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
    const { mutate, isError, isLoading, error } = useCreateClickAppointmentValueAssumption({
        onSuccess: () => {
            dispatch(setIsSaved(true));
            setIsSuccessDialogOpen(true);
        },
    });

    useEffect(() => {
        // If the user manually routes to a page that is disabled (i.e refreshes, or changes url), then redirect to the main value page
        if (isRouteDisabled(route)) {
            history.push(URLPaths.VALUE);
            dispatch(reset());
        }
    }, [dispatch, history, isRouteDisabled, route]);

    useEffect(() => {
        // When the user is on the send specialty page, the email preview should be closed to show the forecasted value image (default state).
        if (route === URLPaths.VALUE_SEND_SPECIALTY) {
            dispatch(setIsPreviewVisible(false));
        }

        // When the user is on the conversion event page, the email preview should be opened so they can select a link.
        if (route === URLPaths.VALUE_CONVERSION_EVENT) {
            dispatch(setIsPreviewVisible(true));
        }

        // When the user is on the conversion metric or conversion value page, the email preview should be closed because there is no control for opening/closing the preview.
        if (route === URLPaths.VALUE_CONVERSION_METRIC || route === URLPaths.VALUE_CONVERSION_VALUE) {
            dispatch(setIsPreviewVisible(false));
        }
    }, [dispatch, history, route]);

    const valueAssumptionInput: ValueAssumptionInput = {
        appointmentValue,
        attendRate,
        clickUrl,
        contactEventRecordType,
        scheduleRate,
        sendId,
        specialty,
        uniqueClickRate,
    };

    const saveValueAssumption = () => mutate(valueAssumptionInput);

    const handleExitCurationWorkflow = (path: URLPaths) => {
        history.push(path);
        dispatch(reset());
        recordSatismeterEvent('publish_curation_popup');
    };

    return (
        <Flow
            feedback={
                <>
                    <ValueFlowRouteLeavingGuard />
                    {isError && !isLoading && <ErrorSnackbar errorMessage={error.message} />}
                    <SuccessDialog
                        title="high five!"
                        body="Nice work, you've successfully saved your value calculation. For calculations that have sent, you can view estimates and track progress over time in Insights."
                        isOpen={isSuccessDialogOpen}
                        setIsOpen={setIsSuccessDialogOpen}
                        onClose={() => setIsSuccessDialogOpen(false)}
                        buttonOneText="values"
                        buttonTwoText="insights"
                        buttonOneOnClick={() => handleExitCurationWorkflow(URLPaths.VALUE)}
                        buttonTwoOnClick={() => handleExitCurationWorkflow(URLPaths.INSIGHTS)}
                    />
                </>
            }
            topRight={
                <Stack direction="row" alignItems="center" columnGap={2}>
                    <Button startIcon={<ArrowBack />} onClick={() => history.goBack()}>
                        Back
                    </Button>
                    <LegacyButtonContinue
                        buttonTextConditional="Save"
                        handleRouteConditional={saveValueAssumption}
                        routes={ValueCalculatorRoutes}
                        routeConditional={URLPaths.VALUE_CONVERSION_VALUE}
                        routeConditionalDisabled={(state) =>
                            selectIsSaveDisabled(state) || !isAppointmentValueInRange(appointmentValue)
                        }
                        selectIsRouteDisabled={selectIsValueRouteDisabled}
                    />
                </Stack>
            }
            bottomLeft={<ValueCalculatorNav />}
            bottomRight={
                <>
                    {isPreviewVisible && (
                        <EmailPreviewContent
                            isCloseEnabled={route !== URLPaths.VALUE_CONVERSION_EVENT}
                            isOpen={isPreviewVisible}
                            emailTemplateHTML={send?.contentSnapshot}
                            onClose={() => dispatch(setIsPreviewVisible(false))}
                            selectedLink={previewClickUrl}
                        />
                    )}
                    {!isPreviewVisible && (
                        <ForecastedValue
                            appointmentValue={appointmentValue}
                            attendRate={attendRate}
                            scheduleRate={scheduleRate}
                            sendId={sendId}
                            specialty={specialty}
                            uniqueClickRate={uniqueClickRate}
                            isCalculationEnabled={route === URLPaths.VALUE_CONVERSION_VALUE && !isSaveDisabled}
                            route={route}
                        />
                    )}
                </>
            }
        />
    );
};

export default ValueCalculatorFlow;
