import { CuredApiPaths } from '../../models/enums';
import { UseMutationOptions } from 'react-query';
import { ApiMethods } from '../../models/enums';
import useMutation from '../useMutation';
import MutationKeys from './keys';

const useUpdateCampaignStatus = (
    campaignId: string,
    status: string,
    mutateOptions?: UseMutationOptions<unknown, Error>
) => {
    const path = `${CuredApiPaths.CAMPAIGNS}/${campaignId}/status/${status}`;

    return useMutation(MutationKeys.UPDATE_CAMPAIGN_STATUS, path, mutateOptions, ApiMethods.PATCH);
};

export default useUpdateCampaignStatus;
