import React from 'react';
import { Stack, Typography } from '@mui/material';

type Props = {
    caption: string | JSX.Element;
    header: string;
    guidingText?: string;
    children?: React.ReactNode;
};

/**
 * Empty state layout template for pages where no data is available.
 * @param caption The small text above the header.
 * @param header The header text of the page.
 * @param guidingText The subtext of the page to guide the user's actions.
 *
 * @returns
 */
const EmptyStateLayout = ({ caption, header, guidingText, children }: Props) => (
    <Stack height="100%" alignItems="center" justifyContent="center" textAlign="center" rowGap={4}>
        {typeof caption === 'string' ? (
            <Typography variant="caption" mb={-2}>
                {caption}
            </Typography>
        ) : (
            caption
        )}
        <Typography variant="h3">{header}</Typography>
        <Typography variant="body1">{guidingText}</Typography>
        <Stack justifyContent="center">{children}</Stack>
    </Stack>
);

export default EmptyStateLayout;
