import { useState } from 'react';
import { Email, Send } from 'domains/campaigns/types';
import { EmailForCampaignDetails } from 'hooks/useEmailById/useEmailById';

type EmailBySendIdProps = Email & {
    isPreviewOpen: boolean;
    closePreview: () => void;
    togglePreview: () => void;
};

const useEmailBySendId = (sendId: Send['id']): EmailBySendIdProps => {
    const email = EmailForCampaignDetails(sendId);

    const [isPreviewOpen, setIsPreviewOpen] = useState(false);

    const closePreview = () => setIsPreviewOpen(false);
    const togglePreview = () => setIsPreviewOpen(!isPreviewOpen);

    return {
        ...email,
        isPreviewOpen,
        closePreview,
        togglePreview,
    };
};

export default useEmailBySendId;
