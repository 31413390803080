import { CalendarTodayOutlined } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { getPercentageFromRate } from 'utils';
import useSegmentFilters from 'hooks/queries/useSegmentFilters';
import FormLayout from 'domains/core/components/FormLayout';
import { ValueCalculatorStep } from 'domains/value/types';
import {
    resetAttendRate as resetAttendRateActionCreator,
    resetScheduleRate as resetScheduleRateActionCreator,
    setAttendRate as setAttendRateActionCreator,
    setContactEventRecordType as setContactEventRecordTypeActionCreator,
    setScheduleRate as setScheduleRateActionCreator,
} from 'domains/value/state/value';
import ValueCalculatorConversionMetricForm from './ValueCalculatorConversionMetricForm';

const ValueCalculatorConversionMetricPage = (props: RouteComponentProps) => {
    const dispatch = useDispatch();

    const attendRate = getPercentageFromRate(useSelector((state: any) => state.value.attendRate));
    const contactEventRecordType = useSelector((state: any) => state.value.contactEventRecordType);
    const scheduleRate = getPercentageFromRate(useSelector((state: any) => state.value.scheduleRate));

    const { data: segmentFilters, isLoading: isSegmentFiltersLoading } = useSegmentFilters({
        version: 2,
    });

    const contactEventRecordTypesOptions = segmentFilters?.contactEvent?.recordTypes?.map((recordTypeFilter) => ({
        label: recordTypeFilter.recordType,
        value: recordTypeFilter.recordType,
    }));

    const header = 'choose the conversion metric';

    const resetAttendRate = () => dispatch(resetAttendRateActionCreator());
    const resetScheduleRate = () => dispatch(resetScheduleRateActionCreator());

    const setAttendRate = (attendRate: number) => dispatch(setAttendRateActionCreator(attendRate));
    const setContactEventRecordType = (contactEventRecordType: string) =>
        dispatch(setContactEventRecordTypeActionCreator(contactEventRecordType));
    const setScheduleRate = (scheduleRate: number) => dispatch(setScheduleRateActionCreator(scheduleRate));

    return (
        <>
            <FormLayout
                header={header}
                icon={<CalendarTodayOutlined />}
                stepName={ValueCalculatorStep.CONVERSION_METRIC}
                guidingText="Confirm or adjust the default schedule and attendance rates for contacts who click the conversion link."
                guidingTooltipText="Cured combines industry averages with real customer data by specialty to provide a healthcare-specific recommendation."
            >
                <ValueCalculatorConversionMetricForm
                    attendRate={attendRate}
                    contactEventRecordType={contactEventRecordType}
                    contactEventRecordTypesOptions={contactEventRecordTypesOptions}
                    scheduleRate={scheduleRate}
                    isLoading={isSegmentFiltersLoading}
                    resetAttendRate={resetAttendRate}
                    resetScheduleRate={resetScheduleRate}
                    setAttendRate={setAttendRate}
                    setContactEventRecordType={setContactEventRecordType}
                    setScheduleRate={setScheduleRate}
                />
            </FormLayout>
        </>
    );
};

export default ValueCalculatorConversionMetricPage;
