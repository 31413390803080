import { useCustomAuth } from 'auth/AuthContext';
import useConfig from 'hooks/useConfig';

export type LDUser = {
    key: string;
    email: string;
    custom: {
        role: string;
    };
};

/**
 * Gets the LD user used for targeting.
 * @returns The LD user or undefined if the user is not authenticated
 */
const useLDUser = (): LDUser | undefined => {
    const { isAuthenticated, user, isInnovaccerLogin } = useCustomAuth();

    const {
        config: { AUTH0_AUDIENCE_ENDPOINT },
    } = useConfig();

    if (!isAuthenticated) return;
    if (isInnovaccerLogin) {
        const { email, role } = user;
        const ldUser: LDUser = { key: email, email, custom: { role } };
        return ldUser;
    }
    const { email } = user;
    const roles = user[AUTH0_AUDIENCE_ENDPOINT];
    const role: string = roles[0];
    const ldUser: LDUser = { key: email, email, custom: { role } };
    return ldUser;
};

export default useLDUser;
