import { Chip as MuiChip, ChipProps } from '@mui/material';
import theme from 'theme';

export const greyChipStyles = {
    borderRadius: '6px',
    backgroundColor: theme.palette.grey[300],
    '& .MuiChip-deleteIcon': {
        fontSize: '16px',
        color: 'text.primary',
    },
};

const defaultChipStyles = {
    // default chip styles are for use in a table
    borderRadius: '6px',
    fontSize: '14px',
    fontWeight: '500',
    height: '28px',
    padding: '1px 8px 3px',
    // overriding padding coming from Chip's label
    span: {
        padding: 0,
    },
};

type Props = {
    variant?: ChipProps['variant'];
    label: string;
    sx?: object;
};

const Chip = ({ label, sx, variant }: Props) => (
    <MuiChip color="primary" label={label} sx={{ ...defaultChipStyles, ...sx }} variant={variant} />
);

export default Chip;
