import { PowerBIEmbed as PowerBIContainer } from 'powerbi-client-react';
import * as pbi from 'powerbi-client';
import { Stack } from '@mui/material';
import './PowerBIEmbed.css';

type Props = {
    embedURL: string;
    embedToken: string;
};

const PowerBIEmbed = ({ embedURL, embedToken }: Props) => {
    const powerBIConfig: pbi.IEmbedConfiguration = {
        type: 'report',
        tokenType: pbi.models.TokenType.Embed,
        accessToken: embedToken,
        embedUrl: embedURL,
        permissions: pbi.models.Permissions.Read,
        viewMode: pbi.models.ViewMode.View,
        settings: {
            panes: {
                filters: {
                    expanded: false,
                    visible: false,
                },
            },
            navContentPaneEnabled: false,
        },
    };

    return (
        <>
            <Stack sx={{ height: '100%' }}>
                <PowerBIContainer embedConfig={powerBIConfig} cssClassName="powerbi-embed-container" />
            </Stack>
        </>
    );
};

export default PowerBIEmbed;
