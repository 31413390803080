import Card from 'pages/InsightsPage/dashboards/Card';
import PieChart from 'pages/InsightsPage/visualizations/PieChart';
import useContactSex from './useContactSex';

const SexCard = () => {
    const { data, isError, isLoading } = useContactSex();

    return (
        <Card isError={isError} isLoading={isLoading} label="Sex">
            <PieChart data={data} />
        </Card>
    );
};

export default SexCard;
