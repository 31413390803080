import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import LegacyCampaignFlow from 'domains/campaigns/components/LegacyCampaignFlow';
import LegacyButtonContinue from 'domains/core/components/LegacyButtonContinue';
import CampaignFlowRouteLeavingGuard from 'domains/campaigns/components/CampaignFlowRouteLeavingGuard';
import {
    selectConditionalSplitRoutes,
    selectIsConditionalSplitRouteDisabled,
    selectConditionalSplitSecondEmailTimestamp,
    selectIsConditionalSplitCurationFormComplete,
} from 'domains/campaigns/state/selectors';
import QueryKeys from 'hooks/queries/keys';
import useSegments from 'hooks/queries/useSegments';
import useCreateCampaign, { CreateCampaignMutationInput } from 'hooks/mutations/useCreateCampaign';
import useTemplates from 'hooks/queries/useTemplates';
import { CampaignType, CurationSegmentsAction, URLPaths } from 'models/enums';
import { CONDITIONAL_SPLIT_EDIT_METADATA, CONDITIONAL_SPLIT_RESET_CURATION } from 'domains/campaigns/state/actionTypes';
import { editPublish } from 'domains/campaigns/state/conditionalSplit/publish/publishActions';
import { CampaignChannels, EmailSendInput, SendRecurrenceFrequency } from 'domains/campaigns/types';
import ConditionalSplitNavBar from './ConditionalSplitNavBar';
import ConditionalSplitNav from './ConditionalSplitNav';
import { getFormattedParentAudienceDelayInterval } from '../../CurationsPage/CampaignDetailsPage/timeFormatting';
import { recordSatismeterEvent } from 'helpers/injectSatismeter/injectSatismeter';

const formatSendForAPI = (isRecurringCuration: boolean, send: EmailSendInput): EmailSendInput => {
    if (isRecurringCuration) {
        const { scheduledTimestamp, ...restOfSend } = send;
        return { ...restOfSend };
    }
    if (!isRecurringCuration) {
        const { parentAudienceDelayInterval, parentIndex, ...restOfSend } = send;
        return { ...restOfSend };
    }
};

const ConditionalSplitPage = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
    const conditionalSplitRoutes = useSelector(selectConditionalSplitRoutes);

    // Prefetch the cached data for the different curation pages
    useSegments();
    const { data: templates } = useTemplates();

    const {
        mutate,
        isError: isCreateCampaignError,
        isLoading: isCreateCampaignLoading,
        error: createCampaignError,
    } = useCreateCampaign({
        onSuccess: () => {
            // Invalidate queries used for the sends and campaigns tables
            queryClient.invalidateQueries(QueryKeys.CAMPAIGNS);

            setIsSuccessDialogOpen(true);
        },
    });

    const state = useSelector((state: any) => state.curation.conditionalSplit);
    const conditionalSplitSecondEmailTimestamp = useSelector(selectConditionalSplitSecondEmailTimestamp);

    const publishCampaign = () => {
        dispatch(editPublish());

        const { curationName } = state.metadata;
        const {
            preheader: preheader1,
            senderProfileId: senderProfileId1,
            subjectLine: subjectLine1,
            templateId: templateId1,
        } = state.content1;

        const { action: segmentsMeetsAction } = state.segmentsMeetsAction;
        const isSegmentsMeetsActionSendEmail = segmentsMeetsAction === CurationSegmentsAction.SEND_EMAIL;
        const {
            preheader: preheader2,
            senderProfileId: senderProfileId2,
            subjectLine: subjectLine2,
            templateId: templateId2,
        } = state.segmentsMeetsContent;

        const { action: segmentsMeetsNotAction } = state.segmentsMeetsNotAction;
        const isSegmentsMeetsNotActionSendEmail = segmentsMeetsNotAction === CurationSegmentsAction.SEND_EMAIL;
        const {
            preheader: preheader3,
            senderProfileId: senderProfileId3,
            subjectLine: subjectLine3,
            templateId: templateId3,
        } = state.segmentsMeetsNotContent;

        /**
         * TODO: use getSendNames from time delay if possible + abstract to shared place
         */
        /**
         * @returns a string of curation name, template name, and suffix (if duplicate template is used in curation)
         */
        const getSendName = (sendIndex: 0 | 1 | 2) => {
            const template1 = templates?.find((template) => template.id === templateId1);
            const template2 =
                isSegmentsMeetsActionSendEmail && templates?.find((template) => template.id === templateId2);
            const template3 =
                isSegmentsMeetsNotActionSendEmail && templates?.find((template) => template.id === templateId3);
            const templatesUsed = [template1, template2, template3];
            const templateCurrent = templatesUsed[sendIndex];

            const templateName = `${templateCurrent.name}`;

            const hasDuplicate = templatesUsed.some(
                (template, index) => index !== sendIndex && template.name === templateCurrent.name
            );

            const suffix = hasDuplicate ? ` ${sendIndex}` : ``;

            return `${curationName} - ${templateName}${suffix}`;
        };

        const getSends = () => {
            const { audienceId } = state.audience;
            const { scheduledEndTimestamp, scheduledTimestamp, recurrenceFrequency } = state.schedule;
            const sends: CreateCampaignMutationInput['sends'] = [
                {
                    channel: CampaignChannels.EMAIL,
                    name: getSendName(0),
                    templateId: templateId1,
                    senderProfileId: senderProfileId1,
                    subjectLine: subjectLine1,
                    preHeader: preheader1,
                    recurrenceFrequency,
                    scheduledTimestamp,
                    scheduledEndTimestamp,
                    segmentId: audienceId,
                },
            ];

            const isRecurringCuration = recurrenceFrequency !== SendRecurrenceFrequency.DOES_NOT_REPEAT;
            const { delay, unit } = state.delay;
            const parentAudienceDelayInterval = getFormattedParentAudienceDelayInterval(delay, unit);

            const { segments } = state.segments;

            const getMeetsActionSend = (): EmailSendInput => {
                const segmentsMeetsSegments = [segments[0]];
                const meetsActionSend: EmailSendInput = {
                    channel: CampaignChannels.EMAIL,
                    name: getSendName(1),
                    templateId: templateId2,
                    senderProfileId: senderProfileId2,
                    subjectLine: subjectLine2,
                    parentAudienceDelayInterval,
                    parentIndex: 0,
                    preHeader: preheader2,
                    scheduledTimestamp: conditionalSplitSecondEmailTimestamp,
                    segments: segmentsMeetsSegments,
                };

                const formattedSend = formatSendForAPI(isRecurringCuration, meetsActionSend);
                return formattedSend;
            };
            const meetsActionSend = getMeetsActionSend();
            isSegmentsMeetsActionSendEmail && sends.push(meetsActionSend);

            const getMeetsNotActionSend = (): EmailSendInput => {
                const segmentsMeetsNotSegments = [segments[1]];
                const meetsNotActionSend: EmailSendInput = {
                    channel: CampaignChannels.EMAIL,
                    name: getSendName(2),
                    templateId: templateId3,
                    senderProfileId: senderProfileId3,
                    subjectLine: subjectLine3,
                    parentAudienceDelayInterval,
                    parentIndex: 0,
                    preHeader: preheader3,
                    scheduledTimestamp: conditionalSplitSecondEmailTimestamp,
                    segments: segmentsMeetsNotSegments,
                };

                const formattedSend = formatSendForAPI(isRecurringCuration, meetsNotActionSend);
                return formattedSend;
            };
            const meetsNotActionSend = getMeetsNotActionSend();
            isSegmentsMeetsNotActionSendEmail && sends.push(meetsNotActionSend);

            return sends;
        };

        const input: CreateCampaignMutationInput = {
            name: curationName,
            type: CampaignType.CONDITIONAL_SPLIT,
            sends: getSends(),
        };

        mutate(input);
        recordSatismeterEvent('publish_curation');
    };

    return (
        <LegacyCampaignFlow
            ButtonContinue={
                <LegacyButtonContinue
                    routes={conditionalSplitRoutes}
                    selectIsRouteDisabled={selectIsConditionalSplitRouteDisabled}
                    buttonTextConditional="Publish"
                    routeConditional={URLPaths.CURATIONS_CONDITIONAL_SPLIT_PUBLISH}
                    handleRouteConditional={publishCampaign}
                />
            }
            createCampaignError={createCampaignError}
            campaignMetadataSelector={(state: any) => state.curation.conditionalSplit.metadata}
            campaignContentsSelector={(state: any) => [
                state.curation.conditionalSplit.content1,
                state.curation.conditionalSplit.segmentsMeetsContent,
                state.curation.conditionalSplit.segmentsMeetsNotContent,
            ]}
            editMetaDataActionType={CONDITIONAL_SPLIT_EDIT_METADATA}
            isCreateCampaignError={isCreateCampaignError}
            isCreateCampaignLoading={isCreateCampaignLoading}
            isSuccessDialogOpen={isSuccessDialogOpen}
            mainRoute={URLPaths.CURATIONS_CONDITIONAL_SPLIT}
            NavBar={<ConditionalSplitNavBar />}
            Navigation={<ConditionalSplitNav />}
            CampaignFlowRouteLeavingGuard={
                <CampaignFlowRouteLeavingGuard
                    mainRoute={URLPaths.CURATIONS_CONDITIONAL_SPLIT}
                    resetCampaignActionType={CONDITIONAL_SPLIT_RESET_CURATION}
                    selectIsCampaignComplete={selectIsConditionalSplitCurationFormComplete}
                />
            }
            resetCampaignActionType={CONDITIONAL_SPLIT_RESET_CURATION}
            selectIsCampaignRouteDisabled={selectIsConditionalSplitRouteDisabled}
            setIsSuccessDialogOpen={setIsSuccessDialogOpen}
        />
    );
};

export default ConditionalSplitPage;
