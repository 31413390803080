import { useState } from 'react';
import { Snackbar } from '@mui/material';
import Alert from '../../Alert';
import theme from 'theme';

type Props = {
    warningMessage: string;
};

const WarningSnackbar = ({ warningMessage }: Props) => {
    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Snackbar
            open={open}
            autoHideDuration={10000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert
                onClose={handleClose}
                severity="warning"
                sx={{
                    // should be automatically enforced based on severity="warning" but currently not
                    color: theme.palette.warning.contrastText,
                }}
            >
                {warningMessage}
            </Alert>
        </Snackbar>
    );
};

export default WarningSnackbar;
