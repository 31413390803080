import { useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { DataObject } from '@mui/icons-material';
import { CSSObject, Stack, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import theme from 'theme';
import { CampaignChannels } from 'domains/campaigns/types';
import { MergeTag } from 'domains/content/types';
import { getFriendlyName, getMergeTags, getTimezone } from 'utils';
import { getSendDatetimeError, maxDate, maxTime, minDate, minTime } from 'domains/campaigns/utils';
import CurationAudienceDropdown from 'domains/campaigns/components/CurationAudienceDropdown';
import { editSMSCuration, setDatetimeError } from 'domains/campaigns/state/sms/smsActions';
import Button from 'domains/core/components/Button';
import DatePicker from 'domains/core/components/DatePicker';
import Menu from 'domains/core/components/Menu';
import TextField from 'domains/core/components/TextField';
import TimePicker from 'domains/core/components/TimePicker';
import useContactColumns from 'hooks/queries/useContactColumns';

const selectAudienceId = (state: any) => state.curation.sms.audienceId;
const selectButtonClicked = (state: any) => state.curation.sms.buttonClicked;
const selectContent = (state: any) => state.curation.sms.content;
const selectName = (state: any) => state.curation.sms.name;
const selectScheduledTimestamp = (state: any) => state.curation.sms.scheduledTimestamp;

const selector = createSelector(
    selectAudienceId,
    selectButtonClicked,
    selectContent,
    selectName,
    selectScheduledTimestamp,
    (audienceId, buttonClicked, content, name, scheduledTimestamp) => ({
        audienceId,
        buttonClicked,
        content,
        name,
        scheduledTimestamp,
    })
);

const previewButton: CSSObject = {
    paddingX: theme.spacing(2),
};

export type Props = {
    setIsTestSendDialogOpen: (isTestSendDialogOpen: boolean) => void;
};

export const SMSCurationForm = ({ setIsTestSendDialogOpen }: Props) => {
    const dispatch = useDispatch();
    const { data: contactColumns, isSuccess: isContactColumnsSuccess } = useContactColumns();
    const { audienceId, buttonClicked, content, name, scheduledTimestamp } = useSelector(selector);

    const sendDatetimeLuxon = scheduledTimestamp ? DateTime.fromISO(scheduledTimestamp) : DateTime.now();

    const [isContentSet, setIsContentSet] = useState(false);
    const [isContentPreview, setIsContentPreview] = useState(false);
    const [contentPreview, setContentPreview] = useState('');
    const [contentPreviewToggles, setContentPreviewToggles] = useState(['']);
    const [contentTagPosition, setContentTagPosition] = useState(0);
    const contentRef = useRef(null);

    const [date, setDate] = useState<DateTime>(scheduledTimestamp ? DateTime.fromISO(scheduledTimestamp) : null);
    const [time, setTime] = useState<DateTime>(scheduledTimestamp ? DateTime.fromISO(scheduledTimestamp) : null);

    const mergeTagsMemo = useMemo(() => {
        if (isContactColumnsSuccess) return getMergeTags(contactColumns);
    }, [contactColumns, isContactColumnsSuccess]);

    const mergeTagsItems = useMemo(() => {
        const onClickMergeTagsItems = (tagValue?: string) => (e: any) => {
            if (isContentPreview) {
                onContentPreviewChange(e);
            }

            contentRef.current.focus();
            setContentTagPosition(e.target.selectionStart);

            const newContent =
                contentTagPosition === 0
                    ? tagValue + content.slice(contentTagPosition)
                    : contentTagPosition
                    ? content.slice(0, contentTagPosition) + tagValue + content.slice(contentTagPosition)
                    : content.slice(0, contentTagPosition) + tagValue;

            dispatch(
                editSMSCuration({
                    audienceId,
                    buttonClicked,
                    content: newContent,
                    name,
                    scheduledTimestamp,
                })
            );
            setContentPreview(newContent);
        };

        const alphabetized = mergeTagsMemo?.sort((a, b) => a.value.localeCompare(b.value));

        return alphabetized?.map(({ name: tagName, value: tagValue, previewValue: tagPreviewValue }: MergeTag) => ({
            name: getFriendlyName(tagName),
            value: tagValue,
            previewValue: tagPreviewValue,
            onClick: onClickMergeTagsItems(tagValue),
        }));
    }, [
        audienceId,
        buttonClicked,
        content,
        contentTagPosition,
        dispatch,
        isContentPreview,
        mergeTagsMemo,
        name,
        scheduledTimestamp,
    ]);

    const newContentPreview = () => {
        const regex = /{{.*?}}/g;
        const matches = content.match(regex);

        if (matches) {
            const mergeTagMap = new Map();

            for (const mergeTag of mergeTagsMemo) {
                mergeTagMap.set(mergeTag.value, mergeTag.previewValue);
            }

            let newContent: string = content;

            for (const match of matches) {
                const preview = mergeTagMap.get(match);
                newContent = newContent.replaceAll(match, preview);
            }
            return newContent;
        }
        return content;
    };

    const onNameChange = (e: any) => {
        dispatch(
            editSMSCuration({
                audienceId,
                buttonClicked,
                content,
                name: e.target.value,
                scheduledTimestamp,
            })
        );
    };
    const onAudienceChange = (audienceId: number) => {
        dispatch(editSMSCuration({ audienceId, buttonClicked, content, name, scheduledTimestamp }));
    };
    const onContentBlur = (e: any) => {
        setContentTagPosition(e.target.selectionStart);
    };
    const onContentChange = (e: any) => {
        const value = e.target.value;

        const valueLength = value.length;
        const MIN_LENGTH = 3;
        const MAX_LENGTH = 1600;

        MIN_LENGTH <= valueLength && valueLength <= MAX_LENGTH ? setIsContentSet(true) : setIsContentSet(false);

        dispatch(
            editSMSCuration({
                audienceId,
                buttonClicked,
                content: value,
                name,
                scheduledTimestamp,
            })
        );
        setContentPreview(value);
    };
    const onContentPreviewChange = (e: any) => {
        setIsContentPreview(false);
        setContentPreviewToggles([]);
    };
    const onContentPreviewToggleChange = (e: any, values: string[]) => {
        if (values.includes('preview merge tags')) {
            setContentPreviewToggles(values);
        } else if (contentPreviewToggles.includes('preview merge tags')) {
            setContentPreviewToggles([]);
        }
    };
    const onChangeDatetime = (datetime: DateTime, isDate: boolean, setState: (datetime: DateTime) => void) => {
        setState(datetime);

        if (datetime && datetime.isValid) {
            const newDate = sendDatetimeLuxon.set({
                year: datetime.year,
                month: datetime.month,
                day: datetime.day,
                hour: sendDatetimeLuxon.hour,
                minute: sendDatetimeLuxon.minute,
            });
            const newTime = sendDatetimeLuxon.set({
                year: sendDatetimeLuxon.year,
                month: sendDatetimeLuxon.month,
                day: sendDatetimeLuxon.day,
                hour: datetime.hour,
                minute: datetime.minute,
            });
            const newDatetime = isDate ? newDate : newTime;

            dispatch(
                editSMSCuration({
                    audienceId,
                    buttonClicked,
                    content,
                    name,
                    scheduledTimestamp: newDatetime.toISO(),
                })
            );

            if (!Boolean(getSendDatetimeError(newDatetime, isDate))) {
                setDate(newDatetime);
                setTime(newDatetime);

                dispatch(setDatetimeError(false));
            } else {
                dispatch(setDatetimeError(true));
            }
        } else {
            dispatch(setDatetimeError(true));
        }
    };

    return (
        <Stack alignItems="center" rowGap={4}>
            <TextField
                value={name}
                label="Name"
                style={{ margin: 'auto' }}
                onChange={onNameChange}
                inputProps={{ maxLength: 255, 'data-testid': 'name-field' }}
                width="24rem"
                withCounter={true}
                counterCurrent={name.length ?? 0}
                counterMax={255}
            />
            <CurationAudienceDropdown
                audienceId={audienceId}
                channel={CampaignChannels.SMS}
                editAudience={onAudienceChange}
                labelIconTooltipText="Cured automatically includes opt-out instructions in messages sent to first-time SMS recipients within your audience."
            />
            <TextField
                value={isContentPreview ? contentPreview : content}
                label="Content"
                onBlur={onContentBlur}
                onChange={isContentPreview ? onContentPreviewChange : onContentChange}
                inputProps={{ maxLength: 1600, 'data-testid': 'content-field' }}
                required
                width="24rem"
                withCounter={true}
                counterCurrent={content.length}
                counterMax={1600}
                multiline
                rows={3}
                inputRef={contentRef}
            />
            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                <ToggleButtonGroup value={contentPreviewToggles} onChange={onContentPreviewToggleChange}>
                    <Menu
                        items={isContactColumnsSuccess ? mergeTagsItems : []}
                        button={
                            <ToggleButton
                                value="add merge tag"
                                sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                            >
                                <Tooltip aria-label="add merge tag" title="Add merge tag.">
                                    <Stack alignItems="center">
                                        <DataObject />
                                    </Stack>
                                </Tooltip>
                            </ToggleButton>
                        }
                    />
                    <ToggleButton
                        value="preview merge tags"
                        onClick={() => {
                            setIsContentPreview(!isContentPreview);
                            setContentPreview(isContactColumnsSuccess ? newContentPreview() : '');
                        }}
                        sx={previewButton}
                    >
                        <Tooltip aria-label="preview merge tag" title="Preview merge tag.">
                            <span>Preview</span>
                        </Tooltip>
                    </ToggleButton>
                </ToggleButtonGroup>
                <Tooltip
                    aria-label="send test sms"
                    title={
                        isContentSet
                            ? ''
                            : 'Please provide a content between 3 and 1600 characters before sending a test SMS.'
                    }
                >
                    <span>
                        <Button
                            disabled={!isContentSet}
                            onClick={() => setIsTestSendDialogOpen(true)}
                            variant="outlined"
                        >
                            Send Test
                        </Button>
                    </span>
                </Tooltip>
            </Stack>
            <Stack rowGap={4} width="100%">
                <DatePicker
                    data-testid="date-picker"
                    label="send date"
                    value={date}
                    minDate={minDate()}
                    maxDate={maxDate()}
                    onChange={(datetime) => onChangeDatetime(datetime, true, setDate)}
                    slotProps={{
                        popper: { style: { paddingRight: theme.spacing(3.25) } },
                        textField: {
                            error: date && Boolean(getSendDatetimeError(date, true)),
                            helperText: date && getSendDatetimeError(date, true),
                            required: true,
                            sx: { width: '100%' },
                        },
                    }}
                />
                <TimePicker
                    label={`send time (${getTimezone()})`}
                    value={time}
                    minTime={minTime(scheduledTimestamp)}
                    maxTime={maxTime(scheduledTimestamp)}
                    onChange={(datetime) => onChangeDatetime(datetime, false, setTime)}
                    slotProps={{
                        textField: {
                            error: time && Boolean(getSendDatetimeError(time, false)),
                            helperText: time && getSendDatetimeError(time, false),
                            required: true,
                            sx: { width: '100%' },
                        },
                    }}
                />
            </Stack>
        </Stack>
    );
};

export default SMSCurationForm;
