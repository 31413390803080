import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { CampaignType } from 'models/enums';
import QueryKeys from 'hooks/queries/keys';
import useSegments from 'hooks/queries/useSegments';
import useCreateCampaign, { CreateCampaignMutationInput } from 'hooks/mutations/useCreateCampaign';
import useTemplates from 'hooks/queries/useTemplates';
import CampaignFlow from 'domains/campaigns/components/CampaignFlow';
import ButtonContinue from 'domains/core/components/ButtonContinue';
import CampaignFlowRouteLeavingGuard from 'domains/campaigns/components/CampaignFlowRouteLeavingGuard';
import CampaignNavBar from 'domains/campaigns/components/CampaignNavBar/CampaignNavBar';
import { CampaignKey } from 'domains/campaigns/types';
import { CampaignNodeType, MetaDataNode } from 'domains/campaigns/state/createCampaignSlice';
import {
    selectIsCampaignComplete,
    selectIsCampaignRouteDisabled,
    selectIsRouteNextDisabled,
    selectIsRouteStartOrPublish,
    selectMetadataFields,
    selectNextRoute,
    selectNodeByType,
    selectStateByCampaignKey,
} from 'domains/campaigns/state/selectors';
import { editName, resetState, setContentPreviewNodeId, setPublish } from 'domains/campaigns/state/singleSend';
import { getCampaignRoute } from 'domains/campaigns/state/utils';
import SingleSendNav from './SingleSendNav';
import { recordSatismeterEvent } from 'helpers/injectSatismeter/injectSatismeter';

const SingleSendPage = () => {
    const campaignKey = CampaignKey.SINGLE_SEND;

    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const {
        mutate,
        isError: isCreateCampaignError,
        isLoading: isCreateCampaignLoading,
        error: createCampaignError,
    } = useCreateCampaign({
        onSuccess: () => {
            // Invalidate queries used for the sends and campaigns tables
            queryClient.invalidateQueries(QueryKeys.CAMPAIGNS);
            setIsSuccessDialogOpen(true);
        },
    });

    const singleSendState = useSelector(selectStateByCampaignKey(campaignKey));
    const metaDataFields = useSelector(selectMetadataFields(campaignKey));

    const routeMain = getCampaignRoute({ campaignKey });
    const routePublish = getCampaignRoute({
        campaignKey,
        nodes: singleSendState.campaignGraph.nodes,
        nodeId: '37755e2f-03cd-55d8-9afa-49a41938afff',
    });

    const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);

    // Prefetch the cached data for the different curation pages
    useSegments();
    useTemplates();

    const publishCampaign = () => {
        const { name } = metaDataFields;
        const { sends } = singleSendState.campaignTree;

        const input: CreateCampaignMutationInput = {
            name,
            type: CampaignType.SINGLE_SEND,
            sends: sends.map((send) => ({
                ...send,
                name,
            })),
        };
        mutate(input);
        dispatch(setPublish(true));
        recordSatismeterEvent('publish_curation');
    };

    return (
        <CampaignFlow
            ButtonContinue={
                <ButtonContinue
                    selectIsRouteDisabled={selectIsCampaignRouteDisabled(campaignKey)}
                    selectIsRouteNextDisabled={selectIsRouteNextDisabled(campaignKey)}
                    selectNextRoute={selectNextRoute(campaignKey)}
                    buttonTextConditional="Publish"
                    routeConditional={routePublish}
                    handleRouteConditional={publishCampaign}
                />
            }
            CampaignFlowRouteLeavingGuard={
                <CampaignFlowRouteLeavingGuard
                    mainRoute={routeMain}
                    actionCreatorResetState={resetState}
                    selectIsCampaignComplete={selectIsCampaignComplete(campaignKey)}
                />
            }
            NavBar={
                <CampaignNavBar
                    campaignKey={campaignKey}
                    selectCampaignState={selectStateByCampaignKey(campaignKey)}
                    selectIsCampaignRouteDisabled={selectIsCampaignRouteDisabled(campaignKey)}
                    selectIsRouteStartOrPublish={selectIsRouteStartOrPublish(campaignKey)}
                />
            }
            Navigation={<SingleSendNav />}
            campaignKey={campaignKey}
            createCampaignError={createCampaignError}
            actionCreatorEditName={editName}
            actionCreatorResetState={resetState}
            actionCreatorSetContentPreviewNodeId={setContentPreviewNodeId}
            isCreateCampaignError={isCreateCampaignError}
            isCreateCampaignLoading={isCreateCampaignLoading}
            isSuccessDialogOpen={isSuccessDialogOpen}
            mainRoute={routeMain}
            selectIsCampaignRouteDisabled={selectIsCampaignRouteDisabled(campaignKey)}
            selectMetaDataNode={selectNodeByType<MetaDataNode>(campaignKey, CampaignNodeType.META_DATA_NODE)}
            setIsSuccessDialogOpen={setIsSuccessDialogOpen}
        />
    );
};

export default SingleSendPage;
