import { PaidOutlined } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { URLPaths } from 'models/enums';
import useClickApptValueAssumption from 'hooks/queries/useClickApptValueAssumption';
import Button from 'domains/core/components/Button';
import EmptyStateLayout from 'domains/core/components/EmptyStateLayout';
import PageLayout from 'domains/core/components/PageLayout';
import ErrorSnackbar from 'domains/core/components/Snackbars/ErrorSnackbar';
import ValueCalculatorTable from 'domains/value/pages/ValueCalculatorTable';
import { reset } from 'domains/value/state/value';

const ValueCalculatorPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {
        data: clickApptValueAssumption,
        error: clickApptValueAssumptionError,
        isError: isClickApptValueAssumptionError,
        isRefetching: isClickApptValueAssumptionRefetching,
        isSuccess: isClickApptValueAssumptionSuccess,
        refetch: clickApptValueAssumptionRefetch,
    } = useClickApptValueAssumption();

    const onClickCreate = () => {
        history.push(URLPaths.VALUE_SEND_SPECIALTY);
        dispatch(reset());
    };

    const isEmpty = isClickApptValueAssumptionSuccess && !clickApptValueAssumption?.length;

    const createButton = (
        <Button variant="outlined" endIcon={<PaidOutlined />} onClick={onClickCreate}>
            Create
        </Button>
    );

    if (isEmpty) {
        return (
            <EmptyStateLayout caption="value" header="track the impact of your marketing efforts">
                {createButton}
            </EmptyStateLayout>
        );
    }

    return (
        <PageLayout header="value" headerIcon={<PaidOutlined fontSize="inherit" />} headerAction={createButton}>
            {isClickApptValueAssumptionError && <ErrorSnackbar errorMessage={clickApptValueAssumptionError?.message} />}
            {isClickApptValueAssumptionSuccess && (
                <ValueCalculatorTable
                    clickApptValueAssumptionResponses={clickApptValueAssumption}
                    isRefetching={isClickApptValueAssumptionRefetching}
                    refetch={clickApptValueAssumptionRefetch}
                />
            )}
        </PageLayout>
    );
};

export default ValueCalculatorPage;
