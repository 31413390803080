import { Route } from 'react-router-dom';
import { URLPaths } from 'models/enums';
import CurationLandingPage from './CurationLandingPage';
import SingleSendPage from './SingleSendPage';
import TimeDelayPage from './TimeDelayPage';
import ConditionalSplitPage from './ConditionalSplitPage';
import CustomPage from './CustomPage';

const CurationPage = () => (
    <>
        <Route component={CurationLandingPage} path={URLPaths.CURATIONS_LANDING} exact />
        <Route component={CustomPage} path={URLPaths.CURATIONS_CUSTOM} />
        <Route component={ConditionalSplitPage} path={URLPaths.CURATIONS_CONDITIONAL_SPLIT} />
        <Route component={SingleSendPage} path={URLPaths.CURATIONS_SINGLE_SEND} />
        <Route component={TimeDelayPage} path={URLPaths.CURATIONS_EMAIL_SERIES} />
    </>
);

export default CurationPage;
