import { EmailOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import CampaignFlow from 'domains/campaigns/components/CampaignFlow';
import Button from 'domains/core/components/Button';
import ButtonContinue from 'domains/core/components/ButtonContinue';
import CampaignFlowRouteLeavingGuard from 'domains/campaigns/components/CampaignFlowRouteLeavingGuard';
import CampaignNavBar from 'domains/campaigns/components/CampaignNavBar';
import { CampaignKey, EmailSendInput, SendRecurrenceFrequency } from 'domains/campaigns/types';
import QueryKeys from 'hooks/queries/keys';
import useCreateCampaign, { CreateCampaignMutationInput } from 'hooks/mutations/useCreateCampaign';
import useTemplates from 'hooks/queries/useTemplates';
import { CampaignType } from 'models/enums';
import {
    selectFieldsByRoute,
    selectIsCampaignComplete,
    selectIsCampaignRouteDisabled,
    selectIsRouteAfterLastContent,
    selectIsRouteDelayAfterLastContent,
    selectIsRouteNextDisabled,
    selectIsRouteStartOrPublish,
    selectMetadataFields,
    selectNextRoute,
    selectNodeByType,
    selectNodeIdByRoute,
    selectNodesByType,
    selectStateByCampaignKey,
} from 'domains/campaigns/state/selectors';
import { route as routeSelector } from 'state/selectors';
import TimeDelayNav from './TimeDelayNav';
import {
    addSend,
    deleteSend,
    editName,
    resetState,
    setPublish,
    setContentPreviewNodeId,
} from 'domains/campaigns/state/emailSeries';
import { CampaignNodeType, ContentNode, DelayNode, MetaDataNode } from 'domains/campaigns/state/createCampaignSlice';
import { getCampaignRoute } from 'domains/campaigns/state/utils';
import { recordSatismeterEvent } from 'helpers/injectSatismeter/injectSatismeter';

/**
 * Gets the send names based on the curation name, template names, and whether or not the same template is used for both sends.
 */
export const getSendNames = (curationName: string, templatesNameMap: Map<number, string>, sends: EmailSendInput[]) => {
    const nameCount: Record<string, number> = {}; // Track the count of each name

    return sends.map((send) => {
        const templateName = templatesNameMap.get(send.templateId);

        if (!templateName) {
            throw new Error(`No template found for templateId ${send.templateId}`);
        }

        // If the template name has been used before, add a count to it
        if (nameCount[templateName]) {
            nameCount[templateName]++;
            return `${curationName} - ${templateName} ${nameCount[templateName]}`;
        } else {
            nameCount[templateName] = 1;
            return `${curationName} - ${templateName}`;
        }
    });
};

const TimeDelayPage = () => {
    const campaignKey = CampaignKey.EMAIL_SERIES;

    const dispatch = useDispatch();
    const history = useHistory();
    const queryClient = useQueryClient();
    const { data: templates } = useTemplates();

    const {
        mutate,
        isError: isCreateCampaignError,
        isLoading: isCreateCampaignLoading,
        error: createCampaignError,
    } = useCreateCampaign({
        onSuccess: () => {
            // Invalidate queries used for the sends and campaigns tables
            queryClient.invalidateQueries(QueryKeys.CAMPAIGNS);

            setIsSuccessDialogOpen(true);
        },
    });

    const route = useSelector(routeSelector);
    const nextRoute = useSelector(selectNextRoute(campaignKey))(route);
    const nodeId = useSelector(selectNodeIdByRoute(campaignKey))(route);

    const emailSeriesState = useSelector(selectStateByCampaignKey(CampaignKey.EMAIL_SERIES));

    const isRouteAfterLastContent = useSelector(selectIsRouteAfterLastContent(campaignKey))(route);
    const isRouteDelayAfterLastContent = useSelector(selectIsRouteDelayAfterLastContent(campaignKey))(route);
    const isRouteDisabled = useSelector(selectIsCampaignRouteDisabled(campaignKey))(route);
    const isRouteNextDisabled = useSelector(selectIsRouteNextDisabled(campaignKey))(route);

    const delayNodes = useSelector(selectNodesByType<DelayNode>(campaignKey, CampaignNodeType.DELAY_NODE));
    const isDelayNodesFull = delayNodes.length === 11;

    const metaDataFields = useSelector(selectMetadataFields(campaignKey));
    const contentFields = useSelector(selectFieldsByRoute<ContentNode>(campaignKey))(route);

    const [isSendUpdated, setIsSendUpdated] = useState(false);
    const [isSendRemoved, setIsSendRemoved] = useState(false);
    const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);

    const routeMain = getCampaignRoute({ campaignKey });
    const routePublish = getCampaignRoute({
        campaignKey,
        nodes: emailSeriesState.campaignGraph.nodes,
        nodeId: 'bdb900b6-5423-56fb-ada4-f8750b5b4110',
    });

    const publishCampaign = () => {
        const { name } = metaDataFields;
        const { sends } = emailSeriesState.campaignTree;

        const templateIds = new Set(sends.map((send) => send.templateId));
        const templatesFiltered = templates.filter(({ id }) => templateIds.has(id));

        // Create a Map of template.id & template.name for quick lookup by id
        const templatesIdNameMap = new Map(templatesFiltered.map(({ id, name }) => [id, name]));

        // Generate the send names
        const sendNames = getSendNames(name, templatesIdNameMap, sends);

        // Add name and remove extraneous values before sending to the API
        const getSendForApi = (send: EmailSendInput, index: number): EmailSendInput => {
            const name = sendNames[index];
            const isRecurringCuration = sends[0]?.recurrenceFrequency !== SendRecurrenceFrequency.DOES_NOT_REPEAT;

            if (index === 0) {
                return { ...send, name };
            }
            if (isRecurringCuration) {
                // We don't want to send the scheduledTimestamp for delayed sends in recurring campaigns
                const { scheduledTimestamp, ...restOfSend } = send;
                return { ...restOfSend, name };
            }
            if (!isRecurringCuration) {
                // We don't want to send the parentAudienceDelayInterval or parentIndex
                // for delayed sends in non-recurring campaigns
                const { parentAudienceDelayInterval, parentIndex, ...restOfSend } = send;
                return { ...restOfSend, name };
            }
        };

        const input: CreateCampaignMutationInput = {
            name,
            type: CampaignType.TIME_DELAY,
            sends: sends.map((send, index) => getSendForApi(send, index)),
        };
        mutate(input);
        dispatch(setPublish(true));
        recordSatismeterEvent('publish_curation');
    };

    useEffect(() => {
        if (isSendRemoved && nodeId) {
            setIsSendRemoved(false);
        }
        if (isSendUpdated) {
            history.push(nextRoute);
            setIsSendUpdated(false);
        }
    }, [contentFields, dispatch, history, isSendRemoved, isSendUpdated, nextRoute, nodeId]);

    const onClickAddSend = () => {
        dispatch(addSend());
        setIsSendUpdated(true);
    };

    const onClickRemoveSend = () => {
        setIsSendUpdated(true);
        setIsSendRemoved(true);
    };

    return (
        <CampaignFlow
            ButtonContinue={
                <Stack direction="row" columnGap={2}>
                    {isRouteAfterLastContent && !isDelayNodesFull && (
                        <Button
                            disabled={
                                isRouteDelayAfterLastContent ||
                                isRouteDisabled ||
                                isRouteNextDisabled ||
                                contentFields?.isAddOnSendClicked
                            }
                            startIcon={<EmailOutlined />}
                            sx={{ width: 160 }}
                            onClick={onClickAddSend}
                        >
                            Add a Send
                        </Button>
                    )}
                    <ButtonContinue
                        selectIsRouteDisabled={selectIsCampaignRouteDisabled(campaignKey)}
                        selectIsRouteNextDisabled={selectIsRouteNextDisabled(campaignKey)}
                        selectNextRoute={selectNextRoute(campaignKey)}
                        buttonTextConditional="Publish"
                        routeConditional={routePublish}
                        handleRouteConditional={publishCampaign}
                    />
                </Stack>
            }
            CampaignFlowRouteLeavingGuard={
                <CampaignFlowRouteLeavingGuard
                    mainRoute={routeMain}
                    actionCreatorResetState={resetState}
                    selectIsCampaignComplete={selectIsCampaignComplete(campaignKey)}
                />
            }
            NavBar={
                <CampaignNavBar
                    actionCreatorDeleteSend={deleteSend}
                    campaignKey={campaignKey}
                    onClickRemoveSend={onClickRemoveSend}
                    selectCampaignState={selectStateByCampaignKey(campaignKey)}
                    selectIsCampaignRouteDisabled={selectIsCampaignRouteDisabled(campaignKey)}
                    selectIsRouteStartOrPublish={selectIsRouteStartOrPublish(campaignKey)}
                />
            }
            Navigation={<TimeDelayNav />}
            campaignKey={campaignKey}
            createCampaignError={createCampaignError}
            actionCreatorEditName={editName}
            actionCreatorResetState={resetState}
            actionCreatorSetContentPreviewNodeId={setContentPreviewNodeId}
            isCreateCampaignError={isCreateCampaignError}
            isCreateCampaignLoading={isCreateCampaignLoading}
            isSuccessDialogOpen={isSuccessDialogOpen}
            mainRoute={routeMain}
            selectIsCampaignRouteDisabled={selectIsCampaignRouteDisabled(campaignKey)}
            selectMetaDataNode={selectNodeByType<MetaDataNode>(campaignKey, CampaignNodeType.META_DATA_NODE)}
            setIsSuccessDialogOpen={setIsSuccessDialogOpen}
        />
    );
};

export default TimeDelayPage;
