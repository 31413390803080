import Big from 'big.js';
import { DateTime } from 'luxon';
import { Contact, ErrorCodes, ContactColumn } from 'models/types';
import { MergeTag } from 'domains/content/types';
import { UseAudienceCountResponse } from 'hooks/queries/useAudienceCount';
import { CampaignChannels } from 'domains/campaigns/types';
import { Segment } from 'domains/segments/types';

/**
 * Gets the greeting corresponding with the time of day.
 * 12:00 AM - 11:59 AM = good morning
 * 12:00 PM - 4:59 PM = good afternoon
 * 5:00 PM - 11:59 PM = good evening
 * @returns Greeting string
 */
export const getTimeDependentGreeting = (date: Date): string => {
    const hours = date.getHours();
    if (hours >= 0 && hours < 12) {
        return 'good morning';
    } else if (hours >= 12 && hours < 17) {
        return 'good afternoon';
    } else {
        return 'good evening';
    }
};

/**
 * Returns a date with the following format: Tuesday, October 26, 2021.
 * @param date
 * @returns A user friendly date string.
 */
export const getUserFriendlyDate = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
};

/**
 * Returns numbers with commas e.g. 1000 -> 1,000
 *
 * @param num The number that will be converted.
 */
export const getNumberWithCommas = (num: number) => {
    if (isNaN(num)) {
        num = 0;
    }
    return Math.floor(num)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 * Formats the address of the contact.
 * @param contact The contact whose address will be formatted.
 * @returns The formatted address.
 */
export const getFormattedAddress = (contact: Contact) => {
    const addressFields = [contact.street_address, contact.street_address_2, contact.zip_code];
    return addressFields.filter((field) => !!field).join(', ');
};

export const getFormattedDate = (date: string): string => DateTime.fromISO(date).toUTC().toFormat('LLL dd, yyyy');
export const getFormattedLocalDate = (date: string): string => DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);

/**
 * Returns the user's respective timezone.
 * @returns
 */
export const getTimezone = (): string => {
    let options: Intl.DateTimeFormatOptions = {
        timeZoneName: 'short',
    };
    const timezoneArray = new Date().toLocaleTimeString('en-US', options).split(/\s/);
    // Get the last element in the array. Different browsers will return a different number of elements.
    // In case the timezone is not found, return undefined instead of crashing.
    return timezoneArray[timezoneArray.length - 1] ?? '';
};

export const getFormattedLocalTime = (time: string): string => {
    const dateTime = DateTime.fromISO(time);
    const formattedTime = dateTime.toFormat('t');
    const timezone = getTimezone();
    return `${formattedTime} ${timezone}`;
};

export const getAgeFromBirthday = (birthday?: string): number => {
    if (!birthday) return;

    var today = new Date();
    var birthDate = new Date(birthday);

    if (birthDate.getTime() === new Date(0).getTime()) {
        return null;
    }
    var age = today.getFullYear() - birthDate.getFullYear();
    var month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
};

/**
 * Formats a ten digit phone number to the following format: (123) 456-7890
 * @param phone The (10 digit/US) phone number whose string will be formatted.
 * @returns The formatted phone number.
 */
export const getFormattedPhoneNumber = (phone: string): string => {
    if (!phone) {
        return '';
    }
    const formattedPhone = phone.replace(/[^\d]/g, '');

    // Check if US phone number
    if (formattedPhone.length === 10) {
        return formattedPhone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    } else {
        return phone;
    }
};

/**
 * Finds an audience id given the audience name
 * @param name the audience name to look for
 * @param audienceList an array of audience objects
 * @returns an id as an integer
 */
export const getAudienceIdFromName = (name: string, audienceList: Segment[]): number => {
    const audienceObj = audienceList.find((audience) => audience.name === name);
    return audienceObj.id;
};

/**
 * Creates and returns an error with the corresponding error message, or the default error message.
 * @param error the error that was caught from Axios
 * @returns the error to be thrown
 */
export const getErrorWithMessage = (error: any): Error => {
    const userFriendlyErrorMessageFromAPI: string | undefined = error.response?.data.message;
    const errorCode: string | undefined = error.response?.data.errorCode;
    // The ErrorCodes[errorCode] is the legacy way of defining error messages.
    const errorMessage: string = userFriendlyErrorMessageFromAPI ?? ErrorCodes[errorCode] ?? ErrorCodes.DEFAULT;
    return new Error(errorMessage);
};

/**
 * Gets the audience label for a select, based on the state from the useAudienceCount hook.
 * @param audience the audience for which the label will be returned
 * @param data data from the useAudienceCount hook
 * @param selectedAudienceId the selected audience id (counts will only be shown for this audience)
 * @param isError isError from the useAudienceCount hook
 * @param isLoadingOrFetching isLoading or isFetching from the useAudienceCount hook
 * @returns the audience label with the count if it is selected
 */
export const getAudienceLabel = (
    audience: Partial<Segment>,
    data: UseAudienceCountResponse,
    selectedAudienceId: number,
    isError: boolean,
    isLoadingOrFetching: boolean,
    channel?: CampaignChannels
): string => {
    if (data && audience.name && audience.id === selectedAudienceId && !isError && !isLoadingOrFetching) {
        const maxLength = 39;

        const audienceCountText = `(${getNumberWithCommas(data.count)} ${channel ? `${channel.toLowerCase()} ` : ''}${
            data.count == 1 ? 'contact' : 'contacts' // eslint-disable-line
        })`;

        const fullValueLength = audience.name.length + audienceCountText.length + 1;

        const audienceName = `${
            channel && fullValueLength > maxLength
                ? audience.name.substring(0, maxLength - audienceCountText.length - 4) + '...'
                : audience.name
        }`;

        return `${audienceName} ${audienceCountText}`;
    }

    return audience.name;
};

/**
 * Converts a snake case column name to a more friendly format.
 * For example, column_name -> column name.
 * @param name The snake case column name.
 * @returns The formatted friendly name.
 */
export const getFriendlyName = (name: string): string => name.replace(/_/g, ' ');

/**
 * Converts contact columns to merge tags.
 * @param contactColumns The contact column types.
 * @returns The merge tags.
 */
export const getMergeTags = (contactColumns: ContactColumn[]): MergeTag[] => {
    const supportedContactColumns = contactColumns.filter((contactColumn) => contactColumn.isMergeTag);

    return supportedContactColumns.map(
        ({ columnName, personalizationPreview }: ContactColumn): MergeTag => ({
            name: columnName,
            value: `{{${columnName}}}`,
            previewValue: personalizationPreview ?? '',
        })
    );
};

export const getPercentageFromRate = (rate: number): number =>
    Big(rate ?? 0)
        .times(100)
        .toNumber();

export const getCamelToKebabCase = (str: string): string =>
    str
        .replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2')
        .toLowerCase()
        .replace(/^-/, '');
