const QueryKeys = {
    APP: (path: string) => ['app', path],
    AUDIENCE_COUNT: (audienceId: string, channel?: string) => ['audience-count', audienceId, channel],
    CAMPAIGN_AUDIENCE: 'campaign-audience',
    CAMPAIGNS: 'campaigns',
    CONTACTS: (segmentId: number) => ['contacts', segmentId],
    CONTACTS_DOWNLOAD: (segmentId: number) => ['contacts-download', segmentId],
    CONTACT_COLUMN_TOP_VALUES: (columnName: string) => ['contactColumnTopValues', columnName],
    CONTENT: (folderId?: number, type?: string) => ['content', folderId, type],
    CURATION_ID: (curationId: string) => ['curation-id', curationId],
    DATA_VIS_DASHBOARD_URL: (dashboard: string) => `data-vis/dashboards/${dashboard}`,
    DATA_VIS_LOOK_URL: (look: string) => `data-vis/looks/${look}`,
    FILE_UPLOAD: 'file-upload',
    FEATURE_SETTINGS: 'feature-settings',
    FEATURE_SETTINGS_BY_NAME: (featureName: string) => `feature-settings/${featureName}`,
    FOLDER_LINEAGE: (folderId: number) => `folder-breadcrumb/${folderId}`,
    IMPORTS: 'imports',
    LANDING_PAGES: 'landing-pages',
    LANDING_PAGE_ID: (landingPageId: string) => ['landing-page-id', landingPageId],
    LANDING_PAGE_HTML: (landingPageId: number) => ['landing-page-html', landingPageId],
    PREVIEW_AUDIENCE: 'preview-audience',
    PREVIEW_EMAIL: (contactCuredId: string, templateId: number, senderProfileId: number) => [
        'preview-email',
        contactCuredId,
        `${templateId}`,
        `${senderProfileId}`,
    ],
    POWERBI_DATA_VIS_DASHBOARD_URL: (dashboard: string) => `powerbi-data-vis/dashboards/${dashboard}`,
    SEGMENTS: 'segments',
    SEGMENT_FILTERS: 'segment-filters',
    SEGMENT_ID: (segmentId: string) => ['segment-id', segmentId],
    SENDER_PROFILES: 'sender-profiles',
    SENDS: (path: string) => ['sends', path],
    SEND_ID: (sendId: string) => ['send-id', sendId],
    SEND_ID_CLICKABLE_URLS: (sendId: number) => ['send-id-clickable-urls', sendId],
    TEMPLATES: 'templates',
    TEMPLATE_ID: (templateId: string) => ['template-id', templateId],
    TEMPLATE_ID_SUBJECT_LINES: (templateId: number) => ['template-id-subject-lines', templateId],
};

export default QueryKeys;
