import { useEffect } from 'react';
import { CuredApiPaths } from 'models/enums';
import Card from 'pages/InsightsPage/dashboards/Card';
import { FiltersForApiState } from 'pages/InsightsPage/dashboards/filtering/types';
import useFilteredData from 'pages/InsightsPage/dashboards/filtering/useFilteredData';
import LineGraph from 'pages/InsightsPage/visualizations/LineGraph';
import { LineGraphData } from 'pages/InsightsPage/visualizations/LineGraph/LineGraph';

type Props = {
    filtersForApi: FiltersForApiState;
};

const MessagesOverTimeCard = ({ filtersForApi }: Props) => {
    const { getData, isError, isLoading, data } = useFilteredData({
        key: 'curations-dashboard/messages',
        path: CuredApiPaths.REPORT_CURATION_MESSAGES,
    });

    useEffect(() => {
        getData(filtersForApi);
    }, [filtersForApi, getData]);

    return (
        <Card isError={isError} isLoading={isLoading} label="Messages over Time">
            <LineGraph
                bottomLegendLabel="date"
                data={[data as LineGraphData]}
                leftLegendLabel="messages sent"
                tickValues="every 2 months"
                tooltipLabel="total send count"
            />
        </Card>
    );
};

export default MessagesOverTimeCard;
