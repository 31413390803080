import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { CurationStep } from 'models/enums';
import { CampaignKey } from 'domains/campaigns/types';
import CampaignPublishPage from 'domains/campaigns/pages/CampaignPublishPage';
import CurationNamePage from 'domains/campaigns/pages/CurationNamePage';
import CurationAudiencePage from 'domains/campaigns/pages/CurationAudiencePage';
import CurationContentPage from 'domains/campaigns/pages/CurationContentPage';
import CurationSchedulePage from 'domains/campaigns/pages/CurationSchedulePage';
import { CampaignNodeType } from 'domains/campaigns/state/createCampaignSlice';
import {
    selectContentFieldsAll,
    selectContentFieldsByNodeId,
    selectMetadataFields,
    selectNodeIdsByType,
    selectScheduleFields,
    selectScheduleFieldsAll,
    selectSegmentId,
    selectStateByCampaignKey,
} from 'domains/campaigns/state/selectors';
import {
    editContentFieldsByNodeId,
    editName,
    editScheduleFields,
    editSegmentId,
    setContentPreviewNodeId,
    setScheduleDatetimeError,
} from 'domains/campaigns/state/singleSend';
import { getCampaignRoute } from 'domains/campaigns/state/utils';

const campaignKey = CampaignKey.SINGLE_SEND;

const SingleSendNav = () => {
    const { nodes } = useSelector(selectStateByCampaignKey(campaignKey)).campaignGraph;

    const routeSegment = getCampaignRoute({ campaignKey, nodes, nodeId: '267a4b2a-52f2-53a8-a4fc-baa20186d88e' });
    const routeContent = getCampaignRoute({ campaignKey, nodes, nodeId: '51f72c60-cf7d-5afe-b855-46b1a33d8ed0' });
    const routeSchedule = getCampaignRoute({ campaignKey, nodes, nodeId: '30facd24-a154-52f6-8f1c-1a49e0e12ab2' });
    const routePublish = getCampaignRoute({ campaignKey, nodes, nodeId: '37755e2f-03cd-55d8-9afa-49a41938afff' });

    return (
        <Switch>
            <Route
                exact
                path={routeSegment}
                render={(props) => (
                    <CurationAudiencePage
                        {...props}
                        editSegmentIdActionCreator={editSegmentId}
                        selectSegmentId={selectSegmentId(campaignKey)}
                    />
                )}
            />
            <Route
                exact
                path={routeContent}
                render={(props) => (
                    <CurationContentPage
                        {...props}
                        formLayoutProps={{
                            guidingText:
                                'Focus on the goal of the customer interaction and choose content from the dropdown that supports your objective.',
                            stepName: CurationStep.EMAIL,
                        }}
                        nodeId="51f72c60-cf7d-5afe-b855-46b1a33d8ed0"
                        selectContentFields={selectContentFieldsByNodeId(
                            campaignKey,
                            '51f72c60-cf7d-5afe-b855-46b1a33d8ed0'
                        )}
                        selectMetadataFields={selectMetadataFields(campaignKey)}
                        editContentActionCreator={editContentFieldsByNodeId}
                        setPreviewContentNodeIdActionCreator={setContentPreviewNodeId}
                    />
                )}
            />
            <Route
                exact
                path={routeSchedule}
                render={(props) => (
                    <CurationSchedulePage
                        {...props}
                        editScheduleFieldsActionCreator={editScheduleFields}
                        formLayoutProps={{
                            guidingText:
                                'Set the date and time of your send. Have your audience in mind to optimize your send time.',
                            stepName: CurationStep.EMAIL,
                        }}
                        setScheduleDatetimeErrorActionCreator={setScheduleDatetimeError}
                        allowRecurrence
                        selectScheduleFields={selectScheduleFields(campaignKey)}
                    />
                )}
            />
            <Route
                exact
                path={routePublish}
                render={(props) => (
                    <CampaignPublishPage
                        selectMetadataFields={selectMetadataFields(campaignKey)}
                        selectSegmentId={selectSegmentId(campaignKey)}
                        selectOrderedContentNodeIds={selectNodeIdsByType(campaignKey, CampaignNodeType.CONTENT_NODE)}
                        selectScheduleFieldsAll={selectScheduleFieldsAll(campaignKey)}
                        selectContentFieldsAll={selectContentFieldsAll(campaignKey)}
                        actionCreatorSetContentPreviewNodeId={setContentPreviewNodeId}
                        {...props}
                    />
                )}
            />
            <Route
                render={(props) => (
                    <CurationNamePage
                        {...props}
                        editNameActionCreator={editName}
                        selectMetadataFields={selectMetadataFields(campaignKey)}
                    />
                )}
            />
        </Switch>
    );
};

export default SingleSendNav;
