import { useEffect, useMemo } from 'react';
import { CuredApiPaths } from 'models/enums';
import Card from 'pages/InsightsPage/dashboards/Card';
import { FiltersForApiState } from 'pages/InsightsPage/dashboards/filtering/types';
import useFilteredData from 'pages/InsightsPage/dashboards/filtering/useFilteredData';
import DataGrid from 'pages/InsightsPage/visualizations/DataGrid';
import {
    getHeaderName,
    getValueFormatter,
    useGetRows,
    ValueFormatTypes,
} from 'pages/InsightsPage/visualizations/DataGrid/utils';

type Props = {
    filtersForApi: FiltersForApiState;
};

const LinksByClickCard = ({ filtersForApi }: Props) => {
    const { getData, isError, isLoading, data } = useFilteredData({
        key: 'curations-dashboard/links-by-click',
        path: CuredApiPaths.REPORT_CURATION_TOP_CLICKS,
    });

    useEffect(() => {
        getData(filtersForApi);
    }, [filtersForApi, getData]);

    const tableData = data as {}[];

    const columns: any = useMemo(() => {
        if (!tableData?.length) return;

        const columnFields = Object.keys(tableData?.[0]);
        const getAlignment = (field: string): 'left' | 'right' | 'center' => {
            if (field.includes('percent')) return 'right';
            if (field.includes('clicks')) return 'center';
            return 'left';
        };

        const columns = columnFields?.map((field) => ({
            align: getAlignment(field),
            field,
            flex: 1,
            headerAlign: 'center',
            headerName: getHeaderName(field),
            valueFormatter: ({ value }: { value?: string }) => {
                const getType = () => {
                    if (field.includes('rate')) return ValueFormatTypes.PERCENTAGE;
                    return null;
                };
                return getValueFormatter({ type: getType(), value });
            },
            valueGetter: ({ value }: { value?: string }) => {
                if (value == null) return '';
                if (field.includes('clicks')) return Number(value);
                return value;
            },
        }));

        return columns;
    }, [tableData]);

    const rows = useGetRows(tableData);

    return (
        <Card isError={isError} isLoading={isLoading} label="Top 25 Links by Click">
            <DataGrid
                columns={columns}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'clicks', sort: 'desc' }],
                    },
                }}
                rows={rows}
            />
        </Card>
    );
};

export default LinksByClickCard;
