import { useDispatch, useSelector } from 'react-redux';
import FormLayout, { Props as FormLayoutProps } from 'domains/core/components/FormLayout';
import ContentIcon from 'domains/core/components/SvgIcons/ContentIcon';
import {
    editContent as editContentActionCreator,
    editMetadata as editMetadataActionCreator,
} from 'domains/campaigns/state/actionCreators';
import { ContentState, EditContentPayload, EditMetadataPayload, MetadataState } from 'domains/campaigns/types';
import { CurationStep } from 'models/enums';
import CurationContentForm from './CurationContentForm';

type Props = {
    contentSelector: (state: any) => ContentState;
    metadataSelector: (state: any) => MetadataState;
    contentIndex: number;
    editContentActionType: string;
    editMetadataActionType: string;
    formLayoutProps?: Partial<FormLayoutProps>;
};

export const CurationContentPage = ({
    contentSelector,
    metadataSelector,
    contentIndex,
    editContentActionType,
    editMetadataActionType,
    formLayoutProps,
}: Props) => {
    const dispatch = useDispatch();

    const editContent = (payload: EditContentPayload) =>
        dispatch(editContentActionCreator(editContentActionType, payload));
    const editMetadata = (payload: EditMetadataPayload) =>
        dispatch(editMetadataActionCreator(editMetadataActionType, payload));

    const contentState = useSelector(contentSelector);
    const metadataState = useSelector(metadataSelector);

    return (
        <FormLayout
            header="select content"
            icon={<ContentIcon />}
            stepName={CurationStep.EMAIL}
            guidingText="Focus on the goal of the customer interaction and choose content from the dropdown that supports your objective."
            {...formLayoutProps}
        >
            <CurationContentForm
                contentState={contentState}
                metadataState={metadataState}
                editContent={editContent}
                editMetadata={editMetadata}
                contentIndex={contentIndex}
            />
        </FormLayout>
    );
};

export default CurationContentPage;
