import { UseQueryOptions } from 'react-query';
import useQuery from 'hooks/useQuery';
import { CuredApiPaths } from 'models/enums';
import QueryKeys from './keys';

type Params = {
    templateId: number;
    count?: number; // must be between 1 and 20
    reactQueryOptions?: UseQueryOptions<string[], Error>;
};

const useTemplateSuggestedSubjectLines = ({ templateId, count, reactQueryOptions }: Params) => {
    const queryKey = QueryKeys.TEMPLATE_ID_SUBJECT_LINES(templateId);

    const path = `${CuredApiPaths.TEMPLATES}/${templateId}/suggested-subjectlines`;

    const params = {
        ...(count && { count }),
    };

    const axiosOptions = {
        ...(Object.keys(params).length && { params }),
    };

    return useQuery(queryKey, path, reactQueryOptions, axiosOptions);
};

export default useTemplateSuggestedSubjectLines;
