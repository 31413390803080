import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { CuredApiPaths } from 'models/enums';
import Card from 'pages/InsightsPage/dashboards/Card';
import { FiltersForApiState } from 'pages/InsightsPage/dashboards/filtering/types';
import useFilteredData from 'pages/InsightsPage/dashboards/filtering/useFilteredData';
import LineGraph from 'pages/InsightsPage/visualizations/LineGraph';
import CustomTooltip from 'pages/InsightsPage/visualizations/CustomTooltip';

type Props = {
    filtersForApi: FiltersForApiState;
};

const RatesCards = ({ filtersForApi }: Props) => {
    const { getData, isError, isLoading, data } = useFilteredData({
        key: 'overview-dashboard/unique-rates',
        path: CuredApiPaths.REPORT_UNIQUE_RATES,
    });

    useEffect(() => {
        getData(filtersForApi);
    }, [filtersForApi, getData]);

    const renderCustomTooltip = (point: any) => (
        <CustomTooltip color={point.serieColor} label={point.data.yFormatted} value={point.data.tooltipLabel} />
    );

    return (
        <>
            <Grid item xs={12}>
                <Card isError={isError} isLoading={isLoading} label="Unique Open Rates over Time">
                    <LineGraph
                        data={[data?.unique_open_rate]}
                        hideBottomAxis
                        leftLegendLabel="unique open rate"
                        renderCustomTooltip={renderCustomTooltip}
                        scale="percentage"
                    />
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card
                    isError={isError}
                    isLoading={isLoading}
                    label="Click-Through Rate"
                    tooltip="Click-through rate is defined as unique clicks / delivered messages."
                >
                    <LineGraph
                        data={[data?.click_through_rate]}
                        hideBottomAxis
                        leftLegendLabel="click-through rate"
                        renderCustomTooltip={renderCustomTooltip}
                        scale="percentage"
                    />
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card isError={isError} isLoading={isLoading} label="Click to Open Rate over Time">
                    <LineGraph
                        data={[data?.click_to_open_rate]}
                        hideBottomAxis
                        leftLegendLabel="click to open rate"
                        renderCustomTooltip={renderCustomTooltip}
                        scale="percentage"
                    />
                </Card>
            </Grid>
        </>
    );
};

export default RatesCards;
