import { useHistory } from 'react-router';
import { ArrowBack, SwapCalls } from '@mui/icons-material';
import { URLPaths } from 'models/enums';
import Button from 'domains/core/components/Button';
import Flow from 'domains/core/components/Flow';
import PageHeader from 'domains/core/components/PageHeader';
import { Stack } from '@mui/material';

type Props = {
    left: React.ReactNode;
    right?: React.ReactNode;
};

const CampaignDetailsLayout = ({ left, right }: Props) => {
    const history = useHistory();

    return (
        <Flow
            topLeft={<PageHeader icon={<SwapCalls fontSize="inherit" />} header="curation details" />}
            topRight={
                <Button startIcon={<ArrowBack />} onClick={() => history.push(URLPaths.CURATIONS)}>
                    Back
                </Button>
            }
            bottomLeft={<Stack rowGap={4}>{left}</Stack>}
            bottomRight={right}
        />
    );
};

export default CampaignDetailsLayout;
