import Card from 'pages/InsightsPage/dashboards/Card';
import LineGraph from 'pages/InsightsPage/visualizations/LineGraph';
import useContactUnsubscribes from './useContactUnsubscribes';

const UnsubscribesCard = () => {
    const { data, isError, isLoading } = useContactUnsubscribes();

    return (
        <Card isError={isError} isLoading={isLoading} label="Unsubscribes over Time">
            <LineGraph
                bottomLegendLabel="unsubscribe date"
                data={[data]}
                leftLegendLabel="unsubscribe total"
                tickValues="every 3 months"
                tooltipLabel="total contact count"
            />
        </Card>
    );
};

export default UnsubscribesCard;
