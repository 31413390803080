import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { PaidOutlined } from '@mui/icons-material';
import FormLayout from 'domains/core/components/FormLayout';
import {
    resetAppointmentValue as resetAppointmentValueActionCreator,
    setAppointmentValue as setAppointmentValueActionCreator,
} from 'domains/value/state/value';
import { ValueCalculatorStep } from 'domains/value/types';
import ValueCalculatorConversionValueForm from './ValueCalculatorConversionValueForm';

const ValueCalculatorConversionValuePage = (props: RouteComponentProps) => {
    const dispatch = useDispatch();

    const appointmentValue = useSelector((state: any) => state.value.appointmentValue);

    const resetAppointmentValue = () => dispatch(resetAppointmentValueActionCreator());

    const setAppointmentValue = (scheduleRate: number) => dispatch(setAppointmentValueActionCreator(scheduleRate));

    return (
        <>
            <FormLayout
                header="define the conversion value"
                icon={<PaidOutlined />}
                stepName={ValueCalculatorStep.CONVERSION_VALUE}
                guidingText="Confirm or update the conversion value of the selected specialty."
                guidingTooltipText="Cured combines industry averages with real customer data by specialty to provide a healthcare-specific recommendation."
            >
                <ValueCalculatorConversionValueForm
                    appointmentValue={appointmentValue}
                    resetAppointmentValue={resetAppointmentValue}
                    setAppointmentValue={setAppointmentValue}
                />
            </FormLayout>
        </>
    );
};

export default ValueCalculatorConversionValuePage;
