import { useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import { ArrowBack, AutoGraph, PaidOutlined } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import useEmailBySendId from 'hooks/useEmailBySendId';
import useClickApptValueAssumption from 'hooks/queries/useClickApptValueAssumption';
import { AudienceStatus, URLPaths } from 'models/enums';
import AutocompleteWithClickAway from 'domains/core/components/AutocompleteWithClickAway';
import Button from 'domains/core/components/Button';
import DetailItem from 'domains/core/components/DetailItem';
import Flow from 'domains/core/components/Flow';
import EmailIconButton from 'domains/campaigns/pages/CurationsPage/CampaignDetailsPage/EmailIconButton';
import EmailPreviewContent from 'domains/content/components/EmailPreviewContent';
import ForecastedValue from 'domains/value/components/ForecastedValue';
import PageHeader from 'domains/core/components/PageHeader';

const ValueCalculatorDetailsPage = () => {
    const history = useHistory();

    const { sendId } = useParams<{ sendId: string }>();

    const {
        contentSnapshot,
        fromAddress,
        preHeader,
        sendName,
        subjectLine,
        templateName,
        isPreviewOpen,
        togglePreview,
        closePreview,
    } = useEmailBySendId(+sendId);

    const { data } = useClickApptValueAssumption();

    const clickApptValueAssumptions = useMemo(
        () => data?.filter(({ audienceStatus }) => audienceStatus !== AudienceStatus.CANCELED),
        [data]
    );

    const clickApptValueAssumption = useMemo(
        () =>
            clickApptValueAssumptions?.find((clickApptValueAssumption) => clickApptValueAssumption.sendId === +sendId),
        [clickApptValueAssumptions, sendId]
    );

    const isInsightsEnabled =
        clickApptValueAssumption?.audienceStatus === AudienceStatus.ACTIVE ||
        clickApptValueAssumption?.audienceStatus === AudienceStatus.COMPLETE;

    return (
        <Flow
            topLeft={<PageHeader icon={<PaidOutlined fontSize="inherit" />} header="value details" />}
            topRight={
                <Button startIcon={<ArrowBack />} onClick={() => history.push(URLPaths.VALUE)}>
                    Back
                </Button>
            }
            bottomLeft={
                <Stack rowGap={4}>
                    <AutocompleteWithClickAway
                        header="Send"
                        description={sendName}
                        options={clickApptValueAssumptions}
                        option={clickApptValueAssumption}
                        optionLabelKey="sendName"
                        optionValueKey="sendId"
                        onChange={(option) => {
                            history.push(`${URLPaths.VALUE_DETAILS}/${option.value}/summary`);
                            closePreview();
                        }}
                    />
                    <DetailItem header="Specialty" description={clickApptValueAssumption?.specialty} />
                    <DetailItem
                        header="Email"
                        description={templateName}
                        descriptionIcon={<EmailIconButton onClick={togglePreview} />}
                        descriptionIconTooltip="Click to preview email."
                        isActive={isPreviewOpen}
                    />
                    <DetailItem description={clickApptValueAssumption?.clickUrl} header={'Qualification Criteria'} />
                    {clickApptValueAssumption?.contactEventRecordType && (
                        <DetailItem
                            header="Conversion Event"
                            description={clickApptValueAssumption?.contactEventRecordType}
                        />
                    )}
                    <DetailItem
                        header="Send Status"
                        description={clickApptValueAssumption?.audienceStatus}
                        descriptionIcon={
                            isInsightsEnabled && <AutoGraph onClick={() => history.push(URLPaths.INSIGHTS)} />
                        }
                        descriptionIconTooltip={
                            isInsightsEnabled &&
                            'Click to view value estimates and track your data over time in Insights.'
                        }
                    />
                </Stack>
            }
            bottomRight={
                clickApptValueAssumption && (
                    <>
                        <EmailPreviewContent
                            isGridItem
                            emailTemplateHTML={contentSnapshot}
                            fromAddress={fromAddress}
                            preHeader={preHeader}
                            subjectLine={subjectLine}
                            isOpen={isPreviewOpen}
                            onClose={closePreview}
                        />
                        <Box display={isPreviewOpen ? 'none' : 'block'}>
                            <ForecastedValue
                                appointmentValue={clickApptValueAssumption?.appointmentValue}
                                attendRate={clickApptValueAssumption?.attendRate}
                                scheduleRate={clickApptValueAssumption?.scheduleRate}
                                sendId={+sendId}
                                specialty={clickApptValueAssumption?.specialty}
                                uniqueClickRate={clickApptValueAssumption?.uniqueClickRate}
                                isCalculationEnabled={true}
                            />
                        </Box>
                    </>
                )
            }
        />
    );
};

export default ValueCalculatorDetailsPage;
