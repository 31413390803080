import { UseMutationOptions } from 'react-query';
import useMutation from 'hooks/useMutation';
import { ApiMethods, CuredApiPaths } from 'models/enums';
import { PathWithID } from 'models/types';
import { UpdateTemplateInput } from 'domains/content/types';
import MutationKeys from './keys';

const useUpdateTemplate = (
    templateId: string,
    mutateOptions?: UseMutationOptions<unknown, Error, UpdateTemplateInput>
) =>
    useMutation<UpdateTemplateInput>(
        MutationKeys.UPDATE_TEMPLATE,
        new PathWithID(CuredApiPaths.TEMPLATES, parseInt(templateId)),
        mutateOptions,
        ApiMethods.PUT
    );

export default useUpdateTemplate;
