import { keyframes } from '@mui/material';
import IconButton, { Props as IconButtonProps } from './IconButton';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

type Props = {
    isRefetching: boolean;
};

const RotatingIconButton = ({ isRefetching, ...rest }: Props & IconButtonProps) => {
    const animationStyles = {
        animation: isRefetching ? `${rotate} .75s linear infinite` : 'none',
    };
    return <IconButton {...rest} sx={{ ...rest.sx, ...animationStyles }} />;
};

export default RotatingIconButton;
