import { URLPaths } from 'models/enums';
import { useHistory } from 'react-router';

const useGetCreateContentDropdownOptions = () => {
    const history = useHistory();

    return [
        {
            label: 'email',
            onClick: () => history.push(`${URLPaths.CONTENT_CREATE_EMAIL}?id=default`),
        },
        {
            label: 'landing page',
            onClick: () => history.push(`${URLPaths.CONTENT_CREATE_PAGE}?id=default`),
        },
    ];
};

export default useGetCreateContentDropdownOptions;
