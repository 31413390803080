import { ContentType } from 'domains/content/types';
import { useHistory, useParams } from 'react-router-dom';
import useQueryParams from './useQueryParams';
import { URLPaths } from 'models/enums';

/**
 * A hook to get the type, folderId and the function to update both for the content page.
 * */
const useContentQueryManager = () => {
    const history = useHistory();
    const query = useQueryParams();
    const type = query.get('type') as ContentType;
    let { folderId } = useParams<{ folderId: string | undefined }>();

    const numericFolderId = folderId === undefined ? -1 : Number(folderId);

    const basePath = `${URLPaths.CONTENT}`;

    const getFolderPath = (newFolderId: number | undefined) => {
        if (newFolderId) {
            return newFolderId === -1 ? '' : `/${newFolderId}`;
        }
        return folderId ? `/${folderId}` : '';
    };

    const getTypePath = (newType: string | undefined) => {
        if (newType) {
            return newType === ContentType.ALL ? '' : `?type=${newType}`;
        }
        return type ? `?type=${type}` : '';
    };

    const changeViewParams = ({
        newFolderId,
        newType,
    }: {
        newFolderId?: number;
        newType?: string;
    } = {}) => {
        const folderPath = getFolderPath(newFolderId);
        const typePath = getTypePath(newType);

        const path = `${basePath}${folderPath}${typePath}`;

        history.push(path);
    };

    return { type, folderId: numericFolderId, changeViewParams };
};

export default useContentQueryManager;
