import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { CuredApiPaths } from 'models/enums';
import Card from 'pages/InsightsPage/dashboards/Card';
import { FiltersForApiState } from 'pages/InsightsPage/dashboards/filtering/types';
import useFilteredData from 'pages/InsightsPage/dashboards/filtering/useFilteredData';

type DataProps = {
    total_messages_sent: string;
    total_send_count: string;
    average_audience: string;
    delivered_rate: string;
    total_subscribers: string;
    opt_out_rate: string;
};

type Props = {
    filtersForApi: FiltersForApiState;
};

const SMSStatsCards = ({ filtersForApi }: Props) => {
    const { getData, isError, isLoading, data } = useFilteredData({
        key: 'sms-dashboard/stats',
        path: CuredApiPaths.REPORT_SMS_STATS,
    });

    useEffect(() => {
        getData(filtersForApi);
    }, [filtersForApi, getData]);

    const getFigure = (figure: string, isPercentage?: boolean) =>
        isPercentage ? `${figure}%` : Number(figure).toLocaleString();

    const statsData = data as DataProps;

    return (
        <Grid container item spacing={2} xs={12} md={6}>
            <Grid item xs={12} sm={6} md={4}>
                <Card isError={isError} isFigureCard isLoading={isLoading} label="Total Messages Sent">
                    {getFigure(statsData?.total_messages_sent)}
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Card isError={isError} isFigureCard isLoading={isLoading} label="Total Send Count">
                    {getFigure(statsData?.total_send_count)}
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Card isError={isError} isFigureCard isLoading={isLoading} label="Average Audience Size">
                    {getFigure(statsData?.average_audience)}
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Card isError={isError} isFigureCard isLoading={isLoading} label="Delivery Rate">
                    {getFigure(statsData?.delivered_rate, true)}
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Card isError={isError} isFigureCard isLoading={isLoading} label="Total Subscribers Count">
                    {getFigure(statsData?.total_subscribers)}
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Card isError={isError} isFigureCard isLoading={isLoading} label="Opt-Out Rate">
                    {getFigure(statsData?.opt_out_rate, true)}
                </Card>
            </Grid>
        </Grid>
    );
};

export default SMSStatsCards;
