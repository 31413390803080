import { UseMutationOptions } from 'react-query';
import { CuredApiPaths } from 'models/enums';
import useMutation from '../useMutation';
import MutationKeys from './keys';

export type RenameContentPostBody = {
    name: string;
    id: number;
    type: string;
};

/**
 * Renames content.
 * @param mutateOptions react-query useMutation option
 * @returns the react-query useMutation results
 * See https://react-query-v3.tanstack.com/reference/useMutation for options and return value
 */
const useRenameContent = (mutateOptions?: UseMutationOptions<null, Error, RenameContentPostBody>) =>
    useMutation<RenameContentPostBody, null>(MutationKeys.RENAME_CONTENT, CuredApiPaths.CONTENT_RENAME, mutateOptions);

export default useRenameContent;
