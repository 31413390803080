import { DateTime } from 'luxon';
import { Typography, TypographyProps } from '@mui/material';
import { SendRecurrenceFrequency } from 'domains/campaigns/types';
import { getRecurrenceLabel } from 'domains/campaigns/utils';
import { getFormattedLocalDate, getFormattedLocalTime } from 'utils';

type RecurrenceRenderData = {
    startDatetimeLabel: string;
};

const getDatetimeLabel = (
    recurrenceFrequency: SendRecurrenceFrequency,
    startDatetime: string
): RecurrenceRenderData => {
    const startDatetimeObj = DateTime.fromISO(startDatetime);
    const startDate = getFormattedLocalDate(startDatetimeObj.toISO());
    const startTime = getFormattedLocalTime(startDatetimeObj.toISO());

    const recurrenceLabel = getRecurrenceLabel(recurrenceFrequency, startDatetimeObj.toISO());

    switch (recurrenceFrequency) {
        case SendRecurrenceFrequency.DOES_NOT_REPEAT:
            return {
                startDatetimeLabel: `at ${startTime}`,
            };
        case SendRecurrenceFrequency.CONTINUOUS:
            return {
                startDatetimeLabel: recurrenceLabel,
            };
        case SendRecurrenceFrequency.WEEKLY:
            return {
                startDatetimeLabel: `${recurrenceLabel} at ${startTime}`,
            };
        default:
            if (recurrenceFrequency) {
                return {
                    startDatetimeLabel: `${recurrenceFrequency} at ${startTime}`,
                };
            }
            return {
                startDatetimeLabel: `${startDate} at ${startTime}`,
            };
    }
};

export type Props = {
    recurrenceFrequency: SendRecurrenceFrequency;
    startDatetime: string;
};

const Recurrence = ({ recurrenceFrequency, startDatetime, ...rest }: Props & TypographyProps) => {
    const { startDatetimeLabel } = getDatetimeLabel(recurrenceFrequency, startDatetime);

    return (
        <>
            <Typography variant="body2" {...rest}>
                {startDatetimeLabel}
            </Typography>
        </>
    );
};

export default Recurrence;
