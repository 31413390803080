import { CuredApiPaths } from '../../models/enums';
import { UseMutationOptions } from 'react-query';
import useMutation from '../useMutation';
import MutationKeys from './keys';

type SendTestEmailInput = {
    templateId: number;
    senderProfileId: number;
    toAddresses: string[];
    subjectLine?: string;
    preheader?: string;
};

/**
 * Sends a test email.
 * @param mutateOptions react-query useMutation option
 * @returns the react-query useMutation results
 * See https://react-query-v3.tanstack.com/reference/useMutation for options and return value
 */
const useSendTestEmail = (mutateOptions?: UseMutationOptions<unknown, Error, SendTestEmailInput>) =>
    useMutation<SendTestEmailInput>(MutationKeys.SEND_TEST_EMAIL, CuredApiPaths.TEST_EMAILS, mutateOptions);

export default useSendTestEmail;
