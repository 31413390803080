import { UseQueryOptions } from 'react-query';
import { CuredApiPaths } from 'models/enums';
import useQuery from 'hooks/useQuery';
import QueryKeys from 'hooks/queries/keys';
import { ClickApptValueAssumption } from 'domains/value/types';

type Params = {
    path?: CuredApiPaths.CLICK_APPOINTMENT_VALUE_ASSUMPTIONS | string;
    reactQueryOptions?: UseQueryOptions<ClickApptValueAssumption[], Error>;
};

const useClickApptValueAssumption = (params: Params = {}) => {
    const { path = CuredApiPaths.CLICK_APPOINTMENT_VALUE_ASSUMPTIONS, reactQueryOptions } = params;
    return useQuery(QueryKeys.APP(path), path, reactQueryOptions);
};

export default useClickApptValueAssumption;
