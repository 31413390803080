import ConditionalSplitNavBar from 'domains/campaigns/pages/CurationPage/ConditionalSplitPage/ConditionalSplitNavBar';
import { Campaign, Send, SendRecurrenceFrequency } from 'domains/campaigns/types';
import { Segment, EngagementAPIOperator } from 'domains/segments/types';
import useSegment from 'hooks/queries/useSegment';
import MultiLevelConditionalSplitDetailsPage from './MultiLevelConditionalSplitDetailsPage';
import { getConditionalSplitCurationDetails } from 'domains/campaigns/utils';
import CampaignDetailsPage from '../CampaignDetailsPage';

export type Props = {
    campaign: Campaign;
};

type FindEngagementReturn = {
    index: number;
};

type FindCriteriaReturn = {
    send: Send;
};

const findSendBySegmentId = (sends: Send[], segmentId: number): Send | undefined =>
    sends?.find((send) => send.segmentId === segmentId);

const findCriteria = (
    segments: any,
    segmentIds: any,
    sends: Send[],
    operator: EngagementAPIOperator
): FindCriteriaReturn => {
    const { index } = findEngagementFilterByOperator(segments, operator);
    const send = findSendBySegmentId(sends, segmentIds[index]);
    return { send };
};

const findEngagementFilterByOperator = (segments: Segment[], operator: EngagementAPIOperator): FindEngagementReturn => {
    let returnObject: FindEngagementReturn = { index: -1 };
    // Start at i=1 to ignore the first segment which we know is not the meets or does not meet segment.
    for (let i = 1; i < segments.length; i++) {
        const segment = segments[i];
        if (segment?.segmentDefinition?.engagement) {
            for (const segmentEngagement of segment.segmentDefinition.engagement) {
                if (segmentEngagement.operator === operator && segmentEngagement?.name !== 'received_sends') {
                    returnObject.index = i;
                }
            }
        }
    }
    return returnObject;
};

const ConditionalSplitDetailsPageWrapper = ({ campaign }: Props) => {
    const firstSend = campaign.sends[0];
    const secondSend = campaign.sends[1];
    const thirdSend = campaign.sends[2];

    const { data: firstSegment } = useSegment(firstSend.segmentId, { enabled: !!firstSend.segmentId });
    const { data: secondSegment } = useSegment(secondSend?.segmentId, { enabled: !!secondSend?.segmentId });
    const { data: thirdSegment } = useSegment(thirdSend?.segmentId, { enabled: !!thirdSend?.segmentId });

    const campaignIsMultiLevel = !!campaign.sends.slice(1).find((send) => send.parentId !== firstSend.id);
    if (campaignIsMultiLevel && firstSend.recurrenceFrequency === SendRecurrenceFrequency.CONTINUOUS) {
        return <MultiLevelConditionalSplitDetailsPage campaign={campaign} />;
    }

    const segments = [firstSegment, secondSegment, thirdSegment];

    const segmentIds = [firstSend.segmentId, secondSend?.segmentId, thirdSend?.segmentId];

    const { send: yesCriteriaSend } = findCriteria(
        segments,
        segmentIds,
        campaign.sends,
        EngagementAPIOperator.CONTAINS
    );
    const { send: noCriteriaSend } = findCriteria(
        segments,
        segmentIds,
        campaign.sends,
        EngagementAPIOperator.DOES_NOT_CONTAIN
    );

    const details = getConditionalSplitCurationDetails({
        firstSend,
        noCriteriaSend,
        segments,
        yesCriteriaSend,
    });

    return (
        <CampaignDetailsPage
            campaign={campaign}
            details={details}
            NavBar={
                <ConditionalSplitNavBar
                    readonly
                    yesIsExit={Boolean(!yesCriteriaSend)}
                    noIsExit={Boolean(!noCriteriaSend)}
                />
            }
        />
    );
};

export default ConditionalSplitDetailsPageWrapper;
