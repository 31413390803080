import { useEffect, useMemo } from 'react';
import { CuredApiPaths } from 'models/enums';
import Card from 'pages/InsightsPage/dashboards/Card';
import { FiltersForApiState } from 'pages/InsightsPage/dashboards/filtering/types';
import useFilteredData from 'pages/InsightsPage/dashboards/filtering/useFilteredData';
import DataGrid from 'pages/InsightsPage/visualizations/DataGrid';
import {
    getHeaderName,
    renderCellWithPercentageBar,
    useGetRows,
} from 'pages/InsightsPage/visualizations/DataGrid/utils';

type Props = {
    filtersForApi: FiltersForApiState;
};

const TopSendsByClickCard = ({ filtersForApi }: Props) => {
    const { getData, isError, isLoading, data } = useFilteredData({
        key: 'curations-dashboard/top-sends-by-click',
        path: CuredApiPaths.REPORT_OVERVIEW_TOP_CLICKS,
    });

    useEffect(() => {
        getData(filtersForApi);
    }, [filtersForApi, getData]);

    const tableData = data as {}[];

    const columns: any = useMemo(() => {
        if (!tableData?.length) return;

        const columnFields = Object.keys(tableData?.[0]);
        const columns = columnFields?.map((field) => ({
            field,
            flex: 1,
            headerName: getHeaderName(field),
            renderCell: ({ value }: { value?: string }) => {
                if (field.includes('rate')) {
                    return renderCellWithPercentageBar(value);
                }
                return value;
            },
        }));

        return columns;
    }, [tableData]);

    const rows = useGetRows(tableData);

    return (
        <Card isError={isError} isLoading={isLoading} label="Top 10 Sends by Unique Click Rate">
            <DataGrid
                columns={columns}
                hideFooterRowCount
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'click_through_rate', sort: 'desc' }],
                    },
                }}
                rows={rows}
            />
        </Card>
    );
};

export default TopSendsByClickCard;
