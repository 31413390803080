import { AccessTime, DoNotDisturb, MailOutline, SupervisedUserCircleOutlined, SwapCalls } from '@mui/icons-material';
import { Box } from '@mui/material';
import { Fragment } from 'react';
import { Campaign, Send, SplitActions, SplitTypes } from 'domains/campaigns/types';
import CustomCampaignNavigationButton from 'domains/campaigns/components/CustomCampaign/CustomCampaignNavigation/CustomCampaignNavigationButton';

type Props = {
    campaign: Campaign;
    isPreviewOpen: boolean;
};

const CustomCampaignDetailsNavigation = ({ campaign, isPreviewOpen }: Props) => {
    const renderSend = (send: Send) => {
        if (send?.splitAction === SplitActions.EXIT) {
            return <CustomCampaignNavigationButton icon={<DoNotDisturb />} label="exit" />;
        }
        return <CustomCampaignNavigationButton icon={<MailOutline />} label="email" />;
    };

    const renderSendDetails = (send: Send, index: number) => {
        // The first send cannot be time delayed and we do not need to indicate that there's a time
        // delay on the second action in a split. The delay is inicated before the split details.
        const isTimeDelayedSend = index !== 0 && send?.splitType !== SplitTypes.DOES_NOT_MEET;
        // Presently, all splits begin with a meets criteria action. As such, we want to show
        // the conditional split details before that send.
        const shouldShowConditionalSplitDetails = send?.splitType === SplitTypes.MEETS;

        return (
            <Fragment key={`send-${index}`}>
                {isTimeDelayedSend && <CustomCampaignNavigationButton icon={<AccessTime />} label="time delay" />}
                {shouldShowConditionalSplitDetails && (
                    <CustomCampaignNavigationButton icon={<SwapCalls />} label="conditional split" />
                )}
                {send?.splitType && <div>{send.splitType}</div>}
                {renderSend(send)}
            </Fragment>
        );
    };

    return (
        <Box display={isPreviewOpen ? 'none' : 'block'} ml={3} height="100%" width="75%">
            <CustomCampaignNavigationButton icon={<SupervisedUserCircleOutlined />} label="audience" />
            {campaign.sends.map((send, index) => renderSendDetails(send, index))}
        </Box>
    );
};

export default CustomCampaignDetailsNavigation;
