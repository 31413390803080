import * as actionTypes from 'domains/campaigns/state/actionTypes';

export type EditSMSPayload = {
    audienceId: number | null;
    buttonClicked: boolean;
    content: string;
    name: string;
    scheduledTimestamp: string;
};

export const editSMSCuration = (payload: EditSMSPayload) => ({
    type: actionTypes.SINGLE_SEND_EDIT_SMS_CURATION,
    payload,
});

export const setDatetimeError = (isError: boolean) => ({
    type: actionTypes.SINGLE_SEND_SET_SMS_CURATION_DATETIME_ERROR,
    payload: isError,
});
