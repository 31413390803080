import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Grid } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import CurationFormImageLayout from 'domains/campaigns/components/CurationFormImageLayout';
import { CampaignChannels } from 'domains/campaigns/types';
import ErrorSnackbar from 'domains/core/components/Snackbars/ErrorSnackbar';
import SuccessDialog from 'domains/core/components/SuccessDialog';
import useCreateCampaign from 'hooks/mutations/useCreateCampaign';
import QueryKeys from 'hooks/queries/keys';
import { CurationStep, URLPaths, CampaignType } from 'models/enums';
import { minimizeLeftNavPanel } from 'state/appVisual/appVisualActions';
import { resetStateSingleSendLegacy } from 'domains/campaigns/state/actionCreators';
import { editSMSCuration } from 'domains/campaigns/state/sms/smsActions';
import { isFormComplete } from 'domains/campaigns/state/sms/smsReducer';
import SMSCurationForm from './SMSCurationForm';
import SMSTestSendDialog from './SMSTestSendDialog';
import CampaignFlowRouteLeavingGuard from 'domains/campaigns/components/CampaignFlowRouteLeavingGuard';
import { SINGLE_SEND_RESET_CURATION } from 'domains/campaigns/state/actionTypes';
import { selectIsSMSCurationComplete } from 'domains/campaigns/state/selectors';
import { recordSatismeterEvent } from 'helpers/injectSatismeter/injectSatismeter';

const selectAudienceId = (state: any) => state.curation.sms.audienceId;
const selectContent = (state: any) => state.curation.sms.content;
const selectName = (state: any) => state.curation.sms.name;
const selectScheduledTimestamp = (state: any) => state.curation.sms.scheduledTimestamp;
const selectSenderProfileId = (state: any) => state.curation.sms.senderProfileId;
const selectSmsState = (state: any) => state.curation.sms;

const selector = createSelector(
    selectAudienceId,
    selectContent,
    selectName,
    selectScheduledTimestamp,
    selectSenderProfileId,
    selectSmsState,
    (audienceId, content, name, scheduledTimestamp, senderProfileId, smsState) => ({
        audienceId,
        content,
        name,
        scheduledTimestamp,
        senderProfileId,
        smsState,
    })
);

const SMSCurationPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const queryClient = useQueryClient();
    const { audienceId, content, name, scheduledTimestamp, smsState } = useSelector(selector);

    const { mutate, isError, error } = useCreateCampaign({
        onSuccess: () => {
            // Invalidate queries used for the sends and campaigns tables
            queryClient.invalidateQueries(QueryKeys.CAMPAIGNS);

            setIsSuccessDialogOpen(true);
        },
    });

    const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
    const [isTestSendDialogOpen, setIsTestSendDialogOpen] = useState(false);

    const handleExitCurationWorkflow = (path: string) => {
        history.push(path);
        dispatch(minimizeLeftNavPanel(false));
        dispatch(resetStateSingleSendLegacy());
        recordSatismeterEvent('publish_curation_popup');
    };

    return (
        <>
            {isError && <ErrorSnackbar errorMessage={error.message} />}
            <CampaignFlowRouteLeavingGuard
                mainRoute={URLPaths.CURATIONS_SMS}
                resetCampaignActionType={SINGLE_SEND_RESET_CURATION}
                selectIsCampaignComplete={selectIsSMSCurationComplete}
            />
            <SuccessDialog
                title="high five!"
                body="Nice work, you've successfully scheduled your message. Once it sends, visit Insights to monitor its performance."
                isOpen={isSuccessDialogOpen}
                setIsOpen={setIsSuccessDialogOpen}
                onClose={() => setIsSuccessDialogOpen(false)}
                buttonOneText="insights"
                buttonOneOnClick={() => {
                    handleExitCurationWorkflow(URLPaths.INSIGHTS);
                }}
                buttonTwoText="curations"
                buttonTwoOnClick={() => {
                    handleExitCurationWorkflow(URLPaths.CURATIONS);
                }}
            />
            <SMSTestSendDialog
                isOpen={isTestSendDialogOpen}
                setIsOpen={setIsTestSendDialogOpen}
                onClose={() => setIsTestSendDialogOpen(false)}
            />
            <Grid container mt={8}>
                <CurationFormImageLayout
                    continueButtonText="Publish"
                    header="direct communication"
                    stepName={CurationStep.SMS}
                    guidingText="Quickly get in touch through SMS sends."
                    canContinue={isFormComplete(smsState)}
                    onContinue={() => {
                        dispatch(editSMSCuration({ ...smsState, buttonClicked: true }));
                        mutate({
                            name: name,
                            type: CampaignType.SINGLE_SEND,
                            sends: [
                                {
                                    segmentId: audienceId,
                                    channel: CampaignChannels.SMS,
                                    name,
                                    scheduledTimestamp,
                                    content,
                                },
                            ],
                        });
                        recordSatismeterEvent('publish_curation');
                    }}
                >
                    <SMSCurationForm setIsTestSendDialogOpen={setIsTestSendDialogOpen} />
                </CurationFormImageLayout>
            </Grid>
        </>
    );
};

export default SMSCurationPage;
