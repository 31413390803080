import { Campaign } from 'domains/campaigns/types';
import { useState } from 'react';
import CampaignDetailsLayout from './CampaignDetailsLayout';
import CurationListAndFirstAudience from './CurationListAndFirstAudience';
import CampaignDetails, { Detail } from 'domains/campaigns/components/CampaignDetails/CampaignDetails';
import EmailPreviewContent from 'domains/content/components/EmailPreviewContent';
import { Box } from '@mui/material';
import useSenderProfile from 'hooks/queries/useSenderProfile';

export type Props = {
    campaign: Campaign;
    details?: Detail[];
    NavBar?: React.ReactNode;
};

const CampaignDetailsPage = ({ campaign, details, NavBar }: Props) => {
    const [contentPreviewId, setContentPreviewId] = useState<string | null>();
    const closePreview = () => setContentPreviewId(null);

    const emailDetails = campaign.sends.map((send) => ({
        contentNodeId: `${send.id}`,
        contentId: send.id,
        parentAudienceDelayInterval: send?.parentAudienceDelayInterval,
        recurrenceFrequency: send?.recurrenceFrequency,
        scheduledEndTimestamp: send?.scheduledEndTimestamp,
        scheduledTimestamp: send?.scheduledTimestamp,
        onClick: (id: string) => setContentPreviewId(id),
    }));

    const detailsWithOnClick = details?.map((detail: Detail) => ({
        ...detail,
        ...(detail?.contentId ? { onClick: (id: string) => setContentPreviewId(id) } : {}),
    }));

    const previewContent = campaign.sends.find((send) => `${send.id}` === contentPreviewId);

    const { data: previewContentSenderProfile } = useSenderProfile(previewContent?.emailSenderProfileId, {
        enabled: Boolean(previewContent),
    });

    return (
        <CampaignDetailsLayout
            left={
                <>
                    <CurationListAndFirstAudience campaign={campaign} onChange={closePreview} />
                    <CampaignDetails
                        channel={campaign.channel}
                        contentPreviewNodeId={contentPreviewId}
                        details={detailsWithOnClick ?? emailDetails}
                        isCampaign
                    />
                </>
            }
            right={
                <>
                    <EmailPreviewContent
                        emailTemplateHTML={previewContent?.contentSnapshot}
                        fromAddress={previewContentSenderProfile?.from_address}
                        isGridItem
                        isOpen={Boolean(previewContent)}
                        preHeader={previewContent?.preHeader}
                        subjectLine={previewContent?.subjectLine}
                        onClose={closePreview}
                    />
                    {NavBar && (
                        <Box
                            data-testid={!previewContent && 'navbar'}
                            display={previewContent ? 'none' : 'block'}
                            height="100%"
                        >
                            {NavBar}
                        </Box>
                    )}
                </>
            }
        />
    );
};

export default CampaignDetailsPage;
