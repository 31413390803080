import { Stack, SxProps, Tooltip, Typography } from '@mui/material';
import theme from 'theme';
import UpdateIcon from '../SvgIcons/UpdateIcon';

export type Props = {
    body?: string | JSX.Element;
    header?: string | JSX.Element;
    tag: string;
    tagSx?: SxProps;
};

const UpdateTag = ({ body, header, tag, tagSx }: Props) => {
    if (!!body || !!header) {
        return (
            <Tooltip
                data-testid="tooltip"
                placement="right"
                title={
                    <Stack rowGap={2.5}>
                        <Stack
                            direction="row"
                            columnGap={1}
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                backgroundColor: theme.palette.info.main,
                                borderRadius: '4px',
                                maxWidth: 'fit-content',
                                px: '0.5rem',
                                height: '1.75rem',
                            }}
                        >
                            <UpdateIcon sx={{ fontSize: '16px', color: 'white' }} />
                            <Typography variant="overline" color="white" mb={0.25}>
                                new feature update
                            </Typography>
                        </Stack>
                        {typeof header === 'string' ? <Typography variant="h3">{header}</Typography> : header}
                        {typeof body === 'string' ? <Typography variant="body2">{body}</Typography> : body}
                    </Stack>
                }
                PopperProps={{
                    sx: {
                        '.MuiTooltip-tooltip': {
                            backgroundColor: theme.palette.other.lightMint,
                            padding: 4,
                        },
                        '.MuiTooltip-arrow:before': {
                            backgroundColor: theme.palette.other.lightMint,
                        },
                    },
                }}
            >
                <Stack
                    direction="row"
                    columnGap={1}
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        backgroundColor: theme.palette.info.main,
                        borderRadius: '4px',
                        height: '2.25rem',
                        minWidth: 'fit-content',
                        px: '0.75rem',
                        zIndex: 1000,
                        ...tagSx,
                    }}
                >
                    <UpdateIcon />
                    <Typography variant="body2" color="white" mb={0.125}>
                        {tag}
                    </Typography>
                </Stack>
            </Tooltip>
        );
    }

    return (
        <Stack
            direction="row"
            columnGap={1}
            alignItems="center"
            justifyContent="center"
            sx={{
                backgroundColor: theme.palette.info.main,
                borderRadius: '4px',
                height: '2.25rem',
                minWidth: 'fit-content',
                px: '0.75rem',
                zIndex: 1000,
                ...tagSx,
            }}
        >
            <UpdateIcon />
            <Typography variant="body2" color="white" mb={0.125}>
                {tag}
            </Typography>
        </Stack>
    );
};

export default UpdateTag;
