import { DateTime } from 'luxon';
import { FiltersForApiState, FilterValuesState } from './types';

export const minDate = DateTime.fromISO('2021-01-01');

export const getIsValidDateRange = (timeframe?: FilterValuesState['filterTimeframe']) => {
    if (!timeframe) return false;

    const { start, end } = timeframe;
    const isValidStart = start?.isValid && start >= minDate;
    const isValidDates = isValidStart && end?.isValid;
    const isValidDateStartEnd = start <= end;
    return isValidDates && isValidDateStartEnd;
};

export const getIsValidRelativeTimeframe = (relativeTimeframe?: FilterValuesState['filterRelativeTimeframe']) => {
    if (!relativeTimeframe) return false;

    const { direction, duration, unit } = relativeTimeframe;
    return !!direction && !!duration && !!unit;
};

export const getTimeframeForApi = (
    isRelativeTimeframe: boolean,
    filterValues: FilterValuesState
): FiltersForApiState['timeframe'] => {
    if (isRelativeTimeframe) {
        const { direction, duration, unit } = filterValues.filterRelativeTimeframe;
        const durationString = duration?.toString();
        const offsetValue = direction === 'the last' ? `-${durationString}` : durationString;
        return {
            offset: {
                unit,
                value: offsetValue,
            },
            duration: {
                unit,
                value: durationString,
            },
        };
    }

    return {
        start: filterValues.filterTimeframe.start?.toFormat('yyyy-MM-dd'),
        end: filterValues.filterTimeframe.end?.toFormat('yyyy-MM-dd'),
    };
};
