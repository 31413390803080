import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { minimizeLeftNavPanel } from 'state/appVisual/appVisualActions';

export type Props = {
    feedback?: React.ReactNode;
    topLeft?: React.ReactNode;
    topRight?: React.ReactNode;
    bottomLeft?: React.ReactNode;
    bottomRight?: React.ReactNode;
};

const Flow = ({ feedback, topLeft, topRight, bottomLeft, bottomRight }: Props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(minimizeLeftNavPanel(true));
    }, [dispatch]);

    return (
        <>
            {feedback}
            <Grid container mt={8} maxWidth="80rem" alignSelf="center">
                <Grid item xs={10}>
                    {topLeft}
                </Grid>
                <Grid container item xs={2} justifyContent="flex-end" mb={8}>
                    {topRight}
                </Grid>
                <Grid item sm md={4.25} mb={4}>
                    {bottomLeft}
                </Grid>
                <Grid item md />
                <Grid item sm={12} md={7} height="75vh">
                    {bottomRight}
                </Grid>
            </Grid>
        </>
    );
};

export default Flow;
