import { UseQueryOptions } from 'react-query';
import { SenderProfile } from 'domains/content/types';
import { CuredApiPaths } from 'models/enums';
import { PathWithID } from 'models/types';
import useQuery from '../useQuery';

const useSenderProfile = (senderProfileId: number | null, reactQueryOptions?: UseQueryOptions<SenderProfile, Error>) =>
    useQuery<SenderProfile>(
        ['sender-profile', senderProfileId?.toString()],
        new PathWithID(CuredApiPaths.SENDER_PROFILES, senderProfileId),
        reactQueryOptions
    );

export default useSenderProfile;
