import { UseQueryOptions } from 'react-query';
import useQuery from 'hooks/useQuery';
import { CuredApiPaths } from 'models/enums';
import QueryKeys from './keys';

type Params = {
    sendId: number;
    reactQueryOptions?: UseQueryOptions<string[], Error>;
};

const useSendClickableUrls = ({ sendId, reactQueryOptions }: Params) => {
    const queryKey = QueryKeys.SEND_ID_CLICKABLE_URLS(sendId);

    const path = `${CuredApiPaths.APP_SENDS}/${sendId}/clickable-urls`;

    return useQuery(queryKey, path, reactQueryOptions);
};

export default useSendClickableUrls;
