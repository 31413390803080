import { AutoFixHigh } from '@mui/icons-material';
import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useTemplates from 'hooks/queries/useTemplates';
import useSenderProfiles from 'hooks/queries/useSenderProfiles';
import useTemplateSuggestedSubjectLines from 'hooks/queries/useTemplateSuggestedSubjectLines';
import { ContentFields } from 'domains/campaigns/types';
import SubjectLinesDialog from 'domains/campaigns/components/SubjectLinesDialog';
import { EditContentPayload } from 'domains/campaigns/state/createCampaignSlice';
import { SenderProfile, Template } from 'domains/content/types';
import AutocompleteContent from 'domains/core/components/AutocompleteContent';
import { AutocompleteOption } from 'domains/core/components/Autocomplete/Autocomplete';
import IconButton from 'domains/core/components/IconButton';
import InputLabel from 'domains/core/components/InputLabel';
import Select from 'domains/core/components/Select';
import TextField from 'domains/core/components/TextField';

type Props = {
    contentFields: ContentFields;
    nodeId: string;
    editContent: (contentFields: EditContentPayload) => void;
    contentPreviewNodeId: string;
    setContentPreviewNodeId: (contentPreviewNodeId: string) => void;
};

const CurationContentForm = ({
    contentFields,
    nodeId,
    editContent,
    contentPreviewNodeId,
    setContentPreviewNodeId,
}: Props) => {
    const { preHeader, senderProfileId, subjectLine, templateId } = contentFields;

    const [contentInputValue, setContentInputValue] = useState('');
    const [selectedContent, setSelectedContent] = useState<AutocompleteOption<Template>>();
    const [isSubjectLinesDialogOpen, setIsSubjectLinesDialogOpen] = useState(false);

    const { isLoading: isSenderProfilesLoading, data: senderProfiles } = useSenderProfiles();

    const { data: templates } = useTemplates();

    const { data: suggestedSubjectLines } = useTemplateSuggestedSubjectLines({
        templateId,
        reactQueryOptions: {
            enabled: !!selectedContent && !!templateId,
        },
    });

    useEffect(() => {
        if (templateId) {
            const templateFound = templates.find((template: Template) => template.id === templateId);
            const templateOption = { index: 1, label: templateFound.name, value: templateFound };

            setSelectedContent(templateOption);
            setContentInputValue(templateOption.label);
        }
    }, [templateId, templates]);

    const clearContentFields = () => {
        editContent({
            contentFields: {
                ...contentFields,
                templateId: null,
                senderProfileId: null,
                subjectLine: '',
                preHeader: '',
            },
            nodeId,
        });
    };

    const updateContentFields = (option: AutocompleteOption<Template>) => {
        editContent({
            contentFields: {
                ...contentFields,
                templateId: option.value.id,
                senderProfileId: option.value.senderProfileId,
                subjectLine: option.value.subjectLine ?? '',
                preHeader: option.value.preHeader ?? '',
            },
            nodeId,
        });
    };

    const onChangeTemplate = (e: any, option: AutocompleteOption<Template>) => {
        if (option) {
            updateContentFields(option);
        } else {
            clearContentFields();
            setSelectedContent(null);
        }
    };

    const subjectLineGeneratorHelperText = templateId
        ? `Click to use Cured's Subject Line Curator.`
        : `Select content for the Subject Line Generator.`;

    return (
        <Stack rowGap={4}>
            <AutocompleteContent
                onEmailPreviewClick={() =>
                    selectedContent.value.id && !contentPreviewNodeId
                        ? setContentPreviewNodeId(nodeId)
                        : setContentPreviewNodeId(null)
                }
                onChange={onChangeTemplate}
                // For some reason the signature of this prop is wrong and does not match the docs.
                // @ts-ignore
                onInputChange={(e: React.SyntheticEvent, inputValue: string, reason: string) => {
                    if (reason === 'clear') {
                        editContent({ contentFields: { ...contentFields, templateId: null }, nodeId });
                        setContentPreviewNodeId(null);
                        setContentInputValue('');
                        setSelectedContent(undefined);
                    } else {
                        setContentInputValue(inputValue);
                    }
                }}
                isPreviewVisible={Boolean(contentPreviewNodeId)}
                textfieldInput={contentInputValue}
                value={selectedContent}
            />
            {/* If the user clears the templateId from the Autocomplete, we don't want to hide the other fields if they have filled them out. */}
            <>
                <Select
                    data-test="curation-content-page-sender-profile-dropdown"
                    data-testid="curation-content-page-sender-profile-dropdown"
                    required
                    label={<InputLabel label="Sender profile" labelIconTooltipText="Who is sending this email?" />}
                    menuItemStyles={{ textTransform: 'none' }}
                    onChange={(e: any) => {
                        editContent({
                            contentFields: { ...contentFields, senderProfileId: e.target.value as number },
                            nodeId,
                        });
                    }}
                    value={senderProfileId ?? ''}
                    values={
                        !isSenderProfilesLoading
                            ? senderProfiles?.map((senderProfile: SenderProfile) => ({
                                  value: senderProfile.id,
                                  label: senderProfile.from_address,
                              }))
                            : []
                    }
                    fullWidth
                />
                <div style={{ position: 'relative' }}>
                    <TextField
                        counterCurrent={subjectLine.length ?? 0}
                        counterMax={255}
                        data-test="curation-content-page-subject-line"
                        data-testid="curation-content-page-subject-line"
                        fullWidth
                        inputProps={{ maxLength: 255 }}
                        label={
                            <InputLabel
                                label="Subject line"
                                labelIconTooltipText="A descriptive overview of your message that gives contacts a reason to open."
                            />
                        }
                        value={subjectLine}
                        withCounter
                        onChange={(e: any) => {
                            editContent({
                                contentFields: { ...contentFields, subjectLine: e.target.value },
                                nodeId,
                            });
                        }}
                    />
                    <IconButton
                        buttonWrapperCss={{
                            position: 'absolute',
                            right: '-50px',
                            top: '4px',
                        }}
                        disabled={!templateId}
                        handleClick={() => setIsSubjectLinesDialogOpen(true)}
                        Icon={AutoFixHigh}
                        title={subjectLineGeneratorHelperText}
                    />
                    <SubjectLinesDialog
                        isOpen={isSubjectLinesDialogOpen}
                        subjectLines={suggestedSubjectLines}
                        onClickSubjectLine={(index: number) => {
                            editContent({
                                contentFields: { ...contentFields, subjectLine: suggestedSubjectLines[index] },
                                nodeId,
                            });
                        }}
                        onClose={() => setIsSubjectLinesDialogOpen(false)}
                    />
                </div>
                <TextField
                    data-test="curation-content-page-preview"
                    data-testid="curation-content-page-preview"
                    label={
                        <InputLabel
                            label="Preview text"
                            labelIconTooltipText="A quick glimpse into the content of your message that appears after the subject line."
                        />
                    }
                    value={preHeader}
                    onChange={(e: any) => {
                        editContent({
                            contentFields: { ...contentFields, preHeader: e.target.value },
                            nodeId,
                        });
                    }}
                    inputProps={{ maxLength: 100 }}
                    withCounter
                    counterCurrent={preHeader.length ?? 0}
                    counterMax={100}
                    fullWidth
                />
            </>
        </Stack>
    );
};

export default CurationContentForm;
