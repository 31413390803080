import React, { ChangeEventHandler, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, ClickAwayListener, InputAdornment, TextField as MuiTextField, Typography } from '@mui/material';
import {
    StandardTextFieldProps as STFP,
    FilledTextFieldProps as FTFP,
    OutlinedTextFieldProps as OTFP,
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import pencilInactive from 'assets/images/svg/pencil-inactive.svg';
import pencilActive from 'assets/images/svg/pencil-active.svg';

/* implementation details based on this quirk on textfield and input adornments:
https://github.com/mui-org/material-ui/issues/13898 */

export type CommonTextFieldProps = {
    'data-testid'?: string;
    width?: string | number;
    withCounter?: boolean;
    counterCurrent?: number;
    counterMax?: number;
    helperText?: string;
    leadingIcon?: React.ReactNode;
    trailingIcon?: React.ReactNode;
    prefixText?: string;
    suffixText?: string;
    withClickAway?: boolean;
    onCancel?: () => void;
    onClickAway?: () => void;
    hidePencilUntilHovered?: boolean;
};

export type ReduxProps = {
    curationName: string;
    editMetadata: (curationName: string) => void;
    isHiddenOnRoute: boolean;
};

type NameDisplayProps = {
    title: string;
    onClick: () => void;
    hidePencilUntilHovered: boolean;
    'data-testid'?: string;
};

export type TextFieldProps =
    | (CommonTextFieldProps & STFP)
    | (CommonTextFieldProps & FTFP)
    | (CommonTextFieldProps & OTFP);

type StyleProps = {
    labelOffset: number | undefined;
};

export type OnChange = ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;

const useStyles = (withLeadingIconOrText: boolean) =>
    makeStyles((theme: any) => ({
        inputLabelRoot: {
            display: ({ labelOffset }: StyleProps) => (labelOffset !== undefined ? 'block' : 'block'),
            transition: '.3s cubic-bezier(.25,.8,.5,1)',
            fontSize: '1rem',
            top: '-4px',
        },
        inputLabelShrink: {
            marginLeft: () => 0,
            left: '-4px',
            top: '0px',
        },
    }));

const TextFieldBase = (props: TextFieldProps) => {
    const {
        children,
        counterCurrent,
        counterMax,
        leadingIcon,
        prefixText,
        suffixText,
        trailingIcon,
        withClickAway,
        withCounter,
        ...rest
    } = props;

    const startAdornmentRef = useRef<HTMLDivElement>(null);
    const [labelOffset, setLabelOffset] = useState<number>();

    const [shrink, setShrink] = useState<boolean>();

    const classes = useStyles(!!leadingIcon || !!prefixText)({
        labelOffset,
    });

    useEffect(() => {
        setLabelOffset(startAdornmentRef.current?.offsetWidth);
    }, [leadingIcon]);

    useEffect(() => {
        const shouldShrink = props.focused ? true : String(props.value).length !== 0 || false;
        setShrink(shouldShrink);
    }, [props.focused, props.value]);

    const onFocus = useCallback(
        (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setShrink(true);
            if (props.onFocus) {
                props.onFocus(event);
            }
        },
        [props]
    );

    const onBlur = useCallback(
        (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (event.target.value.length === 0 && !props.InputProps?.startAdornment) {
                setShrink(props.focused);
            }
            if (props.onBlur) {
                props.onBlur(event);
            }
        },
        [props]
    );

    const StartAdornment = useMemo(() => {
        if (leadingIcon || prefixText) {
            return (
                <InputAdornment
                    position="start"
                    ref={startAdornmentRef}
                    sx={{
                        '.MuiSvgIcon-root': {
                            color: props.disabled ? `#95A6A7` : `#123133`,
                        },
                        '.MuiTypography-root': {
                            color: props.error ? `#D8302B` : props.disabled ? `#95A6A7` : `#123133`,
                        },
                    }}
                >
                    {leadingIcon}
                    <Typography variant="body1">{prefixText}</Typography>
                </InputAdornment>
            );
        }
    }, [leadingIcon, prefixText, props.disabled, props.error]);

    const endAdornment = (
        <InputAdornment
            position="end"
            sx={{
                '.MuiSvgIcon-root': {
                    color: props.disabled ? `#95A6A7` : `#123133`,
                },
                '.MuiTypography-root': {
                    color: props.error ? `#D8302B` : props.disabled ? `#95A6A7` : `#123133`,
                },
            }}
        >
            <Typography variant="body1">{suffixText}</Typography>
            {trailingIcon}
        </InputAdornment>
    );

    return (
        <MuiTextField
            {...rest}
            onFocus={onFocus}
            onBlur={onBlur}
            size={props.size ? props.size : 'medium'}
            helperText={
                props.helperText
                    ? props.helperText
                    : props.withCounter
                    ? `${props.counterCurrent} / ${props.counterMax}`
                    : null
            }
            sx={{
                '& .MuiFilledInput-input': {
                    marginLeft: labelOffset ? `${(labelOffset || 0) - 13}px` : 0,
                },
                // Disable the increment and decrement buttons on the input when type=number
                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                    display: 'none',
                },
                '& input[type=number]': {
                    MozAppearance: 'textfield',
                },
                backgroundColor: 'white',
                height: 'fit-content',
                width: props.fullWidth ? '100%' : props.width || '16rem',
                ...props.sx,
            }}
            InputLabelProps={{
                style: { pointerEvents: 'auto' },
                shrink: shrink,
                classes: {
                    root: clsx(classes.inputLabelRoot, props.InputLabelProps?.classes?.root),
                    shrink: clsx(classes.inputLabelShrink, props.InputLabelProps?.classes?.shrink),
                    ...props.InputLabelProps?.classes,
                },
                // Setting pointerEvents: 'auto' allows the Tootltip to show on hover if it's used in the label.
                ...props.InputLabelProps,
            }}
            InputProps={{
                startAdornment: StartAdornment,
                endAdornment: endAdornment,
                ...props.InputProps,
            }}
            inputProps={{
                'data-test': 'text-field',
                'data-testid': 'text-field',
                autoComplete: 'off',
                maxLength: props.counterMax ? props.counterMax : Infinity,
                ...props.inputProps,
            }}
        >
            {children}
        </MuiTextField>
    );
};

const NameDisplay = ({ title, onClick, hidePencilUntilHovered, ...rest }: NameDisplayProps) => {
    const [isHovered, setIsHovered] = useState(false);
    const pencilInactiveImage = <img src={pencilInactive} alt="edit name" width="24px" />;
    const pencilActiveImage = <img src={pencilActive} alt="edit name" width="24px" />;
    const componentContainerStyles = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
        '&:hover': {
            cursor: 'pointer',
        },
    };
    const typoContainerStyles = {
        display: 'inline-block',
        maxWidth: '21.25rem',
    };
    const iconContainerStyles = {
        position: 'relative',
        width: '1rem',
        height: '1.5rem',
        marginLeft: '1rem',
    };
    const activeStyles = {
        position: 'absolute',
        visibility: isHovered ? 'visible' : 'hidden',
    };
    const inactiveStyles = {
        position: 'absolute',
        visibility: isHovered ? 'hidden' : 'visible',
        ...(hidePencilUntilHovered && { display: 'none' }),
    };

    return (
        <Box
            data-test-id={rest['data-testid']}
            data-testid={rest['data-testid']}
            sx={componentContainerStyles}
            onClick={onClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <Box sx={typoContainerStyles}>
                <Typography noWrap>{!title || title.length === 0 ? 'untitled' : title}</Typography>
            </Box>
            <Box sx={iconContainerStyles}>
                <Box id="inactive" sx={inactiveStyles}>
                    {pencilInactiveImage}
                </Box>
                <Box id="active" sx={activeStyles}>
                    {pencilActiveImage}
                </Box>
            </Box>
        </Box>
    );
};

const TextFieldWithClickAway = (props: TextFieldProps) => {
    const { hidePencilUntilHovered, width, onCancel, onClickAway, ...rest } = props;
    const [showTextField, setShowTextField] = useState(false);
    const containerStyles = {
        display: 'flex',
        alignItems: 'center',
        height: props.size === 'small' ? '2.5rem' : '3.5rem',
    };
    const textContainerStyles = {
        width,
    };

    const handleClickAway = () => {
        if (!props.error) {
            setShowTextField(false);
            if (onClickAway) {
                onClickAway();
            }
        }
    };

    const handleCancel = () => {
        setShowTextField(false);
        if (onCancel) {
            onCancel();
        }
    };

    const handleKeyDown = (event: any) => {
        // close the textfield on pressing Enter
        if (event.key === 'Enter') {
            event.preventDefault();
            handleClickAway();
        }

        if (event.key === 'Escape') {
            event.preventDefault();
            onCancel();
        }

        if (event.key === 'Escape') {
            event.preventDefault();
            handleCancel();
        }
    };

    const renderField = () => {
        if (!showTextField) {
            return (
                <NameDisplay
                    data-testid={props['data-testid']}
                    hidePencilUntilHovered={hidePencilUntilHovered}
                    title={props.value as string}
                    onClick={() => setShowTextField(true)}
                />
            );
        }
        return (
            <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleClickAway}>
                <Box sx={textContainerStyles}>
                    <TextFieldBase onKeyDown={handleKeyDown} autoFocus={true} {...rest} fullWidth />
                </Box>
            </ClickAwayListener>
        );
    };

    return <Box sx={containerStyles}>{renderField()}</Box>;
};

const TextField = forwardRef((props: TextFieldProps, ref) => {
    if (props.withClickAway) {
        return <TextFieldWithClickAway {...props} />;
    }
    return <TextFieldBase {...props} />;
});

export default TextField;
