import { Stack, SxProps, Theme } from '@mui/material';
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { getTimezone } from 'utils';

type DateTimeRangePickerSx = {
    dateTimePickerSx?: SxProps<Theme>;
    stackSx?: SxProps<Theme>;
};

type Props = {
    onChangeValueStart: (dateTime: DateTime) => void;
    onChangeValueEnd: (dateTime: DateTime) => void;

    styles?: DateTimeRangePickerSx;
    valueStart: DateTime;
    valueEnd: DateTime;
};

const DateTimeRangePickerInputs = ({
    onChangeValueStart,
    onChangeValueEnd,
    styles = {},
    valueStart,
    valueEnd,
}: Props) => {
    const { dateTimePickerSx, stackSx } = styles;
    const isError = valueStart > valueEnd;

    const sharedProps: DateTimePickerProps<DateTime> = {
        label: `Date/Time (${getTimezone()})`,
        slotProps: {
            textField: {
                helperText: isError && 'Please enter a valid date range',
            },
        },
        sx: {
            width: '16rem',
            ...(isError && { marginBottom: '16px' }),
            ...dateTimePickerSx,
        },
    };

    return (
        <Stack columnGap={2} data-testid="date-range-value" direction="row" rowGap={2} sx={stackSx}>
            <DateTimePicker {...sharedProps} maxDateTime={valueEnd} value={valueStart} onChange={onChangeValueStart} />
            <DateTimePicker {...sharedProps} minDateTime={valueStart} value={valueEnd} onChange={onChangeValueEnd} />
        </Stack>
    );
};

export default DateTimeRangePickerInputs;
