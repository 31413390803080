import { CampaignChannels, CampaignKey, SendRecurrenceFrequency } from '../types';
import createCampaignSlice, { CampaignNodeType, CampaignState } from './createCampaignSlice';

const temporaryInitialState: CampaignState = {
    campaignGraph: {
        nodes: [
            {
                id: '438cfb9c-11db-54cd-b924-259911b6813e',
                type: CampaignNodeType.META_DATA_NODE,
                name: '',
                contentPreviewNodeId: null,
            },
            { id: '267a4b2a-52f2-53a8-a4fc-baa20186d88e', type: CampaignNodeType.SEGMENT_NODE, sendIndex: 0 },
            {
                id: '51f72c60-cf7d-5afe-b855-46b1a33d8ed0',
                type: CampaignNodeType.CONTENT_NODE,
                sendIndex: 0,
                isAddOnSendClicked: false,
            },
            {
                id: '30facd24-a154-52f6-8f1c-1a49e0e12ab2',
                type: CampaignNodeType.SCHEDULE_NODE,
                sendIndex: 0,
                dateTimeError: false,
                isRecommendedTimeEnabled: false,
            },
            {
                id: '37755e2f-03cd-55d8-9afa-49a41938afff',
                type: CampaignNodeType.PUBLISH_NODE,
                isPublishClicked: false,
            },
        ],
        edges: [
            {
                id: 'bb47ba59-0280-5f58-a97f-1e4b1a348875',
                source: '438cfb9c-11db-54cd-b924-259911b6813e',
                target: '267a4b2a-52f2-53a8-a4fc-baa20186d88e',
            },
            {
                id: '4c9d092f-29b9-5f24-ab6b-1c14531ae4bd',
                source: '267a4b2a-52f2-53a8-a4fc-baa20186d88e',
                target: '51f72c60-cf7d-5afe-b855-46b1a33d8ed0',
            },
            {
                id: 'd9c68dac-7763-5aa2-893a-8793ad6b7327',
                source: '51f72c60-cf7d-5afe-b855-46b1a33d8ed0',
                target: '30facd24-a154-52f6-8f1c-1a49e0e12ab2',
            },
            {
                id: '79e5530b-615a-543d-9a62-1eae16d3e937',
                source: '30facd24-a154-52f6-8f1c-1a49e0e12ab2',
                target: '37755e2f-03cd-55d8-9afa-49a41938afff',
            },
        ],
    },
    campaignTree: {
        sends: [
            {
                channel: CampaignChannels.EMAIL,
                name: '',
                preHeader: '',
                scheduledTimestamp: '',
                recurrenceFrequency: SendRecurrenceFrequency.DOES_NOT_REPEAT,
                senderProfileId: null,
                subjectLine: '',
                templateId: null,
                segmentId: null,
            },
        ],
    },
};

const singleSend = createCampaignSlice(temporaryInitialState, CampaignKey.SINGLE_SEND);

export const {
    editContentFieldsByNodeId,
    editName,
    setPublish,
    editScheduleFields,
    editSegmentId,
    resetState,
    setState,
    setContentPreviewNodeId,
    setScheduleDatetimeError,
} = singleSend.actions;

export default singleSend.reducer;
