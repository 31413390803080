import { Grid } from '@mui/material';
import { useEffect } from 'react';
import DateFilter from '../../filtering/DateFilter';
import { FilterValuesState } from '../../filtering/types';
import FiltersWrapper from '../../filtering/FiltersWrapper/FiltersWrapper';
import { getIsValidDateRange, getIsValidRelativeTimeframe, getTimeframeForApi } from '../../filtering/utils';
import SendsFilter from '../../filtering/SendsFilter';
import useGetFilterData from './useGetFilterData';

type Props = {
    filterValues: FilterValuesState;
    handleApplyFilters: () => void;
    isRelativeTimeframe?: boolean;
    setFilterValues: (filters: FilterValuesState) => void;
    setIsRelativeTimeframe?: (isRelativeTimeframe: boolean) => void;
};

const Filters = ({
    filterValues,
    handleApplyFilters,
    isRelativeTimeframe,
    setFilterValues,
    setIsRelativeTimeframe,
}: Props) => {
    const isValidDateRange = getIsValidDateRange(filterValues?.filterTimeframe);
    const isValidRelativeTimeframe = getIsValidRelativeTimeframe(filterValues?.filterRelativeTimeframe);
    const hasInvalidFilters = isRelativeTimeframe ? !isValidRelativeTimeframe : !isValidDateRange;

    const { getData: getFilterData, isError, isLoading, data } = useGetFilterData();

    useEffect(() => {
        if (!hasInvalidFilters) {
            const timeframeForApi = getTimeframeForApi(isRelativeTimeframe, filterValues);
            getFilterData({ timeframe: timeframeForApi });
        }
        // We only want this to run when the timeframes are updated
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValues.filterTimeframe, filterValues.filterRelativeTimeframe]);

    return (
        <FiltersWrapper disabled={hasInvalidFilters} handleApplyFilters={handleApplyFilters}>
            <DateFilter
                filterValues={filterValues}
                isRelativeTimeframe={isRelativeTimeframe}
                setFilterValues={setFilterValues}
                setIsRelativeTimeframe={setIsRelativeTimeframe}
            />
            <Grid item xs={12} md={6} lg={5} my={2}>
                <SendsFilter
                    data={data?.sends}
                    disabled={isError || isLoading}
                    filterValues={filterValues}
                    labelTooltip="Filter options for sends adjust based on the selected date filter."
                    setFilterValues={setFilterValues}
                />
            </Grid>
        </FiltersWrapper>
    );
};

export default Filters;
