import { Grid } from '@mui/material';
import { FilterValuesState } from '../../filtering/types';
import FiltersWrapper from '../../filtering/FiltersWrapper/FiltersWrapper';
import useGetFilterData from './useGetFilterData';
import SendsFilter from './SendsFilter';
import SpecialtiesFilter from './SpecialtiesFilter';

type Props = {
    filterValues: FilterValuesState;
    handleApplyFilters: () => void;
    setFilterValues: (filters: FilterValuesState) => void;
};

const Filters = ({ filterValues, handleApplyFilters, setFilterValues }: Props) => {
    const { data, isError, isLoading } = useGetFilterData();

    return (
        <FiltersWrapper disabled={isLoading || isError} handleApplyFilters={handleApplyFilters}>
            <Grid alignItems="center" container display="flex" mt={0} mb={2} spacing={2}>
                <Grid item xs={12} md={6} lg={5}>
                    <SendsFilter
                        data={data?.sends}
                        disabled={isLoading || isError}
                        filterValues={filterValues}
                        setFilterValues={setFilterValues}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={5}>
                    <SpecialtiesFilter
                        data={data?.specialties}
                        disabled={isLoading || isError}
                        filterValues={filterValues}
                        setFilterValues={setFilterValues}
                    />
                </Grid>
            </Grid>
        </FiltersWrapper>
    );
};

export default Filters;
