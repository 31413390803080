import { combineReducers, compose, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import curationReducer from 'domains/campaigns/state';
import value from 'domains/value/state/value';
import appVisualReducer from './appVisual/appVisualReducer';

const createRootReducer = (history: any) =>
    combineReducers({
        appVisual: appVisualReducer,
        curation: curationReducer,
        router: connectRouter(history),
        value,
    });

export const history = createBrowserHistory();

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(applyMiddleware(thunk));
const store = createStore(createRootReducer(history), enhancer);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
