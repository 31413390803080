import { SxProps, Theme } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { getTimezone } from 'utils';

type Props = {
    onChange: (dateTime: DateTime) => void;
    sx?: SxProps<Theme>;
    value: DateTime;
};

const DateTimePickerInput = ({ onChange, sx, value }: Props) => (
    <DateTimePicker
        label={`Date/Time (${getTimezone()})`}
        value={value}
        onChange={onChange}
        sx={{ width: '16rem', ...sx }}
    />
);

export default DateTimePickerInput;
