import { ArrowBack } from '@mui/icons-material';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import IosShareIcon from '@mui/icons-material/IosShare';
import Button from 'domains/core/components/Button';
import useQueryParams from 'hooks/useQueryParams';
import useCreateLandingPage from 'hooks/mutations/useCreateLandingPage';
import useUpdateLandingPage from 'hooks/mutations/useUpdateLandingPage';
import useBeeConfig from 'hooks/queries/useBeeConfig';
import useLandingPage from 'hooks/queries/useLandingPage';
import { URLPaths, BeeConfigType } from 'models/enums';
import ErrorSnackbar from 'domains/core/components/Snackbars/ErrorSnackbar';
import TextField from 'domains/core/components/TextField';
import { isLandingPage as isLandingPageResponse } from 'domains/content/types';
import BeeEditor from 'domains/content/components/Bee/BeeEditor';
import useBeeAutoSave from 'domains/content/components/Bee/useBeeAutoSave';
import useLandingPageHTML from 'hooks/queries/useLandingPageHTML';
import downloadFile from 'helpers/downloadFile/downloadFile';

const getNameHelperText = (isNameUndefinedOnTouch: boolean, isNameTooShort: boolean) => {
    if (isNameUndefinedOnTouch) {
        return 'please enter a landing page name';
    }

    if (isNameTooShort) {
        return 'landing page name must have at least 3 characters';
    }
};

const ContentPageBuilderPage = () => {
    const [name, setName] = useState('untitled');
    const [isNameTouched, setIsNameTouched] = useState(false);
    /**
     * Bee-specific states, hooks, and functions
     */
    const [beeJson, setBeeJson] = useState('');
    const [isNoRestart, setIsNoRestart] = useState(false);
    const [isSaveNameClicked, setIsSaveNameClicked] = useState(false);
    const [isSaveBeeJsonClicked, setIsSaveBeeJsonClicked] = useState(false);
    const [isHtmlButtonPressed, setIsHtmlButtonPressed] = useState(false);

    const history = useHistory();

    const queryClient = useQueryClient();
    const query = useQueryParams();
    const id = query.get('id');

    const onSuccess = (data: any) => {
        queryClient.invalidateQueries({
            // When we update a landing page, the cached network calls will be stale for the updated landing pages, so we need to invalidate the cached GET requests.
            predicate: (query: any) => {
                const firstQueryKey: string = query.queryKey[0];
                const invalidate = firstQueryKey === 'landing-page';
                return invalidate;
            },
        });

        // Reset to prevent useBeeAutoSave from running onUpdate() on every name change
        setIsSaveNameClicked(false);

        // If creating or copying a landing page, we need to change the route to transition to "edit" mode.
        if (isCreate || isCopy) {
            setIsNoRestart(true);
            history.push(`${URLPaths.CONTENT_CREATE_PAGE}?id=${data.id}`);
        }
        // If save was clicked by the user (not autosaved), enabled the nav, and navigate back to the content page with email type.
        if (isSaveBeeJsonClicked) {
            goToContentPage();
        }
    };

    const {
        error: saveError,
        isError: isSaveError,
        mutate: saveLandingPage,
        isLoading: isCreateLandingPageLoading,
    } = useCreateLandingPage({
        onSuccess,
    });

    const {
        error: updateError,
        isError: isUpdateError,
        mutate: updateLandingPage,
        isLoading: isUpdateLandingPageLoading,
    } = useUpdateLandingPage(id, {
        onSuccess,
    });

    const {
        data: landingPageQuery,
        error: landingPageError,
        isError: isLandingPageError,
        isSuccess: isLandingPageSuccess,
    } = useLandingPage({
        landingPageId: id,
    });

    const { isLoading: isHtmlLoading, isRefetching: isHTMLRefetching, refetch: refetchHTML } = useLandingPageHTML({
        landingPageId: Number(id),
        reactQueryOptions: {
            enabled: isHtmlButtonPressed && id !== 'default',
            onSuccess: (html) => {
                downloadFile({
                    file: html,
                    fileType: 'html',
                    fileName: name,
                });
            },
        },
    });

    // If the isCopy flag is set to true, the content create page saves a new landing page
    const isLandingPage = isLandingPageSuccess && isLandingPageResponse(landingPageQuery);
    const isCopy = query.get('isCopy') === 'true';
    const isCreate = !isCopy && !isLandingPage;
    const isUpdate = !isCopy && isLandingPage;
    const isError = isLandingPageError || isSaveError || isUpdateError;

    const landingPage = isLandingPage ? JSON.parse(landingPageQuery.beeJson) : landingPageQuery;

    const isSaveClicked = isSaveNameClicked || isSaveBeeJsonClicked;
    const isNameUndefinedOnSave = !name && isSaveClicked;
    const isNameUndefinedOnTouch = !name && isNameTouched;
    const isNameTooShort = isNameTouched && name.length < 3;

    const { isDirty, trackChange } = useBeeAutoSave({
        beeJson,
        id,
        isNameTooShort,
        isSaveClicked,
        isTemplate: false,
        isTemplateLayout: false,
        isUpdate,
        name,
        onSave: saveLandingPage,
        onUpdate: updateLandingPage,
    });
    const { isSuccess: isBeeConfigSuccess, data: beeConfig } = useBeeConfig(BeeConfigType.PAGE, { cacheTime: 0 });

    // Update the landing page's name local state when the landing page loads.
    useEffect(() => {
        if (isLandingPage && isCopy) {
            setName(`Copy of ${landingPageQuery.name}`);
        } else if (isLandingPage && !isCopy) {
            setName(landingPageQuery.name);
        } else if (!isNoRestart) {
            setName('Untitled');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLandingPageSuccess]);

    const goToContentPage = () => history.push(`${URLPaths.CONTENT}?type=page`);

    /**
     * Handles the autosave event. We are not using Bee's autosave callback because the only variable we have access to is the json
     * and we need to check to see if there are unsaved changes and make the post with the name, and those variables are stale when the callback is invoked.
     */
    const handleAutoSave = (beeJson: string) => {
        setBeeJson(beeJson);
    };

    const handleSaveBeeJson = (beeJson: string) => {
        setBeeJson(beeJson);
        setIsSaveBeeJsonClicked(true);
    };

    const handleChangeBeeEditor = (beeJson: string, response: object) => {
        setBeeJson(beeJson);
        trackChange(response);
    };

    const handleChangeName = (e: any) => {
        if (!isNameTouched) {
            setIsNameTouched(true);
        }
        setName(e.target.value);
    };

    const handleSaveName = () => {
        const getJson = () => {
            if (beeJson) {
                return beeJson;
            }
            if (isLandingPage) {
                return landingPageQuery.beeJson;
            }
            return JSON.stringify(landingPageQuery);
        };
        setBeeJson(getJson());
        setIsSaveNameClicked(true);
    };

    return (
        <Stack height="100%" mt={8} mb={4} rowGap={4}>
            {(isError || isNameUndefinedOnSave) && (
                <ErrorSnackbar
                    errorMessage={
                        landingPageError?.message ||
                        saveError?.message ||
                        updateError?.message ||
                        'Your landing page must include a name.'
                    }
                />
            )}
            <Stack direction="row" alignItems="center" columnGap={1}>
                <IconButton aria-label="back" size="large" onClick={goToContentPage}>
                    <ArrowBack />
                </IconButton>
                <Typography data-testid="page-header" data-test="page-header" variant="h6" sx={{ pb: 0.75 }}>
                    {isCreate && 'create a new landing page'}
                    {isCopy && 'copy an existing landing page'}
                    {isUpdate && 'update an existing landing page'}
                </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Box flex={1}>
                    <TextField
                        aria-label="name"
                        data-testid="name-input"
                        error={isNameUndefinedOnTouch || isNameTooShort}
                        helperText={getNameHelperText(isNameUndefinedOnTouch, isNameTooShort)}
                        label="Name"
                        required
                        value={name}
                        width="50%"
                        withClickAway
                        onChange={handleChangeName}
                        onClickAway={handleSaveName}
                    />
                </Box>
                <Tooltip
                    title={
                        <Typography variant="body2" color="white">
                            Export landing page HTML
                        </Typography>
                    }
                    placement="top"
                >
                    <span>
                        <Button
                            disabled={
                                id === 'default' ||
                                isHtmlLoading ||
                                isCreateLandingPageLoading ||
                                isUpdateLandingPageLoading ||
                                isHTMLRefetching ||
                                isDirty ||
                                isSaveNameClicked
                            }
                            onClick={() => {
                                if (isHtmlButtonPressed) {
                                    setIsHtmlButtonPressed(true);
                                } else {
                                    refetchHTML();
                                }
                            }}
                            variant="text"
                            startIcon={<IosShareIcon />}
                        >
                            Export
                        </Button>
                    </span>
                </Tooltip>
            </Stack>
            <Stack height="100%">
                {isBeeConfigSuccess && (
                    <BeeEditor
                        beeConfig={beeConfig}
                        beeJson={landingPage}
                        isNoRestart={isNoRestart}
                        isSuccess={isLandingPageSuccess}
                        showCustomToolbar={false}
                        onAutoSave={handleAutoSave}
                        onChange={handleChangeBeeEditor}
                        onSave={handleSaveBeeJson}
                    />
                )}
            </Stack>
        </Stack>
    );
};

export default ContentPageBuilderPage;
