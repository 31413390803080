export const fontSizeH6 = '1.25rem'; // 20px
export const fontSizeB1 = '1rem'; // 16px
export const fontSizeOverline = '.75rem'; // 12px

export const fontSansSerif = [
    '"acumin-pro"',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    '"Helvetica Neue"',
    'Roboto',
    'Arial',
    'sans-serif',
].join(',');
export const fontSerif = ['"minion-3-display"', 'Georgia', 'serif'].join(', ');
