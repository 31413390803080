import { useMemo, useState } from 'react';
import Autocomplete from 'domains/core/components/Autocomplete';
import { AutocompleteOption } from 'domains/core/components/Autocomplete/Autocomplete';
import { ClickAwayListener, Stack, Typography } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';

export type Props<T> = {
    header: string;
    description: string;
    options: T[];
    option: T;
    optionLabelKey: keyof T;
    optionValueKey: keyof T;
    onChange: (option: AutocompleteOption<T[keyof T]>) => void;
};

const AutocompleteWithClickAway = <T extends Record<string, any>>({
    header,
    description,
    options,
    option,
    optionLabelKey,
    optionValueKey,
    onChange,
}: Props<T>) => {
    const [autocompleteInputValue, setAutocompleteInputValue] = useState('');
    const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false);

    const autocompleteOptions: AutocompleteOption<T[keyof T]>[] = useMemo(
        () =>
            options?.map((option) => ({
                label: option[optionLabelKey],
                value: option[optionValueKey],
            })),
        [optionLabelKey, optionValueKey, options]
    );

    const autocompleteValue = useMemo(
        () => autocompleteOptions?.find((autocompleteOption) => autocompleteOption.value === option[optionValueKey]),
        [autocompleteOptions, option, optionValueKey]
    );

    const onChangeCallback = (_e: any, option: AutocompleteOption<T[keyof T]>) => {
        if (option) {
            onChange(option);

            setAutocompleteInputValue(option.label);
            setIsAutocompleteOpen(false);
        }
    };

    return (
        <ClickAwayListener mouseEvent="onMouseDown" onClickAway={() => setIsAutocompleteOpen(false)}>
            <Stack rowGap={1}>
                {isAutocompleteOpen ? (
                    <Autocomplete
                        open={isAutocompleteOpen}
                        getOptionLabel={(option) => option.label}
                        inputValue={autocompleteInputValue}
                        value={autocompleteValue}
                        options={autocompleteOptions}
                        label="Select send"
                        type="number"
                        onChange={onChangeCallback}
                        onInputChange={(_e: any, inputValue) => setAutocompleteInputValue(inputValue)}
                    />
                ) : (
                    <>
                        <Typography variant="caption" color="action.active">
                            {header}
                        </Typography>
                        {description && (
                            <Stack
                                direction="row"
                                alignItems="center"
                                columnGap={1}
                                onClick={() => setIsAutocompleteOpen(true)}
                            >
                                <Typography data-testid={header} variant="body1" color="primary.main" noWrap>
                                    {description}
                                </Typography>
                                <KeyboardArrowDown />
                            </Stack>
                        )}
                    </>
                )}
            </Stack>
        </ClickAwayListener>
    );
};
export default AutocompleteWithClickAway;
