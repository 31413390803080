import { themeColors } from 'colors';

export const sharedButtonStyles = {
    border: `2px solid ${themeColors.primary.main}`,
    borderRadius: 1,
    margin: '0 0 16px',
    padding: '16px 12px',
    '&:hover': {
        background: themeColors.primary.outlinedHoverBackground,
    },
};

export const disabledButtonStyles = {
    borderColor: themeColors.action.disabled,
    span: {
        color: themeColors.text.disabled,
    },
    '&:hover': {
        background: 'transparent',
        cursor: 'default',
    },
};

export const labelStyles = {
    display: 'flex',
    alignItems: 'center',
    marginTop: '-2px',
};

export const iconStyles = {
    marginRight: 1,
    marginTop: '2px',
};
