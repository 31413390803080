import { Dialog, DialogTitle, DialogContent, DialogActions, Stack } from '@mui/material';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import theme from 'theme';
import mime from 'mime';
import useSegmentFilters from 'hooks/queries/useSegmentFilters';
import Button from 'domains/core/components/Button';
import Select, { Value } from 'domains/core/components/Select';
import { RecordTypeFilter } from 'domains/segments/types';
import { AllowedMimeTypes } from 'models/enums';

const otherValue = {
    label: 'other',
    value: 'other',
};

const getDataCategoryValues = (recordTypeFilters: RecordTypeFilter[], dataCategoryText: string): Value[] =>
    recordTypeFilters?.map(({ recordType }) => ({
        label: `${dataCategoryText}: ${recordType}`,
        value: `${dataCategoryText}/${recordType}`,
    }));

const DEFAULT_MIME_TYPES = `${AllowedMimeTypes.TEXT_CSV}, .pgp`;

const getAcceptedFileFormat = (dataCategory: string): string =>
    dataCategory === 'other'
        ? Object.values(AllowedMimeTypes).join(', ') + ', .pgp' // Explicitly adding .pgp support for input attribute
        : DEFAULT_MIME_TYPES;

export type Props = {
    dataCategory: string;
    handleClose: () => void;
    handleUpload: (uploader: any, s3Path: string) => void;
    handleUploadError: (error: string) => void;
    open: boolean;
    setDataCategory: Dispatch<SetStateAction<string>>;
};

const FileUploadDialog = ({
    dataCategory,
    handleClose,
    handleUpload,
    handleUploadError,
    open,
    setDataCategory,
}: Props) => {
    const uploader = useRef();
    const { data: segmentFilters, isSuccess: isSegmentFiltersSuccess } = useSegmentFilters({
        version: 2,
    });

    const [isFileChosen, setIsFileChosen] = useState(false);
    const [s3path, setS3Path] = useState('');

    const dataCategoryValues: Value[] = isSegmentFiltersSuccess && [
        ...getDataCategoryValues(segmentFilters?.demographic.recordTypes, 'contact'),
        ...getDataCategoryValues(segmentFilters?.contactEvent.recordTypes, 'event'),
        otherValue,
    ];

    const acceptedFileFormat = getAcceptedFileFormat(dataCategory);
    const isUploadDisabled = !isFileChosen || s3path === '';

    const handleChange = (e: any) => {
        setDataCategory(e.target.value);
        setS3Path(e.target.value);
    };

    const clearSettingsAndLeave = () => {
        setDataCategory('');
        setS3Path('');
        setIsFileChosen(false);
        handleClose();
    };

    const handleFileChange = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            const mimeType = mime.getType(file.name);
            const allowedMimeTypes = Object.values(AllowedMimeTypes).includes(mimeType as AllowedMimeTypes);
            if (allowedMimeTypes) {
                setIsFileChosen(true);
            } else {
                setIsFileChosen(false);
                handleUploadError('Invalid file format. Please choose a valid file format.');
                handleClose();
                e.target.value = '';
            }
        }
    };

    return (
        <Dialog
            aria-labelledby="upload-contacts-dialog"
            data-testid="file-upload-dialog"
            fullWidth
            maxWidth="sm"
            open={open}
            onClose={clearSettingsAndLeave}
        >
            <DialogTitle id="upload-contacts-dialog">upload data</DialogTitle>
            <DialogContent>
                {/* @ts-ignore */}
                <Stack rowGap={4} paddingTop={2}>
                    {!!dataCategoryValues?.length && (
                        <Select
                            data-test="content-type-upload-dropdown"
                            error={false}
                            fullWidth={false}
                            label="Select data category"
                            value={dataCategory}
                            values={dataCategoryValues}
                            style={{
                                textTransform: 'capitalize',
                                width: theme.spacing(32),
                            }}
                            onChange={handleChange}
                        />
                    )}
                    <input type="file" accept={acceptedFileFormat} onChange={handleFileChange} ref={uploader} />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="text" onClick={clearSettingsAndLeave}>
                    Cancel
                </Button>
                <Button
                    data-testid="file-upload-button"
                    disabled={isUploadDisabled}
                    variant="contained"
                    onClick={() => handleUpload(uploader, s3path)}
                >
                    Upload
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FileUploadDialog;
