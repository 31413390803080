import { Box } from '@mui/material';
import TextField from 'domains/core/components/TextField';

type Props = {
    curationName: string;
    editName: (name: string) => void;
};

const CurationNameForm = ({ curationName, editName }: Props) => (
    <Box display="flex" flexDirection="row">
        <TextField
            data-testid="curation-name-page-name-field"
            label="Name"
            value={curationName}
            onChange={(e: any) => {
                editName(e.target.value);
            }}
            inputProps={{ maxLength: 255 }}
            fullWidth
            withCounter={true}
            counterCurrent={curationName.length ?? 0}
            counterMax={255}
        />
    </Box>
);

export default CurationNameForm;
