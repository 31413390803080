import { SwapCalls } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import FormLayout from 'domains/core/components/FormLayout';
import { editMetadata as editMetadataActionCreator } from 'domains/campaigns/state/actionCreators';
import { MetadataState } from 'domains/campaigns/types';
import { CurationStep } from 'models/enums';
import CurationNameForm from './CurationNameForm';

type Props = {
    metadataSelector: (state: any) => MetadataState;
    editMetadataActionType: string;
};

const CurationNamePage = ({ editMetadataActionType, metadataSelector }: Props) => {
    const dispatch = useDispatch();
    const metadataState = useSelector(metadataSelector);
    const editMetadata = (curationName: string) =>
        dispatch(editMetadataActionCreator(editMetadataActionType, { curationName }));
    const { curationName } = metadataState;
    return (
        <FormLayout
            header="let's get going"
            stepName={CurationStep.CURATION}
            icon={<SwapCalls />}
            guidingText="Name your Curation. Then continue in the flow to set up your send. All it takes is a few steps. You've got this!"
        >
            <CurationNameForm curationName={curationName} editMetadata={editMetadata} />
        </FormLayout>
    );
};

export default CurationNamePage;
