import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import tabArrowHead from 'assets/images/svg/tab-arrow-head.svg';
import { useDispatch } from 'react-redux';
import { minimizeLeftNavPanel } from 'state/appVisual/appVisualActions';

export enum NavigationButtonVariant {
    WIDE,
    NARROW,
}

export type Props = {
    path: string;
    helperText: string;
    icon: React.ReactNode;
    variant?: NavigationButtonVariant;
};

export type NarrowProps = Props & {
    variant: NavigationButtonVariant.NARROW;
    title?: never;
};

export type WideProps = Props & {
    variant: NavigationButtonVariant.WIDE;
    title: string;
};

/**
 * One list item in the nav panel on the left side of the screen.
 * Shows "selected" style if the current page matches the path.
 *
 * @param title The title of the button (only shown on the "wide" variant)
 * @param path The path the button links to, including the leading '/'.
 * @param helperText The text displayed when hovering over the button.
 * @param icon The icon to be displayed.
 * @param variant The type of button to display.
 *
 * @returns The React node created by this component.
 */
export const NavigationButton = ({
    path,
    helperText,
    icon,
    variant = NavigationButtonVariant.NARROW,
    title,
}: NarrowProps | WideProps) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const basePath = location.pathname.split('/')[1];
    const [isHover, setIsHover] = useState(false);

    const textColor = basePath === path.slice(1) ? 'secondary.main' : 'primary.main';
    const isPanelCollapsed = variant === NavigationButtonVariant.NARROW;
    const isPanelOpen = variant === NavigationButtonVariant.WIDE;

    const routeDescriptionPopout = (
        <Box onMouseOver={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
            <Box
                display="flex"
                zIndex={6}
                position="fixed"
                left={isPanelCollapsed ? '2.875rem' : '15.375rem'}
                paddingTop="0.1875rem"
                height="2.875rem"
            >
                <img height="100%" src={tabArrowHead} alt={helperText} />
            </Box>
            <Box
                zIndex={6}
                position="fixed"
                paddingRight="1rem"
                left={isPanelCollapsed ? '5rem' : '17.5rem'}
                height="2.875rem"
                borderRadius="0.5rem"
                width="fit-content"
                sx={{ bgcolor: 'secondary.outlinedRestingBackground' }}
            >
                <Box
                    display="flex"
                    position="fixed"
                    left={isPanelCollapsed ? '2.6875rem' : '14.25rem'}
                    height="3.25rem"
                    zIndex={6}
                >
                    <img src={tabArrowHead} alt={helperText} />
                </Box>
                <Stack
                    position="fixed"
                    left={isPanelCollapsed ? '4.8125rem' : '16.375rem'}
                    height="3.25rem"
                    width="fit-content"
                    justifyContent="center"
                    paddingRight="1rem"
                    borderRadius="0.5rem"
                    zIndex={6}
                    sx={{ bgcolor: 'action.active' }}
                >
                    <Typography variant="tooltip" color="white">
                        {helperText}
                    </Typography>
                </Stack>
            </Box>
        </Box>
    );

    return (
        <>
            {isHover && routeDescriptionPopout}
            <ListItem
                data-test="list-item"
                data-testid="list-item"
                sx={{ color: textColor, height: '3.25rem', '&:hover': { color: 'action.active' } }}
                button
                onClick={() => {
                    dispatch(minimizeLeftNavPanel(false));
                    history.push(path);
                }}
                selected={basePath === path.slice(1)}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
            >
                <ListItemIcon sx={{ color: 'inherit' }}>{icon}</ListItemIcon>
                {isPanelOpen && (
                    <ListItemText
                        sx={{ whiteSpace: 'nowrap' }}
                        disableTypography
                        primary={
                            <Typography color="inherit" variant="body1" mb={0.75}>
                                {title}
                            </Typography>
                        }
                    />
                )}
            </ListItem>
        </>
    );
};

export default NavigationButton;
