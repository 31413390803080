import { ValueState } from 'domains/value/state/value';
import { URLPaths } from 'models/enums';
import { RootState } from 'state';

export const isAppointmentValueInRange = (appointmentValue: any): boolean =>
    appointmentValue >= 0 && appointmentValue <= 1000000;

export const selectIsSaveDisabled = (state: any): boolean =>
    !Boolean(state.value.appointmentValue) ||
    !Boolean(state.value.attendRate) ||
    !Boolean(state.value.clickUrl) ||
    !Boolean(state.value.scheduleRate) ||
    !Boolean(state.value.sendId) ||
    !Boolean(state.value.specialty) ||
    !Boolean(state.value.uniqueClickRate);

export const selectIsValueFlowComplete = (state: any): boolean =>
    !selectIsSaveDisabled(state) && Boolean(state.value.isSaved);

export const selectIsValueRouteDisabled = (state: RootState) => (route: string): boolean => {
    const { attendRate, clickUrl, scheduleRate, sendId, specialty, uniqueClickRate } = state.value as ValueState;
    const isSendSpecialtyComplete = Boolean(sendId) && Boolean(specialty);
    const isConversionEventComplete = Boolean(clickUrl) && Boolean(uniqueClickRate);
    const isConversionMetricComplete = Boolean(scheduleRate) && Boolean(attendRate);

    if (route === URLPaths.VALUE_CONVERSION_EVENT) {
        return !isSendSpecialtyComplete;
    }
    if (route === URLPaths.VALUE_CONVERSION_METRIC) {
        return !isSendSpecialtyComplete || !isConversionEventComplete;
    }
    if (route === URLPaths.VALUE_CONVERSION_VALUE) {
        return !isSendSpecialtyComplete || !isConversionEventComplete || !isConversionMetricComplete;
    }

    return false;
};
