import { CurationNavState, URLPaths } from 'models/enums';
import { Action } from 'models/types';
import { ROUTE_CHANGE } from 'state/constants';
import * as actionTypes from 'domains/campaigns/state/actionTypes';
import { getCurationNavState } from 'domains/campaigns/state/utils';
import { ContentState } from 'domains/campaigns/types';

export const initialState: ContentState = {
    navState: CurationNavState.PREVIEW,
    isComplete: false,
    preheader: '',
    senderProfileId: null,
    subjectLine: '',
    templateId: null,
};

export const isContentState = (content: ContentState): content is ContentState =>
    Boolean((content as ContentState)?.templateId);

export const isFormComplete = (state: ContentState) => {
    // If the user enters a preheader, we want to validate the length
    if (state.preheader.length > 0) {
        return Boolean(
            state.senderProfileId && state.preheader.length >= 3 && state.subjectLine.length >= 3 && state.templateId
        );
    } else {
        return Boolean(state.senderProfileId && state.subjectLine.length >= 3 && state.templateId);
    }
};

const isFormInProgress = (state: ContentState) =>
    state.senderProfileId !== null || state.subjectLine.length >= 3 || state.templateId !== null;

export const contentReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case actionTypes.CONDITIONAL_SPLIT_EDIT_CONTENT1:
            const stateAfterEdit = {
                ...state,
                preheader: action.payload.preheader,
                senderProfileId: action.payload.senderProfileId,
                subjectLine: action.payload.subjectLine,
                templateId: action.payload.templateId,
            };
            const isComplete = isFormComplete(stateAfterEdit);
            return {
                ...stateAfterEdit,
                navState: getCurationNavState(
                    isComplete,
                    isFormInProgress(stateAfterEdit),
                    URLPaths.CURATIONS_CONDITIONAL_SPLIT_1_CONTENT,
                    undefined,
                    URLPaths.CURATIONS_CONDITIONAL_SPLIT
                ),
                isComplete: isComplete,
            };
        case ROUTE_CHANGE:
            return {
                ...state,
                navState: getCurationNavState(
                    isFormComplete(state),
                    isFormInProgress(state),
                    URLPaths.CURATIONS_CONDITIONAL_SPLIT_1_CONTENT,
                    action,
                    URLPaths.CURATIONS_CONDITIONAL_SPLIT
                ),
            };
        case actionTypes.CONDITIONAL_SPLIT_RESET_CURATION:
            return initialState;
        default:
            return state;
    }
};

export default contentReducer;
