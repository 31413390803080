import { useState, useEffect } from 'react';
import { useQueryClient, MutationKey } from 'react-query';
import { MutationStatus } from 'models/enums';

const useMutationStatus = (mutationKey: MutationKey) => {
    const [mutationStatus, setMutationStatus] = useState(MutationStatus.IDLE);
    const queryClient = useQueryClient();
    const mutationCache = queryClient.getMutationCache();

    const unsubscribe = mutationCache.subscribe((mutation) => {
        if (mutation.options.mutationKey === mutationKey) {
            setMutationStatus(mutation.state.status as MutationStatus);
        }
    });

    useEffect(
        () => () => {
            unsubscribe();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return mutationStatus;
};

export default useMutationStatus;
