import { Grid, TableCell, TableRow, Typography, styled } from '@mui/material';
import { Segment } from 'domains/segments/types';
import { hasInvalidRelativeDates } from 'domains/segments/utils';
import EventDetails from './EventDetails';
import AudienceDetails from './AudienceDetails';
import DemographicDetails from './DemographicDetails';
import EngagementDetails from './EngagementDetails';

// Using styled here to increase the specificity of borderLeftColor without using !important, which is needed if applying the styles using the sx prop.
const StyledTableCell = styled(TableCell)(() => ({
    '&.MuiTableCell-root': {
        paddingBottom: 0,
        paddingTop: 0,
        borderLeftColor: 'transparent',
    },
}));

export type SegmentProps = {
    segment: Segment;
};

/**
 * Displays the count and filters for a segment.
 */
const SegmentDetails = ({ segment }: SegmentProps) => {
    const { demographic, engagement, contactEvent, matchType } = segment?.segmentDefinition;

    const hasDemographicFilter = demographic?.length > 0;
    const hasEngagementFilter = engagement?.length > 0;
    const hasContactEventFilter = contactEvent?.length > 0;

    return (
        <TableRow
            sx={{
                '&:hover': {
                    backgroundColor: 'other.tableHover',
                },
            }}
        >
            <StyledTableCell colSpan={4}>
                <Grid container p={2}>
                    <Grid item xs={3}>
                        <AudienceDetails segment={segment} />
                    </Grid>
                    <Grid item xs={9} textAlign="right">
                        {(hasContactEventFilter || hasDemographicFilter || hasEngagementFilter) && (
                            <Typography mb={2}>Filters</Typography>
                        )}
                        {hasInvalidRelativeDates(segment) ? (
                            <Typography component="span" color="error.dark">
                                Unsupported offset & duration for relative date filters
                            </Typography>
                        ) : (
                            <>
                                {hasDemographicFilter && (
                                    <DemographicDetails details={demographic} matchType={matchType} />
                                )}
                                {hasEngagementFilter && (
                                    <EngagementDetails
                                        details={engagement}
                                        matchType={matchType}
                                        shouldShowMatchType={hasDemographicFilter}
                                    />
                                )}
                                {hasContactEventFilter && (
                                    <EventDetails
                                        details={contactEvent}
                                        matchType={matchType}
                                        shouldShowMatchType={hasDemographicFilter || hasEngagementFilter}
                                    />
                                )}
                            </>
                        )}
                    </Grid>
                </Grid>
            </StyledTableCell>
        </TableRow>
    );
};

export default SegmentDetails;
