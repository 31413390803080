import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { Dialog, DialogTitle, DialogContent, DialogActions, Stack, Typography } from '@mui/material';
import { SenderProfile, isTemplate } from 'domains/content/types';
import Button from 'domains/core/components/Button';
import Select from 'domains/core/components/Select';
import ErrorSnackbar from 'domains/core/components/Snackbars/ErrorSnackbar';
import SuccessSnackbar from 'domains/core/components/Snackbars/SuccessSnackbar';
import WarningSnackbar from 'domains/core/components/Snackbars/WarningSnackbar';
import ContentIcon from 'domains/core/components/SvgIcons/ContentIcon';
import TextField from 'domains/core/components/TextField';
import useSenderProfiles from 'hooks/queries/useSenderProfiles';
import useSendTestEmail from 'hooks/mutations/useSendTestEmail';
import useTemplate from 'hooks/queries/useTemplate';
import theme from 'theme';

type BeeEditorTestSendDialogProps = {
    handleClose: () => void;
    isOpen: boolean;
    templateId: number;
};

type FormData = {
    templateId: number;
    subjectLine: string;
    preHeader: string;
    senderProfileId: number;
    toAddresses: string;
};

const BeeEditorTestSendDialog = ({ handleClose, isOpen, templateId }: BeeEditorTestSendDialogProps) => {
    const location = useLocation();
    const isDefaultTemplate = location.search === '?id=default';

    const { data: senderProfiles, isLoading: isSenderProfilesLoading } = useSenderProfiles();

    const {
        error: sendTestEmailError,
        isError: isSendTestEmailError,
        isLoading: isSendTestEmailLoading,
        isSuccess: isSendTestEmailSuccess,
        mutate: sendTestEmail,
        reset: resetSendTestEmail,
    } = useSendTestEmail();

    const { refetch: refetchTemplate } = useTemplate({
        templateId: `${templateId}`,
        reactQueryOptions: {
            enabled: isOpen,
            onSuccess: (template) => {
                if (isTemplate(template) && template) {
                    const { senderProfileId, subjectLine, preHeader } = template;
                    reset({
                        senderProfileId,
                        subjectLine,
                        preHeader,
                    });
                }
            },
        },
    });

    const {
        control,
        formState: { isValid },
        handleSubmit,
        reset,
    } = useForm<FormData>({
        defaultValues: {
            senderProfileId: null,
            toAddresses: '',
            subjectLine: '',
            preHeader: '',
        },
        mode: 'onBlur',
        reValidateMode: 'onChange',
    });

    useEffect(() => {
        if (isOpen) {
            resetSendTestEmail();
            refetchTemplate();
        }
    }, [isOpen, refetchTemplate, resetSendTestEmail]);

    const onSubmit = (data: FormData) => {
        const delimiters = /[,]+/; // comma
        const splitAddresses = data.toAddresses.split(delimiters);
        const trimSplitAddresses = splitAddresses.map((address) => address.trim());

        sendTestEmail({
            preheader: data.preHeader,
            subjectLine: data.subjectLine,
            templateId,
            senderProfileId: data.senderProfileId,
            toAddresses: trimSplitAddresses,
        });
        handleClose();
    };

    const mapSenderProfilesToSelectValues = (profiles: SenderProfile[]) =>
        profiles.map((profile) => ({
            value: profile.id,
            label: profile.from_address,
        }));

    return (
        <>
            {isDefaultTemplate && isOpen && (
                <WarningSnackbar warningMessage="Please make changes to the template before sending a test email." />
            )}
            {isSendTestEmailError && <ErrorSnackbar errorMessage={sendTestEmailError.message} />}
            {isSendTestEmailSuccess && !isOpen && (
                <SuccessSnackbar successMessage="You've successfully sent a test email." />
            )}
            <Dialog maxWidth="sm" fullWidth open={isOpen} onClose={handleClose}>
                <DialogTitle id="upload-contacts-dialog">
                    <Stack alignItems="center" direction="row" spacing={2}>
                        <ContentIcon sx={{ fontSize: theme.spacing(5) }} />
                        <Typography variant="h6">send test email</Typography>
                    </Stack>
                </DialogTitle>
                <DialogContent sx={{ px: 0 }}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Stack sx={{ px: 4, py: 2 }} rowGap={4}>
                            <Controller
                                control={control}
                                name="senderProfileId"
                                render={({ field: { onBlur, onChange, value }, fieldState: { error, invalid } }) => (
                                    <Select
                                        disabled={isDefaultTemplate}
                                        error={invalid}
                                        fullWidth
                                        helperText={invalid ? error.message : null}
                                        label="Send from"
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        value={value ?? ''}
                                        values={
                                            !isSenderProfilesLoading
                                                ? mapSenderProfilesToSelectValues(senderProfiles)
                                                : []
                                        }
                                    />
                                )}
                                rules={{
                                    required: 'please select a sender profile',
                                }}
                            />
                            <Controller
                                control={control}
                                name="toAddresses"
                                render={({ field: { onBlur, onChange, value }, fieldState: { error, invalid } }) => {
                                    const multipleAddressesHelper = 'use commas to separate multiple emails';

                                    return (
                                        <TextField
                                            disabled={isDefaultTemplate}
                                            error={invalid}
                                            fullWidth
                                            helperText={invalid ? error.message : multipleAddressesHelper}
                                            label="Send to"
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            value={value}
                                        />
                                    );
                                }}
                                rules={{
                                    required: 'please enter recipient email(s)',
                                    pattern: {
                                        value: /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/,
                                        message: 'please enter recipient email(s) in valid format',
                                    },
                                }}
                            />
                            <Controller
                                control={control}
                                name="subjectLine"
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        disabled={isDefaultTemplate}
                                        fullWidth
                                        label="Subject line (optional)"
                                        onChange={onChange}
                                        value={value}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="preHeader"
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        disabled={isDefaultTemplate}
                                        fullWidth
                                        label="Preview text (optional)"
                                        onChange={onChange}
                                        value={value}
                                    />
                                )}
                            />
                        </Stack>
                    </form>
                    <DialogActions>
                        <Button variant="text" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button
                            disabled={isDefaultTemplate || isSendTestEmailLoading || !isValid}
                            variant="contained"
                            onClick={handleSubmit(onSubmit)}
                        >
                            Send
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default BeeEditorTestSendDialog;
