import { Typography, Tooltip, CSSObject } from '@mui/material';
import { EmailOutlined } from '@mui/icons-material';

export type Props = {
    isActive: boolean;
    onClick: () => void;
};

const labelStyles: CSSObject = {
    color: '#ffffff',
    fontSize: '1rem',
    position: 'relative',
    top: '-0.094rem',
};

const ButtonEmailPreview = ({ onClick, isActive }: Props) => {
    const iconStyles = {
        color: isActive ? 'secondary.main' : 'primary.main',
        '&:hover': {
            color: 'secondary.main',
            cursor: 'pointer',
        },
    };

    return (
        <Tooltip
            title={
                <Typography variant="body1" sx={labelStyles}>
                    Click to preview email.
                </Typography>
            }
            placement="top"
        >
            <EmailOutlined aria-label="preview content" sx={iconStyles} onClick={onClick} />
        </Tooltip>
    );
};

export default ButtonEmailPreview;
