import { useDispatch, useSelector } from 'react-redux';
import { CallSplit } from '@mui/icons-material';
import { CurationStep } from 'models/enums';
import FormLayout from 'domains/core/components/FormLayout';
import { EditSegmentsPayload, SegmentsState } from 'domains/campaigns/types';
import { editSegments as editSegmentsActionCreator } from 'domains/campaigns/state/actionCreators';
import CurationSegmentsForm from './CurationSegmentsForm';

type Props = {
    segmentsSelector: (state: any) => SegmentsState;
    editSegmentsActionType: string;
};

const CurationSegmentsPage = ({ segmentsSelector, editSegmentsActionType }: Props) => {
    const dispatch = useDispatch();
    const segmentsState = useSelector(segmentsSelector);

    const editSegments = (segments: EditSegmentsPayload) =>
        dispatch(editSegmentsActionCreator(editSegmentsActionType, segments));

    return (
        <FormLayout
            header="select split criteria"
            icon={<CallSplit />}
            stepName={CurationStep.SEGMENTS}
            guidingText="Create a split in your Curation based on audience behavior."
        >
            <CurationSegmentsForm segmentsState={segmentsState} editSegments={editSegments} />
        </FormLayout>
    );
};

export default CurationSegmentsPage;
