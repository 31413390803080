import { Box } from '@mui/material';
import TextField from 'domains/core/components/TextField';

export type Props = {
    isHidden: boolean;
};

export type ReduxProps = {
    curationName: string;
    editName: (name: string) => void;
};

// todo: consolidate with CampaignNameInput
const CurationNameInput = ({ curationName, editName, isHidden }: ReduxProps & Props) => {
    if (isHidden) {
        return <Box />;
    }
    return (
        <TextField
            counterCurrent={curationName.length ?? 0}
            counterMax={255}
            data-testid="update-name-input"
            focused
            label="name"
            value={curationName}
            width="50%"
            withClickAway
            withCounter
            onChange={(e: any) => editName(e.target.value)}
        />
    );
};

export default CurationNameInput;
