import { Stack } from '@mui/material';
import { EditSegmentsPayload, SegmentsState } from 'domains/campaigns/types';
import Select from 'domains/core/components/Select';
import { EngagementAPIOperator } from 'domains/segments/types';
import { CurationSegments } from 'models/enums';

const values = [
    {
        label: CurationSegments.DID_OPEN,
        value: 'opened_sends',
    },
    {
        label: CurationSegments.DID_CLICK,
        value: 'clicked_sends',
    },
];

type Props = {
    segmentsState: SegmentsState;
    editSegments: (payload: EditSegmentsPayload) => void;
};

const CurationSegmentsForm = ({ segmentsState, editSegments }: Props) => {
    const { segments } = segmentsState;
    const initialSegment = segments[0].name;

    const onChange = (e: any) => {
        const value = e.target.value;

        editSegments({
            segments: [
                {
                    name: value,
                    operator: EngagementAPIOperator.CONTAINS,
                    value: null,
                },
                {
                    name: value,
                    operator: EngagementAPIOperator.DOES_NOT_CONTAIN,
                    value: null,
                },
            ],
        });
    };

    return (
        <Stack alignItems="center">
            <Select
                label={initialSegment ? 'criteria' : 'select criteria'}
                sx={{ textTransform: 'capitalize' }}
                value={initialSegment ?? ''}
                values={values}
                onChange={onChange}
            />
        </Stack>
    );
};
export default CurationSegmentsForm;
