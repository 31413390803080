import { SxProps, Theme } from '@mui/material';
import { DateTime } from 'luxon';
import DatePicker from 'domains/core/components/DatePicker';

type Props = {
    onChangeDate: (date: DateTime) => void;
    sx?: SxProps<Theme>;
    value: DateTime;
};

const DatePickerInput = ({ onChangeDate, sx, value }: Props) => (
    <DatePicker
        data-testid="date-picker"
        label="Date"
        value={value}
        onChange={onChangeDate}
        sx={{ width: '12rem', ...sx }}
    />
);

export default DatePickerInput;
