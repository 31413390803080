import { Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { SQLType } from 'models/enums';
import {
    DateRange,
    isDateRangeOperator as isDateRangeOperatorType,
    isDateRangeType,
    isRelativeDateRangeType,
    SegmentDemographic,
} from 'domains/segments/types';
import {
    getInclusionOperatorFromAPIToUI,
    isRelativeDateRangeLast,
    isRelativeDateRangeNext,
    isRelativeDateRangeToday,
} from 'domains/segments/utils';

export const secondarySpanProps = {
    color: 'secondary',
    component: 'span',
};

const renderArrayValues = (values: string[] | number[]) => {
    if (values.length === 2) {
        // If there are exactly two items, render them with an 'or' conjunction.
        return (
            <>
                <Typography {...secondarySpanProps}>{values[0]}</Typography>
                <Typography component="span"> or </Typography>
                <Typography {...secondarySpanProps}>{values[1]}</Typography>
            </>
        );
    } else {
        // Render the list with commas, and 'or' before the last item.
        const allValuesButLastValue = values.slice(0, -1).join(', ');
        const lastValue = values[values.length - 1];

        return (
            <>
                {allValuesButLastValue && (
                    <>
                        {allValuesButLastValue}
                        {', '}
                        <Typography component="span"> or </Typography>
                    </>
                )}
                {lastValue}
            </>
        );
    }
};

export const renderValue = (value: SegmentDemographic['value']) => {
    // If 'value' is an array, delegate to a specialized Array rendering function.
    if (Array.isArray(value)) {
        return <Typography {...secondarySpanProps}> {renderArrayValues(value)}</Typography>;
    }
    // If 'value' is not an array, render it directly.
    return (
        <Typography {...secondarySpanProps}>
            <> {value}</>
        </Typography>
    );
};

const renderDateRange = (startDate: string, endDate: string) => (
    <>
        {startDate}
        <Typography component="span" color="primary">
            {' - '}
        </Typography>
        {endDate}
    </>
);

// TODO: revisit this and refactor for readability
const FilterDetails = ({ includeNullValues, name, operator, sqlType, value }: SegmentDemographic) => {
    const isDateRangeOperator = isDateRangeOperatorType(operator);

    const isDateRangeValue = isDateRangeType(value);
    const isRelativeDateRangeValue = isRelativeDateRangeType(value);
    const isToday = isRelativeDateRangeToday(value);
    const isNext = isRelativeDateRangeNext(value);
    const isLast = isRelativeDateRangeLast(value);
    const isMultipleValue = Array.isArray(value);

    const dateStart = isDateRangeValue && !isMultipleValue && DateTime.fromISO((value as DateRange).start);
    const dateEnd = isDateRangeValue && !isMultipleValue && DateTime.fromISO((value as DateRange).end);
    const datetime =
        !isDateRangeValue && !isMultipleValue && DateTime.fromISO(value as string).toFormat('LL/dd/yyyy h:mm a ZZZZ');

    const dateRange =
        isDateRangeValue && renderDateRange(dateStart.toFormat('LL/dd/yyyy'), dateEnd.toFormat('LL/dd/yyyy'));
    const datetimeRange =
        isDateRangeValue &&
        renderDateRange(dateStart.toFormat('LL/dd/yyyy h:mm a ZZZZ'), dateEnd.toFormat('LL/dd/yyyy h:mm a ZZZZ'));

    const renderedValue = renderValue(value);

    const dateRangeText = isToday
        ? 'today'
        : isRelativeDateRangeValue &&
          `${value.duration.value} ${value.duration.unit}${value.duration.value > 1 ? 's' : ''}`;

    return (
        <>
            <Typography component="span" color="secondary">
                {name}{' '}
            </Typography>
            <Typography component="span">
                {isToday ? 'is ' : `${getInclusionOperatorFromAPIToUI(operator)} `}
                {isNext && `the next `}
                {isLast && `the last `}
            </Typography>
            <Typography component="span" color="secondary">
                {isDateRangeOperator ? (
                    <>
                        {sqlType === SQLType.TIMESTAMP_WITH_TIME_ZONE ? datetimeRange : dateRange}
                        {dateRangeText}
                    </>
                ) : (
                    <>{sqlType === SQLType.TIMESTAMP_WITH_TIME_ZONE ? datetime : renderedValue}</>
                )}
            </Typography>
            {includeNullValues && <Typography component="span"> including nulls</Typography>}
        </>
    );
};

export default FilterDetails;
