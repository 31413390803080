import * as actionTypes from 'domains/campaigns/state/actionTypes';
import { Action } from 'models/types';

export type State = {
    audienceId: number | null;
    /**
     * Used to determine if a user can leave the curation flow.
     * The form must be complete, and they have to have clicked the button to submit vs using the tab navigation.
     */
    buttonClicked: boolean;
    content: string;
    isComplete: boolean;
    name: string;
    scheduledTimestamp: string | null;
    isDatetimeError: boolean;
};

export const initialState: State = {
    audienceId: null,
    buttonClicked: false,
    content: '',
    isComplete: false,
    name: '',
    scheduledTimestamp: null,
    isDatetimeError: false,
};

export const isFormComplete = ({ audienceId, content, isDatetimeError, name, scheduledTimestamp }: State) =>
    Boolean(audienceId) &&
    Boolean(content) &&
    content.length >= 3 &&
    Boolean(name) &&
    name.length >= 3 &&
    Boolean(scheduledTimestamp) &&
    !isDatetimeError;

/**
 * The sms reducer is used for the sms curation.
 * @param state
 * @param action
 * @returns
 */
export const smsReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case actionTypes.SINGLE_SEND_EDIT_SMS_CURATION:
            const stateAfterEdit = {
                ...state,
                ...action.payload,
            };
            const isComplete = isFormComplete(stateAfterEdit);
            return {
                ...stateAfterEdit,
                isComplete: isComplete,
            };
        case actionTypes.SINGLE_SEND_SET_SMS_CURATION_DATETIME_ERROR:
            return {
                ...state,
                isDatetimeError: action.payload,
                isComplete: isFormComplete({
                    ...state,
                    isDatetimeError: action.payload,
                }),
            };
        case actionTypes.SINGLE_SEND_RESET_CURATION:
            return initialState;
        default:
            return state;
    }
};

export default smsReducer;
