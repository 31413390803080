import { CuredApiPaths } from 'models/enums';
import { PathWithID } from 'models/types';
import { Campaign } from 'domains/campaigns/types';

import { UseQueryOptions } from 'react-query';
import useQuery from '../useQuery';
import QueryKeys from './keys';

const useCampaignById = (campaignId: string, reactQueryOptions?: UseQueryOptions<Campaign, Error>) =>
    useQuery(QueryKeys.CURATION_ID(campaignId), new PathWithID(CuredApiPaths.CAMPAIGNS, campaignId), reactQueryOptions);

export default useCampaignById;
