import { Grid } from '@mui/material';
import Card from 'pages/InsightsPage/dashboards/Card';

const AudienceAndConversionCards = ({
    audience,
    conversion,
    isError,
    isLoading,
}: {
    audience: number;
    conversion: number;
    isError: boolean;
    isLoading: boolean;
}) => (
    <Grid container item spacing={2} xs={12} md={4}>
        <Grid item xs={12} md={6}>
            <Card
                isError={isError}
                isFigureCard
                isLoading={isLoading}
                label="Audience"
                tooltip="Total contacts who were sent the message"
            >
                {audience?.toLocaleString()}
            </Card>
        </Grid>
        <Grid item xs={12} md={6}>
            <Card
                isError={isError}
                isFigureCard
                isLoading={isLoading}
                label="Conversion Value"
                tooltip="Event value as determined in the value calculator"
            >
                ${conversion}
            </Card>
        </Grid>
    </Grid>
);

export default AudienceAndConversionCards;
