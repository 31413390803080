import Calendar from '@mui/icons-material/CalendarToday';
import { UncapitalizeObjectKeys } from '@mui/x-date-pickers/internals/utils/slots-migration';
import {
    DateRangePicker as MUIDateRangePicker,
    DateRangePickerProps,
    DateRangePickerSlotsComponents,
    DateRangePickerSlotsComponentsProps,
} from '@mui/x-date-pickers-pro';
import merge from 'lodash/merge';
import { DateTime } from 'luxon';
import theme from 'theme';

const DateRangePicker = ({
    slotProps: slotPropsFromProps,
    slots: slotsFromProps,
    ...rest
}: DateRangePickerProps<DateTime>) => {
    const slotProps: DateRangePickerSlotsComponentsProps<DateTime> = {
        textField: {
            // @ts-ignore
            'data-testid': 'date-range-picker-field',
            'data-test': 'date-range-picker-field',
            label: 'date',
            InputProps: {
                startAdornment: <Calendar sx={{ mr: 1, cursor: 'grab' }} />,
            },
        },
        desktopPaper: {
            sx: {
                marginTop: 2.125,
                marginBottom: 2.125,
                paddingTop: 2,
            },
        },
        mobilePaper: {
            sx: {
                marginTop: 2.125,
                marginBottom: 2.125,
                paddingTop: 2,
            },
        },
        popper: {
            placement: 'bottom',
            sx: {
                '.MuiPickersArrowSwitcher-button:not(.Mui-disabled) svg': {
                    fill: theme.palette.action.active,
                },
                '.MuiButtonBase-root:hover': {
                    backgroundColor: theme.palette.action.hover,
                    color: theme.palette.primary.main,
                },
                '.MuiPickersDay-root.Mui-selected': {
                    backgroundColor: theme.palette.action.active,
                },
                '.MuiPickersDay-root.Mui-selected:hover': {
                    backgroundColor: theme.palette.action.hover,
                    color: theme.palette.primary.main,
                },
                '.PrivatePickersYear-yearButton:hover': {
                    backgroundColor: theme.palette.action.hover,
                    color: theme.palette.primary.main,
                },
                '.PrivatePickersYear-yearButton.Mui-selected': {
                    backgroundColor: theme.palette.action.active,
                },
                '.PrivatePickersYear-yearButton.Mui-selected:hover': {
                    backgroundColor: theme.palette.action.hover,
                    color: theme.palette.primary.main,
                },
            },
        },
    };

    const slots: UncapitalizeObjectKeys<DateRangePickerSlotsComponents<DateTime>> = {
        fieldSeparator: () => null,
    };

    return (
        <MUIDateRangePicker
            format="MM/dd/yyyy"
            slots={merge(slots, slotsFromProps)}
            slotProps={merge(slotProps, slotPropsFromProps)}
            {...rest}
        />
    );
};

export default DateRangePicker;
