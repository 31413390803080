import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useTemplates from 'hooks/queries/useTemplates';
import useSenderProfiles from 'hooks/queries/useSenderProfiles';
import { ContentState, EditContentPayload, EditMetadataPayload, MetadataState } from 'domains/campaigns/types';
import { SenderProfile, Template } from 'domains/content/types';
import AutocompleteContent from 'domains/core/components/AutocompleteContent';
import { AutocompleteOption } from 'domains/core/components/Autocomplete/Autocomplete';
import Select from 'domains/core/components/Select';
import TextField from 'domains/core/components/TextField';
import InputLabel from 'domains/core/components/InputLabel';

type Props = {
    contentIndex: number;
    contentState: ContentState;
    metadataState: MetadataState;
    editContent: (payload: EditContentPayload) => void;
    editMetadata: (payload: EditMetadataPayload) => void;
};

const CurationContentForm = ({ contentIndex, contentState, metadataState, editContent, editMetadata }: Props) => {
    const { preheader, senderProfileId, subjectLine, templateId } = contentState;

    const [contentInputValue, setContentInputValue] = useState('');
    const [selectedContent, setSelectedContent] = useState<AutocompleteOption<Template>>();

    const { isLoading: isSenderProfilesLoading, data: senderProfiles } = useSenderProfiles();
    const { data: templates } = useTemplates();

    useEffect(() => {
        if (templateId) {
            const templateFound = templates.find((template: Template) => template.id === templateId);
            const templateOption = { index: 1, label: templateFound.name, value: templateFound };

            setSelectedContent(templateOption);
            setContentInputValue(templateOption.label);
        }
    }, [templateId, templates]);

    const clearContentFields = () => {
        editContent({ ...contentState, templateId: null, senderProfileId: null, subjectLine: '', preheader: '' });
    };

    const updateContentFields = (option: AutocompleteOption<Template>) => {
        editContent({
            ...contentState,
            templateId: option.value.id,
            senderProfileId: option.value.senderProfileId,
            subjectLine: option.value.subjectLine ?? '',
            preheader: option.value.preHeader ?? '',
        });
    };

    const onChangeTemplate = (e: any, option: AutocompleteOption<Template>) => {
        if (option) {
            updateContentFields(option);
        } else {
            clearContentFields();
            setSelectedContent(null);
        }
    };

    return (
        <Stack rowGap={4}>
            <AutocompleteContent
                onEmailPreviewClick={() =>
                    contentIndex === metadataState.previewContentId
                        ? editMetadata({ previewContentId: null })
                        : editMetadata({ previewContentId: contentIndex })
                }
                onChange={onChangeTemplate}
                // For some reason the signature of this prop is wrong and does not match the docs.
                // @ts-ignore
                onInputChange={(e: React.SyntheticEvent, inputValue: string, reason: string) => {
                    if (reason === 'clear') {
                        editContent({ ...contentState, templateId: null });
                        editMetadata({ previewContentId: null });
                        setContentInputValue('');
                        setSelectedContent(undefined);
                    } else {
                        setContentInputValue(inputValue);
                    }
                }}
                isPreviewVisible={Boolean(metadataState.previewContentId)}
                textfieldInput={contentInputValue}
                value={selectedContent}
            />
            {/* If the user clears the templateId from the Autocomplete, we don't want to hide the other fields if they have filled them out. */}
            <>
                <Select
                    data-test="curation-content-page-sender-profile-dropdown"
                    data-testid="curation-content-page-sender-profile-dropdown"
                    required
                    label={<InputLabel label="sender profile" labelIconTooltipText="Who is sending this email?" />}
                    menuItemStyles={{ textTransform: 'none' }}
                    onChange={(e: any) => {
                        editContent({ ...contentState, senderProfileId: e.target.value as number });
                    }}
                    value={senderProfileId ?? ''}
                    values={
                        !isSenderProfilesLoading
                            ? senderProfiles?.map((senderProfile: SenderProfile) => ({
                                  value: senderProfile.id,
                                  label: senderProfile.from_address,
                              }))
                            : []
                    }
                    fullWidth
                />
                <TextField
                    counterCurrent={subjectLine.length ?? 0}
                    counterMax={255}
                    data-test="curation-content-page-subject-line"
                    data-testid="curation-content-page-subject-line"
                    fullWidth
                    inputProps={{ maxLength: 255 }}
                    label={
                        <InputLabel
                            label="subject line"
                            labelIconTooltipText="A descriptive overview of your message that gives contacts a reason to open."
                        />
                    }
                    onChange={(e: any) => {
                        editContent({ ...contentState, subjectLine: e.target.value });
                    }}
                    value={subjectLine}
                    withCounter={true}
                />
                <TextField
                    counterCurrent={preheader.length ?? 0}
                    counterMax={100}
                    data-test="curation-content-page-preview"
                    data-testid="curation-content-page-preview"
                    fullWidth
                    inputProps={{ maxLength: 100 }}
                    label={
                        <InputLabel
                            label="preview text"
                            labelIconTooltipText="A quick glimpse into the content of your message that appears after the subject line."
                        />
                    }
                    onChange={(e: any) => {
                        editContent({ ...contentState, preheader: e.target.value });
                    }}
                    value={preheader}
                    withCounter={true}
                />
            </>
        </Stack>
    );
};

export default CurationContentForm;
