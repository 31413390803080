import ContentDetail from 'domains/content/components/ContentDetail/ContentDetail';
import DetailItem from 'domains/core/components/DetailItem/DetailItem';
import useSegment from 'hooks/queries/useSegment';
import { Campaign, Send, SendRecurrenceFrequency } from 'domains/campaigns/types';
import CurationListAndFirstAudience from '../../CurationListAndFirstAudience';

type Props = {
    activeSendId?: number;
    campaign: Campaign;
    firstSendRecurrenceFrequency: SendRecurrenceFrequency;
    send: Send;
    setActiveSendId: (value: number) => void;
};

const SendDetails = ({ activeSendId, campaign, firstSendRecurrenceFrequency, send, setActiveSendId }: Props) => {
    const { data: segmentData } = useSegment(send.segmentId, {
        enabled: !!send,
    });
    // Andy said this is okay for now, but it's a code smell that should be addressed
    const { data: conditionSegmentData } = useSegment(segmentData?.segmentDefinition?.segmentId, {
        enabled: !!segmentData?.segmentDefinition?.segmentId,
    });

    const isChildSend = !!send.parentId;
    const isSendPreviewOpen = activeSendId === send.id;

    if (isChildSend) {
        return (
            <>
                <DetailItem header="Conditional Split" description={conditionSegmentData?.name} />
                <DetailItem header="Meets Criteria" description="exit curation" />
                <ContentDetail
                    header="Does not Meets Criteria"
                    contentId={send.id}
                    firstEmailRecurrenceFrequency={firstSendRecurrenceFrequency}
                    isCampaign
                    isPreviewOpen={isSendPreviewOpen}
                    scheduledTimestamp={send.scheduledTimestamp}
                    timeDelay={send.parentAudienceDelayInterval}
                    onClick={() => setActiveSendId(send.id)}
                />
            </>
        );
    }

    return (
        <>
            <CurationListAndFirstAudience campaign={campaign} onChange={() => setActiveSendId(null)} />
            <ContentDetail
                header="Email"
                contentId={send.id}
                isCampaign
                isPreviewOpen={isSendPreviewOpen}
                recurrenceFrequency={send.recurrenceFrequency}
                scheduledTimestamp={send.scheduledTimestamp}
                onClick={() => setActiveSendId(send.id)}
            />
        </>
    );
};

export default SendDetails;
