import { UseQueryOptions } from 'react-query';
import { CuredApiPaths, BeeConfigType } from 'models/enums';
import { PathWithID } from 'models/types';
import { LandingPage as LandingPageResponse } from 'domains/content/types';
import useQuery from 'hooks/useQuery';
import QueryKeys from './keys';

// The API will return a Landing Page for saved landing pages or a string (the footer as a json string) for the default.
type LandingPage = string | LandingPageResponse;

type UseLandingPageParams = {
    landingPageId: string;
    type?: BeeConfigType;
    reactQueryOptions?: UseQueryOptions<LandingPage, Error>;
};

const useLandingPage = ({ landingPageId, type = BeeConfigType.PAGE, reactQueryOptions }: UseLandingPageParams) =>
    useQuery<LandingPage>(
        QueryKeys.LANDING_PAGE_ID(landingPageId),
        `${
            new PathWithID(
                CuredApiPaths.LANDING_PAGES,
                landingPageId === 'default' ? landingPageId : Number(landingPageId)
            ).url
        }?type=${type}`,
        reactQueryOptions
    );

export default useLandingPage;
