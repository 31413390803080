import React from 'react';
import LoginButton from './LoginButton';
import logoImage from 'assets/images/svg/cured-logo-green.svg';
import { Stack } from '@mui/material';

/**
 * The login page of the app
 *
 * @returnsThe React node created by this component.
 */
const LoginPage = () => (
    <Stack direction="column" justifyContent="center" alignItems="center" rowGap={4} flex={1}>
        <img src={logoImage} alt="cured logo" style={{ height: '8rem' }} />
        <LoginButton />
    </Stack>
);

export default LoginPage;
