import { CuredApiPaths } from '../../models/enums';
import { UseMutationOptions } from 'react-query';
import { PathWithID } from '../../models/types';
import { ApiMethods } from '../../models/enums';
import useMutation from '../useMutation';
import MutationKeys from './keys';

export type UpdateCampaignMutationInput = {
    name: string;
};

const useUpdateCampaign = (
    campaignId: string,
    mutateOptions?: UseMutationOptions<unknown, Error, UpdateCampaignMutationInput>
) =>
    useMutation(
        MutationKeys.UPDATE_CAMPAIGN,
        new PathWithID(CuredApiPaths.CAMPAIGNS, campaignId),
        mutateOptions,
        ApiMethods.PATCH
    );

export default useUpdateCampaign;
