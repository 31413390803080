import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { SupervisedUserCircleOutlined } from '@mui/icons-material';
import { CurationStep } from 'models/enums';
import FormLayout from 'domains/core/components/FormLayout';
import CurationAudienceForm from './CurationAudienceForm';
import { RootState } from 'state';

type Props = {
    selectSegmentId: (state: RootState) => number;
    editSegmentIdActionCreator: ActionCreatorWithOptionalPayload<number, string>;
};

const CurationAudiencePage = ({ selectSegmentId, editSegmentIdActionCreator }: Props) => {
    const dispatch = useDispatch();
    const segmentId = useSelector(selectSegmentId);
    const editAudience = (segmentId: number) => dispatch(editSegmentIdActionCreator(segmentId));
    return (
        <FormLayout
            header="select audience"
            icon={<SupervisedUserCircleOutlined />}
            guidingText="Select an audience from the drop down to choose the contacts you want to connect with in this Curation."
            stepName={CurationStep.SEGMENT}
        >
            <CurationAudienceForm segmentId={segmentId} editAudience={editAudience} />
        </FormLayout>
    );
};

export default CurationAudiencePage;
