import { useEffect, useState } from 'react';
import FilterAutocompleteInput from '../FilterAutocompleteInput';
import { FilterValuesState, SendForFilter } from '../types';

type Props = {
    data: SendForFilter[];
    disabled: boolean;
    filterValues: FilterValuesState;
    labelTooltip?: string;
    setFilterValues: (filters: FilterValuesState) => void;
};

const SendsFilter = ({ data, disabled, filterValues, labelTooltip, setFilterValues }: Props) => {
    const [sendsToDisplay, setSendsToDisplay] = useState(data);

    const selectedSends = filterValues?.selectedSendsValue;

    // Filter sends to display based on selected curations
    useEffect(() => {
        let sends = data;

        if (filterValues?.selectedCurationsValue?.length) {
            const selectedCurationIds = filterValues?.selectedCurationsValue.map((curation) => curation.campaign_id);
            sends = sends?.filter((send: SendForFilter) => selectedCurationIds.includes(send.campaign_id));
        }

        setSendsToDisplay(sends);
    }, [data, filterValues]);

    // Filter selected sends based on available data (which is based on the selected date range)
    useEffect(() => {
        if (!selectedSends) return;

        const selectedSendIds = selectedSends.map((send: SendForFilter) => send.send_id);
        const filteredSends = data?.filter((send: SendForFilter) => selectedSendIds.includes(send.send_id));

        if (selectedSends !== filteredSends) {
            setFilterValues({
                ...filterValues,
                selectedSendsValue: filteredSends,
            });
        }
        // We only want this to run if data (which populates the dropdown options) changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <FilterAutocompleteInput
            disabled={disabled}
            label="Send Name"
            labelTooltip={labelTooltip}
            onChange={(newValue: any) => {
                const filteredSends = newValue.map((value: any) =>
                    sendsToDisplay.find((send: SendForFilter) => send.send_id === value.id)
                );
                setFilterValues({
                    ...filterValues,
                    selectedSendsValue: filteredSends,
                });
            }}
            options={sendsToDisplay}
            selectedValues={filterValues.selectedSendsValue}
        />
    );
};

export default SendsFilter;
