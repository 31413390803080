import * as actionTypes from 'domains/campaigns/state/actionTypes';
import { getCurationNavState } from 'domains/campaigns/state/utils';
import { Action } from 'models/types';
import { MetadataState } from 'domains/campaigns/types';
import { CurationNavState, URLPaths } from 'models/enums';
import { ROUTE_CHANGE } from 'state/constants';

export const initialState: MetadataState = {
    curationName: '',
    isComplete: false,
    // There is currently no tab for this, but adding so that it can inhcerit the isComplete prop from CurationState.
    navState: CurationNavState.PREVIEW,
    previewContentId: null,
    urlPath: URLPaths.CURATIONS,
};

export const isFormComplete = (state: MetadataState) => state.curationName.length >= 3;

export const metadataReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case actionTypes.CONDITIONAL_SPLIT_EDIT_METADATA:
            const stateAfterEdit = {
                ...state,
                curationName: action.payload.curationName ?? state.curationName,
                previewContentId:
                    action.payload.previewContentId !== undefined
                        ? action.payload.previewContentId
                        : state.previewContentId,
            };
            const isComplete = isFormComplete(stateAfterEdit);
            return {
                ...stateAfterEdit,
                navState: getCurationNavState(
                    isComplete,
                    false,
                    URLPaths.CURATIONS_CONDITIONAL_SPLIT,
                    undefined,
                    URLPaths.CURATIONS_CONDITIONAL_SPLIT
                ),
                isComplete: isComplete,
            };
        case ROUTE_CHANGE:
            return {
                ...state,
                navState: getCurationNavState(
                    isFormComplete(state),
                    false,
                    URLPaths.CURATIONS_CONDITIONAL_SPLIT,
                    action,
                    URLPaths.CURATIONS_CONDITIONAL_SPLIT
                ),
            };
        case actionTypes.CONDITIONAL_SPLIT_RESET_CURATION:
            return initialState;
        default:
            return state;
    }
};

export default metadataReducer;
