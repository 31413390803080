import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import Button from 'domains/core/components/Button';

export type DialogProps = {
    cancelButtonText?: string;
    confirmButtonText?: string;
    contentText: string;
    title: string;
};

type Props = {
    dialogProps: DialogProps;
    disabled?: boolean;
    open: boolean;
    onCancel?: () => void;
    onConfirm: () => void;
    onModalClose: () => void;
};
const ConfirmationDialog = ({ dialogProps, disabled, open, onCancel, onConfirm, onModalClose }: Props) => {
    const { cancelButtonText = 'Cancel', confirmButtonText = 'Confirm', contentText, title } = dialogProps;

    return (
        <Dialog maxWidth="sm" fullWidth open={open} onClose={onModalClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Typography variant="body1">{contentText}</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="text" onClick={onCancel ?? onModalClose}>
                    {cancelButtonText}
                </Button>
                <Button disabled={disabled} variant="contained" onClick={onConfirm}>
                    {confirmButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default ConfirmationDialog;
