import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CampaignKey } from 'domains/campaigns/types';
import CampaignPublishPage from 'domains/campaigns/pages/CampaignPublishPage';
import CurationAudiencePage from 'domains/campaigns/pages/CurationAudiencePage';
import CurationContentPage from 'domains/campaigns/pages/CurationContentPage';
import CurationDelayPage from 'domains/campaigns/pages/CurationDelayPage';
import CurationNamePage from 'domains/campaigns/pages/CurationNamePage';
import CurationSchedulePage from 'domains/campaigns/pages/CurationSchedulePage';
import { CampaignNode, CampaignNodeType, ContentNode, DelayNode } from 'domains/campaigns/state/createCampaignSlice';
import {
    selectContentFieldsAll,
    selectContentFieldsByNodeId,
    selectDelayErrorMessage,
    selectDelayFieldsByNodeId,
    selectMetadataFields,
    selectNodeIdsByType,
    selectNodesByType,
    selectScheduleFields,
    selectScheduleFieldsAll,
    selectSegmentId,
    selectTimestampsBeforeAndAfterDelay,
    selectStateByCampaignKey,
} from 'domains/campaigns/state/selectors';
import {
    editContentFieldsByNodeId,
    editDelayFieldsByNodeId,
    editName,
    editScheduleFields,
    editSegmentId,
    setContentPreviewNodeId,
    setScheduleDatetimeError,
} from 'domains/campaigns/state/emailSeries';
import { getCampaignRoute } from 'domains/campaigns/state/utils';

const campaignKey = CampaignKey.EMAIL_SERIES;

const getCampaignRouteDynamically = (nodes: CampaignNode[], nodeId: string, isDelay?: Boolean) => {
    const path = getCampaignRoute({ campaignKey, nodes, nodeId });

    return (
        <Route
            exact
            key={nodeId}
            path={path}
            render={(props) =>
                isDelay ? (
                    <CurationDelayPage
                        nodeId={nodeId}
                        editDelayActionCreator={editDelayFieldsByNodeId}
                        selectScheduleFields={selectScheduleFields(campaignKey)}
                        selectDelayFields={selectDelayFieldsByNodeId(campaignKey, nodeId)}
                        selectTimestampsBeforeAndAfterDelay={selectTimestampsBeforeAndAfterDelay(campaignKey, nodeId)}
                        selectDelayErrorMessage={selectDelayErrorMessage(campaignKey, nodeId)}
                        {...props}
                    />
                ) : (
                    <CurationContentPage
                        nodeId={nodeId}
                        selectContentFields={selectContentFieldsByNodeId(campaignKey, nodeId)}
                        selectMetadataFields={selectMetadataFields(campaignKey)}
                        editContentActionCreator={editContentFieldsByNodeId}
                        setPreviewContentNodeIdActionCreator={setContentPreviewNodeId}
                        {...props}
                    />
                )
            }
        />
    );
};

const TimeDelayNav = () => {
    const contentNodes = useSelector(selectNodesByType<ContentNode>(campaignKey, CampaignNodeType.CONTENT_NODE));
    const delayNodes = useSelector(selectNodesByType<DelayNode>(campaignKey, CampaignNodeType.DELAY_NODE));
    const { nodes } = useSelector(selectStateByCampaignKey(CampaignKey.EMAIL_SERIES)).campaignGraph;

    const routeSegment = getCampaignRoute({ campaignKey, nodes, nodeId: '267a4b2a-52f2-53a8-a4fc-baa20186d88e' });
    const routeSchedule = getCampaignRoute({ campaignKey, nodes, nodeId: '30facd24-a154-52f6-8f1c-1a49e0e12ab2' });
    const routePublish = getCampaignRoute({ campaignKey, nodes, nodeId: 'bdb900b6-5423-56fb-ada4-f8750b5b4110' });

    return (
        <Switch>
            <Route
                exact
                path={routeSegment}
                render={(props) => (
                    <CurationAudiencePage
                        {...props}
                        editSegmentIdActionCreator={editSegmentId}
                        selectSegmentId={selectSegmentId(campaignKey)}
                    />
                )}
            />
            <Route
                exact
                path={routeSchedule}
                render={(props) => (
                    <CurationSchedulePage
                        allowRecurrence
                        editScheduleFieldsActionCreator={editScheduleFields}
                        selectScheduleFields={selectScheduleFields(campaignKey)}
                        setScheduleDatetimeErrorActionCreator={setScheduleDatetimeError}
                        {...props}
                    />
                )}
            />
            {contentNodes?.map(({ id }) => getCampaignRouteDynamically(nodes, id, false))}
            {delayNodes?.map(({ id }) => getCampaignRouteDynamically(nodes, id, true))}
            <Route
                exact
                path={routePublish}
                render={(props) => (
                    <CampaignPublishPage
                        selectMetadataFields={selectMetadataFields(campaignKey)}
                        selectSegmentId={selectSegmentId(campaignKey)}
                        selectOrderedContentNodeIds={selectNodeIdsByType(campaignKey, CampaignNodeType.CONTENT_NODE)}
                        selectScheduleFieldsAll={selectScheduleFieldsAll(campaignKey)}
                        selectContentFieldsAll={selectContentFieldsAll(campaignKey)}
                        actionCreatorSetContentPreviewNodeId={setContentPreviewNodeId}
                        {...props}
                    />
                )}
            />
            {/* Will render if none of the other routes match */}
            <Route
                render={(props) => (
                    <CurationNamePage
                        {...props}
                        editNameActionCreator={editName}
                        selectMetadataFields={selectMetadataFields(campaignKey)}
                    />
                )}
            />
        </Switch>
    );
};

export default TimeDelayNav;
