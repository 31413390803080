/* React Router */
export const ROUTE_CHANGE = '@@router/LOCATION_CHANGE';

/* App visual constants */
export const TOGGLE_LEFT_NAV_PANEL_VISIBILITY = 'TOGGLE_LEFT_NAV_PANEL_VISIBILITY';
export const MINIMIZE_LEFT_NAV_PANEL = 'MINIMIZE_LEFT_NAV_PANEL';
export const MAXIMIZE_LEFT_NAV_PANEL = 'MAXIMIZE_LEFT_NAV_PANEL';
export const ON_LEAVE_CURATION_WORKFLOW = 'ON_LEAVE_CURATION_WORKFLOW';
export const SET_LEFT_NAV_PANEL_BUTTON_VISIBILITY = 'SET_LEFT_NAV_PANEL_BUTTON_VISIBILITY';

/* Cured data constants */
export const SET_ONE_COLUMN_INFO_TYPE = 'SET_ONE_COLUMN_INFO_TYPE';
export const TOGGLE_COLUMN_INFO_SHOULD_INCLUDE = 'TOGGLE_COLUMN_INFO_SHOULD_INCLUDE';
