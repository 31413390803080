import { UseQueryOptions } from 'react-query';
import { CuredApiPaths, BeeConfigType } from 'models/enums';
import { BeeConfig } from 'domains/content/types';
import useQuery from '../useQuery';

const useBeeConfig = (type: BeeConfigType, reactQueryOptions?: UseQueryOptions<BeeConfig, Error>) => {
    const path =
        type === BeeConfigType.EMAIL
            ? CuredApiPaths.CONTENT_EDITOR_CONFIG_EMAIL
            : CuredApiPaths.CONTENT_EDITOR_CONFIG_PAGE;

    return useQuery<BeeConfig>('content-editor-config', path, reactQueryOptions);
};

export default useBeeConfig;
