import TextField from 'domains/core/components/TextField';

type Props = {
    name: string;
    handleNameChange: (name: string) => void;
    withClickAway?: boolean;
};

const CustomCampaignNameInput = ({ name, handleNameChange, withClickAway }: Props) => (
    <TextField
        counterCurrent={name.length ?? 0}
        counterMax={255}
        inputProps={{ maxLength: 255 }}
        label="Name"
        value={name}
        width={withClickAway ? '50%' : '100%'}
        withClickAway={withClickAway}
        withCounter
        onChange={(e: any) => handleNameChange(e.target.value)}
    />
);

export default CustomCampaignNameInput;
