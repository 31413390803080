import { UseQueryOptions } from 'react-query';
import useQuery from 'hooks/useQuery';

export type ContactFieldTopValue = {
    value: string;
    displayValue: string;
};

/**
 * Get the top values for a given valuesRef.
 * The values ref comes from the data returned from GET /segments/filters.
 * See https://react-query.tanstack.com/reference/useQuery for docs on the options and return object.
 * @param valuesRef
 * @param reactQueryOptions the options for the react-query hook
 * @returns a react-query object
 */
const useContactFieldsTopValues = (
    valuesRef: string,
    reactQueryOptions?: UseQueryOptions<ContactFieldTopValue[], Error>
) => useQuery(valuesRef, valuesRef, reactQueryOptions);

export default useContactFieldsTopValues;
