import { UseQueryOptions } from 'react-query';
import { Segment } from 'domains/segments/types';
import { CuredApiPaths } from 'models/enums';
import useQuery from '../useQuery';
import QueryKeys from './keys';

const useSegments = (reactQueryOptions?: UseQueryOptions<Segment[], Error>) =>
    useQuery(QueryKeys.SEGMENTS, CuredApiPaths.SEGMENTS, reactQueryOptions);

export default useSegments;
