import Card from 'pages/InsightsPage/dashboards/Card';
import BarGraph from 'pages/InsightsPage/visualizations/BarGraph';
import { StatsDataProps } from '../../ValueOverviewDashboard';

type Props = {
    data: StatsDataProps['events'];
    isError: boolean;
    isLoading: boolean;
};

const EventsBarGraphCard = ({ data, isError, isLoading }: Props) => (
    <Card
        isError={isError}
        isLoading={isLoading}
        label="Events"
        tooltip="Comparison of the forecasted, estimated, and actual events"
    >
        <BarGraph data={data} leftLegendLabel="number of appointments" useSingleColor={false} />
    </Card>
);

export default EventsBarGraphCard;
