import { Typography } from '@mui/material';
import { DateTime } from 'luxon';
import theme from 'theme';
import { DelayFields, ScheduleFields } from 'domains/campaigns/types';
import Alert from 'domains/core/components/Alert';
import { getTimezone } from 'utils';
import { getIsRecurrenceContinuous, getIsRecurrenceEnabled } from 'domains/campaigns/utils';

type Props = {
    delayFields: DelayFields;
    nextEmailTimestamp: string;
    recurrenceFrequency: ScheduleFields['recurrenceFrequency'];
};

const sharedTypographyStyles = {
    component: 'span',
    fontSize: '16px',
};

const DelayInfo = ({ delayFields, nextEmailTimestamp, recurrenceFrequency }: Props) => {
    if (!nextEmailTimestamp) return;

    const renderScheduledInfo = () => {
        const { delay, unit } = delayFields;

        const dateTime = DateTime.fromISO(nextEmailTimestamp);
        const month = dateTime.toFormat('LLLL');
        const dayAndYear = dateTime.toFormat('dd, yyyy');
        const time = `${dateTime.toFormat('h:mm a')} ${getTimezone()}`;

        const delayUnitText = ` ${delay} ${unit} after previous send`;
        const atTimeText = (
            <>
                <Typography color="primary" {...sharedTypographyStyles}>
                    at{' '}
                </Typography>
                {time}
            </>
        );

        if (getIsRecurrenceContinuous(recurrenceFrequency)) {
            return delayUnitText;
        }

        if (getIsRecurrenceEnabled(recurrenceFrequency)) {
            return (
                <>
                    {delayUnitText} {atTimeText}
                </>
            );
        }

        return (
            <>
                {' '}
                on {month} {dayAndYear} {atTimeText}
            </>
        );
    };

    return (
        <Alert
            elevation={0}
            severity="info"
            variant="outlined"
            style={{ borderColor: theme.palette.info.main, borderWidth: '2px' }}
        >
            Email is scheduled to send
            <Typography color="success.dark" {...sharedTypographyStyles}>
                {renderScheduledInfo()}
            </Typography>
        </Alert>
    );
};

export default DelayInfo;
