import { Close } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import Button from 'domains/core/components/Button';

type Props = {
    tooltipTitle: string;
    onClick: () => void;
    onMouseLeave?: () => void;
    onMouseOver?: () => void;
};

const DeleteButton = ({ tooltipTitle, onClick, onMouseLeave, onMouseOver }: Props) => (
    <Tooltip title={tooltipTitle}>
        <Button
            onClick={onClick}
            onMouseLeave={onMouseLeave}
            onMouseOver={onMouseOver}
            sx={{
                height: 'unset',
                minWidth: 'unset',
                padding: '0',
                svg: {
                    height: '20px',
                    width: '20px',
                },
            }}
        >
            <Close />
        </Button>
    </Tooltip>
);

export default DeleteButton;
