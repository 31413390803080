import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { CampaignChannels, ContentFields, DelayFields, DelayUnit, EmailSendInput, ScheduleFields } from '../types';
import {
    getDelayedSendTime,
    getFormattedParentAudienceDelayInterval,
} from '../pages/CurationsPage/CampaignDetailsPage/timeFormatting';

export enum CampaignNodeType {
    META_DATA_NODE = 'META_DATA_NODE',
    SEGMENT_NODE = 'SEGMENT_NODE',
    CONTENT_NODE = 'CONTENT_NODE',
    CONDITIONAL_SPLIT_NODE = 'CONDITIONAL_SPLIT_NODE',
    SCHEDULE_NODE = 'SCHEDULE_NODE',
    DELAY_NODE = 'DELAY_NODE',
    PUBLISH_NODE = 'PUBLISH_NODE',
}

type Node = {
    id: string;
    sendId?: number;
    sendIndex?: number;
    type: CampaignNodeType;
};

type ConditionalSplitNode = Node & {
    type: CampaignNodeType.CONDITIONAL_SPLIT_NODE;
};

export type ContentNode = Node & {
    type: CampaignNodeType.CONTENT_NODE;
    isAddOnSendClicked: boolean;
};

export type MetaDataNode = Node & {
    type: CampaignNodeType.META_DATA_NODE;
    name: string;
    contentPreviewNodeId: string;
};

export type ScheduleNode = Node & {
    type: CampaignNodeType.SCHEDULE_NODE;
    isRecommendedTimeEnabled: boolean;
    dateTimeError: boolean;
};

export type SegmentNode = Node & {
    type: CampaignNodeType.SEGMENT_NODE;
};

export type DelayNode = Node & {
    type: CampaignNodeType.DELAY_NODE;
    delay: number;
    unit: DelayUnit;
};

export type PublishNode = Node & {
    type: CampaignNodeType.PUBLISH_NODE;
    isPublishClicked: boolean;
};

export type CampaignNode =
    | ConditionalSplitNode
    | ContentNode
    | MetaDataNode
    | PublishNode
    | ScheduleNode
    | SegmentNode
    | DelayNode;

type Edge = {
    id: string;
    source: string;
    target: string;
};

export type CampaignGraph = {
    nodes: CampaignNode[];
    edges: Edge[];
};

export type CampaignTree = {
    sends: EmailSendInput[];
};

export type CampaignState = {
    campaignGraph: CampaignGraph;
    campaignTree: CampaignTree;
};

export type EditContentPayload = {
    contentFields: Partial<ContentFields>;
    nodeId: string;
};

export type EditDelayPayload = {
    delayFields: Partial<DelayFields>;
    nodeId: string;
};

const updateScheduledFields = (
    startNode: CampaignNode,
    state: CampaignState,
    startTimestamp: string,
    parentAudienceDelayInterval?: string
) => {
    let currentNode: CampaignNode | undefined = startNode;
    let currentSendTimestamp: string | undefined = startTimestamp;
    let delay: string | undefined = parentAudienceDelayInterval;

    while (currentNode) {
        const currentNodeId: string = currentNode.id;

        // Find the edge starting from the current node
        const currentEdge = state.campaignGraph.edges.find((edge: Edge) => edge.source === currentNodeId);

        if (!currentEdge) break; // Stop the loop if there's no more connected edge

        currentNode = state.campaignGraph.nodes.find((node) => node.id === currentEdge.target);

        // Stop the loop if we're not in a content node
        if (currentNode?.type !== CampaignNodeType.CONTENT_NODE) break;

        const currentSend = state.campaignTree.sends[currentNode.sendIndex];

        if (currentSend) {
            currentSend.scheduledTimestamp = currentSendTimestamp;
            currentSend.parentAudienceDelayInterval = delay;
        }
    }
};

const createCampaignSlice = (initialState: CampaignState, name: string) =>
    createSlice({
        name,
        initialState,
        reducers: {
            addSend(state) {
                // Push new send data to the campaignTree
                state.campaignTree.sends.push({
                    channel: CampaignChannels.EMAIL,
                    name: '',
                    parentIndex: state.campaignTree.sends.length - 1,
                    preHeader: '',
                    senderProfileId: null,
                    subjectLine: '',
                    templateId: null,
                });

                // Generate a new delay node and a new content node
                const newDelayNode: DelayNode = {
                    id: uuidv4(),
                    type: CampaignNodeType.DELAY_NODE,
                    delay: null,
                    unit: null,
                };

                const newContentNode: ContentNode = {
                    id: uuidv4(),
                    type: CampaignNodeType.CONTENT_NODE,
                    sendIndex: state.campaignTree.sends.length - 1,
                    isAddOnSendClicked: false,
                };

                // Get the publish node
                const publishNode = state.campaignGraph.nodes.find(
                    (node) => node.type === CampaignNodeType.PUBLISH_NODE
                );

                // Find the edge that was connected to the publish node
                const edgeToPublish = state.campaignGraph.edges.find((edge) => edge.target === publishNode.id);

                // Find the last content node before the publish node
                const lastContentNode = state.campaignGraph.nodes.find(
                    (node) => node.id === edgeToPublish.source && node.type === CampaignNodeType.CONTENT_NODE
                ) as ContentNode;

                // Update the isAddOnSendClicked property of the last content node
                if (lastContentNode) {
                    lastContentNode.isAddOnSendClicked = true;
                }

                // Find the index of the publish node
                const publishIndex = state.campaignGraph.nodes.findIndex((node) => node.id === publishNode.id);

                // Push the new delay node and the new content node to the campaignGraph nodes at the correct position
                state.campaignGraph.nodes.splice(publishIndex, 0, newDelayNode, newContentNode);

                // Redirect this edge to the new delay node
                edgeToPublish.target = newDelayNode.id;

                // Connect the new content node and the publish node
                const newEdge = {
                    id: uuidv4(),
                    source: newContentNode.id,
                    target: publishNode.id,
                };

                // Connect the new delay node and the new content node
                const newDelayEdge = {
                    id: uuidv4(),
                    source: newDelayNode.id,
                    target: newContentNode.id,
                };

                // Push these new edges to the campaignGraph edges
                state.campaignGraph.edges.push(newDelayEdge, newEdge);
            },
            deleteSend(state, action: PayloadAction<number>) {
                const sendIndex = action.payload;

                // Ensure sendIndex is within range
                if (sendIndex < 0 || sendIndex >= state.campaignTree.sends.length) {
                    throw new Error(`sendIndex:${sendIndex} is not within range for send removal`);
                }

                // Remove the send data from the campaignTree
                state.campaignTree.sends.splice(sendIndex, 1);

                // Identify the corresponding content node first, then delay node
                // (sendIndex doesn't exist in delay node)
                const contentNode = state.campaignGraph.nodes.find(
                    (node) => node.type === CampaignNodeType.CONTENT_NODE && node.sendIndex === sendIndex
                ) as ContentNode;

                const delayNodeEdge = state.campaignGraph.edges.find((edge) => edge.target === contentNode?.id);
                const delayNode = state.campaignGraph.nodes.find(
                    (node) => node.id === delayNodeEdge.source && node.type === CampaignNodeType.DELAY_NODE
                ) as DelayNode;

                // Ensure delayNode and contentNode were found
                if (!delayNode || !contentNode) {
                    throw new Error(`Corresponding nodes were not found for send removal`);
                }

                // Remove the delay and content nodes
                state.campaignGraph.nodes = state.campaignGraph.nodes.filter(
                    (node) => node.id !== delayNode.id && node.id !== contentNode.id
                );

                // Identify the edges to be removed and the edge to be modified
                const edgeFromDelayNode = state.campaignGraph.edges.find((edge) => edge.source === delayNode.id);
                const edgeFromContentNode = state.campaignGraph.edges.find((edge) => edge.source === contentNode.id);
                const edgeToDelayNode = state.campaignGraph.edges.find((edge) => edge.target === delayNode.id);

                // Ensure the edges were found
                if (!edgeFromDelayNode || !edgeFromContentNode || !edgeToDelayNode) {
                    throw new Error(`Corresponding edges were not found for send removal`);
                }

                // Remove the edges from the delay and content nodes
                state.campaignGraph.edges = state.campaignGraph.edges.filter(
                    (edge) => edge.id !== edgeFromDelayNode.id && edge.id !== edgeFromContentNode.id
                );

                // Modify the edge that pointed to the delay node to point to the node that followed the content node
                edgeToDelayNode.target = edgeFromContentNode.target;

                // Handle the case where there are no sends remaining in the campaign
                if (state.campaignTree.sends.length === 0) {
                    state.campaignGraph.edges = state.campaignGraph.edges.filter((edge) => edge !== edgeToDelayNode);
                }

                // Adjust sendIndexes of nodes and sends that come after the removed one
                state.campaignGraph.nodes.forEach((node) => {
                    if ('sendIndex' in node && node.sendIndex > sendIndex) {
                        node.sendIndex -= 1;
                    }
                });

                // Find the publish node
                const publishNode = state.campaignGraph.nodes.find(
                    (node) => node.type === CampaignNodeType.PUBLISH_NODE
                );

                // Find the edge that was connected to the publish node
                const edgeToPublish = state.campaignGraph.edges.find((edge) => edge.target === publishNode.id);

                // Find the last content node before the publish node
                const lastContentNode = state.campaignGraph.nodes.find(
                    (node) => node.id === edgeToPublish.source && node.type === CampaignNodeType.CONTENT_NODE
                ) as ContentNode;

                // Update the isAddOnSendClicked property of the last content node
                if (lastContentNode) {
                    lastContentNode.isAddOnSendClicked = true;
                }
            },
            editContentFieldsByNodeId: (state, action: PayloadAction<EditContentPayload>) => {
                const { nodeId, contentFields } = action.payload;
                const { isAddOnSendClicked, preHeader, senderProfileId, subjectLine, templateId } = contentFields;

                const contentNode = state.campaignGraph.nodes.find((node) => node.id === nodeId) as ContentNode;

                if (!contentNode) {
                    // handle error: no content node with the given nodeId found
                    return;
                }

                contentNode.isAddOnSendClicked = isAddOnSendClicked ?? contentNode.isAddOnSendClicked;

                const send = state.campaignTree.sends[contentNode.sendIndex];

                send.preHeader = preHeader ?? send.preHeader;
                send.senderProfileId = senderProfileId;
                send.subjectLine = subjectLine ?? send.subjectLine;
                send.templateId = templateId;
            },
            editDelayFieldsByNodeId: (state, action: PayloadAction<EditDelayPayload>) => {
                const { nodeId, delayFields } = action.payload;
                const { delay, unit } = delayFields;

                const delayNode = state.campaignGraph.nodes.find(
                    (node) => node.type === CampaignNodeType.DELAY_NODE && node.id === nodeId
                ) as DelayNode;

                if (!delayNode) {
                    // handle error: no delay node with the given nodeId found
                    return;
                }

                const updatedDelay = delay ?? delayNode.delay;
                const updatedUnit = unit ?? delayNode.unit;

                delayNode.delay = updatedDelay;
                delayNode.unit = updatedUnit;

                if (updatedDelay && updatedUnit) {
                    // Get the edge that connects to the delay node
                    const connectedEdge = state.campaignGraph.edges.find((edge) => edge.target === delayNode.id);

                    // Get the node before the delay node
                    const beforeNode = state.campaignGraph.nodes.find((node) => node.id === connectedEdge.source);

                    const beforeSend = state.campaignTree.sends[beforeNode.sendIndex];

                    // Calculate the next send timestamp by adding the delay to the current send timestamp
                    const nextSendTimestamp = getDelayedSendTime(beforeSend.scheduledTimestamp, delayNode);

                    const parentAudienceDelayInterval = getFormattedParentAudienceDelayInterval(
                        delayNode.delay,
                        delayNode.unit
                    );

                    updateScheduledFields(delayNode, state, nextSendTimestamp, parentAudienceDelayInterval);
                }
            },
            editName(state, action: PayloadAction<string>) {
                const metadataNode = state.campaignGraph.nodes.find(
                    (node) => node.type === CampaignNodeType.META_DATA_NODE
                ) as MetaDataNode;
                metadataNode.name = action.payload;
            },
            editScheduleFields(state, action: PayloadAction<Partial<ScheduleFields>>) {
                const {
                    isRecommendedTimeEnabled,
                    scheduledTimestamp,
                    recurrenceFrequency,
                    scheduledEndTimestamp,
                } = action.payload;

                const scheduleNode = state.campaignGraph.nodes.find(
                    (node) => node.type === CampaignNodeType.SCHEDULE_NODE
                ) as ScheduleNode;

                const send = state.campaignTree.sends[scheduleNode.sendIndex];
                const updatedTimestamp = scheduledTimestamp ?? send.scheduledTimestamp;

                scheduleNode.isRecommendedTimeEnabled =
                    isRecommendedTimeEnabled ?? scheduleNode.isRecommendedTimeEnabled;
                send.scheduledTimestamp = updatedTimestamp;
                send.recurrenceFrequency = recurrenceFrequency ?? send.recurrenceFrequency;
                send.scheduledEndTimestamp = scheduledEndTimestamp ?? send.scheduledEndTimestamp;

                updateScheduledFields(scheduleNode, state, updatedTimestamp);
            },
            editSegmentId(state, action: PayloadAction<number>) {
                const segmentNode = state.campaignGraph.nodes.find(
                    (node) => node.type === CampaignNodeType.SEGMENT_NODE
                ) as SegmentNode;
                const send = state.campaignTree.sends[segmentNode.sendIndex];
                send.segmentId = action.payload;
            },
            resetState: () => initialState,
            setState: (state, action: PayloadAction<CampaignState>) => action.payload,
            setContentPreviewNodeId(state, action: PayloadAction<string>) {
                const metadataNode = state.campaignGraph.nodes.find(
                    (node) => node.type === CampaignNodeType.META_DATA_NODE
                ) as MetaDataNode;
                metadataNode.contentPreviewNodeId = action.payload;
            },
            setPublish(state, action: PayloadAction<boolean>) {
                const publishNode = state.campaignGraph.nodes.find(
                    (node) => node.type === CampaignNodeType.PUBLISH_NODE
                ) as PublishNode;
                publishNode.isPublishClicked = action.payload;
            },
            setScheduleDatetimeError(state, action: PayloadAction<boolean>) {
                const scheduleNode = state.campaignGraph.nodes.find(
                    (node) => node.type === CampaignNodeType.SCHEDULE_NODE
                ) as ScheduleNode;
                scheduleNode.dateTimeError = action.payload;
            },
        },
    });

export default createCampaignSlice;
