import { Box, Link, Typography } from '@mui/material';
import useSendById from 'hooks/queries/useSendById';
import { SegmentDefinition, SegmentEngagement } from 'domains/segments/types';
import MatchTypeChip from '../MatchTypeChip';

type Props = {
    details: SegmentEngagement[];
    matchType: SegmentDefinition['matchType'];
    shouldShowMatchType: boolean;
};

// TODO: refactor this, especially UserAction and CurationName
const EngagementDetails = ({ details, matchType, shouldShowMatchType }: Props) => (
    <>
        {details.map((eng: SegmentEngagement, index) => {
            const delivered = eng.name === 'received_sends' && eng.operator === 'contains';
            const didNotDeliver = eng.name === 'received_sends' && eng.operator === 'does not contain';
            const clicked = eng.name === 'clicked_sends' && eng.operator === 'contains';
            const didNotClick = eng.name === 'clicked_sends' && eng.operator === 'does not contain';
            const opened = eng.name === 'opened_sends' && eng.operator === 'contains';
            const didNotOpen = eng.name === 'opened_sends' && eng.operator === 'does not contain';
            const UserAction = () => (
                <>
                    {delivered && ' did receive '}
                    {didNotDeliver && ' did not receive '}
                    {clicked && ' did click '}
                    {didNotClick && ' did not click '}
                    {opened && ' did open '}
                    {didNotOpen && ' did not open '}
                </>
            );
            const CurationName = () => {
                const { data, isError, isLoading } = useSendById(eng.value);
                if (isError) {
                    return null;
                }
                if (isLoading) {
                    return <>Loading....</>;
                }
                return <>{data.name}</>;
            };
            const emailText = eng.segmentLinkValue ? 'in the email ' : 'the email ';

            return (
                <Box data-testid="filter" key={index} textAlign="right">
                    {(shouldShowMatchType || index !== 0) && <MatchTypeChip matchType={matchType} />}
                    <Typography component="span" color="secondary" sx={{ textWrap: 'wrap' }}>
                        Contact Activity
                    </Typography>
                    <Typography component="span" sx={{ textWrap: 'wrap' }}>
                        <UserAction />
                    </Typography>
                    {eng.segmentLinkValue && (
                        <Typography component="span" color="secondary" sx={{ textWrap: 'wrap' }}>
                            <Link
                                href={eng.segmentLinkValue}
                                color="inherit"
                                target="_blank"
                                rel="noopener"
                                underline="hover"
                            >
                                {eng.segmentLinkValue}
                            </Link>{' '}
                        </Typography>
                    )}
                    <Typography component="span">{emailText}</Typography>
                    <Typography component="span" color="secondary" sx={{ textWrap: 'wrap' }}>
                        <CurationName />
                    </Typography>
                </Box>
            );
        })}
    </>
);

export default EngagementDetails;
