import { useEffect } from 'react';
import ReactFlow, { Edge, FitViewOptions, Node, useReactFlow } from 'reactflow';
import EndNode, { Props as EndNodeProps } from './EndNode';
import LegacyNavigationNode, { Props as LegacyNavigationNodeProps } from './LegacyNavigationNode';
import NavigationNode, { Props as NavigationNodeProps } from './NavigationNode';
import { NodeType } from './types';
import 'reactflow/dist/style.css';

const nodeTypes = {
    [NodeType.END_NODE]: (props: EndNodeProps) => <EndNode {...props} />,
    [NodeType.NAVIGATION]: (props: NavigationNodeProps) => <NavigationNode {...props} />,
    [NodeType.LEGACY_NAVIGATION]: (props: LegacyNavigationNodeProps) => <LegacyNavigationNode {...props} />,
};

const fitViewOptions: FitViewOptions = {
    duration: 500,
};

type Props = {
    nodes: Node<any>[];
    edges: Edge<any>[];
    isFitView?: boolean;
};

const Graph = ({ nodes, edges, isFitView }: Props) => {
    const { fitView } = useReactFlow();

    useEffect(() => {
        if (isFitView) {
            fitView(fitViewOptions);
        }
    }, [isFitView, fitView]);

    return (
        <ReactFlow
            nodeTypes={nodeTypes}
            nodes={nodes}
            edges={edges}
            maxZoom={1}
            minZoom={0.125}
            onInit={() => fitView(fitViewOptions)}
        />
    );
};
export default Graph;
