import { Box, CSSObject } from '@mui/material';
import { EmailOutlined } from '@mui/icons-material';

type EmailIconButtonProps = {
    onClick: () => void;
};

const EmailIconButton = ({ onClick }: EmailIconButtonProps) => {
    const buttonStyles: CSSObject = {
        position: 'relative',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            cursor: 'pointer',
        },
    };
    return (
        <Box sx={buttonStyles} onClick={onClick}>
            <EmailOutlined />
        </Box>
    );
};

export default EmailIconButton;
