import { UseMutationOptions } from 'react-query';
import { stringifyUrl } from 'query-string';
import { ApiMethods } from '../../models/enums';
import useMutation from '../useMutation';
import MutationKeys from './keys';

type UnsubscribeMutationInput = {
    status: 'unsubscribed';
};

type UseUnsubscribeParams = {
    campaignId: string;
    clientId: string;
    curedId: string;
    jobId: string;
};

/**
 * Unsubscribes the user associated with the campaignId, clientId, curedId, jobId.
 * @param campaignId The id of the campaign associated with the unsubscribing user.
 * @param clientId The id of the client associated with the unsubscribing user.
 * @param curedId The id of the cured associated with the unsubscribing user.
 * @param jobId The id of the campaign associated with the unsubscribing user.
 * @param mutateOptions react-query useMutation option
 * @returns the react-query useMutation results
 * See https://react-query-v3.tanstack.com/reference/useMutation for options and return value
 */
const useUnsubscribe = (
    { campaignId, clientId, curedId, jobId }: UseUnsubscribeParams,
    mutateOptions?: UseMutationOptions<unknown, Error, UnsubscribeMutationInput>
) => {
    const url = stringifyUrl({
        url: `/clients/${clientId}/contacts/${curedId}/pubs/all`,
        query: { ...(campaignId && { campaignId: campaignId }), ...(jobId && { jobId: jobId }) },
    });
    return useMutation<UnsubscribeMutationInput>(MutationKeys.UNSUBSCRIBE, url, mutateOptions, ApiMethods.PUT, false);
};

export default useUnsubscribe;
