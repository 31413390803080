import { ResponsivePie } from '@nivo/pie';
import { graphColors } from 'colors';
import CustomTooltip from '../CustomTooltip';
import NoDataMessage from '../NoDataMessage';

type DataItem = {
    id: string | number;
    label: string;
    value: number;
    percentage?: string;
};

export type PieChartProps = {
    data: DataItem[];
};

const PieChart = ({ data }: PieChartProps) => {
    if (!data?.length) return <NoDataMessage />;

    const getFormattedLabel = (datum: any) => {
        if (typeof datum.value !== 'number') return datum.value;

        const formattedLabel = Intl.NumberFormat('en-US', {
            notation: 'compact',
            maximumFractionDigits: 1,
        }).format(datum.value);

        return formattedLabel;
    };

    const dataWithColors = data.map((datum, index) => ({
        ...datum,
        color: graphColors[index],
    }));

    const legendData = dataWithColors.slice(0, 9).map((datum) => ({
        ...datum,
        label: datum.label.length > 14 ? `${datum.label.slice(0, 14)}...` : datum.label,
    }));

    const renderTooltip = (datum: any) => (
        <CustomTooltip
            color={datum.color}
            label={datum.label}
            value={`${datum.value.toLocaleString()} (${datum.data.percentage}%)`}
        />
    );

    return (
        <div style={{ display: 'flex', height: '400px', width: '100%' }}>
            <ResponsivePie
                arcLinkLabel={(datum) => getFormattedLabel(datum)}
                arcLinkLabelsColor={{ from: 'color' }}
                arcLinkLabelsDiagonalLength={8}
                arcLinkLabelsStraightLength={8}
                arcLinkLabelsSkipAngle={5}
                arcLinkLabelsThickness={2}
                borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                borderWidth={1}
                colors={{ datum: 'data.color' }}
                data={dataWithColors}
                enableArcLabels={false}
                legends={[
                    {
                        anchor: 'right',
                        data: legendData,
                        direction: 'column',
                        justify: false,
                        translateX: 90,
                        translateY: 0,
                        itemsSpacing: 4,
                        itemWidth: 10,
                        itemHeight: 18,
                        itemOpacity: 1,
                        symbolSize: 16,
                        symbolShape: 'circle',
                    },
                ]}
                margin={{ top: 40, right: 200, bottom: 70, left: 70 }}
                tooltip={({ datum }) => renderTooltip(datum)}
            />
        </div>
    );
};

export default PieChart;
