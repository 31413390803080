import { Dispatch, SetStateAction } from 'react';
import FileUploadDialog from 'domains/core/components/FileUploadDialog';
import ErrorSnackbar from 'domains/core/components/Snackbars/ErrorSnackbar';
import SuccessSnackbar from 'domains/core/components/Snackbars/SuccessSnackbar';
import { Typography } from '@mui/material';
import InfoSnackbar from 'domains/core/components/Snackbars/InfoSnackbar';

export type Props = {
    dataCategory: string;
    fileUploadError: string;
    fileUploadFinish: boolean;
    fileUploadFinishMessage: string;
    handleUpload: (uploader: any, s3Path: string) => void;
    isFileUploadDialogOpen: boolean;
    isFileUploading: boolean;
    setDataCategory: Dispatch<SetStateAction<string>>;
    setIsFileUploadDialogOpen: (isOpen: boolean) => void;
    setIsFileUploadError: Dispatch<SetStateAction<string>>;
    setIsFileUploadFinish: Dispatch<SetStateAction<boolean>>;
    snackbarKey: number;
};

const UploadProgressMessage: React.FC = () => {
    const uploadMessage = 'File upload in progress';
    return (
        <Typography color="textSecondary" component="span" variant="body2">
            {uploadMessage}
        </Typography>
    );
};

const ContactsUploadDialog = ({
    dataCategory,
    fileUploadError,
    fileUploadFinish,
    fileUploadFinishMessage,
    handleUpload,
    isFileUploadDialogOpen,
    isFileUploading,
    setDataCategory,
    setIsFileUploadDialogOpen,
    setIsFileUploadError,
    snackbarKey,
}: Props) => (
    <>
        <FileUploadDialog
            dataCategory={dataCategory}
            handleClose={() => setIsFileUploadDialogOpen(false)}
            handleUpload={handleUpload}
            handleUploadError={(error) => setIsFileUploadError(error)}
            open={isFileUploadDialogOpen}
            setDataCategory={setDataCategory}
        />
        {isFileUploading && (
            <InfoSnackbar key="file-upload-info" successMessage={<UploadProgressMessage />} autoHideDuration={null} />
        )}
        {fileUploadError && <ErrorSnackbar key={snackbarKey} errorMessage={fileUploadError} />}
        {fileUploadFinish && <SuccessSnackbar key={snackbarKey} successMessage={fileUploadFinishMessage} />}
    </>
);

export default ContactsUploadDialog;
