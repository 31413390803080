import { Send } from 'domains/campaigns/types';
import { CuredApiPaths } from 'models/enums';
import { UseQueryOptions } from 'react-query';
import useQuery from '../useQuery';
import QueryKeys from './keys';

export type UseAppSendsResponse = {
    id: Send['id'];
    name: Send['name'];
};

const useAppSendsValueAssumption = (reactQueryOptions?: UseQueryOptions<UseAppSendsResponse[], Error>) => {
    const path = CuredApiPaths.APP_SENDS_VALUE_ASSUMPTION;
    return useQuery(QueryKeys.APP(path), path, reactQueryOptions);
};

export default useAppSendsValueAssumption;
