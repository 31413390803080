import { AutoAwesome } from '@mui/icons-material';
import FormLayout from 'domains/core/components/FormLayout';

const CustomCampaignPublishStep = () => (
    <FormLayout
        header="last step!"
        stepName="publish"
        icon={<AutoAwesome />}
        guidingText="Take this moment to confirm the details of your Curation. You can always cancel upcoming sends, but cannot take back ones that have been sent."
    >
        <></>
    </FormLayout>
);

export default CustomCampaignPublishStep;
