import { useEffect, useState } from 'react';
import FilterAutocompleteInput from '../../../filtering/FilterAutocompleteInput';
import { CampaignForFilter, FilterValuesState, SendForFilter } from '../../../filtering/types';

type Props = {
    data: CampaignForFilter[];
    disabled: boolean;
    filterValues: FilterValuesState;
    setFilterValues: (filters: FilterValuesState) => void;
};

const CurationsFilter = ({ data, disabled, filterValues, setFilterValues }: Props) => {
    const [curationsToDisplay, setCurationsToDisplay] = useState([]);

    const selectedCurations = filterValues?.selectedCurationsValue;

    // Filter curations to display based on selected sends
    useEffect(() => {
        let curations = data;

        if (filterValues?.selectedSendsValue?.length) {
            const selectedSendCampaignIds = filterValues?.selectedSendsValue.map(
                (send: SendForFilter) => send.campaign_id
            );
            curations = curations?.filter((curation: CampaignForFilter) =>
                selectedSendCampaignIds.includes(curation.campaign_id)
            );
        }

        setCurationsToDisplay(curations);
    }, [data, filterValues]);

    // Filter selected curations based on available data (which is based on the selected date range)
    useEffect(() => {
        if (!selectedCurations) return;

        const selectedCurationIds = selectedCurations.map((curation: CampaignForFilter) => curation.campaign_id);
        const filteredCurations = data?.filter((curation: CampaignForFilter) =>
            selectedCurationIds.includes(curation.campaign_id)
        );

        if (selectedCurations !== filteredCurations) {
            setFilterValues({
                ...filterValues,
                selectedCurationsValue: filteredCurations,
            });
        }
        // We only want this to run if data (which populates the dropdown options) changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <FilterAutocompleteInput
            disabled={disabled}
            label="Curation Name"
            labelTooltip="Filter options for Curations adjust based on the selected date and send filters."
            onChange={(newValue: any) => {
                const filteredCurations = newValue.map((value: any) =>
                    curationsToDisplay.find((curation: CampaignForFilter) => curation.campaign_id === value.id)
                );
                setFilterValues({
                    ...filterValues,
                    selectedCurationsValue: filteredCurations,
                });
            }}
            options={curationsToDisplay}
            selectedValues={selectedCurations}
        />
    );
};

export default CurationsFilter;
