import { useEffect, useState } from 'react';
import { FilterValuesState } from 'pages/InsightsPage/dashboards/filtering/types';
import FilterAutocompleteInput from 'pages/InsightsPage/dashboards/filtering/FilterAutocompleteInput';
import { ValueFilterReturnData } from '../useGetFilterData';

type Props = {
    data: ValueFilterReturnData['specialties'];
    disabled: boolean;
    filterValues: FilterValuesState;
    setFilterValues: (filters: FilterValuesState) => void;
};

const SpecialtiesFilter = ({ data, disabled, filterValues, setFilterValues }: Props) => {
    const [specialtiesToDisplay, setSpecialtiesToDisplay] = useState(data);

    const selectedSpecialties = filterValues?.selectedSpecialtiesValue;

    // Filter specialties to display based on selected send
    useEffect(() => {
        let specialties = data;

        if (filterValues?.selectedSendsValue?.length) {
            const selectedSendSpecialties = filterValues?.selectedSendsValue.map((send) => send.specialty);
            specialties = specialties?.filter((specialty: string) => selectedSendSpecialties.includes(specialty));
        }

        setSpecialtiesToDisplay(specialties);
    }, [data, filterValues]);

    return (
        <FilterAutocompleteInput
            disabled={disabled}
            label="Specialty Name"
            onChange={(newValue: any) => {
                const filteredSpecialties = newValue.map((value: any) =>
                    specialtiesToDisplay.find((specialty: string) => specialty === value.label)
                );
                setFilterValues({
                    ...filterValues,
                    selectedSpecialtiesValue: filteredSpecialties,
                });
            }}
            options={specialtiesToDisplay}
            selectedValues={selectedSpecialties}
        />
    );
};

export default SpecialtiesFilter;
